import {createContext} from "react";
import IAppObj from "../type/context/IAppObj";
import IElementGalleryCommentLine from "../type/gallery/IElementGalleryCommentLine";

import ICtxGalleryDashboard from "../type/context/ICtxGalleryDashboard";
/*
    The problem here is that you need to help TypeScript understand the types of the objects you're dealing with.
    https://blog.logrocket.com/how-to-use-react-context-typescript/
 */
const defaultContext: ICtxGalleryDashboard = {
    imageVisitCounter: 0,
    storeOrigCommentObj: null,
    setStoreOrigCommentObj: () => {},
    storeNewCommentInfoObj: null,
    setStoreNewCommentInfoObj: () => {},
    storeDeleteCommentObj: null,
    setStoreDeleteCommentObj: () => {},
    storeModeTabValue: 0,
    setStoreModeTabValue: () => {},
    storeLatestCommentNgObj: null,
    setStoreLatestCommentNgObj: () => {},
};
const CtxGalleryDashboard = createContext<ICtxGalleryDashboard>(defaultContext);
//const AppObjContext = createContext(null);

export default CtxGalleryDashboard;
