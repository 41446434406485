import RestApiService from "./RestApiService";
import {bin2hex, afdObjectToUrlString} from './../../util/Util';
import {API_URL} from "./RestApiService";
import IAmocourseRegInsert from "../../type/amocourse/IAmocourseRegInsert";
import IAxiosPostData from "../../type/axios/IAxiosPostData";
import IRQOutputList from "../../type/rq/IRQOutputList";
import IRQOutputSingle from "../../type/rq/IRQOutputSingle";
import IRQQueryKey from "../../type/rq/IRQQueryKey";
import {useQuery} from "react-query";
import {rqOptionStaticObj} from "../rqOptionStaticObj";
import linkDictionary from "../../linkDictionary";

class RestApiAmocourseService extends RestApiService {

    constructor() {
        super();
    }

    static setAmocoursePreregLogInsert(afdSessionId: string,
                                       afdCourseEventId: number,
                                       afdUserId: number,
                                       afdFieldName: string,
                                       afdFieldValue: string
    ): string {
        const myUrl = new URL(API_URL);

        if (afdFieldName.includes('email')) {
            afdFieldValue = bin2hex(encodeURIComponent(afdFieldValue));
        }

        myUrl.searchParams.append("custom_call", 'set_amocourse_form_prereg_log_insert');
        myUrl.searchParams.append("session_id", afdSessionId);
        myUrl.searchParams.append("course_event_id", afdCourseEventId.toString());
        myUrl.searchParams.append("user_id", afdUserId.toString());
        myUrl.searchParams.append("field_name", afdFieldName);
        myUrl.searchParams.append("field_value", afdFieldValue);

        return myUrl.href;
    }

    static setNewsletterSubscriberInsert(afdSessionId: string,
                                         afdUserId: number,
                                         email: string,
                                         afdMessage: string
    ): string {
        email = bin2hex(encodeURIComponent(email));
        if (typeof afdUserId === 'undefined') {
            afdUserId = 0;
        }
        //BUGFIX: URL za długi = wykluczono USER_ID
        //session_id="+afdSessionId+"&
        //timeout: insertAndEmailingTimeout
        var insertAndEmailingTimeout = 7000;
        const myUrl = new URL(API_URL);

        myUrl.searchParams.append("custom_call", 'set_newsletter_subscriber_insert');
        myUrl.searchParams.append("email", email);
        myUrl.searchParams.append("message", afdMessage);

        return myUrl.href;

    }

    static sNewsPopStat(afdSessionId: string,
                        afdUserId: number,
                        afdCtrlParamPageItemid: number,
                        popupStatus: string,
    ): string {
        if (typeof afdUserId === 'undefined') {
            afdUserId = 0;
        }

        const myUrl = new URL(API_URL);

        myUrl.searchParams.append("custom_call", 'sNewsPopStat');
        myUrl.searchParams.append("session_id", afdSessionId);
        myUrl.searchParams.append("user_id", afdUserId.toString());
        myUrl.searchParams.append("ctrl_param_page_item_id", afdCtrlParamPageItemid.toString());
        myUrl.searchParams.append("popup_status", popupStatus);

        return myUrl.href;
    }

    /*Przetwarzaj wszystkie kEY obiektu*/
    static setUserRegistrationInsert(sendDataObject: IAmocourseRegInsert
    ): string {

        const myUrl = new URL(API_URL);

        Object.keys(sendDataObject).forEach(
            // @ts-ignore
            (key: string) => myUrl.searchParams.append(key, sendDataObject[key])
        );

        return myUrl.href;
    }

    /*Przetwarzaj wszystkie kEY obiektu*/
    static setUserRegistrationPackageInsert(
        postObj: any,
    ): IAxiosPostData {
        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: postObj,
        };
        return postDataObj;
    }

    static getGlobalFormFieldValueUrl(fieldGroup: string,
                                      fieldName: string,
                                      objectValue: string | number,
                                      wName?: string
    ): string {
        const myUrl = new URL(API_URL);

        /* Ponieważ  źródło wartośsci to wewnętrzna implementacja REST API - robimy to na poziomie klienta, ale nie wyżej */
        if (fieldGroup == 'profile_user_dict' ||
            fieldGroup == 'profile_user_newsletter'
        ) {
            myUrl.searchParams.append("user_dict_select", '1');
            myUrl.searchParams.append("wname", wName ? wName : '');
        } else {
            // TODO - zmienić z AMOCOIURSE - uniwersalna metoda
            myUrl.searchParams.append("amocourse_field_value_select", '1');
        }

        myUrl.searchParams.append("field_group", fieldGroup);
        myUrl.searchParams.append("field_name", fieldName);
        myUrl.searchParams.append("oname", objectValue.toString());

        return myUrl.href;
    }

    static getGlobalFormFieldValueRQ(fieldGroup: string,
                                     fieldName: string,
                                     objectValue: string | number,
    ): IRQOutputSingle {
        let rqQueryKey: IRQQueryKey = [
            'getGlobalFormFieldValueRQ',
            fieldGroup,
            fieldName,
            objectValue
        ];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiAmocourseService.getGlobalFormFieldValueUrl(
                        queryKey[1],
                        queryKey[2],
                        queryKey[3]
                    )
                ),
            rqOptionStaticObj
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }


    /*    static (sendDataObject) {
            var requestParamObj = {
                url : "https://www.akademia-fotografii-dzieciecej.pl//components/php_restapi/index.php?" + afdObjectToUrlString(sendDataObject),
                method: "GET"
            };


            return (requestParamObj.url);
        }*/
    static setGlobalFormFieldUpdate(fieldGroup: string,
                                    fieldName: string,
                                    whereName: string,
                                    whereValue: string,
                                    newValueName: string): string {

        const myUrl = new URL(API_URL);

        if (fieldGroup == 'profile_user_dict' ||
            fieldGroup == 'profile_user_newsletter'
        ) {
            myUrl.searchParams.append("user_dict_insert", '1');
        } else {
            // TODO - zmienić z AMOCOIURSE - uniwersalna metoda
            myUrl.searchParams.append("global_form_field_update", '1');
        }

        myUrl.searchParams.append("field_group", fieldGroup);
        myUrl.searchParams.append("field_name", fieldName);
        myUrl.searchParams.append("wname", whereName);
        myUrl.searchParams.append("oname", whereValue);
        myUrl.searchParams.append("vname", newValueName);

        return myUrl.href;
    }

    /* Dodaj nową wartość dla pola dla całkiem nowego MESSAGE_ID */
    static setGlobalFormFieldInsert(fieldGroup: string,
                                    fieldName: string,
                                    whereName: string,
                                    whereValue: string,
                                    newValueName: string): string {

        const myUrl = new URL(API_URL);

        myUrl.searchParams.append("global_form_field_insert", '1');

        myUrl.searchParams.append("field_group", fieldGroup);
        myUrl.searchParams.append("field_name", fieldName);
        myUrl.searchParams.append("wname", whereName);
        myUrl.searchParams.append("oname", whereValue);
        myUrl.searchParams.append("vname", newValueName);

        return myUrl.href;
    }

    static setUserPromotionPackageCourseReplace(
        postObj: any,
    ): IAxiosPostData {

        let postDataObj: IAxiosPostData = {
            url: '',
            postData: postObj,
        };
        return postDataObj;
    }


    /* Musi być osobny CALL bo po stronie BACKEND złożone przetwarzanie */
    static getAmocoursePriceArraySelect(afdCourseEventId: number,
                                        afdUserId: number,
    ): string {

        const myUrl = new URL(API_URL);

        myUrl.searchParams.append("get_amocourse_price_info", '1');
        myUrl.searchParams.append("course_event_id", afdCourseEventId.toString());
        myUrl.searchParams.append("user_id", afdUserId.toString());

        return myUrl.href;
    }

    static getAmocoursePriceArraySelectRQ(afdCourseEventId: number,
                                          afdUserId: number,
    ): IRQOutputSingle {
        let rqQueryKey: IRQQueryKey = [
            'getAmocoursePriceArraySelectRQ',
            afdCourseEventId,
            afdUserId
        ];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiAmocourseService.getAmocoursePriceArraySelect(
                        queryKey[1],
                        queryKey[2],
                    )
                ),
            {
                ...rqOptionStaticObj,
            }
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }


    static getAmocoursePromocodePostRegExcInfo(afdCourseEventId: number,
                                               afdUserId: number,
                                               afdPromocode: string,
    ) {
        afdPromocode = encodeURIComponent(afdPromocode);

        const myUrl = new URL(API_URL);

        myUrl.searchParams.append("getAmocoursePromocodePostRegExcInfo", '1');
        myUrl.searchParams.append("course_event_id", afdCourseEventId.toString());
        myUrl.searchParams.append("user_id", afdUserId.toString());
        myUrl.searchParams.append("promocode", afdPromocode);

        return myUrl.href;
    }


    //https://www.akademia-fotografii-dzieciecej.pl/components/php_restapi/index.php?query_call=get_amocourse_form_reg_provider_assoc&user_id=714&course_id=10778
    static getAmocourseFormConfirmProviderAssoc(reg_id: number,
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("query_call", 'get_amocourse_form_confirm_provider_assoc');
        myUrl.searchParams.append("reg_id", reg_id.toString());
        return (myUrl.href);
    }


    static setPrzelewy24Post(
        postObj: any,
    ): IAxiosPostData {


        let postDataObj: IAxiosPostData = {
            url: linkDictionary.PAYMENT_GATE_ENDPOINT_URL,
            postData: postObj,
        };
        return postDataObj;
    }


}

export default RestApiAmocourseService;
