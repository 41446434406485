import React from "react";

const ElementGlobalModuleTitleH3 = ({controllerInfoObj}) => {
    let styleString = {
        "display": controllerInfoObj.css_display
    };
    return (
        <div className="divModuleTitleTable">
            <div className="divModuleTitleTableRow">
                <div className="divModuleTitleTableCell">

                    <h3 className="afd_h3_kurs"> {controllerInfoObj.module_title} </h3>

                </div>
                <div className="divModuleTitleTableCell">
                    <div><a className="afd_tooltip" href="#"> <img style={styleString}
                                                                   src="/images/icon/32x32/Info2.png" alt="image"/>
                        <span className="afd_tooltip_content"> <img style={styleString}
                                                                    src={controllerInfoObj.instructor_image_path}
                                                                    alt="image"/> <strong>{controllerInfoObj.tooltip_title}</strong> {controllerInfoObj.tooltip_content} </span>
                    </a></div>
                </div>
            </div>
        </div>
    );
}

export default ElementGlobalModuleTitleH3;
