import React from "react";

import SoftBox   from "../components/SoftBox";
import SoftTypography from "../components/SoftTypography";
import RokContainerTitle from "./RokContainerTitle";

const ElementGlobalModuleTitleTooltip = ({controllerInfoObj}) => {
    let styleString = {
        "display": controllerInfoObj.css_display
    };
    return (

        <>
            <RokContainerTitle
                containerCard={controllerInfoObj.container_card}
            >{controllerInfoObj.module_title}</RokContainerTitle>
            {
                (controllerInfoObj.tooltip_content !== "") ?
                    <SoftBox mb={2}>
                        <SoftTypography variant="body2" color="text">
                            <strong>{controllerInfoObj.tooltip_title}</strong>
                            {controllerInfoObj.tooltip_content}
                        </SoftTypography>
                    </SoftBox>
                    :
                    ''
            }
            </>
     /*   <div className="divModuleTitleTable">
            <div className="divModuleTitleTableRow">
                <div className="divModuleTitleTableCell">
                    <h2 className="afd_h2_kurs">{containerRow.module_title}</h2>
                </div>
                <div className="divModuleTitleTableCell">
                    <div style={styleString}>
                        <a className="afd_tooltip" href="#">
                            <img
                            src="/images/icon/32x32/FAQ.png"
                            alt="image"/>
                            <span
                                className="afd_tooltip_content"> <img style={styleString}
                                                                      src={containerRow.instructor_image_path}
                                                                      alt="image"/>
                                                    <strong>{containerRow.tooltip_title}</strong> {containerRow.tooltip_content}
                        </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>*/
    );
}

export default ElementGlobalModuleTitleTooltip;
