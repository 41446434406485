const ElementHomeworkCurrentRedirect = React.lazy(() => import("./ElementHomeworkCurrentRedirect")); // page_item_id: 262

import React, {useEffect, useState} from "react";
import {lazy, Suspense} from "react";
import AfdLoadingFullpage from "../../AfdLoadingFullpage";
import Emptybox from "../../emptybox/Emptybox";
import AfdContainerCard from "../../AfdContainerCard";

import useCHContainerData from "../../../hook/useCHContainerData";
import useCHGetContextSoftUI from "../../../hook/useCHGetContextSoftUI";
import IPropContainer from "../../../type/IPropContainer";
import ICtxGlobalConfig from "../../../type/context/ICtxGlobalConfig";

import Grid from "@mui/material/Grid";
import SoftBox from "../../../components/SoftBox";
import SoftAvatar from "../../../components/SoftAvatar";
import SoftTypography from "../../../components/SoftTypography";
import ICtxReducerState from "../../../type/reducer_state/ICtxReducerState";
import {getChapterGridChapterHeaderTSX, getChapterIssueSelectIconTSX} from "../../../util/UtilReactElementChapter";


/*ng-click="psedudoScrollNextPage()"*/
//{containerRow.post_count}



export interface IElementHomeworkCurrent {
    user_id: number;
    group_name: string;
    group_id: number;
    chapter_issue_code: string;
    chapter_issue_title: string;
    chapter_issue_url: string;
    issue_duration_minute: number;
    forum_name: string;
    forum_image_path: string;
    forum_sequence_number: number;
    chapter_header: string;
    chapter_title: string;
    course_image_src: string;
    description: string;
    instructor_letter: string;
    instructor_full_name: string;
    uni_semester: string;
}

const GetClubLearnHomeworkCurrent = ({
                                         controllerInfoObj: controllerInfoObj,
                                         userInfoObj: userInfoObj,
                                         paramObj: paramObj
                                     }: IPropContainer) => {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const components: any = {
        ElementHomeworkCurrentRedirect,

    }
    let ElementComponent: React.JSXElementConstructor<any>;
    if (controllerInfoObj.element1CamelName !== undefined) {
        ElementComponent = components[controllerInfoObj.element1CamelName];
        var [elementTopicObjList, getElementTopicObjList, containerInfoObj, setContainerInfoObj] = useCHContainerData<IElementHomeworkCurrent, any>(controllerInfoObj,
            {
                user_id: userInfoObj.user_id,
            }
        );


    }

    function getContainerContent() {
        let row = containerInfoObj;
        if (controllerInfoObj.containerName === 'CONTAINER_CLUB_HOMEWORK_CURRENT') {
            return (
                <>
                    {
                        elementTopicObjList.map((elementTopicObj: IElementHomeworkCurrent, index: number) => {
                            return (
                                <>
                                    <SoftBox
                                        display="flex"
                                        flexDirection={"row"}
                                        justifyContent={"space-between"}
                                        alignItems="center"
                                        px={{xs: 1, sm: 2}}
                                        py={{xs: 1, sm: 2}}
                                        sx={{
                                            "&:hover": {
                                                cursor: "pointer",
                                            },
                                        }}
                                    >

                                        <SoftBox
                                            display="flex"
                                            flexDirection={"row"}
                                            lineHeight={0}
                                        >
                                            <SoftBox
                                                mr={2}
                                            ><SoftAvatar
                                                src={elementTopicObj.forum_image_path}
                                                alt={"Przejdź do treści rozdziału..."}
                                                size="xl"
                                                variant="rounded"
                                                bgColor={"secondary"}
                                                sx={{p: 1}}
                                            /></SoftBox>
                                            <SoftBox
                                                display="flex"
                                                flexDirection={"column"}
                                                mb={1} lineHeight={0}
                                            >
                                                <SoftTypography variant="h4"
                                                                textTransform="capitalize"
                                                >
                                                    {elementTopicObj.chapter_issue_title}
                                                </SoftTypography>
                                                <SoftBox><SoftTypography
                                                    variant="button"
                                                    fontWeight={"medium"}
                                                >
                                                    {elementTopicObj.forum_sequence_number}. {elementTopicObj.chapter_title}
                                                    {/*{elementTopicObj.description}*/}
                                                </SoftTypography></SoftBox>

                                            </SoftBox>
                                        </SoftBox>
                                        <SoftBox
                                            display="flex"
                                            flexDirection={"column"}
                                            justifyContent={"center"}
                                        >
                                            {getChapterIssueSelectIconTSX(
                                                true,
                                                elementTopicObj.chapter_issue_url,
                                                false,
                                                globalConfigParamObj)
                                            }
                                            <SoftTypography
                                                variant={"caption"}
                                                /*color={"textSecondary"}*/
                                                fontWeight={"light"}
                                                display={"block"}
                                                sx={{
                                                    paddingTop: "0.2em",
                                                }}
                                            >
                                                ~ {elementTopicObj.issue_duration_minute} minut
                                            </SoftTypography>
                                        </SoftBox>
                                    </SoftBox>

                                </>
                            );
                        })
                        }
                </>
            );
        }
    }

    return (
        <>
            <Suspense fallback={<AfdLoadingFullpage
                componentCategory="AfdController_Suspense"
                controllerInfoObj={controllerInfoObj}
            />}></Suspense>
            {
                (elementTopicObjList?.length > 0 && containerInfoObj) ?
                    (
                        <AfdContainerCard
                            controllerInfoObj={controllerInfoObj}
                            userInfoObj={userInfoObj}
                            paramObj={paramObj}
                            elementObjList={elementTopicObjList}
                        >
                            {getContainerContent()}
                        </AfdContainerCard>
                    ) : (<Emptybox
                        controllerInfoObj={controllerInfoObj}
                        userInfoObj={userInfoObj}
                        paramObj={paramObj}
                        elementObjList={elementTopicObjList}
                    />)
            }
        </>
    );
}

export default GetClubLearnHomeworkCurrent;
