import React, {useEffect} from 'react';
import {FormikHelpers, useFormik} from "formik";
import AfdCheckboxSelect from "./AfdCheckboxSelect";
import IJos4Menu from "../../../type/IJos4Menu";
import {
    setFixedNavbar,
    setGeneralDispatch, setMiniSidenav,
    setOpenConfigurator,
    setTransparentSidenav
} from "../../../redux_reducer/util/dispatchShortcut";
import {useCHGetContextSoftUI} from "../../../context/CtxSoftUIGlobalSetupByRdx";
import IRedirectionInfo from "../../../type/IRedirectionInfo";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import ReactSelect from "react-select";
import ICtxGlobalConfig from "../../../type/context/ICtxGlobalConfig";



interface IProp {
    menuItemObj: IJos4Menu;
    redirectionObj?: IRedirectionInfo;
}

function AfdConfiguratorApp({
                                menuItemObj,
                                redirectionObj,
                            }: IProp) {
    /* This is taken from context and Provider: ProviderGlobalConfigCtxWithReducer tag
                    src/context/CtxSoftUIGlobalSetupByRdx/ProviderGlobalConfigCtxWithReducer.tsx
            adds the dispatch function to the context so that we can call it */
    // @ts-ignore
    const [controlerParamObj, rdxConfigDispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const {
        openConfigurator,
        transparentSidenav,
        miniSidenav,
        fixedNavbar,
        sidenavColor,
    } =
        controlerParamObj;
    const appFormikForm = useFormik(
        {
            initialValues: {
                isUIAdminDiag: false
            },
            children: undefined,
            component: undefined,
            initialErrors: undefined,
            initialTouched: undefined,
            innerRef: undefined,
            isInitialValid: undefined,
            onSubmit<Values>(values: Values, formikHelpers: FormikHelpers<Values>): void | Promise<any> {
                return undefined;
            },
        });

    /* Formik handleChange */
    useEffect(() => {
        setGeneralDispatch(rdxConfigDispatchFunction, appFormikForm.values.isUIAdminDiag, "IS_UI_ADMIN_DIAG");
    }, [
        appFormikForm.values.isUIAdminDiag
    ]);

    /* Shourtcut again
        ??? necessary to pass call down to components ?
        *  */
    const handleTransparentSidenav = () => setTransparentSidenav(rdxConfigDispatchFunction, true);
    const handleWhiteSidenav = () => setTransparentSidenav(rdxConfigDispatchFunction, false);
    const handleMiniSidenav = () => setMiniSidenav(rdxConfigDispatchFunction, !miniSidenav);
    const handleFixedNavbar = () => setFixedNavbar(rdxConfigDispatchFunction, !fixedNavbar);

    return (
        <>
            <SoftBox pt={1} pb={2}>
                <SoftTypography variant="h5">AppObj:</SoftTypography>
                <pre>
                 <AfdCheckboxSelect
                     id="isUIAdminDiag"
                     name="isUIAdminDiag"
                     title="ADMIN: Diagnostyka"
                     checked={appFormikForm.values.isUIAdminDiag}
                     handleChecked={appFormikForm.handleChange}
                 />
              </pre>

            </SoftBox>

        </>
    );
}

export default AfdConfiguratorApp;
