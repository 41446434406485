import {createContext} from "react";
import IAppObj from "../type/context/IAppObj";

/*
    The problem here is that you need to help TypeScript understand the types of the objects you're dealing with.
    https://blog.logrocket.com/how-to-use-react-context-typescript/
 */
const defaultContext: IAppObj = {
        userId: 0,
        menuId: 0,
        isDiagnostics: true,
        sessionId: '',
        facebookAppId: '',
        gaTrackingId: ''
    };
const CtxAppObj = createContext<IAppObj>(defaultContext);
//const AppObjContext = createContext(null);

export default CtxAppObj;
