
/* Soft UI Dashboard PRO React reducer
- add NEXT value to the state object - this is like global_param dictionary
- type = parameter name to set
 */
export default  function rdxConfigReducerFunction(state: any, action: { type: any; value: any; }): any {
    switch (action.type) {
        case "MINI_SIDENAV": {
            return { ...state, miniSidenav: action.value };
        }
        case "TRANSPARENT_SIDENAV": {
            return { ...state, transparentSidenav: action.value };
        }
        case "SIDENAV_COLOR": {
            return { ...state, sidenavColor: action.value };
        }
        case "TRANSPARENT_NAVBAR": {
            return { ...state, transparentNavbar: action.value };
        }
        case "FIXED_NAVBAR": {
            return { ...state, fixedNavbar: action.value };
        }
        case "OPEN_CONFIGURATOR": {
            return { ...state, openConfigurator: action.value };
        }
        case "DIRECTION": {
            return { ...state, direction: action.value };
        }
        case "LAYOUT": {
            return { ...state, layout: action.value };
        }
        case "IS_MOBILE": {
            /*debugLog("rdxConfigReducerFunction: IS_MOBILE: action.value: ", action.value);*/
            return { ...state, isMobile: action.value };
        }
        case "IS_UI_ADMIN_DIAG": {
            /*debugLog("Hooray! IS_UI_ADMIN_DIAG:" + action.value);*/
            return { ...state, isUIAdminDiag: action.value };
        }


        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}
