import SoftAlert from "../../components/SoftAlert";
import Icon from "@mui/material/Icon";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import React from "react";

export function AfdAlertErrorForm(props: { templateTSX: JSX.Element | React.ReactNode }) {
    return <SoftAlert color="error">
        <Icon fontSize="medium"
        >warning</Icon>&nbsp;&nbsp;
        <SoftBox
            display="flex"
            flexDirection="column"
        ><SoftTypography
            variant="caption"
            fontWeight="bold"
            color="white"
            display="inline"
            pb={1}
        >
            {props.templateTSX}
        </SoftTypography>
            {/*{formSubmissionResult?.data?.result_error && <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="white"
                        display="inline"
                    >{formSubmissionResult.data.result_error}
                    </SoftTypography>
                }*/}
        </SoftBox>
    </SoftAlert>;
}

export default AfdAlertErrorForm;
