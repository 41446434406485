import React, {useEffect, useState} from "react";

// @mui material components
import useCHSourcererData from "../../hook/useCHSourcererData";
import CtxAppObj from "../../context/CtxApp";
import Menu from "@mui/material/Menu";
import AfdNotificationItem from "../../examples/Items/AfdNotificationItem";
import IUserInfo from "../../type/IUserInfo";
import IElementMyAcademyNotifLine from "../../type/my_academy/IElementMyAcademyNotifLine";
import RestApiCommonService from "../../service/restapi/RestApiCommonService";
import ICtxClubDouble from "../../type/context/ICtxClubDouble";
import CtxClubDouble from "../../context/CtxClubDouble";
import IconButton from "@mui/material/IconButton";
import {navbarIconButton} from "../controller/get_club_dashboard_navbar/styles";
import SoftTypography from "../../components/SoftTypography";
import SoftBox from "../../components/SoftBox";
/*import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';*/
import RestApiGalleryService from "../../service/restapi/RestApiGalleryService";
import {toast} from "react-toastify";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../hook/useCHGetContextSoftUI";
import {getSpecNotificationIconObjList, ISpecNotificationIconListObjList} from "../../util/UtilReactElement";
import DataTable from "../../examples/Tables/DataTable";
import SoftAvatar from "../../components/SoftAvatar";
import {getClubDatatableUserProfile, getDatatableClubButtonGoToPost} from "../../util/UtilReactElementDatatable";

interface IProps {
    userInfoObj: IUserInfo;
    pageItemGroup: "comment" | "post" | "instructor";
    templateModule?: "appbar" | "user_profile";
}



export function getNotificationListTitle(notificationListTitle: string, notificationListSubtitle: string) {
    return <SoftBox
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        px={2}
        py={1}
    ><SoftTypography
        variant={"h4"}
        textTransform={"capitalize"}
    >{notificationListTitle}</SoftTypography>
        <SoftTypography
            display={"block"}
            variant={"caption"}
        >{notificationListSubtitle}</SoftTypography></SoftBox>;
}

function GetMyAcademyNotificationList(
    {
        userInfoObj,
        pageItemGroup,
        templateModule = "appbar",
    }: IProps
) {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const ctxGalleryDouble: ICtxClubDouble = React.useContext(CtxClubDouble);
    const handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void = (event: any) => setOpenMenu(event.currentTarget);

    /* Zmknięcie listy */
    const handleNotifClose: (event: React.MouseEvent<HTMLElement>) => void =
        (event: React.MouseEvent<HTMLElement>) => {
            console.log(event.currentTarget.id);
            /* Odnzacz wszystkie pozycje MY Academy
            - tylko jezeli istnieją nowe
            * */
            if (ctxGalleryDouble.storeMyAcademyNotifCounter[pageItemGroup] > 0) {

                RestApiCommonService.getPromise(RestApiGalleryService.setMyAcademyTablePerfUserMarkAllUrl(
                    specNotificationIconListObjList[pageItemGroup].sqlParamObj.perf_user_id,
                    pageItemGroup,
                )).then((response) => {
                    console.log(response);
                    ctxGalleryDouble.setStoreMyAcademyNotifCounter({
                        ...ctxGalleryDouble.storeMyAcademyNotifCounter,
                        [pageItemGroup]: 0,
                    });
                    toast.info("powiadomienia odznaczone... ");

                }).catch((error) => {
                    console.log(error);
                });
            }

            setOpenMenu(false);
        }
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    let specNotificationIconListObjList: ISpecNotificationIconListObjList = getSpecNotificationIconObjList(
        userInfoObj,
        globalConfigParamObj.isMobile
    );

    /* Przelicz */
    let sqlParamObj = {
        ...specNotificationIconListObjList[pageItemGroup]['sqlParamObj'],
        page_item_group_join: specNotificationIconListObjList[pageItemGroup]['page_item_group_array'].map((table_name, index) => {
                return `'${table_name}'`
            }
        ).join(','),
    }

    let light: boolean = false;
    const appObj = React.useContext(CtxAppObj);
    let elementAddObjList: IElementMyAcademyNotifLine[] = [];
    var [elementNotificationObjList] = useCHSourcererData<IElementMyAcademyNotifLine>(
        'get_my_academy_notification_list',
        // @ts-ignore
        specNotificationIconListObjList[pageItemGroup].sqlColumnName,
        sqlParamObj,
    );


    /* Aktualizuj licznk COUNTER na podstawie pobranych danych */
    useEffect(() => {
            if (elementNotificationObjList.length > 0) {
                ctxGalleryDouble.setStoreMyAcademyNotifCounter({
                        ...ctxGalleryDouble.storeMyAcademyNotifCounter,
                        [pageItemGroup]: getNotifMarkNullObjList(elementNotificationObjList).length,
                    }
                );
            }
        },
        [getNotifMarkNullObjList(elementNotificationObjList).length,
            pageItemGroup
        ]
    );


    function getIconButtonTSX() {
        return (<>
                <SoftBox
                    display={"inline"}
                    sx={{
                        position: 'relative',
                    }}
                ><IconButton
                    size="small"
                    color="inherit"
                    sx={navbarIconButton}
                    aria-controls="notification-menu"
                    aria-haspopup="true"
                    onClick={handleOpenMenu}
                >
                    {specNotificationIconListObjList[pageItemGroup].iconElement}

                    {/*<Icon
                    className={light ? "text-white" : "text-dark"}
                    fontSize="large"

                >{specNotificationIconListObjList[pageItemGroup].iconName}</Icon>*/}
                </IconButton>
                    {
                        ctxGalleryDouble.storeMyAcademyNotifCounter[pageItemGroup] > 0 && <SoftBox
                            position="absolute"
                            bottom={1}
                            right={0.5}
                            width="1.5rem"
                            height="1.5rem"
                            borderRadius={"50%"}
                            sx={{
                                backgroundColor: "error.main",
                                right: "-0.1rem",
                                bottom: "-0.1rem",
                            }}
                        >
                            <SoftTypography
                                /*display={"block"}*/
                                position={"absolute"}
                                variant="caption"
                                bottom={"20%"}
                                right={"25%"}
                                /*top={"-0.2rem"}*/
                                color={"white"}
                                sx={{
                                    fontSize: "0.6rem",
                                }}
                            >
                                {ctxGalleryDouble.storeMyAcademyNotifCounter[pageItemGroup]}
                            </SoftTypography>
                        </SoftBox>
                    }
                </SoftBox></>
        )
            ;
    }

    /* Filtruj i zwracaj listę nieodznaczonych */
    function getNotifMarkNullObjList(elementNotificationObjList: IElementMyAcademyNotifLine[]) {
        let markNullObjList = elementNotificationObjList
            .filter((elementNotificationObj: IElementMyAcademyNotifLine, index: number) => {
                return (elementNotificationObj.mark_datetime === null);
            });
        return markNullObjList;
    }

    function getContainerContent(elementNotificationObjList: IElementMyAcademyNotifLine[]
    ) {
        let displayLimit = 50;
        let displayCounter = displayLimit;
        let markNullObjList = getNotifMarkNullObjList(elementNotificationObjList);
        /* SPLICE first elements:
        - get number of ELEMENTS that we want to display in total and remove the FIRST NON MARKED elements
         */
        let markNotNullRemainingObjList = elementNotificationObjList.slice(0, displayCounter);
        markNotNullRemainingObjList.splice(0, markNullObjList.length);


        if (templateModule === "appbar") {
            return (
                <>
                    <Menu
                        /*// @ts-ignore*/
                        anchorEl={openMenu}
                        /*// @ts-ignore*/
                        anchorReference={null}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        open={Boolean(openMenu)}
                        onClose={handleNotifClose}
                        sx={{
                            mt: 2,
                        }}
                    >
                        <SoftBox
                            pb={2} // SoftBox tylko po to, aby dodać padding
                            height={"70vh"}
                            overflowY={"scroll"}
                        ><SoftTypography
                            variant={"h6"}
                            fontWeight={"regular"}
                            sx={{
                                padding: "0.5rem",
                            }}
                        >
                            {specNotificationIconListObjList[pageItemGroup].title}
                        </SoftTypography>

                            {/*Pobierz nowe*/}
                            {
                                markNullObjList.length > 0 && <>
                                    <SoftTypography
                                        variant={"body2"}
                                        sx={{
                                            padding: "1rem",
                                        }}
                                    >
                                        nowe
                                    </SoftTypography>
                                    {
                                        markNullObjList.map(
                                            (notificationObj: IElementMyAcademyNotifLine, index: number) => (
                                                <AfdNotificationItem
                                                    key={index}
                                                    pageItemGroup={pageItemGroup}
                                                    notificationObj={notificationObj}
                                                    userInfoObj={userInfoObj}
                                                    // @ts-ignore
                                                    redirectionType={specNotificationIconListObjList[pageItemGroup].redirectionType}
                                                    image={
                                                        <img src={notificationObj.friend_picture_link} alt="person"/>}
                                                    title={[notificationObj.friend_username,
                                                        notificationObj.content_what,
                                                        (notificationObj.image_title ? notificationObj.image_title
                                                                : ' na kursie ' + notificationObj.course_name
                                                        )
                                                    ]}
                                                    contentWhen={notificationObj.datetime_ago_desc + ' temu'}
                                                />
                                            )) // end of map
                                    }
                                </>
                            }
                            {/*Pobierz resztę*/}
                            {
                                markNotNullRemainingObjList.length > 0 && <>
                                    {
                                        markNullObjList.length > 0 && <SoftTypography
                                            variant={"body2"}
                                            sx={{
                                                padding: "1rem",
                                            }}
                                        >
                                            wcześniejsze
                                        </SoftTypography>
                                    }
                                    {
                                        markNotNullRemainingObjList.map(
                                            (notificationObj: IElementMyAcademyNotifLine, index: number) => (
                                                <AfdNotificationItem
                                                    key={index}
                                                    pageItemGroup={pageItemGroup}
                                                    notificationObj={notificationObj}
                                                    userInfoObj={userInfoObj}
                                                    // @ts-ignore
                                                    redirectionType={specNotificationIconListObjList[pageItemGroup].redirectionType}
                                                    image={
                                                        <img src={notificationObj.friend_picture_link} alt="person"/>}
                                                    title={[notificationObj.friend_username,
                                                        notificationObj.content_what,
                                                        (notificationObj.image_title ?
                                                                notificationObj.image_title :
                                                                ' na kursie ' + notificationObj.course_name
                                                        )
                                                    ]}
                                                    contentWhen={notificationObj.datetime_ago_desc + ' temu'}
                                                />
                                            )) // end of map
                                    }</>
                            }</SoftBox>
                    </Menu>
                </>
            );
        }
        if (templateModule == 'user_profile') {
            /* return (
                 {
                     days_ago: elementObj.days_ago,
                     course_image_path: elementObj.course_image_path,
                     course_name: elementObj.course_name,
                     post_fragment_15: <SoftTypography
                         variant={"caption"}
                     >{elementObj.post_fragment_15}</SoftTypography>,
                     go_to_post_string: <><SoftButton
                         component={Link}
                         to={elementObj.post_url}
                         variant={"outlined"}
                         color={"primary"}
                         size={"small"}
                     >Idź do posta</SoftButton></>,
                 }
             )*/

            let dataTableRowObjList = elementNotificationObjList.map((notificationObj: IElementMyAcademyNotifLine, index: number) => {
                    return (
                        {
                            datetime_ago_desc: notificationObj.datetime_ago_desc,
                            friend_picture_link: getClubDatatableUserProfile(
                                notificationObj.friend_username,
                                notificationObj.friend_picture_link,
                                globalConfigParamObj.isMobile
                            ),
                            content_what: notificationObj.content_what,
                            image_title:
                                <><SoftAvatar
                                    src={notificationObj.first_image_src}
                                    variant={"rounded"}
                                    sx={{
                                        width: 40,
                                    }}
                                />
                                    <SoftTypography
                                        display={"block"}
                                        variant={globalConfigParamObj.isMobile ? "caption" : "caption"}
                                        sx={globalConfigParamObj.isMobile ?
                                            {
                                                textOverflow: "ellipsis",
                                                width: "9ch",
                                            } : {}}
                                    >{notificationObj.image_title}</SoftTypography>
                                </>,
                            course_name: notificationObj.course_name,
                            academy_wall_url:
                                <>
                                   {
                                        pageItemGroup === "comment" ?
                                            getDatatableClubButtonGoToPost(notificationObj.academy_wall_url,
                                                "Idź do komentarza"
                                            )
                                            : getDatatableClubButtonGoToPost(notificationObj.academy_wall_url,
                                                "Idź do posta"
                                            )
                                    }
                                </>
                        }
                    )
                }
            );

            let dataTableData = {
                columns: [
                    {Header: 'Kiedy', accessor: 'datetime_ago_desc'},
                    {Header: 'Zdjęcie', accessor: 'friend_picture_link'},
                    {Header: 'Co', accessor: 'content_what'},
                    {Header: 'Zdjęcie', accessor: 'image_title'},
                    {Header: 'Kurs', accessor: 'course_name'},
                    {Header: 'Idź do posta', accessor: 'academy_wall_url'},
                ],
                rows: dataTableRowObjList
            }
            /* Usuń kolumnę KURS */
            if (pageItemGroup === "comment") {
                dataTableData.columns.splice(4, 1);
            }

            return (<SoftBox
                    pb={2} // SoftBox tylko po to, aby dodać padding
                    height={"70vh"}
                    overflowY={"scroll"}
                >
                    {getNotificationListTitle(
                        specNotificationIconListObjList[pageItemGroup].title,
                        specNotificationIconListObjList[pageItemGroup].description
                    )
                    }
                    <SoftTypography
                    variant={"h6"}
                    fontWeight={"regular"}
                    sx={{
                        padding: "0.5rem",
                    }}
                >
                    <DataTable
                        table={dataTableData}
                        /*entriesPerPage={false}*/
                        canSearch={false}
                        showTotalEntries={false}
                        isSorted={false}
                        noEndBorder
                    />
                </SoftTypography>
                </SoftBox>
            );
        }


    }

    /* BUGFIX: wyświetlaj ikony nawet jeżeli brak elementów w ogóle (nowy USER)     */
    if (templateModule === "appbar") {
        return (
            <>
                {getIconButtonTSX()}
                {
                    (elementNotificationObjList?.length > 0) ?
                        (
                            getContainerContent(elementNotificationObjList)
                        ) : (<div></div>)
                }
            </>
        );
    }
    if (templateModule == 'user_profile') {
        return (
            <>
                {
                    (elementNotificationObjList?.length > 0) ?
                        (
                            getContainerContent(elementNotificationObjList)
                        ) : (<div></div>)
                }
            </>
        );
    }
    return <></>; // fallback
}

export default GetMyAcademyNotificationList;

