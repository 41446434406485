import React from "react";

import {CircularProgress} from "@mui/material";
import SoftBox from "../components/SoftBox";
import IContainerQuery from "../type/IContainerQuery";

interface IProp {
    componentCategory: "App" | "AppRouter" | "AppRouter2Decode" | "AppRouter2Decode2Gantry"
        | "RtClubChapter"
        | "AfdSimpleSelector" | "AfdSelectorNewbb" | "AfdSelectorGallery"
        | "AfdContainer_Suspense" | "AfdController_Suspense";
    componentInfo?: string;
    controllerInfoObj?: IContainerQuery;
}




const AfdLoadingFullpage = (
    {componentCategory,
        componentInfo,
        controllerInfoObj
    }: IProp) => {
    return (
        <>
            <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                width="100vw"
                bgColor="primary.secondary"
                id={"afd_loading_fullpage_" + componentCategory}
                className={"info_" + componentInfo}
            >
                <CircularProgress />
            </SoftBox>
        </>
    );
}

export default AfdLoadingFullpage;
