
/*Pobierz dane element*/
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import RestApiService from "../service/restapi/RestApiService";
import RestApiCommonService from "../service/restapi/RestApiCommonService";
import {processContainer, processElement} from "../util/UtilReact";
import IRQOutputList   from "../type/rq/IRQOutputList";
import {QueryObserverBaseResult, QueryObserverResult} from "react-query";


export default function useCHSourcererData<T = any>(
    sourcererFunction: string,
    sqlColumnName: "sql_query1" | "sql_query2" | "sql_query3" | "sql_query4" | "sql_query5"  | "sql_query6",
    elementParamObj: any,
    isEnabled: boolean = true,
): [T[],
    Dispatch<SetStateAction<T[]>>,
    (x?: any) => Promise<QueryObserverResult<any, unknown>>,
    boolean?,
    boolean?,
    boolean?
] {

    const [elementObjList, setElementObjList] = useState<T[]>([]);
    const rqSourcererFunction: IRQOutputList = RestApiCommonService.getSourcererFunctionRQ(
        sourcererFunction,
        sqlColumnName,
        elementParamObj,
        isEnabled
    );

    useEffect(() => {
      /*  Promise.all(
            [RestApiService.getPromise(RestApiCommonService.getSourcererFunctionUrl(
                sourcererFunction,
                sqlColumnName,
                elementParamObj,
            ))
            ]).then((responseArray) => {
            setElementObjList(processElement(responseArray[0].data));
        });*/

        if (rqSourcererFunction && rqSourcererFunction?.data?.data
            && !rqSourcererFunction.isLoading
            && !rqSourcererFunction.isRefetching
        ) {
            setElementObjList(processElement(rqSourcererFunction.data.data));
        }

    }, [
        rqSourcererFunction.isLoading ? "1" : "0",
        rqSourcererFunction.isRefetching ? "1" : "0",
        sourcererFunction,
        sqlColumnName,
        JSON.stringify(elementParamObj),
    ]);


    return [
        elementObjList,
        setElementObjList,
        rqSourcererFunction.refetch,
        rqSourcererFunction.isLoading,
        rqSourcererFunction.isRefetching,
        rqSourcererFunction.isIdle
    ]
}
