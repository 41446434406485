/*Pobierz dane element*/
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import RestApiService from "../service/restapi/RestApiService";
import RestApiCommonService from "../service/restapi/RestApiCommonService";
import {processContainer, processElement} from "../util/UtilReact";
import IRQOutputList from "../type/rq/IRQOutputList";
import {QueryObserverBaseResult, QueryObserverResult} from "react-query";
import IElementForumDecodeInfoCurrent from "../type/newbb/IElementForumDecodeInfoCurrent";
import IUserInfo from "../type/IUserInfo";
import IElementNewbbTopicPost from "../type/newbb/IElementNewbbTopicPost";
import IElementNewbbTopicInfo from "../type/newbb/IElementNewbbTopicInfo";

export interface IElementChapterIssueDecodeInfoList {
    chapter_issue_code: string,
    is_issue_current: number,
    chapter_issue_title: string,
    chapter_issue_type: string,
    chapter_issue_link: string,
    forum_chapter_id: number,
    instructor_image_path: string,
    instructor_name: string,
    instructor_name_d: string,
    chapter_topic_url: string,
    chapter_header: string,
    instructor_letter: string,
    forum_chapter_issue_url: string,
    rating_value: number | null,
    rating_desc: string  | null,
    issue_duration_minute: number,
    fail_value: number | null,
    issue_point_count: number,
}


export default function useCHForumChapterIssueDecodeInfoList(
    {
        chapter_forum_id,
        current_chapter_issue_code,
        userInfoObj,
        isEnabled = true,
    }: {
        chapter_forum_id: number,
        current_chapter_issue_code: string,
        userInfoObj: IUserInfo,
        isEnabled?: boolean,
    }
): [
    IElementChapterIssueDecodeInfoList[],
    Dispatch<SetStateAction<IElementChapterIssueDecodeInfoList[]>>,
    (x?: any) => Promise<QueryObserverResult<any, unknown>>,
    boolean,
    boolean,
    boolean
] {
    let sourcererFunction: string = 'get_forum_chapter_issue_decode_info_list';
    const [elementObjList, setElementObjList] = useState<IElementChapterIssueDecodeInfoList[]>([]);

    /* Różne QUERY w zależności od tego czy COURSE_USER_ID */
    const rqSourcererFunction: IRQOutputList = RestApiCommonService.getSourcererFunctionRQ(
        sourcererFunction,
        "sql_query1",
        {
            user_id: userInfoObj.user_id,
            chapter_forum_id: chapter_forum_id,
            current_chapter_issue_code: current_chapter_issue_code,
        },
        isEnabled
    );

    useEffect(() => {

        if (rqSourcererFunction && rqSourcererFunction?.data?.data && !rqSourcererFunction.isLoading) {
            setElementObjList(processElement(rqSourcererFunction.data.data));
        }

    }, [
        rqSourcererFunction.isLoading ? "1" : "0",
        rqSourcererFunction.isRefetching ? "1" : "0",
        sourcererFunction,
        chapter_forum_id,
        userInfoObj.user_id,
    ]);


    return [
        elementObjList,
        setElementObjList,
        rqSourcererFunction.refetch,
        rqSourcererFunction.isLoading,
        rqSourcererFunction.isFetching,
        rqSourcererFunction.isIdle
    ]
}
