import SoftBox from '../../components/SoftBox';
import React from 'react';
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import IFormFormikGantry from "../../type/form/IFormFormikGantry";
import {useSelector} from "react-redux";
import IRdxState from "../../type/reducer_state/IRdxState";
import IUniversalFormSubmitReponse from "../../type/comprofiler/IUniversalFormSubmitReponse";
import {AfdAlertErrorForm} from "../../component/error_boundary/AfdAlertErrorForm";

interface IProp {
    formFieldObj: IFormFieldInfo,
    formikGantry: IFormFormikGantry,
    templateModule: string,
    formSubmissionResult?: IUniversalFormSubmitReponse | undefined
}

function FormNgValidationNoSubmitTemplate({
                                         formFieldObj,
                                         formikGantry,
                                         templateModule,
                                            formSubmissionResult
                                     }: IProp) {

    const state: IRdxState = useSelector((state: IRdxState) => state);


    /* Tutaj także indywidualna walidacja dla SUCCESS */
    function getTemplateContent() {
        if (templateModule === 'ELEMENT_ACCOUNT_CREATE_SUBMIT_NO_VALID') {
            return (
                <>
                    {
                        formSubmissionResult?.message_desc
                    }
                </>
            );
        }
        if (templateModule === 'ELEMENT_SITE_LOGIN_NO_VALIDATION') {
            return (
                <>
                    <span>{
                        formSubmissionResult?.message_desc
                    }</span>
                    <span>{
                        JSON.stringify(formSubmissionResult?.data)
                    }</span>
                </>
            );

        }


    }

    let templateTSX = getTemplateContent();

    if (templateTSX
    ) {
        return (
            <SoftBox
                px={{xs: 1, sm: 2, md: 3}}
                py={{xs: 1, sm: 1, md: 2}}
            >
                <AfdAlertErrorForm templateTSX={templateTSX}/>
            </SoftBox>

            /*
            <SoftBox
                px={{xs: 1, sm: 2, md: 3}}
                py={{xs: 1, sm: 1, md: 2}}
            ><SoftAlert color="error"
                        sx={{
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                        }}
            >
                <SoftTypography
                    variant={"button"}
                    fontWeight={"regular"}
                    color={"white"}
                >{templateTSX}
                </SoftTypography>
            </SoftAlert></SoftBox>*/
        );
    } else {
        return null;
    }

}

export default FormNgValidationNoSubmitTemplate;
