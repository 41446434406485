import React, {Suspense, useEffect, useState} from "react";
import {debugLog} from "../../util/UtilReact";

// @mui material components
import Container from "@mui/material/Container";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";

// Soft UI Dashboard PRO React example components
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import {getPosition2ContainerMap} from "../../util/UtilReact";

import AfdSelectorGallery from "../../selector/AfdSelectorGallery";
import AfdSelectorImage from "../AfdSelectorImage";
import IContainerQuery from "../../type/IContainerQuery";
import useCHGantryData from "../../hook/useCHGantryData";
import IPropGantry from "../../type/IPropGantry";

import GetMenuPageBanner from "../../util/GetMenuPageBanner";

import ISelectorPosition from "../../type/ISelectorPosition";
import Grid from "@mui/material/Grid";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import GetClubDashboardNavbar from "../../component/controller/get_club_dashboard_navbar/GetClubDashboardNavbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import CtxGalleryDashboard from "../../context/CtxGalleryDashboard";
import IElementGalleryCommentLine from "../../type/gallery/IElementGalleryCommentLine";
import AppBar from "@mui/material/AppBar";
import {
    navbar,
    navbarContainer,
    navbarDesktopMenu,
    navbarRow
} from "../../component/controller/get_club_dashboard_navbar/styles";
import Toolbar from "@mui/material/Toolbar";
import Breadcrumbs from "../../examples/Breadcrumbs";
import Icon from "@mui/material/Icon";
import GetGlobalProfileIcon from "../../component/sourcerer/GetGlobalProfileIcon";
import useCHGetContextSoftUI from "../../hook/useCHGetContextSoftUI";
import {Link, useLocation} from "react-router-dom";
import {setMiniSidenav, setOpenConfigurator, setTransparentNavbar} from "../../redux_reducer/util/dispatchShortcut";
import Menu from "@mui/material/Menu";
import NotificationItem from "../../examples/Items/NotificationItem";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import ConfigButtonFactory from "../../examples/Configurator/ConfigButtonFactory";
import {isDev} from "../../util/isDev";
import AfdConfigurator from "../../examples/Configurator/AfdConfigurator";
import IElementGalleryLatestCommentNg from "../../type/gallery/IElementGalleryLatestCommentNg";
import SoftAvatar from "../../components/SoftAvatar";
import linkDictionary from "../../linkDictionary";
import GetMyAcademyNotificationList from "../../component/sourcerer/GetMyAcademyNotificationList";
import {Fade} from "@mui/material";

const team2 = require("../../assets/images/team-2.jpg");
const logoSpotify = require("../../assets/images/small-logos/logo-spotify.svg");

function RtClubDashboard({
                             menuItemObj,
                             containerObjList,
                             userInfoObj,
                             routeCategory,
                             redirectionObj
                         }: IPropGantry) {

    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    var [menuItemListObj, setMenuItemListObj] = useCHGantryData(
        menuItemObj,
        containerObjList,
        userInfoObj,
        routeCategory,
        redirectionObj
    );
    const [storeOrigCommentObj, setStoreOrigCommentObj] = useState<IElementGalleryCommentLine | null>(null);
    /* wybrane informacje na temat NEW COMMENT */
    const [storeNewCommentInfoObj, setStoreNewCommentInfoObj] = useState<{
        comment_id: number,
        comment: string,
        user_id: number,
        orig_comment_id: number | null,
        heart_id: number | null,
    } | null>(null);
    const [storeDeleteCommentObj, setStoreDeleteCommentObj] = useState<IElementGalleryCommentLine | null>(null);
    const [storeModeTabValue, setStoreModeTabValue] = useState(0);
    const [storeLatestCommentNgObj, setStoreLatestCommentNgObj] = useState<IElementGalleryLatestCommentNg | null>(null);
    const [configButtonTSX, setConfigButtonTSX] = useState<JSX.Element | null>(null);
    const [isShow, setIsShow] = useState(false);

    /* Licz kolejne wizyty i przekazuj w CONTEXT */
    const [imageVisitCounter, setImageVisitCounter] = useState(0);
    useEffect(() => {
            setImageVisitCounter((prevCounter) => {
                    return (prevCounter + 1)
                }
            );
        },
        [
            redirectionObj.image_id
        ]
    );


    const handleConfiguratorOpen = () => setOpenConfigurator(dispatchFunction, true);

    /* Button */
    function getLatestCommentButtonTSX() {
        setIsShow(false);
        setTimeout(() => {
            setIsShow(true);
        }
            , 5);
        if (storeLatestCommentNgObj && (!storeLatestCommentNgObj.reactIsVisited)) {
            return <Fade
                in={isShow}
                timeout={3000}
            >
                <SoftAvatar
                    src={storeLatestCommentNgObj.picture_link}
                    alt={storeLatestCommentNgObj.comment_title}
                    variant={"circle"}
                    size={"md"}
                />
            </Fade>
        } else {
            return <Fade
                in={true}
                timeout={1000}
            ><Icon color="inherit">
                rss_feed
            </Icon>
            </Fade>
        }
    }

    useEffect(() => {
            setConfigButtonTSX(
                ConfigButtonFactory(
                    handleConfiguratorOpen,
                    getLatestCommentButtonTSX()
                )
            );
        },
        [
            storeLatestCommentNgObj?.comment_url,
            storeLatestCommentNgObj?.reactIsVisited
        ]
    );

    const [tabValue, setTabValue] = useState<number>(0);
    /*Przełączaj na TAB: DYSKUSE za kazdym razem kiedy zmieniane jest zdjęcie*/
    useEffect(() => {
            setTabValue(0)
        }
        , [redirectionObj.image_id]);

    const handleSetTabValueFunction = (event: any, newValue: any) => {
        setTabValue(newValue);
        debugLog(tabValue);
    };

    const position2ContainerMap = getPosition2ContainerMap(containerObjList);

    /* App bar */
    let absolute: boolean = false;
    let light: boolean = false;
    let isMini: boolean = false;


    const [navbarType, setNavbarType] = useState('');
    const [controller, dispatch] = useCHGetContextSoftUI();
    const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator} = controller;
    const [openMenu, setOpenMenu] = useState(false);
    const route = useLocation().pathname.split("/").slice(1);


    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        /* A function that sets the transparent state of the navbar.
        - either FIXED and position 0
        or
        - not FIXED
         */
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleCloseMenu = () => setOpenMenu(false);


    // Render the notifications menu
    const renderMenu = () => (
        <></>
        /*     <GetMyAcademyNotificationList
                 userInfoObj={userInfoObj}
                 page_code={"xm_club_my_academy_comments_received_t"}
                 forum_id={null}
                 openMenu={openMenu}
                 handleNotifItemClick={handleCloseMenu}
                 handleMenuClose={handleCloseMenu}
             />*/
    );

    const getSelectorPositionJSX = function (positionCode: ISelectorPosition): JSX.Element | null {
        if (position2ContainerMap[positionCode]?.length > 0) {

            /* BUGFIX: disableGutters nie działa */
            if (menuItemObj.xx_display_menutype === 'dummy') {
                return (
                    <AfdSelectorGallery
                        key={positionCode}
                        menuItemObj={menuItemObj}
                        userInfoObj={userInfoObj}
                        position={positionCode}
                        redirectionObj={redirectionObj}
                        containerAssocList={position2ContainerMap[positionCode]}
                    />
                );
            } else {
                return (
                    <Container
                        /*disableGutters={true}*/ // nie działa
                    >
                        <AfdSelectorGallery
                            key={positionCode}
                            menuItemObj={menuItemObj}
                            userInfoObj={userInfoObj}
                            position={positionCode}
                            redirectionObj={redirectionObj}
                            containerAssocList={position2ContainerMap[positionCode]}
                        /></Container>);
            }
        } else {
            return null;
        }

    }

    /*****           *******************************************************                   ******/
    var containerMarginTop: number = GetMenuPageBanner(menuItemObj).containerMarginTop;

    function getTabDiscussionTSX() {
        return <Grid container spacing={1}>
            {/* MAIN container*/}
            <Grid item xs={12} lg={7}>
                {
                    userInfoObj.is_tech_account == 1 ?
                        getSelectorPositionJSX('admin-instructor-toolbar') : null
                }
                {/*zaznaczanie Oli dla CHAT*/}
                {getSelectorPositionJSX('picture-image_big')}
                {getSelectorPositionJSX('REACT-split-left')}
                {getSelectorPositionJSX('REACT-comment-below')}
                {getSelectorPositionJSX('REACT-comment-below-ep')}


                {/* <Grid container spacing={3}>
                                <Grid item xs={12} lg={4}>
                                    {getSelectorPositionJSX('header-a')}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <SoftBox mb={3}>
                                        {getSelectorPositionJSX('ministat-a')}
                                    </SoftBox>
                                    {getSelectorPositionJSX('ministat-b')}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <SoftBox mb={3}>
                                        {getSelectorPositionJSX('ministat-c')}
                                    </SoftBox>
                                    {getSelectorPositionJSX('ministat-d')}
                                </Grid>
                            </Grid>*/}
            </Grid>
            <Grid item xs={12} lg={5}
            >
                {globalConfigParamObj.isMobile ?
                    <>{getSelectorPositionJSX('REACT-split-center-body')}
                        {getSelectorPositionJSX('REACT-split-center-top')}</>
                    :
                    <>{getSelectorPositionJSX('REACT-split-center-top')}
                        {getSelectorPositionJSX('REACT-split-center-body')}</>
                }
            </Grid>
            {/* RIGHT sidebar */}
            {/*       <Grid item xs={12} lg={3}>

            </Grid>*/}
        </Grid>;
    }

    function getTabInformationTSX() {
        return <Grid container>
            <Grid item xs={12} md={9} lg={6}
            >
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-info-body')}
                </SoftBox>
            </Grid>
            <Grid item xs={0} md={3} lg={6}>
            </Grid>
        </Grid>;
    }

    function getTabModerationTSX() {
        return <Grid container>
            <Grid item xs={12} md={9} lg={6}
            >
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-moderation-body')}
                </SoftBox>
            </Grid>
            <Grid item xs={6} lg={6}>
            </Grid>
        </Grid>;
    }

    function getTabFavouriteTSX() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-favourite-body')}
                </SoftBox>
            </Grid>
            {/*<Grid item xs={6} lg={6}>
                <SoftBox my={3}>

                </SoftBox>
            </Grid>*/}
        </Grid>;
    }

    function getTabImageDeleteTSX() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-image-delete-body')}
                </SoftBox>
            </Grid>
            {/*<Grid item xs={6} lg={6}>
                <SoftBox my={3}>

                </SoftBox>
            </Grid>*/}
        </Grid>;
    }


    let specificationObjList: {
        label: string,
        icon: JSX.Element,
        value: number,
        isVisible: boolean
    }[] = [
        {
            label: 'dyskusja',
            icon: <NotesOutlinedIcon/>,
            value: 0,
            isVisible: true
        },
        {
            label: 'informacje',
            icon: <InfoOutlinedIcon/>,
            value: 1,
            isVisible: (userInfoObj.page_set_code != 'viewer')
        },
        {
            label: 'moderacja',
            icon: <FlagOutlinedIcon/>,
            value: 2,
            isVisible:  (userInfoObj.page_set_code != 'viewer')
        },
 /*       {
            label: 'ulubione',
            icon: <BookmarkAddOutlinedIcon/>,
            value: 3,
            isVisible: true
        },*/
        {
            label: 'usuń',
            icon: <DeleteForeverOutlinedIcon/>,
            value: 4,
            isVisible:  (userInfoObj.page_set_code != 'viewer')
        }
    ];

    function getTabListTSX() {
        return <SoftBox
            alignItems={"left"}
        > <Tabs orientation={"horizontal"}
                value={tabValue}
                onChange={handleSetTabValueFunction}>
            {
                specificationObjList.filter((specificationObj) => {
                        return specificationObj.isVisible
                }
                ).map((specificationObj, index) => {
                        return <Tab
                            key={index}
                            label={globalConfigParamObj.isMobile &&
                            index !== tabValue
                                ? '' : specificationObj.label} // nie pokazuj reszty
                            icon={specificationObj.icon}
                            value={specificationObj.value}
                            sx={{
                                paddingRight: 2
                            }}
                        />
                    }
                )
            }
        </Tabs>


        </SoftBox>;
    }

    return (
        <>
            <CtxGalleryDashboard.Provider value={{
                imageVisitCounter: imageVisitCounter,
                storeOrigCommentObj: storeOrigCommentObj,
                setStoreOrigCommentObj: setStoreOrigCommentObj,
                storeNewCommentInfoObj: storeNewCommentInfoObj,
                setStoreNewCommentInfoObj: setStoreNewCommentInfoObj,
                storeDeleteCommentObj: storeDeleteCommentObj,
                setStoreDeleteCommentObj: setStoreDeleteCommentObj,
                storeModeTabValue: storeModeTabValue,
                setStoreModeTabValue: setStoreModeTabValue,
                storeLatestCommentNgObj: storeLatestCommentNgObj,
                setStoreLatestCommentNgObj: setStoreLatestCommentNgObj,
            }}>


                {getSelectorPositionJSX('showcase-a')}
                <DashboardLayout>

                    <AppBar
                        // @ts-ignore
                        position={absolute ? "absolute" : navbarType}
                        color="inherit"
                        sx={
                            (theme) => navbar(
                                theme,
                                {
                                    transparentNavbar, absolute, light
                                }
                            )
                        }
                    >
                        <Toolbar sx={(theme) => navbarContainer(theme)}>
                            <SoftBox color="inherit" mb={{xs: 1, md: 0}}
                                     sx={(theme: any) => navbarRow(theme, {isMini})}>
                                {/*<Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light}/>*/}
                                {globalConfigParamObj.isMobile ?
                                    <><SoftBox
                                        component={Link}
                                        to={linkDictionary.CLUB_GALLERY_NEW_IMAGE}
                                    ><KeyboardReturnOutlinedIcon/></SoftBox>
                                        {getSelectorPositionJSX('top-a')}
                                        {getTabListTSX()}</>
                                    :
                                    <>{getSelectorPositionJSX('top-a')}</>
                                }

                                <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
                                    {miniSidenav ? "menu_open" : "menu"}
                                </Icon>
                            </SoftBox>
                            {isMini ? null : (
                                <SoftBox sx={(theme: any) => navbarRow(theme, {isMini})}>
                                    {/*  <SoftBox pr={1}>
                        <SoftInput
                            placeholder="Type here..."
                            icon={{component: "search", direction: "left"}}
                            label={"id1"}
                            id={"id1"}
                            name={"id1"}
                            type={"text"}
                        />
                    </SoftBox>*/}
                                    <SoftBox color={light ? "white" : "inherit"}>
                                        {/*      TODO
                              <GetGlobalProfileIcon
                                        controllerInfoObj={controllerInfoObj}
                                        userInfoObj={userInfoObj}
                                    />*/}
                                        {/*   <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small">
                  <Icon
                    sx={
                    (
                        { palette: { dark, white } }: any
                    ) => ({
                      color: light ? white.main : dark.main,
                    })
                  }
                  >
                    account_circle
                  </Icon>
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color={light ? "white" : "dark"}
                  >
                    Sign in
                  </SoftTypography>
                </IconButton>
              </Link>*/}
                                        {/*TODO*/}
                                        {/*  <IconButton
                                size="small"
                                color="inherit"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                <Icon className={light ? "text-white" : "text-dark"}>
                                    {miniSidenav ? "menu_open" : "menu"}
                                </Icon>
                            </IconButton>*/}
                                        {/*    <IconButton
                                size="small"
                                color="inherit"
                                sx={navbarIconButton}
                                onClick={handleConfiguratorOpen}
                            >
                                <Icon>settings</Icon>
                            </IconButton>*/}
                                        {/*TODO - bug: sprawdzić specyfikacje: variant="contained"*/}
                                        {/*<IconButton
                                size="small"
                                color="inherit"
                                sx={navbarIconButton}
                                aria-controls="notification-menu"
                                aria-haspopup="true"

                                onClick={handleOpenMenu}
                            >
                                <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
                            </IconButton>*/}
                                        {renderMenu()}
                                    </SoftBox>
                                </SoftBox>
                            )}
                        </Toolbar>
                    </AppBar>

                    <SoftBox
                        display="flex"
                        flexDirection={"row"}
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                        {!globalConfigParamObj.isMobile ? getTabListTSX() : null}
                        {getSelectorPositionJSX('maintop-a')}
                    </SoftBox>
                    <SoftBox mt={2}>
                        {tabValue === 0 && getTabDiscussionTSX()}
                        {/* Below*/}
                        {tabValue === 1 && getTabInformationTSX()}
                        {tabValue === 2 && getTabModerationTSX()}
                        {tabValue === 3 && getTabFavouriteTSX()}
                        {tabValue === 4 && getTabImageDeleteTSX()}

                    </SoftBox>
                    {getSelectorPositionJSX('module-footer-a')}
                    {
                        (menuItemObj)
                            ?
                            <AfdConfigurator
                                menuItemObj={menuItemObj}
                                redirectionObj={redirectionObj}
                                userInfoObj={userInfoObj}
                                routeCategory={routeCategory}
                                selectorTSX={getSelectorPositionJSX('drawer')}
                            /> : null
                    }
                    {configButtonTSX}
                </DashboardLayout>
            </CtxGalleryDashboard.Provider>
        </>
    )
}

export default RtClubDashboard;
