import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom"; // npm i react-router-dom --save
import AfdContainer from "../component/AfdContainer";
import IContainerQuery from "../type/IContainerQuery";
import IParamObj from "../type/IParamObj";
import ISelector from "../type/ISelector";
import {debugLog, debugDir} from "../util/UtilReact";
import AfdLoadingFullpage from "../component/AfdLoadingFullpage";
import useCHForumDecodeInfoCurrent from "../hook/useCHForumDecodeInfoCurrent";
import useCHTopicDecodeInfoCurrent from "../hook/useCHTopicDecodeInfoCurrent";
import useCHSourcererData from "../hook/useCHSourcererData";
import IElementNewbbUniForumInfo from "../type/newbb/IElementNewbbUniForumInfo";
import useCHFunctionSelectResult from "../hook/useCHFunctionSelectResult";
import {extractDigitsFromString} from "../util/UtilTS";
import IElementNewbbAnchorPost from "../type/newbb/IElementNewbbAnchorPost";
import ICtxClubNewbb from "../type/context/ICtxClubNewbb";
import CtxClubNewbb from "../context/CtxClubNewbb";

/* take the container list and slect those which are  relevant for this position *
 */
const AfdSelectorNewbb = ({
                              menuItemObj: menuItemObj,
                              userInfoObj: userInfoObj,
                              position: position,
                              containerAssocList: containerAssocList,
                              redirectionObj: redirectionObj,
                              formikGantry: formikGantry,
                              setFormikParamObj: setFormikParamObj
                          }: ISelector) => {
    const ctxNewbbFunctionObj: ICtxClubNewbb = useContext<ICtxClubNewbb>(CtxClubNewbb);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const queryHookObj = useQuery();

    const [paramObj, setParamObj] = useState<IParamObj>({});
    const [isDataReady, setIsDataReady] = useState(false);

    /* 577:     bottom-a maintop-a body
    * */
    useEffect(() => {
            if (menuItemObj.id == 577) {
                setIsDataReady(false);


                    let internalParamObj = {
                        position_param2: queryHookObj.get('ls'),
                        position_param3: queryHookObj.get('le'),
                        position_param5: queryHookObj.get('real_forum_id'),
                        position_param6: ctxNewbbFunctionObj.urlAnchorPostId,
                    };

                    if (position == 'bottom-a' ||
                        position == 'maintop-a' ||
                        position == 'navigation' ||
                        position == 'top-a' ||
                        position == 'drawer' ||
                        position == 'body'
                    ) {
                        internalParamObj = {
                            ...internalParamObj,
                            ...{
                                position_param0: ctxNewbbFunctionObj.urlDisForumId,
                                position_param1: ctxNewbbFunctionObj.urlDisTopicId,
                                position_param4: ctxNewbbFunctionObj.urlDisPostId,
                                position_param6: ctxNewbbFunctionObj.urlAnchorPostId,
                            }
                        };
                    }
                    if (position == 'REACT-tab2-body') {
                        internalParamObj = {
                            ...internalParamObj,
                            ...{
                                position_param0: ctxNewbbFunctionObj.storeNewbbTopicInfoObj?.forum_id,
                                position_param1: ctxNewbbFunctionObj.storeNewbbUniForumInfoObj?.discussion_topic_id,
                                position_param4: ctxNewbbFunctionObj.storeNewbbUniForumInfoObj?.discussion_id,
                                position_param6: ctxNewbbFunctionObj.urlAnchorPostId,
                            }
                        }
                    }
                    if (position == 'REACT-tab4-body') {
                        internalParamObj = {
                            ...internalParamObj,
                            ...{
                                position_param0: ctxNewbbFunctionObj.storeNewbbTopicInfoObj?.forum_id,
                                position_param1: -1,
                                position_param4: -1,
                                position_param6: ctxNewbbFunctionObj.urlAnchorPostId,
                            }
                        }
                    }
                    if (position == 'REACT-tab5-body') {
                        internalParamObj = {
                            ...internalParamObj,
                            ...{
                                position_param0: ctxNewbbFunctionObj.storeNewbbTopicInfoObj?.forum_id,
                                position_param1: ctxNewbbFunctionObj.storeNewbbUniForumInfoObj?.forum_post_half_topic_id,
                                position_param4: ctxNewbbFunctionObj.storeNewbbUniForumInfoObj?.forum_post_half_id,
                                position_param6: ctxNewbbFunctionObj.urlAnchorPostId,
                            }
                        }

                    }
                    if (position == 'REACT-tab6-body') {
                        internalParamObj = {
                            ...internalParamObj,
                            ...{
                                position_param0: ctxNewbbFunctionObj.storeNewbbTopicInfoObj?.forum_id,
                                position_param1: ctxNewbbFunctionObj.storeNewbbUniForumInfoObj?.forum_post_submit_topic_id,
                                position_param4: ctxNewbbFunctionObj.storeNewbbUniForumInfoObj?.forum_post_submit_id,
                                position_param6: ctxNewbbFunctionObj.urlAnchorPostId,
                            }
                        }
                    }
                    /* Połówka */
                    if (position == 'REACT-tab7-body') {
                        internalParamObj = {
                            ...internalParamObj,
                            ...{
                                position_param0: ctxNewbbFunctionObj.storeNewbbTopicInfoObj?.forum_id,
                                position_param1: ctxNewbbFunctionObj.storeNewbbUniForumInfoObj?.forum_post_final_topic_id,
                                position_param4: ctxNewbbFunctionObj.storeNewbbUniForumInfoObj?.forum_post_final_id,
                                position_param6: ctxNewbbFunctionObj.urlAnchorPostId,
                            }
                        }
                    }
                    /* Upublicznij */
                    if (position == 'REACT-tab8-body') {
                        internalParamObj = {
                            ...internalParamObj,
                            ...{
                                position_param0: ctxNewbbFunctionObj.storeNewbbTopicInfoObj?.forum_id,
                                position_param1: ctxNewbbFunctionObj.storeNewbbUniForumInfoObj?.forum_post_final_topic_id,
                                position_param4: ctxNewbbFunctionObj.storeNewbbUniForumInfoObj?.forum_post_final_public_id,
                                position_param6: ctxNewbbFunctionObj.urlAnchorPostId,
                            }
                        }
                    }

                    setParamObj(internalParamObj);
                    setIsDataReady(true);


                }

        },
        [
            ctxNewbbFunctionObj.urlDisForumId,
            ctxNewbbFunctionObj.urlDisTopicId,
            ctxNewbbFunctionObj.urlDisPostId,
        ]
    );


    /* check if any containers exist for the position */
    return (
        <>
            {
                (typeof containerAssocList !== 'undefined'
                    && typeof paramObj !== "undefined"
                    && isDataReady === true
                        ? containerAssocList.map((element: IContainerQuery) => (
                            <AfdContainer
                                key={element.sequence}
                                menuItemObj={menuItemObj}
                                userInfoObj={userInfoObj}
                                position={position}
                                controllerInfoObj={element}
                                paramObj={paramObj}
                                redirectionObj={redirectionObj}
                                formikGantry={formikGantry}

                            />
                        )) :
                        <AfdLoadingFullpage
                            componentCategory="AfdSelectorNewbb"
                            componentInfo={"p:" + position + "_m:" + menuItemObj.id + "_u:" + userInfoObj.user_id}
                        />
                )
            }
        </>
    );
}

export default AfdSelectorNewbb;
