import React from 'react';
import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";
import { Link } from "react-router-dom";

interface IProps {
    buttonList:
        {
            title: string;
            href: string;
            target: string;
            rel: string;
            color: "info" | "dark";
            variant: "gradient" | "outlined";
        }[]
}

function AfdVertButtonGroup({
    buttonList
                            }: IProps) {
    return (
        <SoftBox mt={3} mb={2}>
            {
                buttonList.map((item: any, index: any) => {
                    return (
                        <SoftBox
                            key={index}
                            mb={2}
                        >
                            <SoftButton
                                component={Link}
                                to={item.href}
                                /*target={item.target}
                                rel={item.rel}*/
                                color={item.color}
                                variant={item.variant}
                                fullWidth
                            >
                                {item.title}
                            </SoftButton>
                        </SoftBox>
                    )
                })
            }
        </SoftBox>
    );
}

export default AfdVertButtonGroup;
