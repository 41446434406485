import React, {Suspense, useEffect, useState} from "react";

// @mui material components
import Container from "@mui/material/Container";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";

// Soft UI Dashboard PRO React example components
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import {getPosition2ContainerMap} from "../../util/UtilReact";

import AfdSelectorSimple from "../../selector/AfdSelectorSimple";
import AfdSelectorImage from "../AfdSelectorImage";
import IContainerQuery from "../../type/IContainerQuery";
import useCHGantryData from "../../hook/useCHGantryData";
import IPropGantry from "../../type/IPropGantry";

import SoftTypography from "../../components/SoftTypography";
import SoftAvatar from "../../components/SoftAvatar";

import GetMenuPageBanner from "../../util/GetMenuPageBanner";
import AfdMainpageBanner from "../AfdMainpageBanner";
import ISelectorPosition from "../../type/ISelectorPosition";
const curved0 = require("../../assets/images/curved-images/curved0.jpg");
const burceMars = require("../../assets/images/bruce-mars.jpg");
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import colors from "../../assets/theme/base/colors";
import boxShadows from "../../assets/theme/base/boxShadows";
import borders from "../../assets/theme/base/borders";

import breakpoints from "../../assets/theme/base/breakpoints";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import CameraOutlinedIcon from '@mui/icons-material/CameraOutlined';
import RssFeedOutlinedIcon from '@mui/icons-material/RssFeedOutlined';
import GetInstructorDesc from "../../component/sourcerer/GetInstructorDesc";


/*import React from "@types/react";*/


function RtSiteInstructor({
                              menuItemObj,
                              containerObjList,
                              userInfoObj,
                              routeCategory,
                              redirectionObj
                          }: IPropGantry) {


    var [menuItemListObj, setMenuItemListObj] = useCHGantryData(
        menuItemObj,
        containerObjList,
        userInfoObj,
        routeCategory,
        redirectionObj
    );


    const [tabValue, setTabValue] = useState(0);
    const [prices, setPrices] = useState(["59", "89", "99"]);
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        /**
         The event listener that's calling the handleTabsOrientation function when resizing the window.
         */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const position2ContainerMap = getPosition2ContainerMap(containerObjList);

    const getSelectorPositionJSX = function (positionCode: ISelectorPosition): JSX.Element | null {
        if (position2ContainerMap[positionCode]?.length > 0) {
            return (
                <Container>
                    <AfdSelectorSimple
                        key={positionCode}
                        menuItemObj={menuItemObj}
                        userInfoObj={userInfoObj}
                        position={positionCode}
                        redirectionObj={redirectionObj}
                        containerAssocList={position2ContainerMap[positionCode]}
                    /></Container>);
        } else {
            return null;
        }

    }
    const handleSetTabValue = (event: any, newValue: any) => setTabValue(newValue);

    function getTabPhotographyTSX() {
        return <Grid container>
            <Grid item xs={6} lg={6}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-photography-left')}
                </SoftBox>
            </Grid>
            <Grid item xs={6} lg={6}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-photography-right')}
                </SoftBox>
            </Grid>
        </Grid>;
    }
    function getTabBLOGTSX() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-instructor-tab-blog')}
                </SoftBox>
            </Grid>
        </Grid>;
    }
    function getTabInfoTSX() {
        return <Grid container>
            <Grid item xs={6} lg={6}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('content-bottom-a')}
                </SoftBox>
            </Grid>
        </Grid>;
    }
    function getTabStudentTSX() {
        return <Grid container>
            <Grid item xs={6} lg={6}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('mainbottom-a')}
                </SoftBox>
            </Grid>
        </Grid>;
    }

    function getInstructorProfileStripTSX() {
        let specificationObjList = [
            {
                label: 'fotografia',
                icon: <CameraOutlinedIcon/>,
                value: 0
            },
            {
                label: 'blog',
                icon: <RssFeedOutlinedIcon/>,
                value: 1
            },
           /* {
                label: 'informacje',
                icon: <InfoOutlinedIcon/>,
                value: 2
            },*/
            {
                label: 'studentki',
                icon: <PersonSearchOutlinedIcon/>,
                value: 3
            },
        ];

        return (
            <SoftBox position="relative">
                {/*<DashboardNavbar absolute light />*/}
              {/*  <SoftBox
                    display="flex"
                    alignItems="center"
                    position="relative"
                    minHeight="18.75rem"
                    borderRadius="xl"
                    sx={{
                        backgroundImage: ({
                                              functions: {
                                                  rgba, linearGradient
                                              },
                                              palette: {gradients}
                                          }: any
                        ) =>
                            `${linearGradient(
                                rgba(gradients.info.main, 0.6),
                                rgba(gradients.info.state, 0.6)
                            )}, url(${curved0})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50%",
                        overflow: "hidden",
                    }}
                />*/}
                <Card
                    sx={{
                        backdropFilter: `saturate(200%) blur(30px)`,
                        backgroundColor: (
                            {functions: {rgba}, palette: {white}}: any) => rgba(white.main, 0.8),
                        boxShadow: ({boxShadows: {navbarBoxShadow}}: any) => navbarBoxShadow,
                        position: "relative",
                        mt: 19, // dopasuj
                        mx: 3,
                        py: 2,
                        px: 2,
                    }}
                >
                    <Grid container spacing={3} alignItems="center">
                        {redirectionObj.object_user_id && <GetInstructorDesc
                            userId={redirectionObj.object_user_id}
                            templateModule={'rt-site-instructor'}
                        />}
                        <Grid item xs={12} md={6} lg={4} sx={{ml: "auto"}}>
                            <AppBar position="static">
                                <Tabs
                                    /*orientation={tabsOrientation}*/
                                    value={tabValue}
                                    onChange={handleSetTabValue}
                                    sx={{background: "transparent"}}
                                >
                                    {
                                        specificationObjList.map((specificationObj, index) => {
                                                return <Tab
                                                    key={index}
                                                    label={specificationObj.label}
                                                    icon={specificationObj.icon}
                                                    value={specificationObj.value}
                                                    sx={{
                                                        paddingRight: 2
                                                    }}
                                                />
                                            }
                                        )
                                    }
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                </Card>
            </SoftBox>
        );
    }

    var containerMarginTop: number = GetMenuPageBanner(menuItemObj).containerMarginTop;
    return (
        <PageLayout>

            <AfdSelectorImage
                menuItemObj={menuItemObj}
                containerObjList={containerObjList}
                userInfoObj={userInfoObj}
                routeCategory={routeCategory}
                redirectionObj={redirectionObj}
                mainpageBannerTSX={ <AfdMainpageBanner
                    menuItemObj={menuItemObj}
                    containerObjList={containerObjList}
                    userInfoObj={userInfoObj}
                    routeCategory={routeCategory}
                    redirectionObj={redirectionObj}
                    getSelectorPositionJSX={getSelectorPositionJSX}
                />}
            >
                <>
                    {getSelectorPositionJSX('top-a')}
                    {getSelectorPositionJSX('showcase-a')}

                    {/*mainpage_banner*/}
                </>
            </AfdSelectorImage>
            {/*mt={-19}*/}


            <SoftBox
                px={{xs: 1, sm: 0}}>
                {/*banner below menu */}
                {getInstructorProfileStripTSX() }
                {/*// @ts-ignore*/}

                <SoftBox mt={2}>
                    {tabValue === 0 && getTabPhotographyTSX()}
                    {tabValue === 1 && getTabBLOGTSX()}
                    {tabValue === 2 && getTabInfoTSX()}
                    {tabValue === 3 && getTabStudentTSX()}
                </SoftBox>
            </SoftBox>
        </PageLayout>

    )
}

export default RtSiteInstructor;
