import RestApiService from "./RestApiService";
import {bin2hex, afdObjectToUrlString} from './../../util/Util';
import {API_URL} from "./RestApiService";
import IAmocourseRegInsert from "../../type/amocourse/IAmocourseRegInsert";
import IAxiosPostData from "../../type/axios/IAxiosPostData";
import IRQOutputList from "../../type/rq/IRQOutputList";
import IRQOutputSingle from "../../type/rq/IRQOutputSingle";
import IRQQueryKey from "../../type/rq/IRQQueryKey";
import {useMutation, useQuery} from "react-query";
import {rqOptionStaticObj} from "../rqOptionStaticObj";
import linkDictionary from "../../linkDictionary";
import axios from "axios";

export  class RestApiNewbbService extends RestApiService {

    constructor() {
        super();
    }

    /*static setPostInsertUrl(afdSessionId: string,
                            afdTopicId: number,
                            afdUserId: number,
                            afdBBCodeUid: string,
                            afdPostText: string,
                            afdOrigPostId: number | null): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("custom_call", 'sPostInsert');
        myUrl.searchParams.append("session_id", afdSessionId);
        myUrl.searchParams.append("topic_id", afdTopicId.toString());
        myUrl.searchParams.append("user_id", afdUserId.toString());
        myUrl.searchParams.append("bbcode_uid", afdBBCodeUid);
        myUrl.searchParams.append("post_text", afdPostText);
        if (afdOrigPostId != null) {
            myUrl.searchParams.append("orig_post_id", afdOrigPostId.toString());
        }

        return myUrl.href;
    }*/

    static setPostInsertData(sessionId: string,
                             topicId: number,
                             afdUserId: number,
                             bBCodeUid: string,
                             postText: string,
                             origPostId: number | null,
                             postNewTopicTitle: string | null,
                             postNewTopicforumId: number | null,
                             postNewTopicType: string | null
                             ): IAxiosPostData {
        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: {
                custom_call: 'sPostInsert',
                session_id: sessionId,
                topic_id: topicId,
                user_id: afdUserId,
                bbcode_uid: bBCodeUid,
                post_text: encodeURIComponent(postText),
                post_new_topic_title: postNewTopicTitle ? encodeURIComponent(postNewTopicTitle) : '', // BUGFIX: nie może być null bo przekazuje string "null"
                post_new_topic_forum_id: postNewTopicforumId,
                post_new_topic_type: postNewTopicType,
            }
        };

        if (origPostId != null) {
            postDataObj.postData.orig_post_id = origPostId;
        }

        return postDataObj;
    }

    static setPostEditData(sessionId: string,
                           postId: number,
                           origPostId: number | null,
                           topicTitle: string | null,
                           postText: string,
    ): IAxiosPostData {
        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: {
                custom_call: 'sPostEdit',
                session_id: sessionId,
                post_id: postId,
                orig_post_id: origPostId,
                topic_title: topicTitle ? encodeURIComponent(topicTitle) : '',
                post_text: encodeURIComponent(postText),

            }
        };

        return postDataObj;
    }

    static setUserInstructorCommentInsertData(postPhpbbUserId: number,
                                                instructorUserId: number,
                                                postId: number
    ): IAxiosPostData {
        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: {
                custom_call: 'sUserInstructorCommentInsert',
                post_phpbb_user_id: postPhpbbUserId,
                instructor_user_id: instructorUserId,
                post_id: postId,
            }
        };

        return postDataObj;
    }

    static setPostReportInsertData(reasonId: number,
                                      postId: number,
                                        phpbbUserId: number,
                                        report_text: string,
                                        reportTime: string | null): IAxiosPostData {
        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: {
                custom_call: 'sPostReportInsert',
                reason_id: reasonId,
                post_id: postId,
                phpbb_user_id: phpbbUserId,
                report_text: encodeURIComponent(report_text),
            }
        }
        if (reportTime != null) {
            postDataObj.postData.report_time = reportTime;
        }

        return postDataObj;
    }


    /*https://react-query-v3.tanstack.com/reference/useMutation*/
    static setPostInsertMutation(afdSessionId: string,
                                 afdTopicId: number,
                                 afdUserId: number,
                                 afdBBCodeUid: string,
                                 afdPostText: string,
                                 afdOrigPostId: number | null) {
        /* First define the function, that returns ASYNC */
        function setPostInsertPromiseResolve() {
            return RestApiService.getPromise('', 'POST', {
                url: '',
                postData: {
                    afdSessionId: afdSessionId,
                    afdTopicId: afdTopicId,
                    afdUserId: afdUserId,
                    afdBBCodeUid: afdBBCodeUid,
                    afdPostText: afdPostText,
                    afdOrigPostId: afdOrigPostId,
                }
            }).then((response) => {
                    console.log(response);
                    return response.data;

                }
            ).catch((error) => {
                    console.log(error);
                }
            );
        }

        /* 2: Second: PUT in a react-query HOOK container */
        const {mutate, isLoading, isError, error, isSuccess, data, status, reset,} = useMutation(
            setPostInsertPromiseResolve,
            {}
        );

    };
}
