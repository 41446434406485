import RestApiService from "./RestApiService";
import {bin2hex, afdObjectToUrlString} from './../../util/Util';
import { API_URL } from "./RestApiService";

class RestApiGalleryService extends RestApiService {

    constructor() {
        super();
    }

    static setImageUserVisitMode(afdUserId: number,
                                    afdImageId: number,
                                    afdMode: string
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("image_user_visit", '1');
        myUrl.searchParams.append("user_id", afdUserId.toString());
        myUrl.searchParams.append("image_id", afdImageId.toString());
        myUrl.searchParams.append("mode", afdMode);
        return myUrl.href;
    }

    /* Odnzacz wszystkie pozycje MY Academy */
    static setMyAcademyTablePerfUserMarkAllUrl(afdPerfUserId: number,
                                               afdPageItemGroup: string
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("set_my_academy_table_perf_user_mark_all", '1');
        myUrl.searchParams.append("perf_user_id", afdPerfUserId.toString());
        myUrl.searchParams.append("page_item_group", afdPageItemGroup);
        return myUrl.href;
    }



}

export default RestApiGalleryService;
