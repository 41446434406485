import React, {useState, useEffect, useMemo, Suspense, lazy, ReactElement, ReactNode} from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import IRouteMenutype   from "./type/IRouteMenutype";
import IUserInfo from "./type/IUserInfo";

    // Images
    const brand = require('./assets/images/logo-ct.png');
    import AppRouter2Decode from "./AppRouter2Decode";
import AfdLoadingFullpage from "./component/AfdLoadingFullpage";
import IAppObj from "./type/context/IAppObj";
import CtxAppObj from "./context/CtxApp";
import breakpoints from "./assets/theme/base/breakpoints";
import {setGeneralDispatch} from "./redux_reducer/util/dispatchShortcut";
import {useCHGetContextSoftUI} from "./context/CtxSoftUIGlobalSetupByRdx";



interface IPropAppRouter {
    userInfoObj: IUserInfo;
    routeMenutypeObjList: IRouteMenutype[];
    routeAddMenutypeObjList: IRouteMenutype[];
}

export default function AppRouter({
            userInfoObj: userInfoObj,
            routeMenutypeObjList: routeMenutypeObjList,
            routeAddMenutypeObjList: routeAddMenutypeObjList
        }: IPropAppRouter
) {

    var routeMenutype  = 'mainmenu';
    var routeFlatArray: IRouteMenutype[] = new Array<IRouteMenutype>();

    const nonmenuRouteObjList: any[] = [
        {
            path: "/nowe-wpisy.html",
            key: "backend_entries",
            routeCategory: "backend_entries",
            routerMenuId: 163,
            userInfoObj: userInfoObj,
        },
        {
            path: "/Backend/Rola-Instruktor/instruktor-podsumowanie-grup.html",
            key: "backend_entries",
            routeCategory: "backend_entries",
            routerMenuId: 260,
            userInfoObj: userInfoObj,
        },
        {
            path: "/Klub-Mam-Akademii/Forum-kursowe/forum-kurs-lista-rozdzialow.html",
            key: "chapter_list",
            routeCategory: "chapter_list",
            routerMenuId: 809,
            userInfoObj: userInfoObj,
        },
        {
            path: "/Klub-Mam-Akademii/Forum-kursowe/forum-kurs-rozdzial-kursu.html",
            key: "chapter",
            routeCategory: "chapter",
            routerMenuId: 810,
            userInfoObj: userInfoObj,
        },
        {
            path: "/component/option,com_comprofiler/Itemid,71/task,userProfile/",
            key: "own_profile",
            routeCategory: "own_profile",
            routerMenuId: 71,
            userInfoObj: userInfoObj,
        },
        {
            path: "/newsletter-poradnik-ocena.html",
            key: "menu",
            routeCategory: "menu",
            routerMenuId: 225,
            userInfoObj: userInfoObj,
        },
        {
            path: "/Klub-Mam-Akademii/Forum-kursowe/klub-forum-kurs-nauka-dyplom.html",
            key: "menu",
            routeCategory: "menu",
            routerMenuId: 223,
            userInfoObj: userInfoObj,
        },
        {
            path: "/Fotografia-dziecieca/Kompetencje-fotograficzne/fotografia-dziecieca-przewodnik.html",
            key: "menu",
            routeCategory: "menu",
            routerMenuId: 120,
            userInfoObj: userInfoObj,
        },
        {
            path: "/konto-reset-hasla.html",
            key: "menu",
            routeCategory: "menu",
            routerMenuId: 488,
            userInfoObj: userInfoObj,
        },
        {
            path: "/Klub-Mam-Akademii/Funkcje-dodatkowe/klub-mam-poradnik-wewnetrzny.html",
            key: "tutorial_mid_in_param",
            routeCategory: "tutorial_mid_in_param",
            routerMenuId: 233,
            userInfoObj: userInfoObj,
        },
        {
            path: "/Mamy-Akademii/Ewolucje/ewolucje-absolwentek-akademii-fotografii-dzieciecej.html",
            key: "evolution",
            routeCategory: "evolution",
            routerMenuId: 692,
            userInfoObj: userInfoObj,
        },
        {
            path: "/Backend/Rola-Marketing/marketing-banner-list-insta-exp.html",
            key: "backend_marketing_iexp",
            routeCategory: "backend_marketing_iexp",
            routerMenuId: 331,
            userInfoObj: userInfoObj,
        },
        {
            path: "/Klub-Mam-Akademii/Forum-kursowe/backend-pretend-review.html",
            key: "menu",
            routeCategory: "menu",
                routerMenuId: 122,
            userInfoObj: userInfoObj,
        },
        {
            path: "/Backend/Rola-Koordynator-jakosci/backend-pretend-review.html",
            key: "menu",
            routeCategory: "menu",
            routerMenuId: 122,
            userInfoObj: userInfoObj,
        },
        {
            path: "/wypisz-z-newslettera.html",
            key: "menu",
            routeCategory: "menu",
            routerMenuId: 489,
            userInfoObj: userInfoObj,
        }

       /* {
            path: "/Klub-Mam-Akademii/Rejestracje-na-kursy/klub-rejestracje-uniwersytet.html",
            key: "any",
            routeCategory: "any",
            routerMenuId: 678,
            userInfoObj: userInfoObj,
        }*/
    ];


                        /* Generuj poszczególne TAGi Route na podstawie tablicy route zdefinikowanej w CONST */
                        // @ts-ignore
                        function getRoutesMenutypeJSX(
                                menutype: string,
                                allRoutes: IRouteMenutype[]
                        ): IRouteMenutype[] {
                            allRoutes.forEach((routeObj: IRouteMenutype) => {
                                /*rekurencja*/
                                if (routeObj.collapse) {
                                    return getRoutesMenutypeJSX(
                                        menutype,
                                        routeObj.collapse);
                                }

                                if (routeObj.href) {
                                    if (routeObj.component_name === 'AppPage' || 1 === 1) {
                                        var RtName = 'AppPage';
                                        // @ts-ignore
                                        routeFlatArray.push(routeObj);
                                    }
                                }

                            }); // end of MAP
                            return routeFlatArray;
                        }

        useEffect(() => {
            getRoutesMenutypeJSX(
                routeMenutype,
                routeMenutypeObjList
            );
        }
        , [routeMenutypeObjList]);

    return (
        (routeMenutypeObjList.length > 0) ?
            (<Routes>
            {
                getRoutesMenutypeJSX(
                    routeMenutype,
                    routeMenutypeObjList
                ).map((routeObj: IRouteMenutype) => (
                    <Route
                        // @ts-ignore
                        exact
                        path={routeObj.href}
                        element={
                            <AppRouter2Decode
                                userInfoObj={userInfoObj}
                                routeCategory="menu"
                                routerMenuId={routeObj.menu_id}
                                routeObj={routeObj}
                            />}
                        key={routeObj.key}/>
                ))

            }
            <Route
                path="/Szkola-fotografii-dla-Mam/Informacje-o-szkole/publiczne-lista-platnosci-pierwsza-rejestracja.html"
                element={
                    <AppRouter2Decode
                        userInfoObj={userInfoObj}
                        routeCategory="menu"
                        routerMenuId={806}
                    />}
                key={"page_806"}
            />
            <Route
                path="/Klub-Mam-Akademii/Funkcje-dodatkowe/klub-mam-galerie-przeciagnij-i-przeslij.html"
                element={
                    <AppRouter2Decode
                        userInfoObj={userInfoObj}
                        routeCategory="menu"
                        routerMenuId={761}
                    />}
                key={"page_761"}
            />
                <Route
                    path="/potwierdzenie-rejestracji-na-kurs.html"
                    element={
                        <AppRouter2Decode
                            userInfoObj={userInfoObj}
                            routeCategory="menu"
                            routerMenuId={498}
                        />}
                    key={"page_498"}
                />
                <Route
                    path="/pobierz-plakat-wyroznienia.html"
                    element={
                        <AppRouter2Decode
                            userInfoObj={userInfoObj}
                            routeCategory="menu"
                            routerMenuId={471}
                        />}
                    key={"page_471"}
                />
                <Route
                    path="/Zapisy-na-szkolenia.html"
                    element={
                        <AppRouter2Decode
                            userInfoObj={userInfoObj}
                            routeCategory="menu"
                            routerMenuId={32}
                        />}
                    key={"page_32"}
                />
            <Route
                path="/"
                element={
                    <AppRouter2Decode
                        userInfoObj={userInfoObj}
                        routeCategory="mainpage"
                    />}
                key="mainpage"
            />
            {/*<Route
                    exact
                    path="/index.php"
                    element={
                        <AppRouter2Decode
                            userInfoObj={userInfoObj}
                            routeCategory="mainpage"
                        />}
                    key="mainpage"
                />*/}
            <Route
                path="/Poradniki/*"
                element={
                    <AppRouter2Decode
                        userInfoObj={userInfoObj}
                        routeCategory="tutorial"
                    />}
                key="tutorial"
            />
            <Route
                path="/component/option,com_comprofiler/Itemid,71/lang,pl/task,userProfile/*"
                element={
                    <AppRouter2Decode
                        userInfoObj={userInfoObj}
                        routerMenuId={71}
                        routeCategory="other_profile"
                    />}
                key="other_profile"
            />

                <Route
                    path="/Forum-Akademii/Temat/klub-forum-temat.html/*"
                    element={
                        <AppRouter2Decode
                            userInfoObj={userInfoObj}
                            routeCategory="newbb"
                            routerMenuId={577}
                            routeObj={routeAddMenutypeObjList.find((routeObj: IRouteMenutype) => routeObj.menu_id == 577)}
                        />}
                    key="newbb"
                />


                {/*Course desc*/}
                <Route
                    path="/Szkola-fotografii-dla-Mam/Opisy-kursow-przez-Internet/*"
                    element={
                        <AppRouter2Decode
                            userInfoObj={userInfoObj}
                            routeCategory="course_desc"
                        />}
                    key="course_desc"
                />
                    <Route
                        path="/Szkola-fotografii-dla-Mam/Opis-kursu/*"
                        element={
                            <AppRouter2Decode
                                userInfoObj={userInfoObj}
                                routeCategory="course_desc"
                            />}
                        key="course_desc"
                    />
                    <Route
                        path="/Galerie/Fotografia-dziecieca/*"
                        element={
                            <AppRouter2Decode
                                userInfoObj={userInfoObj}
                                routeCategory="gallery"
                            />}
                        key="gallery"
                    />
            <Route
                path="/klub-mam-logowanie.html"
                element={
                    <AppRouter2Decode
                        userInfoObj={userInfoObj}
                        routeCategory="login_page"
                    />}
                key="login_page"
            />
                    {/*Additional nonmenuRouteObjList*/}
                        {nonmenuRouteObjList.map((route) => {
                            const element = (
                                <AppRouter2Decode
                                    userInfoObj={route.userInfoObj}
                                    routeCategory={route.routeCategory}
                                    routerMenuId={route.routerMenuId}
                                />
                            );
                            return (
                                <Route
                                    path={route.path}
                                    element={element}
                                    key={route.key}
                                />
                            );
                        })}
                <Route
                    path="/:id"
                    element={
                        <AppRouter2Decode
                            userInfoObj={userInfoObj}
                            routeCategory="any"
                            routeRand={Math.floor(Math.random() * 10000)}
                        />}
                    key="any"
                />
                <Route
                    path="*"
                    element={
                        <AppRouter2Decode
                            userInfoObj={userInfoObj}
                            routeCategory="any"
                            routeRand={Math.floor(Math.random() * 10000)}
                        />}
                    key="any"
                />
            </Routes>) :
            <AfdLoadingFullpage
                componentCategory="AppRouter"
            />
    );
}

{/*<Route path="*" element={<Navigate to="/dashboards/default" />} />*/}
/*
<Route
    // @ts-ignore
    exact
    path="/"
    element={<AppRouter2Decode userInfoObj={userInfoObj} />}
    key="root"
    routeCategory="start"
    routerMenuId={0}
/>*/

