import React from 'react';
import Icon from "@mui/material/Icon";

function AfdConfigCloseIcon({
                                onClick: onClick,
                                iconName: iconName,
                            }: any) {
    return (
        <Icon
            sx={
                (
                    {
                        /*// @ts-ignore*/
                        typography: { size, fontWeightBold },
                        /*// @ts-ignore*/
                        palette: { dark }
                    }
                ) => ({
                    fontSize: `${size.md} !important`,
                    fontWeight: `${fontWeightBold} !important`,
                    stroke: dark.main,
                    strokeWidth: "2px",
                    cursor: "pointer",
                    mt: 2,
                })
            }
            onClick={onClick}
        >
            {iconName}
        </Icon>
    );
}

export default AfdConfigCloseIcon;
