import React, {useState, useEffect} from "react";
import {Form, FormikErrors, FormikHelpers, FormikProps, useFormik} from 'formik';
import AfdConfiguratorUser from "./component/AfdConfiguratorUser";

// react-github-btn
import GitHubButton from "react-github-btn";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";

import AfdColorSelect from "./component/AfdColorSelect";

// Custom styles for the Configurator
import ConfiguratorRoot from "../../examples/Configurator/ConfiguratorRoot";


import {useCHGetContextSoftUI} from "../../context/CtxSoftUIGlobalSetupByRdx";
/* These are reducer functions but in SoftUI are put in context */
import {
    setOpenConfigurator,
    setTransparentSidenav,
    setMiniSidenav,
    setFixedNavbar,
    setSidenavColor,
    setGeneralDispatch
} from "../../redux_reducer/util/dispatchShortcut";

import AfdSidenavSelect from "./component/AfdSidenavSelect";
import AfdCheckboxSelect from "./component/AfdCheckboxSelect";
import AfdVertButtonGroup from "./component/AfdVertButtonGroup";
import AfdThankYou from "./component/AfdThankYou";
import AfdConfigCloseIcon from "./component/AfdConfigCloseIcon";
import IJos4Menu from "../../type/IJos4Menu";

import parse from "html-react-parser";
import {InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import IRedirectionInfo from "../../type/IRedirectionInfo";
import IUserInfo from "../../type/IUserInfo";
import AfdConfiguratorAdminPanel from "../../examples/Configurator/AfdConfiguratorAdminPanel";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import {isDev} from "../../util/isDev";

interface IProp {
    menuItemObj: IJos4Menu;
    redirectionObj?: IRedirectionInfo;
    userInfoObj: IUserInfo;
    routeCategory: string;
    selectorTSX: React.ReactNode
}

function AfdConfigurator(
    {
        menuItemObj,
        redirectionObj,
        userInfoObj,
        routeCategory,
        selectorTSX
    }: IProp
) {
    // @ts-ignore
    const [controlerParamObj, rdxConfigDispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const {
        openConfigurator,
    } =
        controlerParamObj;
    const [disabled, setDisabled] = useState(false);


    // Use the useEffect hook to change the button state for the sidenav type based on window size.
    useEffect(() => {
        // A function that sets the disabled state of the buttons for the sidenav type.
        function handleDisabled() {
            return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
        }

        // The event listener that's calling the handleDisabled function when resizing the window.
        window.addEventListener("resize", handleDisabled);

        // Call the handleDisabled function to set the state with the initial value.
        handleDisabled();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleDisabled);
    }, []);

    const handleCloseConfigurator = () => setOpenConfigurator(rdxConfigDispatchFunction, false);

    /* Wrapper dla każdego typu tresci
       - standardowo wchodzi SELECTOR / position = "drawer" - w DB jako LOCAL przypisywany dla poszczególnych PAGE_ITEM
       - konfigurator PANEL jako opcja alternatywna dla DEV
     */
    return (
        /*// @ts-ignore*/
        <ConfiguratorRoot variant="permanent" ownerState={{openConfigurator}}>

            {/*Panel administratora*/}
            {
                isDev() && <AfdConfiguratorAdminPanel html={menuItemObj.name}
                                                     handleCloseFunction={handleCloseConfigurator}
                                                     userInfoObj={userInfoObj}
                                                     menuItemObj={menuItemObj} redirectionObj={redirectionObj}
                                                     routeCategory={routeCategory}/>
                }
            <SoftBox
                display="flex"
                justifyContent="flex-start"
                alignItems="baseline"
                pt={{
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3
                }
                }
                pl={{
                    xs: 2,
                    sm: 2,
                    md: 1,
                }}
                pb={0.8}
                pr={{xs: 1, sm: 1, md: 1, lg: 1, xl: 1}}
                height={"100%"}
                width={"100%"}
                position={"relative"}
            >
                {selectorTSX}
                <SoftBox
                    position={"absolute"}
                    right={{
                        xs: "5%",
                        sm: "7%",
                        md: "10%",
                        lg: "10%",
                        xl: "10%"
                        }
                    }
                    top={"2%"}
                ><AfdConfigCloseIcon
                    onClick={handleCloseConfigurator}
                    iconName="close"
                /></SoftBox>
            </SoftBox>
        </ConfiguratorRoot>
    );
}

export default AfdConfigurator;
