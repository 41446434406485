import React from "react";
import {getMovieVimeoGlobal} from "../../../../util/UtilReactElement";

const ElementMovieVimeoGlobal = ({
                                     row,
                                     controllerInfoObj
                                 }: any) => {

    var video_id = controllerInfoObj.const_param1;
    var video_type = controllerInfoObj.const_param2;
    /*var dict_code = JRequest.getVar('dict_code');*/
    var instructor_letter = controllerInfoObj.const_param5;
    if (!instructor_letter) instructor_letter = 'M';
    return getMovieVimeoGlobal(video_id);
}

export default ElementMovieVimeoGlobal;
