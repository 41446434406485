import ReactTooltip from "react-tooltip";
import React from "react";
import AfdInstructorTooltip  from "./content/AfdInstructorTooltip";
import AfdUserTooltip from "./content/AfdUserTooltip";
import GetCourseStats from "../sourcerer/course_desc/GetCourseStats";
import SoftBox from "../../components/SoftBox";

interface IProp {
    tooltipId: string,
    tipContent: string | React.ReactNode,
}


/*https://www.npmjs.com/package/react-tooltip*/
function AfdTooltipAmocourseInstr(
    {
        tooltipId,
        tipContent
    }: IProp
) {


    function getElementTooltip() {
            return <SoftBox
                display="block"
                justifyContent="center"
                /*width="40%" height="100%" alignItems="center"*/

              /*  width={{
                    xs: "100%",
                    sm: "100%",
                    md: "70%",
                    lg: "30%",
                    xl: "30%"
                }}*/
            >{tipContent}</SoftBox>
    }


    return <ReactTooltip
        id={tooltipId}
        textColor={"#000000"}
        /*backgroundColor={"white"}*/
        type={"light"} //dark, success, warning, error, info, light
        delayShow={100}
        delayHide={100}
        getContent={(dataTip: any) =>
            getElementTooltip()
        }
    >
        {/**/}
    </ReactTooltip>;
}

export default AfdTooltipAmocourseInstr;
