import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom"; // npm i react-router-dom --save
import AfdContainer from "../component/AfdContainer";
import IContainerQuery from "../type/IContainerQuery";
import IParamObj from "../type/IParamObj";
import ISelector from "../type/ISelector";
import RestApiService from "../service/restapi/RestApiService";
import RestApiCommonService from "../service/restapi/RestApiCommonService";
import RestApiAmocourseService from "../service/restapi/RestApiAmocourseService";
import {debugLog, debugDir} from "../util/UtilReact";

import AfdLoadingFullpage from "../component/AfdLoadingFullpage";
import {processContainer, processElement, setCalculateTotal} from "../util/UtilReact";
import RestApiAuthorizationService from "../service/restapi/RestApiAuthorizationService";
import IAmocourseTotalArray from "../type/amocourse/IAmocourseTotalArray";
import IAmocoursePriceInfo from "../type/amocourse/IAmocoursePriceInfo";
import CtxAmocourse from "../context/CtxAmocourse";
import IRQOutputList from "../type/rq/IRQOutputList";
import IGalleryImageInfo from "../type/gallery/IGalleryImageInfo";
/* take the container list and slect those which are  relevant for this position *
 */
const AfdSelectorProfile = ({
                               menuItemObj: menuItemObj,
                               userInfoObj: userInfoObj,
                               position: position,
                               containerAssocList: containerAssocList,
                               redirectionObj: redirectionObj,
                               formikGantry: formikGantry,
                               setFormikParamObj: setFormikParamObj
                           }: ISelector) => {

    const useQuery = () => new URLSearchParams(useLocation().search);
    const queryHookObj = useQuery();

    let email_enc = queryHookObj.get('enc');
    let Itemid = queryHookObj.get('Itemid');
    let task = queryHookObj.get('task');

    /* urlUserId - w tym miejscu już bazujemy na USER przekazanym w REDIRECTION z uwzględnieniem formatu:
        /component/option,com_comprofiler/Itemid,71/lang,pl/task,userProfile/user,
     */
    function setParamObjSwitch(queryHookObj: any) {
        let targetPosition: number;
        let isMobile = 0;

         if (menuItemObj.id == 71) {
            let url_user_id: number  = (redirectionObj?.object_user_id) ? redirectionObj?.object_user_id : userInfoObj.user_id;
                setParamObj({
                    position_param0: url_user_id.toString(),
                    position_param1: task,
                });
                // TODO
        }
    }


    var promiseArray: Promise<any>[] = [];

    const [paramObj, setParamObj] = useState<IParamObj>({});
    const [isDataReady, setIsDataReady] = useState(false);

    /* After there is promiseArray - execute */
    useEffect(() => {

            setParamObjSwitch(queryHookObj);
            setIsDataReady(true);
        },
        /* rerender after page change */
        [
            menuItemObj.id,
            redirectionObj?.object_user_id,

        ]
    );

    /* check if any containers exist for the position */
    return (
        <>
            {
                (typeof containerAssocList !== 'undefined'
                    && typeof paramObj !== "undefined"
                    && isDataReady === true
                        ? containerAssocList.map((element: IContainerQuery) => (
                            <AfdContainer
                                key={element.sequence}
                                menuItemObj={menuItemObj}
                                userInfoObj={userInfoObj}
                                position={position}
                                controllerInfoObj={element}
                                paramObj={paramObj}
                                redirectionObj={redirectionObj}
                                formikGantry={formikGantry}

                            />
                        )) :
                        <AfdLoadingFullpage
                            componentCategory={"AfdSimpleSelector"}
                            componentInfo={"p:" + position + "_m:" + menuItemObj.id + "_u:" + userInfoObj.user_id}
                        />
                )
            }
        </>
    );
}

export default AfdSelectorProfile;
