
const ElementButtonFormChatNext = lazy(() => import("./ElementButtonFormChatNext")); // page_item_id: 1
import ElementGlobalMenuNewsletterForm from "./ElementGlobalMenuNewsletterForm"; // page_item_id: 1

import React, {useEffect, useState} from "react";
import { lazy , Suspense } from "react";
import RestApiService from "../../../../service/restapi/RestApiService";
import RestApiCommonService from "../../../../service/restapi/RestApiCommonService";
import {camelize, capitalize} from "../../../../util/Util";
import {processContainer, processElement} from "../../../../util/UtilReact";
import Emptybox from "../../../emptybox/Emptybox";
import AfdLoadingFullpage from "../../../AfdLoadingFullpage";
import AfdContainerCard from "../../../AfdContainerCard";

import Grid from "@mui/material/Grid";
import SoftBox from "../../../../components/SoftBox";


/*ng-click="psedudoScrollNextPage()"*/
//{containerRow.post_count}
const GetSiteNewsletterButtonForm = ({
                                 controllerInfoObj: controllerInfoObj,
                                 userInfoObj: userInfoObj,
                                 paramObj: paramObj
}: any) => {
    const components: any = {
        ElementButtonFormChatNext,
ElementGlobalMenuNewsletterForm,

    }
    var ElementComponent = components[controllerInfoObj.element1CamelName];
    var containerDefaultRow: any;

    /*Pobierz dane element*/
    const [elementObjList, setElementObjList] = useState([]);
    const [containerInfoObj, setContainerInfoObj] = useState(containerDefaultRow);
    useEffect(() => {
        Promise.all(
            [RestApiService.getPromise(RestApiCommonService.getElementQueryUrl(
                        controllerInfoObj,
                        {
                            type: 0,
                            object2_id: 0
                        },
                        0,
                        0
                    )),
                    RestApiService.getPromise(RestApiCommonService.getContainerQueryUrl(
                        controllerInfoObj,
                        {
                            object_id: 0,
                            object2_id: 0
                        },
                        0,
                        0
                    ))
            ]).then((responseArray) => {
                setElementObjList(processElement(responseArray[0].data, controllerInfoObj));
                setContainerInfoObj(processContainer(responseArray[1].data[0], controllerInfoObj));
            });


    }, []);

    function getContainerContent()  {
        let row = containerInfoObj;
             if (controllerInfoObj.containerName === 'CONTAINER_SITE_NEWSLETTER_BUTTON_FORM')    {
            return (
                <>
                     {
                    elementObjList.map((element: any) => (
                        <ElementComponent
                            key={element.client_track_id}
                            row={element}
                        />
                    ))
                }
                </>
            );
        }
    }

    return (
        <>
        <Suspense fallback={ <AfdLoadingFullpage
            componentCategory="AfdController_Suspense"
            controllerInfoObj={controllerInfoObj}
        />}>
        </Suspense>
            {
                (elementObjList?.length > 0 && containerInfoObj) ?
                    (
                        <AfdContainerCard
                            controllerInfoObj={controllerInfoObj}
                            userInfoObj={userInfoObj}
                            paramObj={paramObj}
                            elementObjList={elementObjList}
                        >
                            {getContainerContent()}
                        </AfdContainerCard>

                    ) : (<Emptybox
                        controllerInfoObj={controllerInfoObj}
                        userInfoObj={userInfoObj}
                        paramObj={paramObj}
                        elementObjList={elementObjList}
                    />)
            }
        </>
    );
}

export default GetSiteNewsletterButtonForm;
