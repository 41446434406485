import React, {useEffect, useState} from "react";
import {debugLog, getPosition2ContainerMap} from "../../util/UtilReact";

// @mui material components

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";

// Soft UI Dashboard PRO React example components

import AfdSelectorNewbb from "../../selector/AfdSelectorNewbb";
import useCHGantryData from "../../hook/useCHGantryData";
import IPropGantry from "../../type/IPropGantry";

import ISelectorPosition from "../../type/ISelectorPosition";
import Grid from "@mui/material/Grid";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import CtxClubNewbb from "../../context/CtxClubNewbb";
import AppBar from "@mui/material/AppBar";
import {navbar, navbarContainer, navbarRow} from "../../component/controller/get_club_dashboard_navbar/styles";
import Toolbar from "@mui/material/Toolbar";
import Icon from "@mui/material/Icon";
import useCHGetContextSoftUI from "../../hook/useCHGetContextSoftUI";
import {useLocation, useNavigate} from "react-router-dom";
import {setMiniSidenav, setOpenConfigurator, setTransparentNavbar} from "../../redux_reducer/util/dispatchShortcut";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import ConfigButtonFactory from "../../examples/Configurator/ConfigButtonFactory";
import AfdConfigurator from "../../examples/Configurator/AfdConfigurator";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import IElementNewbbTopicPost from "../../type/newbb/IElementNewbbTopicPost";
import IGetBeginnerGroupActive from "../../type/newbb/IGetBeginnerGroupActive";
import useCHForumDecodeInfoCurrent from "../../hook/useCHForumDecodeInfoCurrent";
import useCHTopicDecodeInfoCurrent from "../../hook/useCHTopicDecodeInfoCurrent";
import useCHSourcererData from "../../hook/useCHSourcererData";
import IElementNewbbUniForumInfo from "../../type/newbb/IElementNewbbUniForumInfo";
import IElementNewbbAnchorPost from "../../type/newbb/IElementNewbbAnchorPost";
import {extractDigitsFromString} from "../../util/UtilTS";

export type IOrderByCode = "CREATED_DATE" | "REPLY_COUNT" | "REPLY_LAST_DATETIME";

function RtClubNewbb({
                         menuItemObj,
                         containerObjList,
                         userInfoObj,
                         routeCategory,
                         redirectionObj
                     }: IPropGantry) {


    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    var [menuItemListObj, setMenuItemListObj] = useCHGantryData(
        menuItemObj,
        containerObjList,
        userInfoObj,
        routeCategory,
        redirectionObj
    );
    const [storeOrigPostObj, setStoreOrigPostObj] = useState<IElementNewbbTopicPost | null>(null);
    const [storeNewPostInfoObj, setStoreNewPostInfoObj] = useState<{
        post_id: number,
        topic_id: number,
        post_text: string,
        poster_jos4_user_id: number,
        reply_to_orig_id: number | null,
        post_new_topic_title: string | null,

    } | null>(null);
    const [storeDeletePostObj, setStoreDeletePostObj] = useState<IElementNewbbTopicPost | null>(null);
    const [storeNewbbSelectedUserObj, setStoreNewbbSelectedUserObj] = useState<IGetBeginnerGroupActive | null>(null);
    const [storeActiveAnchorPostId, setStoreActiveAnchorPostId] = useState<string | null>(null);
    /*Sortowanie*/
    const [storeOrderByCode, setStoreOrderByCode] = useState<IOrderByCode>("REPLY_LAST_DATETIME");

    const navigate = useNavigate();
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatchFunction, true);

    const useQuery = () => new URLSearchParams(useLocation().search);
    const queryHookObj = useQuery();

    /* NEWBB: 577
    Nie pakuj useQUERY w IF, is Enabled powoduje, że zmienne dostępne globalnie */
    let isNewbbEnabled = (menuItemObj.id == 577);
    let urlDisForumId: number | null = queryHookObj.get('f') ? Number.parseInt(queryHookObj.get('f') as string) : null;
    let urlDisTopicId: number | null = queryHookObj.get('t') ? Number.parseInt(queryHookObj.get('t') as string) : null;
    let urlDisPostId: number | null = queryHookObj.get('p') ? Number.parseInt(queryHookObj.get('p') as string) : null;
    let urlAnchorPostId: string | null = window.location.hash.substring(1);

    // const [anchorPostId, setAnchorPostId] = useState<number | null>(null);
    const [elementCurrentForumInfoObjList, setElementCurrentForumInfoObjList, refetchElementCurrentForumInfoObjList] = useCHForumDecodeInfoCurrent({
            forum_id: urlDisForumId ? urlDisForumId : -1,
            topic_id: urlDisTopicId ? urlDisTopicId : -1,
            userInfoObj: userInfoObj,
            isCourseInfo: false,
            isEnabled: (isNewbbEnabled && urlDisForumId != null && urlDisTopicId != null),
        },
    );
    /* Pobiera informacje także XX_COURSE_CALENDAR:
    - pobiera informacje o kursie dla NONUNI natomiast Uni pobiera z własnych HOOKs
    *  */
    const [elementTopicAnyUserInfoObjList, setElementTopicAnyUserInfoObjList, refetchElementTopicInfoObjList] = useCHTopicDecodeInfoCurrent(
        {
            topic_id: urlDisTopicId ? urlDisTopicId : -1,
            userInfoObj: userInfoObj,
            isEnabled: (isNewbbEnabled && urlDisForumId != null && urlDisTopicId != null),
        }
    );
    const [elementUniForumInfoObjList, setElementUniForumInfoObjList] = useCHSourcererData<IElementNewbbUniForumInfo>(
        'get_forum_decode_info_current',
        'sql_query4',
        {
            dis_forum_id: urlDisForumId,
        },
        isNewbbEnabled
    );
    const [elementAnchorPostObjList, setElementAnchorPostObjList] = useCHSourcererData<IElementNewbbAnchorPost>(
        'get_forum_decode_info_current',
        'sql_query5',
        {
            url_anchor_post_id: extractDigitsFromString(urlAnchorPostId),
        },
        (!!urlAnchorPostId)
    );

    debugLog('useEffect 577 elementCurrentForumInfoObjList' + elementCurrentForumInfoObjList.length);
    debugLog('useEffect 577 elementTopicAnyUserInfoObjList' + elementTopicAnyUserInfoObjList.length);
    debugLog('useEffect 577 elementUniForumInfoObjList' + elementUniForumInfoObjList.length);
    debugLog('useEffect 577 elementAnchorPostObjList' + elementAnchorPostObjList.length);

    function getGalleryImageListButtonTSX() {
        return <GroupsOutlinedIcon
            sx={{
                /*color: "primary.main",*/
            }}
        />;
    }

    let configButtonTSX = ConfigButtonFactory(
        handleConfiguratorOpen,
        getGalleryImageListButtonTSX()
    );


    const [tabValue, setTabValue] = useState<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8>(1);
    /* Jeżęli wyliczona kotwica - ustaw praametry: TAB i active anchor
      - ustaw jednorazowo, a potem już nie
      * */
    useEffect(() => {
            if (elementAnchorPostObjList[0]?.post_id &&
                !storeActiveAnchorPostId
            ) {
                /* Uni or NOT */
                if (elementUniForumInfoObjList.length && elementCurrentForumInfoObjList[0]?.system_type == 'SYSTEM_TYPE_UNI') {
                    setStoreActiveAnchorPostId(elementAnchorPostObjList[0]?.post_id.toString());
                    /*setTabValue(6);*/
                } else {
                    setStoreActiveAnchorPostId(elementAnchorPostObjList[0]?.post_id.toString());

                }

            } else {
                null
            }
        }
        , [
            /*tabValue,*/ /* BUGFIX: nie może tu być tabValue ponieważ jednorazowo a nie po przełączeniu TAB */
            elementAnchorPostObjList.length,
            elementCurrentForumInfoObjList.length,
            elementUniForumInfoObjList.length,
        ]);
    useEffect(() => {
        if (elementUniForumInfoObjList.length > 0 &&
            elementUniForumInfoObjList[0]?.discussion_topic_id == elementAnchorPostObjList[0]?.topic_id) {
            setTabValue(2);
        } else if (elementUniForumInfoObjList.length > 0 &&
            elementUniForumInfoObjList[0]?.forum_post_half_topic_id == elementAnchorPostObjList[0]?.topic_id) {
            setTabValue(5);
        } else if(elementUniForumInfoObjList.length > 0 &&
            elementUniForumInfoObjList[0]?.forum_post_submit_topic_id == elementAnchorPostObjList[0]?.topic_id) {
            setTabValue(6);
        } else if(elementUniForumInfoObjList.length > 0 &&
            elementUniForumInfoObjList[0]?.forum_post_final_topic_id == elementAnchorPostObjList[0]?.topic_id) {
            setTabValue(7);
        } else if (elementAnchorPostObjList[0]?.topic_type == 2) {
            setTabValue(4);
        }


    },
        [
            storeActiveAnchorPostId]
    );


    const position2ContainerMap = getPosition2ContainerMap(containerObjList);

    /* App bar */
    let absolute: boolean = false;
    let light: boolean = false;
    let isMini: boolean = false;


    const [navbarType, setNavbarType] = useState('');
    const [controller, dispatch] = useCHGetContextSoftUI();
    const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator} = controller;
    const route = useLocation().pathname.split("/").slice(1);


    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        /* A function that sets the transparent state of the navbar.
        - either FIXED and position 0
        or
        - not FIXED
         */
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

    const getSelectorPositionJSX = function (positionCode: ISelectorPosition): JSX.Element | null {
        if (position2ContainerMap[positionCode]?.length > 0) {

            /* BUGFIX: disableGutters nie działa */
            return (
                <AfdSelectorNewbb
                    key={positionCode}
                    menuItemObj={menuItemObj}
                    userInfoObj={userInfoObj}
                    position={positionCode}
                    redirectionObj={redirectionObj}
                    containerAssocList={position2ContainerMap[positionCode]}
                />
            );
        } else {
            return null;
        }

    }


    function getTabBody1() {
        return <Grid container spacing={1}>
            {/* MAIN container*/}
            <Grid item xs={12} md={12} lg={12}>
                {getSelectorPositionJSX('content-top-a')}
                {
                    userInfoObj.is_tech_account == 1 ?
                        getSelectorPositionJSX('admin-instructor-toolbar') : null
                }
                {/*zaznaczanie Oli dla CHAT*/}
                {getSelectorPositionJSX('body')}
                {getSelectorPositionJSX('content-bottom-a')}

            </Grid>
            {/*<Grid item xs={12} md={3} lg={3}
            >
                {getSelectorPositionJSX('sidebar-a')}
                {getSelectorPositionJSX('sidebar-b')}
                {getSelectorPositionJSX('sidebar-c')}
            </Grid>*/}
        </Grid>;
    }

    function getTabBody2() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-tab2-body')}
                </SoftBox>
            </Grid>
        </Grid>;
    }

    function getTabBody3() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-tab3-body')}
                </SoftBox>
            </Grid>
            {/*<Grid item xs={6} lg={6}>
                <SoftBox my={3}>

                </SoftBox>
            </Grid>*/}
        </Grid>;
    }

    function getTabBody4() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-tab4-body')}
                </SoftBox>
            </Grid>
            {/*<Grid item xs={6} lg={6}>
                <SoftBox my={3}>

                </SoftBox>
            </Grid>*/}
        </Grid>;
    }

    function getTabBody5() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-tab5-body')}
                </SoftBox>
            </Grid>
            {/*<Grid item xs={6} lg={6}>
                <SoftBox my={3}>

                </SoftBox>
            </Grid>*/}
        </Grid>;
    }

    function getTabBody6() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-tab6-body')}
                </SoftBox>
            </Grid>
            {/*<Grid item xs={6} lg={6}>
                <SoftBox my={3}>

                </SoftBox>
            </Grid>*/}
        </Grid>;
    }

    function getTabBody7() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-tab7-body')}
                </SoftBox>
            </Grid>
            {/*<Grid item xs={6} lg={6}>
                <SoftBox my={3}>

                </SoftBox>
            </Grid>*/}
        </Grid>;
    }

    function getTabBody8() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-tab8-body')}
                </SoftBox>
            </Grid>
            {/*<Grid item xs={6} lg={6}>
                <SoftBox my={3}>

                </SoftBox>
            </Grid>*/}
        </Grid>;
    }


    function getContent() {


        return (
            <>
                <CtxClubNewbb.Provider value={{
                    storeOrigPostObj: storeOrigPostObj,
                    setStoreOrigPostObj: setStoreOrigPostObj,
                    storeNewPostInfoObj: storeNewPostInfoObj,
                    setStoreNewPostInfoObj: setStoreNewPostInfoObj,
                    storeDeletePostObj: storeDeletePostObj,
                    setStoreDeletePostObj: setStoreDeletePostObj,
                    storeNewbbSelectedUserObj: storeNewbbSelectedUserObj,
                    setStoreNewbbSelectedUserObj: setStoreNewbbSelectedUserObj,
                    tabValue: tabValue,
                    setTabValue: setTabValue,
                    storeActiveAnchorPostId: storeActiveAnchorPostId,
                    setStoreActiveAnchorPostId: setStoreActiveAnchorPostId,
                    storeNewbbForumInfoObj: elementCurrentForumInfoObjList[0],
                    storeNewbbTopicInfoObj: elementTopicAnyUserInfoObjList[0],
                    storeNewbbUniForumInfoObj: elementUniForumInfoObjList[0],
                    storeNewbbAnchorPostObj: elementAnchorPostObjList[0],
                    storeOrderByCode: storeOrderByCode,
                    storeSetOrderByCode: setStoreOrderByCode,
                    urlDisForumId: urlDisForumId,
                    urlDisTopicId: urlDisTopicId,
                    urlDisPostId: urlDisPostId,
                    urlAnchorPostId: urlAnchorPostId,

                }}>
                    {getSelectorPositionJSX('showcase-a')}
                    <DashboardLayout>

                        <AppBar
                            // @ts-ignore
                            position={absolute ? "absolute" : navbarType}
                            color="inherit"
                            sx={
                                (theme) => navbar(
                                    theme,
                                    {
                                        transparentNavbar, absolute, light
                                    }
                                )
                            }
                        >
                            <Toolbar sx={(theme) => navbarContainer(theme)}>
                                <SoftBox color="inherit" mb={{xs: 1, md: 0}}
                                         sx={(theme: any) => navbarRow(theme, {isMini})}
                                         display="flex"
                                         flexDirection={globalConfigParamObj.isMobile ? "column" : "row"}
                                >
                                    <SoftBox
                                        sx={globalConfigParamObj.isMobile ? {order: 2,} : {order: 1,}}
                                    >{getSelectorPositionJSX('top-a')}</SoftBox>
                                    <SoftBox
                                        sx={globalConfigParamObj.isMobile ? {order: 1,} : {order: 2,}}
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        width={globalConfigParamObj.isMobile ? "100%" : "auto"}
                                    ><Icon fontSize="medium"
                                        /*sx={navbarDesktopMenu}*/
                                           onClick={handleMiniSidenav}>
                                        {miniSidenav ? "menu_open" : "menu"}
                                    </Icon>
                                        {getSelectorPositionJSX('navigation')}
                                    </SoftBox>

                                </SoftBox>


                            </Toolbar>
                        </AppBar>

                        <SoftBox
                            display="flex"
                            flexDirection={"row"}
                            justifyContent="space-evenly"
                            alignItems="center"
                        >
                            {getSelectorPositionJSX('maintop-a')}
                        </SoftBox>
                        <SoftBox mt={2}>
                            {tabValue === 1 && getTabBody1()}
                            {tabValue === 2 && getTabBody2()}
                            {tabValue === 3 && getTabBody3()}
                            {tabValue === 4 && getTabBody4()}
                            {tabValue === 5 && getTabBody5()}
                            {tabValue === 6 && getTabBody6()}
                            {tabValue === 7 && getTabBody7()}
                            {tabValue === 8 && getTabBody8()}

                        </SoftBox>
                        <SoftBox
                            display="flex"
                            flexDirection={"column"}
                            justifyContent="space-evenly"
                            alignItems="center"
                        >
                            {getSelectorPositionJSX('mainbottom-a')}
                            {getSelectorPositionJSX('bottom-a')}
                        </SoftBox>
                        {getSelectorPositionJSX('module-footer-a')}
                        {
                            (menuItemObj)
                                ?
                                <AfdConfigurator
                                    menuItemObj={menuItemObj}
                                    redirectionObj={redirectionObj}
                                    userInfoObj={userInfoObj}
                                    routeCategory={routeCategory}
                                    selectorTSX={getSelectorPositionJSX('drawer')}
                                /> : null
                        }
                        {/*TODO*/}
                        {
                            menuItemObj.id != 617 ?
                                configButtonTSX : null}

                    </DashboardLayout>
                </CtxClubNewbb.Provider>
            </>
        );
    }

    return (
        <>
            {
                (elementCurrentForumInfoObjList.length > 0
                    /*&& (urlDisTopicId && elementTopicAnyUserInfoObjList.length > 0 || !urlDisTopicId)*/
                    && elementTopicAnyUserInfoObjList.length > 0
                    && (elementUniForumInfoObjList.length > 0 || elementCurrentForumInfoObjList[0].system_type !== 'SYSTEM_TYPE_UNI')
                    && (elementAnchorPostObjList.length > 0 || !urlAnchorPostId)
                ) ?
                    getContent()
                    :
                    <>  </>
            }

        </>
    );
}

export default RtClubNewbb;
