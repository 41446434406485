import React, {useEffect, useState} from "react";
import Emptybox from "../../../emptybox/Emptybox";
import AfdLoadingFullpage from "../../../AfdLoadingFullpage";
import AfdContainerCard from "../../../AfdContainerCard";
import { lazy , Suspense } from "react";

import useCHContainerData from "../../../../hook/useCHContainerData";

import IPropContainer from "../../../../type/IPropContainer";
// @ts-ignore
import { FacebookProvider, Page } from 'react-facebook';

import Grid from "@mui/material/Grid";
import SoftBox from "../../../../components/SoftBox";
import CtxAppObj from "../../../../context/CtxApp";


/*ng-click="psedudoScrollNextPage()"*/
//{containerRow.post_count}
const GetSiteMainpageMainFacebook = ({
                                 controllerInfoObj: controllerInfoObj,
                                 userInfoObj: userInfoObj,
                                 paramObj: paramObj
}: IPropContainer) => {
    const appObj = React.useContext(CtxAppObj);
    const components: any = {

    }
    if (controllerInfoObj.element1CamelName !== undefined) {
        const ElementComponent = components[controllerInfoObj.element1CamelName];
        var [elementObjList, getElementObjList, containerInfoObj, setContainerInfoObj] = useCHContainerData(controllerInfoObj);

    }

    function getContainerContent()  {
        let row = containerInfoObj;
        let fanpageUrl = 'http://www.facebook.com/Akademia.Fotografii.Dzieciecej';
             if (controllerInfoObj.containerName === 'CONTAINER_SITE_MAINPAGE_FACEBOOK')    {
            return (
                <>
                    <FacebookProvider appId={appObj.facebookAppId}>
                        <Page href={fanpageUrl}
                        /*      tabs="timeline"*/
                        />
                    </FacebookProvider>

                    {/*                    <div className="fb-page" data-href="http://www.facebook.com/Akademia.Fotografii.Dzieciecej"
                         data-width="'.$width.'"
                         data-small-header="false" data-adapt-container-width="true" data-hide-cover="false"
                         data-show-facepile="true" data-show-posts="false">
                        <div className="fb-xfbml-parse-ignore">
                            <blockquote cite="http://www.facebook.com/Akademia.Fotografii.Dzieciecej">
                                <a href="http://www.facebook.com/Akademia.Fotografii.Dzieciecej">Akademia Fotografii
                                    Dziecięcej</a>
                            </blockquote>
                        </div>
                    </div>*/}
                </>
            );
        }
    }

    return (
        <>
        <Suspense fallback={ <AfdLoadingFullpage
            componentCategory="AfdController_Suspense"
            controllerInfoObj={controllerInfoObj}
        />}>
        </Suspense>
            <AfdContainerCard
                controllerInfoObj={controllerInfoObj}
                userInfoObj={userInfoObj}
                paramObj={paramObj}
                elementObjList={elementObjList}
            >
                            {getContainerContent()}
            </AfdContainerCard>
        </>
    );
}

export default GetSiteMainpageMainFacebook;
