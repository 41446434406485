
import React from "react";

import Grid from "@mui/material/Grid";
import SoftBox from "../../../../components/SoftBox";

const ElementGlobalMenuNewsletterForm = ({row}: any) => {
    return (
       <>
                <div className="divTable">
<div className="divTableBody">



<div className="divTableRow">
<div className="divTableCell content_next_chat">
{row.content_chat_next}

Pobierz:
<h3>{row.item_name}</h3>
<div>{row.link_description}</div>
</div>
</div>
<div className="divTableRow">
<div className="divTableCell content_button">
&lt;form name="adminForm"&gt;
{row.content_form_field_list}
<div className="content_success_message">Link do Twojego eBooka wysłaliśmy właśnie emailem. Sprawdź skrzynkę email.<br />Miłej lektury :)</div>
&lt;/form&gt;
</div>
</div>

</div>

<div className="divTableFooting">
<div className="divTableFoot">
<a className="content_cancel">Nie, dziękuję, rezygnuję.</a>
</div>
</div>


</div>
       </>
            );
        }

export default ElementGlobalMenuNewsletterForm;
