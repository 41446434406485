import React, {useEffect, useState} from "react";

// react-router components

// prop-types is a library for typechecking of props.

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";


// Soft UI Dashboard PRO React components
import SoftBox from "../../../components/SoftBox";

// Soft UI Dashboard PRO React example components
import AfdDefaultNavbarLink from "./desktop/AfdDefaultNavbarLink";
import AfdDefaultNavbarMobile from "./mobile/AfdDefaultNavbarMobile";

// Soft UI Dashboard PRO React base styles
import breakpoints from "../../../assets/theme/base/breakpoints";

// DefaultNavbar dropdown menus
import AfdDocsMenu from "./Menus/AfdDocsMenu";
import AfdSingleMenu from "./Menus/AfdSingleMenu";

import IGlobalMenuParent from "../../../type/global_menu/IGlobalMenuParent";
import ICtxGlobalConfig from "../../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../../hook/useCHGetContextSoftUI";
import IUserInfo from "../../../type/IUserInfo";
import {getGlobalMenuLogoTSX} from "../UtilNavbar";
import GetGlobalProfileIcon from "../../../component/sourcerer/GetGlobalProfileIcon";
import IContainerQuery from "../../../type/IContainerQuery";
import {GlobalMenuActionButton} from "./GlobalMenuActionButton";

interface IProp {
    controllerInfoObj: IContainerQuery;
    routes: IGlobalMenuParent[],
    transparent?: boolean;
    light?: boolean;
    action: {
        type: "external" | "internal";
        route: string;
        label: string;
        color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
    },
    userInfoObj: IUserInfo,
}

function AfdDefaultNavbar({
                              controllerInfoObj,
                              routes,
                              transparent = false,
                              light = false,
                              action,
                              userInfoObj,
                          }: IProp
) {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();

    const [parentIsAcademyMenu, setParentIsAcademyMenu] = useState(false);
    const [parentIsEffectMenu, setParentIsEffectMenu] = useState(false);
    const [parentIsHowMenu, setParentIsHowMenu] = useState(false);
    const [parentIsOpinionMenu, setParentIsOpinionMenu] = useState(false);
    const [parentIsRegistrationMenu, setParentIsRegistrationMenu] = useState(false);
    const [parentIsOtherMenu, setParentIsOtherMenu] = useState(false);

    const [mobileNavbar, setMobileNavbar] = useState(false);
    const [mobileView, setMobileView] = useState(false);

    const openMenuArray: any[] = [];
    const closeMenuArray: any[] = [];


    openMenuArray.push(({currentTarget}: any) => setParentIsAcademyMenu(currentTarget));
    closeMenuArray.push(() => setParentIsAcademyMenu(false));
    openMenuArray.push(({currentTarget}: any) => setParentIsEffectMenu(currentTarget));
    closeMenuArray.push(() => setParentIsEffectMenu(false));
    openMenuArray.push(({currentTarget}: any) => setParentIsHowMenu(currentTarget));
    closeMenuArray.push(() => setParentIsHowMenu(false));
    openMenuArray.push(({currentTarget}: any) => setParentIsOpinionMenu(currentTarget));
    closeMenuArray.push(() => setParentIsOpinionMenu(false));
    openMenuArray.push(({currentTarget}: any) => setParentIsRegistrationMenu(currentTarget));
    closeMenuArray.push(() => setParentIsRegistrationMenu(false));
    openMenuArray.push(({currentTarget}: any) => setParentIsOtherMenu(currentTarget));
    closeMenuArray.push(() => setParentIsOtherMenu(false));

    const openMobileNavbar = ({currentTarget}: any) => setMobileNavbar(currentTarget.parentNode);
    const closeMobileNavbar = () => setMobileNavbar(false);

    var i = 0;

    /* Lista parent MENU */
    let parentObjList = [
        {
            parentKey: "mainmenu-separator-fotografia",
            open: parentIsOpinionMenu,
            elementjsx: AfdDocsMenu
        },
        {
            parentKey: "mainmenu-separator-efekty",
            open: parentIsEffectMenu,
            elementjsx: AfdDocsMenu // AfdSingleMenu
        },
        {
            parentKey: "mainmenu-separator-nauka",
            open: parentIsHowMenu,
            elementjsx: AfdDocsMenu
        },
        {
            parentKey: "mainmenu-separator-rozwoj",
            open: parentIsOtherMenu,
            elementjsx: AfdDocsMenu
        },
        {
            parentKey: "mainmenu-separator-rejestracja",
            open: parentIsRegistrationMenu,
            elementjsx: AfdDocsMenu
        },
        {
            parentKey: "mainmenu-separator-akademia",
            open: parentIsAcademyMenu,
            elementjsx: AfdDocsMenu
        },

    ];


    useEffect(() => {
        // A function that sets the display state for the AfdDefaultNavbarMobile.
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
                setMobileNavbar(false);
            } else {
                setMobileView(false);
                setMobileNavbar(false);
            }
        }

        /**
         The event listener that's calling the displayMobileNavbar function when
         resizing the window.
         */
        window.addEventListener("resize", displayMobileNavbar);

        // Call the displayMobileNavbar function to set the state with the initial value.
        displayMobileNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileNavbar);
    }, []);




    function getMobileNavbarIconTSX() {
        return <SoftBox
            display={{xs: "inline-block", lg: "none"}}
            lineHeight={0}
            py={1.5}
            pl={1.5}
            color="inherit"
            sx={{cursor: "pointer"}}
            onClick={openMobileNavbar}
        >
            {/*// @ts-ignore*/}
            <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
        </SoftBox>;
    }

    /* BUGFIX: dla menu = "transparent" nie widać rozwijanych opcji przy jasnych tłach */
    return (
        <Container
            sx={
            globalConfigParamObj?.isMobile ?
            {
                backdropFilter: transparent ? `brightness(0.8)` : `none`,
                borderRadius: transparent ? "30px" : "0px",
                paddingLeft: 0.2,
                paddingRight: 0.2
            } : {
                    backdropFilter: transparent ? `brightness(0.8)` : `none`,
                    borderRadius: transparent ? "30px" : "0px",
                    width: "100%",
                }
        }
        >
            <SoftBox
                py={{xs: 0, sm: 0, md: 1}}
                px={
                    {
                        xs: transparent ? 0 : 1, //xs: transparent ? 4 : 5,
                        sm: transparent ? 2 : 5,
                        lg: transparent ? 0 : 5
                    }
                }
                my={{xs: 0, sm: 1, lg: 2}}
                mx={{xs: 0, sm: 1, lg: 3}}
                width={{xs: "100%", sm: "100%", md: "calc(100% - 48px)", lg: "calc(100% - 48px)"}}
                borderRadius="section"
                shadow={transparent ? "none" : "md"}
                color={light ? "white" : "dark"}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                /*position="absolute"*/
                left={0}
                zIndex={3}
                sx={
                    ({
                         palette: {
                            transparent: transparentColor,
                            white
                        },
                         functions: {
                             rgba
                         }
                    }: any) => ({
                        backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
                        backdropFilter: transparent ? `none` : `saturate(200%) blur(30px)`,
                    })
                }
            >
                {getGlobalMenuLogoTSX(
                    transparent,
                    light,
                    globalConfigParamObj.isMobile,
                    controllerInfoObj
                )}

                {/*Desktop version*/}
                <SoftBox color="inherit" display={{xs: "none", lg: "flex"}} m={0} p={0}>
                    {
                        routes.map((parentMenuItemObj: any) => {
                                i++;
                                return (
                                    <AfdDefaultNavbarLink
                                        key={parentMenuItemObj.key}
                                        name={parentMenuItemObj.name}
                                        openHandler={openMenuArray[i - 1]}
                                        closeHandler={closeMenuArray[i - 1]}
                                        light={light}
                                    />

                                )
                            }
                        )
                    }
                </SoftBox>

                {/*START: action button */}
                <SoftBox display={{xs: "none", lg: "inline-block"}}>
                <GlobalMenuActionButton
                    action={action}
                    isMenuMobile={false}
                    userInfoObj={userInfoObj}
                />
                </SoftBox>
                {/*Profil*/}
                <GetGlobalProfileIcon
                    controllerInfoObj={controllerInfoObj}
                    userInfoObj={userInfoObj}
                />
                {getMobileNavbarIconTSX()}
            </SoftBox>


            {/* <PagesMenu routes={routes} open={parentIsEffectMenu} close={closeParent1Menu} />
      <AuthenticationMenu
        routes={routes}
        open={parentIsHowMenu}
        close={closeParent2Menu}
      />
      <EcommerceMenu routes={routes} open={parentIsOpinionMenu} close={closeParent3Menu} />
      <ApplicationsMenu routes={routes} open={parentIsRegistrationMenu} close={closeParent4Menu} />*/}
            {/*   <AfdDocsMenu menuParentItemObjList={routes} open={parentIsAcademyMenu} close={closeMenuArray[0]} parentKey="mainmenu-separator-fotografia"/>
                    <AfdEvolutionMenu menuParentItemObjList={routes} open={parentIsEffectMenu} close={closeMenuArray[1]} parentKey="mainmenu-separator-efekty"/>
                    <AfdDocsMenu menuParentItemObjList={routes} open={parentIsHowMenu} close={closeMenuArray[2]} parentKey="mainmenu-separator-nauka"/>
                    <AfdDocsMenu menuParentItemObjList={routes} open={parentIsOpinionMenu} close={closeMenuArray[3]} parentKey="mainmenu-separator-rozwoj"/>
                    <AfdDocsMenu menuParentItemObjList={routes} open={parentIsRegistrationMenu} close={closeMenuArray[4]} parentKey="mainmenu-separator-rejestracja"/>
                    <AfdDocsMenu menuParentItemObjList={routes} open={parentIsOtherMenu} close={closeMenuArray[5]} parentKey="mainmenu-separator-akademia"/>
            */}
            {
                parentObjList.map((parentMenuItemObj: any,
                                   index: number
                    ) => {
                        let ElementJSX = parentMenuItemObj.elementjsx;
                        return <ElementJSX
                            key={index}
                            menuParentItemObjList={routes}
                            open={parentMenuItemObj.open}
                            close={closeMenuArray[index]}
                            parentKey={parentMenuItemObj.parentKey}/>;
                    }
                )
            }


            {mobileView && (
                <AfdDefaultNavbarMobile
                    routes={routes}
                    open={mobileNavbar}
                    close={closeMobileNavbar}
                    action={action}
                    userInfoObj={userInfoObj}
                />
            )}
        </Container>
    );

}

export default AfdDefaultNavbar;
