/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useState} from "react";

// react-router-dom components
import {useLocation, NavLink} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";

// Soft UI Dashboard PRO React example components
import SidenavCollapse from "../../examples/Sidenav/SidenavCollapse";
import SidenavList from "../../examples/Sidenav/SidenavList";
import SidenavItem from "../../examples/Sidenav/SidenavItem";
import SidenavCard from "../../examples/Sidenav/SidenavCard";

// Custom styles for the Sidenav
import SidenavRoot from "../../examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "../../examples/Sidenav/styles/sidenav";

// Soft UI Dashboard PRO React context
/*import { useCHSoftUIController, setMiniSidenav } from "context";*/
import {useCHGetContextSoftUI, setMiniSidenav} from "../../context/CtxSoftUIGlobalSetupByRdx";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import IGlobalMenuParent from "../../type/global_menu/IGlobalMenuParent";
import IGlobalMenuChild from "../../type/global_menu/IGlobalMenuChild";
import {getGlobalMenuLogoTSX} from "../Navbars/UtilNavbar";
import IUserInfo from "../../type/IUserInfo";

/*
// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
    color: "info",
    brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    brand: PropTypes.string,
    brandName: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};*/

interface IProp {
    routes: IGlobalMenuParent[];
    userInfoObj: IUserInfo;
    color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
    brand?: string;
    brandName: string;
    onMouseEnter?: any;
    onMouseLeave?: any;
}

function Sidenav({
                     color = "info",
                     brand = "",
                     brandName,
                     routes,
                     userInfoObj,
                     ...rest
                 }: IProp) {
    const [openCollapse, setOpenCollapse] = useState<string | boolean>(false);
    const [openNestedCollapse, setOpenNestedCollapse] = useState<string | boolean>(false);
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const {miniSidenav, transparentSidenav, sidenavColor} = globalConfigParamObj;

    //const [controller, dispatch] = useCHSoftUIController();
    const location = useLocation();
    const {pathname} = location;
    const collapseName = pathname.split("/").slice(1)[0];
    const itemName = pathname.split("/").slice(1)[1];

    const closeSidenav = () => setMiniSidenav(dispatchFunction, true);

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatchFunction, window.innerWidth < 1200);
        }

        /**
         The event listener that's calling the handleMiniSidenav function when resizing the window.
         */
        window.addEventListener("resize", handleMiniSidenav);

        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [dispatchFunction, location]);

    // Render all the nested collapse items from the routes.js
    const renderNestedCollapse = (collapseObjList: IGlobalMenuChild[]) => {

        const template = collapseObjList.map((
                collapseObj: IGlobalMenuChild
            ) =>
                collapseObj.href ? (
                    <Link
                        key={collapseObj.key}
                        href={collapseObj.href}
                        target="_blank"
                        rel="noreferrer"
                        sx={{textDecoration: "none"}}
                    >
                        <SidenavItem
                            name={collapseObj.name}
                            collapseObj={collapseObj}
                            userInfoObj={userInfoObj}
                            nested/>
                    </Link>
                ) : (
                    // @ts-ignore
                    <NavLink to={route} key={key} sx={{textDecoration: "none"}}>
                        <SidenavItem name={collapseObj.name}
                                     collapseObj={collapseObj}
                                     userInfoObj={userInfoObj}
                                     active={collapseObj.route === pathname} nested/>
                    </NavLink>
                )
        );

        return template;
    };

    // Render the all the collpases from the routes.js
    /*{ name: any; collapse: any; route: any; href: any; key: any; }[]*/
    /* w przeciwieństwie do global_site_menu to założenie, ze child także ma COLLAPSE - drugi poziom */
    const renderCollapse = (collapseObjList: IGlobalMenuChild[]
    ) =>
        collapseObjList.map(
            (collapseObj: IGlobalMenuChild
            ) => {
                let returnValue;
                /* PARENT: Rozwijjane 2 poziomu */
                if (collapseObj.collapse) {
                    returnValue = (
                        <SidenavItem
                            key={collapseObj.key}
                            name={collapseObj.name}
                            collapseObj={collapseObj}
                            userInfoObj={userInfoObj}
                            active={collapseObj.key === itemName}
                            open={openNestedCollapse === collapseObj.name}
                            onClick={() =>
                                openNestedCollapse === collapseObj.name
                                    ? setOpenNestedCollapse(false)
                                    : setOpenNestedCollapse(collapseObj.name)
                            }
                        >
                            {renderNestedCollapse(collapseObj.collapse)}
                        </SidenavItem>
                    );

                    /* pierwszy/nornmalny poziom */
                } else {
                    // TODO: podmień link na wew dla wszystkich ostatecznie
                    returnValue = collapseObj.href ? (
                        collapseObj.key == 'key_629' ||
                        collapseObj.key == 'key_298' ||
                        collapseObj.key == 'key_133' ||
                        collapseObj.key == 'key_31'
                            ?
                            <Link
                                href={collapseObj.href}
                                key={collapseObj.key}
                                target="_blank"
                                rel="noreferrer"
                                sx={{textDecoration: "none"}}
                            >
                                <SidenavItem
                                    name={collapseObj.name}
                                    collapseObj={collapseObj}
                                    userInfoObj={userInfoObj}
                                    active={collapseObj.key === itemName}/>
                            </Link>
                            :
                            (userInfoObj.page_set_code == 'viewer' &&
                                collapseObj.viewer_container_module == 0) ? <SidenavItem
                                    name={collapseObj.name}
                                    collapseObj={collapseObj}
                                    userInfoObj={userInfoObj}
                                    active={collapseObj.key === itemName}/> :
                                <NavLink
                                    to={collapseObj.href}
                                    key={collapseObj.key}
                                    rel="noreferrer"
                                >
                                    <SidenavItem
                                        name={collapseObj.name}
                                        collapseObj={collapseObj}
                                        userInfoObj={userInfoObj}
                                        active={collapseObj.key === itemName}/>
                                </NavLink>
                    ) : (
                        // @ts-ignore
                        <NavLink to={route} key={key}
                            // @ts-ignore
                                 sx={{textDecoration: "none"}}>
                            <SidenavItem
                                name={collapseObj.name}
                                collapseObj={collapseObj}
                                userInfoObj={userInfoObj}
                                active={collapseObj.key === itemName}/>
                        </NavLink>
                    );
                }
                return <SidenavList key={collapseObj.key}>{returnValue}</SidenavList>;
            });

    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = routes.map(
        ({type, name, icon, description, title, collapse, noCollapse, key, href, route}: IGlobalMenuParent
        ) => {
            let returnValue;

            if (type === "collapse") {
                if (href) {
                    returnValue = (
                        <Link
                            href={href}
                            key={key}
                            target="_blank"
                            rel="noreferrer"
                            sx={{textDecoration: "none"}}
                        >
                            <SidenavCollapse
                                name={name}
                                icon={description}
                                active={key === collapseName}
                                noCollapse={noCollapse}
                            />
                        </Link>
                    );
                } else if (noCollapse && route) {
                    returnValue = (
                        <NavLink to={route} key={key}>
                            <SidenavCollapse
                                name={name}
                                icon={description}
                                noCollapse={noCollapse}
                                active={key === collapseName}
                            >
                                {collapse ? renderCollapse(collapse) : null}
                            </SidenavCollapse>
                        </NavLink>
                    );
                } else {
                    returnValue = (
                        <SidenavCollapse
                            key={key}
                            name={name}
                            icon={description}
                            active={key === collapseName}
                            open={openCollapse === key}
                            onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
                        >
                            {collapse ? renderCollapse(collapse) : null}
                        </SidenavCollapse>
                    );
                }
            } else if (type === "title") {
                returnValue = (
                    <SoftTypography
                        key={key}
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        opacity={0.6}
                        pl={3}
                        mt={2}
                        mb={1}
                        ml={1}
                    >
                        {title}
                    </SoftTypography>
                );
            } else if (type === "divider") {
                returnValue = <Divider key={key}/>;
            }

            return returnValue;
        }
    );


    function getSidenavHeaderTSX() {
        return <SoftBox pt={3} pb={1} px={4} textAlign="center">
            <SoftBox
                display={{xs: "block", xl: "none"}}
                position="absolute"
                top={0}
                right={0}
                p={1.625}
                onClick={closeSidenav}
                sx={{cursor: "pointer"}}
            >
                <SoftTypography variant="h6" color="secondary">
                    <Icon sx={{fontWeight: "bold"}}>close</Icon>
                </SoftTypography>
            </SoftBox>
            {getGlobalMenuLogoTSX(
                transparentSidenav,
                false,
                globalConfigParamObj.isMobile
            )}
            {/*<SoftBox component={NavLink} to="/" display="flex" alignItems="center">
                {brand && <SoftBox component="img" src={brand} alt="Soft UI Logo" width="2rem"/>}
                <SoftBox
                    // @ts-ignore
                    width={!brandName && "100%"} sx={(theme) => sidenavLogoLabel(theme, {miniSidenav})
                }
                >
                    <SoftTypography component="h6" variant="button" fontWeight="medium">
                        {brandName}
                    </SoftTypography>
                </SoftBox>
            </SoftBox>*/}
        </SoftBox>;
    }

    return (
        // @ts-ignore
        <SidenavRoot {...rest} variant="permanent" ownerState={{transparentSidenav, miniSidenav}}>
            {getSidenavHeaderTSX()}
            <Divider/>
            <List>{renderRoutes}</List>

            {/*<SoftBox pt={2} my={2} mx={2}>
                <SidenavCard/>
            </SoftBox>*/}
        </SidenavRoot>
    );
}


export default Sidenav;
