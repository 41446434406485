// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import React from "react";
import {debugLog} from "../../util/UtilReact";


// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import parse, {HTMLReactParserOptions} from "html-react-parser";
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import {Icon} from "@mui/material";
import {setTextSquareTagToDiv} from "../../util/UtilReact";
import FormNgValidationYesTemplate from "../validation/FormNgValidationYesTemplate";
import {useSelector} from "react-redux";
import IRdxState from "../../type/reducer_state/IRdxState";
import FormNgValidationNoSubmitTemplate from "../validation/FormNgValidationNoSubmitTemplate";


function ElementGlobalFieldInputButton(
        {
            formFieldObj,
            formikGantry,
            parserOptions,
            disabled,
            onClick
        }: IFormFieldProp
        ) {
    const rdxState = useSelector((state: IRdxState) => {
        return state;
    });

    const isLastStep = false;
    debugLog(JSON.stringify(formikGantry.errors));
    debugLog(JSON.stringify(formikGantry.values));
    let color = formFieldObj.field_css_class_list ? formFieldObj.field_css_class_list  : "dark";
    let fieldVariant = formFieldObj.field_variant ? formFieldObj.field_variant  : "gradient";

/*    const handleNext = () => setActiveStep(activeStep + 1);*/

    return (
    <>
        <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
            <SoftButton
                // @ts-ignore
                variant={fieldVariant}
                // @ts-ignore
                color={color}
                disabled={disabled}
                isSubmit={formFieldObj.field_type === "submit"}
                /*onClick={!isLastStep ? handleNext : undefined}*/
                onClick={onClick}
                fullWidth={formFieldObj.field_is_fullwidth == 1 ? true : false}
                startIcon={<Icon>{formFieldObj.field_icon}</Icon>}
                sx={{
                }}
            >
                {isLastStep ?
                    parse(setTextSquareTagToDiv(formFieldObj.field_label), parserOptions) :
                parse(setTextSquareTagToDiv(formFieldObj.field_label), parserOptions)
                }
                {/*<Icon>{formFieldObj.field_icon}</Icon>*/}
            </SoftButton>
        </SoftBox>
            <>
                {
                    /*NO TEMPLATE*/
                ((formFieldObj.ng_validation_no_template
                        && rdxState?.form_submission_result?.status
                        && rdxState?.form_submission_result?.status === "error"
                    ) &&
                    <FormNgValidationNoSubmitTemplate
                        formFieldObj={formFieldObj}
                        formikGantry={formikGantry}
                        templateModule={formFieldObj.ng_validation_no_template}
                        formSubmissionResult={rdxState?.form_submission_result}
                    />)
            }</>
            <>{
                /*YES TEMPLATE*/
                ((formFieldObj.ng_validation_yes_template
                        && rdxState?.form_submission_result?.status
                        && rdxState?.form_submission_result?.status === "success"
                    ) &&
                    <FormNgValidationYesTemplate
                        formFieldObj={formFieldObj}
                        formikGantry={formikGantry}
                        templateModule={formFieldObj.ng_validation_yes_template}
                        formSubmissionResult={rdxState?.form_submission_result}
                    />)
            }</>


    </>
  );
}

/*// typechecking props for FormField
ElementGlobalFieldInputButton.propTypes = {
  field_label: PropTypes.string.isRequired,
};*/

export default ElementGlobalFieldInputButton;
