// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import React, {useState} from "react";

// Soft UI Dashboard PRO React components
import Switch from "@mui/material/Switch";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import HTMLReactParser from "html-react-parser";
import parse from "html-react-parser";
import { HTMLReactParserOptions, Element } from 'html-react-parser';
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import { setTextSquareTagToDiv } from "../../util/UtilReact";
import {ErrorMessage, Field} from "formik";

/*https://mui.com/material-ui/react-switch/*/

function ElementGlobalNewsletterPrefFull(
    {
        formFieldObj,
        formikGantry,
        parserOptions
    }: IFormFieldProp
) {
    const [answersPost, setAnswersPost] = useState(false);

    var formFieldTip= (formFieldObj.field_tip ?
        setTextSquareTagToDiv(formFieldObj.field_tip) : "");

    /*var htmlToReact = function() {
        if (formFieldObj.field_tip) {
            return HTMLReactParser(formFieldObj.field_tip);
        }
    }
    var fieldTip = htmlToReact();*/

    /*https://www.npmjs.com/package/html-react-parser*/

    return (
        <>
            <SoftBox pt={1.5} pb={2} px={2} lineHeight={1.25}>
                <SoftBox display="flex" py={1} mb={0.25}>
                    <SoftBox mt={0.25}>
                        {/*checked={answersPost}
                    onChange={() => setAnswersPost(!answersPost)}*/}
                        <Field
                            name={formFieldObj.field_name} as={Switch}
                            value={"on"} // must be STRING to write to DOM
                            error={formikGantry.errors[formFieldObj.field_name] && formikGantry.touched[formFieldObj.field_name]}
                            success={
                                (formikGantry.values[formFieldObj.field_name] &&
                                formikGantry.values[formFieldObj.field_name].length > 0 &&
                                !formikGantry.errors[formFieldObj.field_name]) ? "true" : "false"}
                        />
                        <SoftTypography variant={"caption"} color={"error"}>
                            <ErrorMessage name={formFieldObj.field_name}/>
                        </SoftTypography>

                        {/*<Switch
                        name={formFieldObj.field_name}
                        id={formFieldObj.field_name}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />*/}
                    </SoftBox>
                    <SoftBox width="80%" ml={2}>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                            {/*        {htmlToReact()}*/}
                            {parse(
                                formFieldTip,
                                parserOptions
                            )
                            }
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            </SoftBox>

            {/*

<div className="divTable">

<div className="divTableHeading">
<div className="divTableHead">{row.item_name}</div>
</div>

<div className="divTableBody">

<div className="divTableRow">
<div className="divTableCell content_front_image"><img alt="image" className="lozad" src="" /><blockquote>{row.link_description}</blockquote>
<span className="content_instruction">{row.content_instruction}</span>
</div>
</div>

<div className="divTableRow">
<div className="divTableCell content_full">
&lt;label for="{row.field_name}"&gt;{row.field_label}&lt;/label&gt;
&lt;input id="{row.field_name}" className="{row.field_css_class_list}" name="{row.field_name}" ng-required="{row.ng_required}" ng-model="formArray.{row.field_name}" ng-init="{row.ng_init_function_name}('{row.field_group}', '{row.field_name}')" type="{row.field_type}" value="{row.field_default_value}" /&gt;</div>
</div>

</div>

</div>
           */}

        </>
    );
}

/*// typechecking props for FormField
ElementGlobalNewsletterPrefFull.propTypes = {
  field_label: PropTypes.string.isRequired,
};*/

export default ElementGlobalNewsletterPrefFull;

