const linkDictionary =   {
    LOGIN_URL:"/klub-mam-logowanie.html",
    PROFILE_URL: "/component/option,com_comprofiler/Itemid,71/task,userProfile/",
    ACCOUNT_CREATE_URL: "/Szkola-fotografii-dla-Mam/Informacje-o-szkole/newsletter-akademii-fotografii-dzieciecej.html",
    CONTACT_US: "/Szkola-fotografii-dla-Mam/Informacje-o-szkole/kontakt.html",
    CLUB_STARTPAGE: "/Klub-Mam-Akademii/Co-nowego/klub-mam-start.html",
    CLUB_LEARN_CURRENT: "/Szkola-fotografii-dla-Mam/Kurs-fotografowania/co-nowego-w-klubie.html",
    CLUB_GALLERY_NEW_IMAGE: "/Klub-Mam-Akademii/Galerie/klub-mam-galeria-nowe.html",
    CLUB_BEGINNER_SUMMARY: "/Klub-Mam-Akademii/Co-nowego/debiuty-akademii.html",
    CLUB_PAYMENT_PAGE_URL: "/index.php?option=com_content&Itemid=629&id=579&lang=pl&view=article",
    GALLERY_SUBMIT_CAT_5: "/index.php?option=com_content&Itemid=761&id=690&lang=pl&view=article&category_id=5",
    AMOCOURSE_REG_CONFIRM: "/index.php?option=com_content&view=article&id=341&Itemid=498",
    GALLERY_IMAGE_POSTER_DOWNLOAD_URL: "/index.php?option=com_content&id=334&lang=pl&view=article&Itemid=471",
    PAYMENT_GATE_ENDPOINT_URL: "https://secure.przelewy24.pl/api/v1/transaction/register",
    BACKEND_NEW_ENTRY_URL: "/index.php?option=com_content&Itemid=163&id=165&lang=pl&view=article",
    ACCOUNT_PASSWORD_RESET_URL: "/konto-reset-hasla.html",
  };

export default linkDictionary;
