
const ElementArticleCourseRegintroClosed = lazy(() => import("./ElementArticleCourseRegintroClosed")); // page_item_id: 1
const ElementArticleCourseRegintroNext = lazy(() => import("./ElementArticleCourseRegintroNext")); // page_item_id: 125
const ElementRegintroNextMainpage = lazy(() => import("./ElementRegintroNextMainpage")); // page_item_id: 1

import React, {useEffect, useState} from "react";
import Emptybox from "../../../emptybox/Emptybox";
import AfdLoadingFullpage from "../../../AfdLoadingFullpage";
import AfdContainerCard from "../../../AfdContainerCard";
import { lazy , Suspense } from "react";

import useCHContainerData from "../../../../hook/useCHContainerData";

import IPropContainer from "../../../../type/IPropContainer";

import Grid from "@mui/material/Grid";
import SoftBox from "../../../../components/SoftBox";


/*ng-click="psedudoScrollNextPage()"*/
//{containerRow.post_count}
const GetSiteRegintroCourseEventNext = ({
                                 controllerInfoObj: controllerInfoObj,
                                 userInfoObj: userInfoObj,
                                 paramObj: paramObj
}: IPropContainer) => {


    var category_id = controllerInfoObj.const_param1;
    var page_item_id = controllerInfoObj.page_item_id;

    /* Czy rozpoczęcie rejestracji przez świadome naciśnięcie linka Rejestracja */
    var is_reg_start = paramObj.position_param0;



    const components: any = {
        ElementArticleCourseRegintroClosed,
        ElementArticleCourseRegintroNext,
        ElementRegintroNextMainpage,

    }
    if (controllerInfoObj.element1CamelName !== undefined) {
        var ElementComponent = components[controllerInfoObj.element1CamelName];
        var [elementObjList, getElementObjList, containerInfoObj, setContainerInfoObj] = useCHContainerData(controllerInfoObj,
            {
                category_id: category_id,
            }
        );

    }

    function getContainerContent()  {
        let row = containerInfoObj;
             if (controllerInfoObj.containerName === 'CONTAINER_SITE_COURSE_DESC_NEXT_COURSE')    {
            return (
                <>
                     {
                    elementObjList.map((element: any,
                                        index: number
                    ) => (
                        <ElementComponent
                            key={index}
                            row={element}
                            controllerInfoObj={controllerInfoObj}
                        />
                    ))
                }
                </>
            );
        }
    }

    return (
        <>
        <Suspense fallback={ <AfdLoadingFullpage
            componentCategory="AfdController_Suspense"
            controllerInfoObj={controllerInfoObj}
        />}>
        </Suspense>
            {
                (elementObjList?.length > 0) ?
                    (
                        <AfdContainerCard
                            controllerInfoObj={controllerInfoObj}
                            userInfoObj={userInfoObj}
                            paramObj={paramObj}
                            elementObjList={elementObjList}
                        >
                            {getContainerContent()}
                        </AfdContainerCard>

                    ) : (<Emptybox
                        controllerInfoObj={controllerInfoObj}
                        userInfoObj={userInfoObj}
                        paramObj={paramObj}
                        elementObjList={elementObjList}
                    />)
            }
        </>
    );
}

export default GetSiteRegintroCourseEventNext;
