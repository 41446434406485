// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";


// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import {ErrorMessage, Field} from "formik";
import {useSelector} from "react-redux";
import IRdxState from "../../type/reducer_state/IRdxState";
import IElementGalleryCommentLine from "../../type/gallery/IElementGalleryCommentLine";

function GalleryCommentTextarea(
    {
        formFieldObj,
        formikGantry
    }: IFormFieldProp
) {


    return (
        <>
          {/* Nagłówek dynamiczny w komponencie
           <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                >
                    {formFieldObj.field_label}
                </SoftTypography>
            </SoftBox>*/}
            {/*// @ts-ignore*/}
            <Field
                name={formFieldObj.field_name} as={SoftInput}
                error={formikGantry.errors[formFieldObj.field_name] && formikGantry.touched[formFieldObj.field_name]}
                success={
                    formikGantry.values[formFieldObj.field_name] &&
                    formikGantry.values[formFieldObj.field_name].length > 0 &&
                    !formikGantry.errors[formFieldObj.field_name]}
                placeholder={formFieldObj.field_placeholder}
                defaultValue={formikGantry.values[formFieldObj.field_name]}
                multiline
                rows={4}
            />
            <SoftTypography variant={"caption"} color={"error"}>
                <ErrorMessage name={formFieldObj.field_name}/>
            </SoftTypography>
        </>
    );
}

/*// typechecking props for FormField
GalleryCommentTextarea.propTypes = {
  field_label: PropTypes.string.isRequired,
};*/

export default GalleryCommentTextarea;
