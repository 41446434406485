import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, useLocation} from "react-router-dom";
import {QueryClientProvider, QueryClient } from "react-query";
import App from "./App";
import {debugLog} from "./util/UtilReact";
import * as Sentry from "@sentry/react";

// Soft UI Context Provider
import {ProviderGlobalConfigCtxWithReducer} from "./context_provider/ProviderGlobalConfigCtxWithReducer";
import {applyMiddleware, createStore} from "redux";
import {rdxAppReduxReducer} from "./redux_reducer/rdxAppReduxReducer";
import {Provider} from "react-redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {ReactQueryDevtools} from "react-query/devtools";



Sentry.init({
    dsn: "https://2f1608ea99734cb5911281386b6f444e@o1384827.ingest.sentry.io/4505108633026560",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const el = document.getElementById("root");
if (el) {
    const root = ReactDOM.createRoot(el);
    const globalReduxStore = createStore(rdxAppReduxReducer,
        composeWithDevTools(
            applyMiddleware(),
            // other store enhancers if any
        )
    );

    let consoleStyle = [
        "color: #fff",
        "background-color: #444",
        "padding: 2px 4px",
        "border-radius: 2px"
    ].join(";");
    debugLog("%cReact Query: Cache INIT", consoleStyle);
    const queryClient = new QueryClient();

    root.render(
        <BrowserRouter>
            <QueryClientProvider client={queryClient} contextSharing={true}>
                <ReactQueryDevtools initialIsOpen={false} />
                <ProviderGlobalConfigCtxWithReducer>
                    <Provider store={globalReduxStore}>
                        <App
                            userId={Number(el?.getAttribute('data-user-id'))}
                            menuId={Number(el?.getAttribute('data-menu-id'))}
                            isDiagnostics={Boolean(el?.getAttribute('data-is-diagnostics'))}
                            sessionId={''}
                            facebookAppId={'482656705081946'}
                            gaTrackingId={'UA-123456789-1'}
                        />
                    </Provider>
                </ProviderGlobalConfigCtxWithReducer>
            </QueryClientProvider>
        </BrowserRouter>
    );

}
