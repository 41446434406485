import {createContext} from "react";
import ICtxYupSchema from "../type/context/ICtxYupSchema";
import IElementNewbbTopicPost from "../type/newbb/IElementNewbbTopicPost";
import IElementForumDecodeInfoCurrent from "../type/newbb/IElementForumDecodeInfoCurrent";
import IElementNewbbTopicInfo from "../type/newbb/IElementNewbbTopicInfo";
import IElementNewbbUniForumInfo from "../type/newbb/IElementNewbbUniForumInfo";
import IElementNewbbAnchorPost from "../type/newbb/IElementNewbbAnchorPost";
import {IOrderByCode} from "../gantry/rt-club-newbb";
import ICtxFormResponse from "../type/context/ICtxFormResponse";
/*
    The problem here is that you need to help TypeScript understand the types of the objects you're dealing with.
    https://blog.logrocket.com/how-to-use-react-context-typescript/
 */

const defaultYupContext: ICtxFormResponse = {
    storeFormResponseObj: null,
    setStorestoreFormResponseObj: () => {},
};


const CtxFormResponse = createContext<ICtxFormResponse>(defaultYupContext)
//const AppObjContext = createContext(null);

export default CtxFormResponse;
