// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import React from "react";
// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import {ErrorMessage, Field} from "formik";
import {setTextSquareTagToDiv} from "../../util/UtilReact";
import parse from "html-react-parser";
import {FormControl, InputLabel, Select, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IAttributeCallbackOptionObjFunc from "../../type/form/IAttributeCallbackOptionObjFunc";

function ElementSimpleFormFieldOption(
    {
        formFieldObj,
        formikGantry,
        parserOptions,
        optionObjListFunction
    }: IFormFieldProp
) {

    /* Calculate option list
    https://stackoverflow.com/questions/36015691/obtaining-the-return-type-of-a-function
    ReturnType<IAttributeCallbackOptionObjFunc>
    * */
    let optionObjList: {
        value: string;
        text: string;
    }[] = optionObjListFunction ? optionObjListFunction(formikGantry) : [];
    var formFieldTip = (formFieldObj.field_tip ?
        setTextSquareTagToDiv(formFieldObj.field_tip) : "");

    return (
        <>
            <SoftBox
                display="flex"
                flexDirection={"column"}
                mb={1}
                ml={0.5}
                mr={1}
                lineHeight={0}>
                <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                >
                    {parse(formFieldObj.field_label)}
                </SoftTypography>
                <Field
                    name={formFieldObj.field_name} as={TextField}
                    select={true}
                    id="input-with-icon-textfield"
                    variant={"standard"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle/>
                            </InputAdornment>
                        ),
                    }}
                    defaultValue={formikGantry.values[formFieldObj.field_name]}
                    /*label={formFieldObj.field_label}*/
                    error={formikGantry.errors[formFieldObj.field_name] && formikGantry.touched[formFieldObj.field_name]}
                 /*   success={
                        formikGantry.values[formFieldObj.field_name] &&
                        formikGantry.values[formFieldObj.field_name].length > 0 &&
                        !formikGantry.errors[formFieldObj.field_name]}*/
                    sx={{
                        /*width: "70vw",
                        minWidth: "70vw",*/
                    }}

                    /*onBlur={formikGantry.handleBlur}*/
                >
                    {
                        optionObjList.map((optionObj, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        value={optionObj.value}

                                    >
                                        {optionObj.text}
                                    </MenuItem>
                                );
                            }
                        )
                    }
                    {/*<MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>*/}
                </Field>

                {formFieldTip && <SoftBox
                    lineHeight={1}
                ><SoftTypography
                    variant={"caption"}
                    fontWeight={"regular"}
                    color={"secondary"}
                >
                    {parse(
                        formFieldTip,
                        parserOptions
                    )}
                </SoftTypography></SoftBox>

                }
                <SoftTypography variant={"caption"} color={"error"}>
                    <ErrorMessage name={formFieldObj.field_name}/>
                </SoftTypography>
            </SoftBox>
        </>
    );
}

/*// typechecking props for FormField
ElementSimpleFormFieldOption.propTypes = {
  field_label: PropTypes.string.isRequired,
};*/

export default ElementSimpleFormFieldOption;
