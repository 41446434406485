
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";

interface IProp {
  name: string;
  openHandler: ({}: any) => void;
  closeHandler: ({}: any) => void;
  children?: React.ReactNode;
  collapseStatus?: boolean;
  light: boolean;
}
/*

  // Setting default values for the props of AfdDefaultNavbarLink
  AfdDefaultNavbarLink.defaultProps = {
    openHandler: false,
    closeHandler: false,
    children: false,
    collapseStatus: false,
    light: false,
  };

  // Typechecking props for the AfdDefaultNavbarLink
  AfdDefaultNavbarLink.propTypes = {
    name: PropTypes.string.isRequired,
    openHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    closeHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    children: PropTypes.node,
    collapseStatus: PropTypes.bool,
    light: PropTypes.bool,
  };

*/

function AfdDefaultNavbarLink({
                             name,
                             openHandler,
                             closeHandler,
                             children,
                             collapseStatus = false,
                             light,
                             ...rest
                           }: IProp
) {
  return (
    <>
      <SoftBox
        {...rest}
        mx={1}
        p={1}
        onMouseEnter={children ? undefined : openHandler}
        onMouseLeave={children ? undefined : closeHandler}
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        color={light ? "white" : "dark"}
        sx={{ cursor: "pointer", userSelect: "none" }}
      >
        <SoftTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color="inherit"
          sx={{ fontWeight: "100%" }}
        >
          {name}
        </SoftTypography>
        <SoftTypography variant="body2" color={light ? "white" : "dark"}
        >
          <Icon sx={
              { fontWeight: "bold", verticalAlign: "middle"
              }
          }>
              keyboard_arrow_down
          </Icon>
        </SoftTypography>
      </SoftBox>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}


export default AfdDefaultNavbarLink;
