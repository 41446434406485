import {createContext} from "react";
import ICtxClubNewbb from "../type/context/ICtxClubNewbb";
import IElementNewbbTopicPost from "../type/newbb/IElementNewbbTopicPost";
import IElementForumDecodeInfoCurrent from "../type/newbb/IElementForumDecodeInfoCurrent";
import IElementNewbbTopicInfo from "../type/newbb/IElementNewbbTopicInfo";
import IElementNewbbUniForumInfo from "../type/newbb/IElementNewbbUniForumInfo";
import IElementNewbbAnchorPost from "../type/newbb/IElementNewbbAnchorPost";
import {IOrderByCode} from "../gantry/rt-club-newbb";
/*
    The problem here is that you need to help TypeScript understand the types of the objects you're dealing with.
    https://blog.logrocket.com/how-to-use-react-context-typescript/
 */

const defaultContext: ICtxClubNewbb = {
    storeOrigPostObj: null,
    setStoreOrigPostObj: () => {},
    storeNewPostInfoObj: null,
    setStoreNewPostInfoObj: () => {},
    storeDeletePostObj: null,
    setStoreDeletePostObj: () => {},
    storeNewbbSelectedUserObj: null,
    setStoreNewbbSelectedUserObj: () => {},
    tabValue: 1,
    setTabValue: () => {},
    storeActiveAnchorPostId: null,
    setStoreActiveAnchorPostId: () => {},
    storeNewbbForumInfoObj: undefined,
    storeNewbbTopicInfoObj: undefined,
    storeNewbbUniForumInfoObj: undefined,
    storeNewbbAnchorPostObj: undefined,
    storeOrderByCode: "REPLY_LAST_DATETIME",
    storeSetOrderByCode: () => {},
    urlDisForumId: null,
    urlDisTopicId: null,
    urlDisPostId: null,
    urlAnchorPostId: null,

};


const CtxClubNewbb = createContext<ICtxClubNewbb>(defaultContext);
//const AppObjContext = createContext(null);

export default CtxClubNewbb;
