import React, {Suspense, useEffect, useState} from "react";
import {debugLog} from "../../util/UtilReact";

// @mui material components
import Container from "@mui/material/Container";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";

// Soft UI Dashboard PRO React example components
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import {getPosition2ContainerMap} from "../../util/UtilReact";

import AfdSelectorSimple from "../../selector/AfdSelectorSimple";
import AfdSelectorImage from "../AfdSelectorImage";
import IContainerQuery from "../../type/IContainerQuery";
import useCHGantryData from "../../hook/useCHGantryData";
import IPropGantry from "../../type/IPropGantry";

import GetMenuPageBanner from "../../util/GetMenuPageBanner";

import ISelectorPosition from "../../type/ISelectorPosition";
import Grid from "@mui/material/Grid";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import GetClubDashboardNavbar from "../../component/controller/get_club_dashboard_navbar/GetClubDashboardNavbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';

import CtxClubDouble from "../../context/CtxClubDouble";
import IElementGalleryCommentLine from "../../type/gallery/IElementGalleryCommentLine";
import AppBar from "@mui/material/AppBar";
import {
    navbar,
    navbarContainer,
    navbarDesktopMenu, navbarIconButton,
    navbarRow
} from "../../component/controller/get_club_dashboard_navbar/styles";
import Toolbar from "@mui/material/Toolbar";
import Breadcrumbs from "../../examples/Breadcrumbs";
import Icon from "@mui/material/Icon";
import GetGlobalProfileIcon from "../../component/sourcerer/GetGlobalProfileIcon";
import useCHGetContextSoftUI from "../../hook/useCHGetContextSoftUI";
import {useLocation, useNavigate} from "react-router-dom";
import {setMiniSidenav, setOpenConfigurator, setTransparentNavbar} from "../../redux_reducer/util/dispatchShortcut";
import Menu from "@mui/material/Menu";
import NotificationItem from "../../examples/Items/NotificationItem";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import ConfigButtonFactory from "../../examples/Configurator/ConfigButtonFactory";
import {isDev} from "../../util/isDev";
import AfdConfigurator from "../../examples/Configurator/AfdConfigurator";
import IElementGalleryLatestCommentNg from "../../type/gallery/IElementGalleryLatestCommentNg";
import SoftAvatar from "../../components/SoftAvatar";
import IconButton from "@mui/material/IconButton";
import GetMyAcademyNotificationList from "../../component/sourcerer/GetMyAcademyNotificationList";
import SoftTypography from "../../components/SoftTypography";
import {ToastContainer} from "react-toastify";
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import linkDictionary from "../../linkDictionary";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import CtxYupSchemaObj from "../../context/CtxYupSchemaObj";

const team2 = require("../../assets/images/team-2.jpg");
const logoSpotify = require("../../assets/images/small-logos/logo-spotify.svg");

function RtClubDouble({
                          menuItemObj,
                          containerObjList,
                          userInfoObj,
                          routeCategory,
                          redirectionObj
                      }: IPropGantry) {

    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    var [menuItemListObj, setMenuItemListObj] = useCHGantryData(
        menuItemObj,
        containerObjList,
        userInfoObj,
        routeCategory,
        redirectionObj
    );
    const [storeOrigCommentObj, setStoreOrigCommentObj] = useState<IElementGalleryCommentLine | null>(null);
    const [storeMyAcademyNotifCounter, setStoreMyAcademyNotifCounter] = useState<{
        comment: number;
        post: number;
        instructor: number;
    }>({
        comment: 0,
        post: 0,
        instructor: 0
    });
    let urlAnchorPostId: string | null = window.location.hash.substring(1);

    const navigate = useNavigate();
    const handleConfiguratorOpen = () => {
        navigate(linkDictionary.CLUB_GALLERY_NEW_IMAGE);

    };

    function getGalleryImageListButtonTSX() {
        return <CollectionsOutlinedIcon
            sx={{
                /*color: "primary.main",*/
            }}
        />;
    }


    let configButtonTSX = ConfigButtonFactory(
        handleConfiguratorOpen,
        getGalleryImageListButtonTSX()
    );

    /* Button */
    function getLatestCommentButtonTSX() {
        return <Icon color="inherit">
            rss_feed
        </Icon>
    }

    const [tabValue, setTabValue] = useState<number>(0);

    const handleSetTabValueFunction = (event: any, newValue: any) => {
        setTabValue(newValue);
        debugLog(tabValue);
    };

    const position2ContainerMap = getPosition2ContainerMap(containerObjList);

    /* App bar */
    let absolute: boolean = false;
    let light: boolean = false;
    let isMini: boolean = false;


    const [navbarType, setNavbarType] = useState('');
    const [controller, dispatch] = useCHGetContextSoftUI();
    const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator} = controller;
    const route = useLocation().pathname.split("/").slice(1);


    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        /* A function that sets the transparent state of the navbar.
        - either FIXED and position 0
        or
        - not FIXED
         */
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

    const getSelectorPositionJSX = function (positionCode: ISelectorPosition): JSX.Element | null {
        if (position2ContainerMap[positionCode]?.length > 0) {

            /* BUGFIX: disableGutters nie działa */
            if (menuItemObj.xx_display_menutype === 'dummy') {
                return (
                    <AfdSelectorSimple
                        key={positionCode}
                        menuItemObj={menuItemObj}
                        userInfoObj={userInfoObj}
                        position={positionCode}
                        redirectionObj={redirectionObj}
                        containerAssocList={position2ContainerMap[positionCode]}
                    />
                );
            } else {
                return (
                    <Container
                        /*disableGutters={true}*/ // nie działa
                    >
                        <AfdSelectorSimple
                            key={positionCode}
                            menuItemObj={menuItemObj}
                            userInfoObj={userInfoObj}
                            position={positionCode}
                            redirectionObj={redirectionObj}
                            containerAssocList={position2ContainerMap[positionCode]}
                        /></Container>);
            }
        } else {
            return null;
        }

    }


    /*****           *******************************************************                   ******/
    var containerMarginTop: number = GetMenuPageBanner(menuItemObj).containerMarginTop;

    function getTabBody1() {
        return <Grid container spacing={1}>
            {/* MAIN container*/}
            <Grid item xs={12} md={9} lg={9}>
                {getSelectorPositionJSX('feature-a')}
                <Grid container spacing={1}>
                    <Grid item xs={12} md={3} lg={3}>
                        {getSelectorPositionJSX('header-a')}
                    </Grid>
                    <Grid item xs={12} md={9} lg={9}>
                        {getSelectorPositionJSX('header-b')}
                    </Grid>
                </Grid>
                {getSelectorPositionJSX('content-top-a')}
                {
                    userInfoObj.is_tech_account == 1 ?
                        getSelectorPositionJSX('admin-instructor-toolbar') : null
                }
                {/*zaznaczanie Oli dla CHAT*/}
                {getSelectorPositionJSX('body')}
                {getSelectorPositionJSX('content-bottom-a')}

            </Grid>
            <Grid item xs={12} md={3} lg={3}
            >
                {getSelectorPositionJSX('sidebar-a')}
                {getSelectorPositionJSX('sidebar-b')}
                {getSelectorPositionJSX('sidebar-c')}
            </Grid>
            {/* RIGHT sidebar */}
            {/*       <Grid item xs={12} lg={3}>

            </Grid>*/}
        </Grid>;
    }

    function getTabBody2() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-info-body')}
                </SoftBox>
            </Grid>
        </Grid>;
    }

    function getTabBody3() {
        return <Grid container>
            <Grid item xs={6} lg={6}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-moderation-body')}
                </SoftBox>
            </Grid>
            <Grid item xs={6} lg={6}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-moderation2-body')}
                </SoftBox>
            </Grid>
        </Grid>;
    }

    function getTabBody4() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-favourite-body')}
                </SoftBox>
            </Grid>
            {/*<Grid item xs={6} lg={6}>
                <SoftBox my={3}>

                </SoftBox>
            </Grid>*/}
        </Grid>;
    }

    function getTabBody5() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-other-5-body')}
                </SoftBox>
            </Grid>
            {/*<Grid item xs={6} lg={6}>
                <SoftBox my={3}>

                </SoftBox>
            </Grid>*/}
        </Grid>;
    }

    function getTabBody6() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-other-6-body')}
                </SoftBox>
            </Grid>
            {/*<Grid item xs={6} lg={6}>
                <SoftBox my={3}>

                </SoftBox>
            </Grid>*/}
        </Grid>;
    }

    function getTabGalleryRankAwardNew() {
        return <SoftBox><Grid container>
            <Grid item xs={12}
                  md={6}
                  lg={4}
                  xl={4}
            >
                {getSelectorPositionJSX('REACT-gallery-rank1')}
            </Grid>
            <Grid item xs={12}
                  md={6}
                  lg={4}
                  xl={4}
            >
                {getSelectorPositionJSX('REACT-gallery-rank2')}
            </Grid>
            <Grid item xs={12}
                  md={6}
                  lg={4}
                  xl={4}
            >
                {getSelectorPositionJSX('REACT-gallery-rank3')}
            </Grid>
        </Grid>
            <SoftBox my={3}>
                {getSelectorPositionJSX('REACT-gallery-body')}
            </SoftBox>
        </SoftBox>;
    }

    let specificationPageObjList: {
            [key: number]: {
                label: string,
                icon: JSX.Element,
                value: number,
                isVisible: boolean
            }[]
        } =
            {
                264: [
                    {
                        label: 'kandydatki',
                        icon: <NotesOutlinedIcon/>,
                        value: 0,
                        isVisible: true
                    },
                    {
                        label: 'ostatnie oceny',
                        icon: <InsertChartOutlinedTwoToneIcon/>,
                        value: 4,
                        isVisible: true
                    },
                    {
                        label: 'następne oceny',
                        icon: <InsertChartOutlinedTwoToneIcon/>,
                        value: 5,
                        isVisible: true
                    },
                    {
                        label: 'Akredytacje',
                        icon: <InsertChartOutlinedTwoToneIcon/>,
                        value: 3,
                        isVisible: true
                    },
                ],
                543: [
                    {
                        label: 'zadania aktualne',
                        icon: <NotesOutlinedIcon/>,
                        value: 0,
                        isVisible: true
                    },
                    {
                        label: 'zadania wykonane',
                        icon: <InsertChartOutlinedTwoToneIcon/>,
                        value: 3,
                        isVisible: true
                    },
                ],
                617: [
                    {
                        label: 'nowe zdjęcia',
                        icon: <NotesOutlinedIcon/>,
                        value: 0,
                        isVisible: true
                    },
                    {
                        label: 'statystyki',
                        icon: <InsertChartOutlinedTwoToneIcon/>,
                        value: 1,
                        isVisible: true
                    },
                    {
                        label: 'znajome',
                        icon: <PeopleAltOutlinedIcon/>,
                        value: 101,
                        isVisible: (userInfoObj.page_set_code != 'viewer')
                    },
                    /*
                       {
                           label: 'ulubione',
                           icon: <BookmarkAddOutlinedIcon/>,
                           value: 3
                       }*/
                ],

            }
    ;

    /* Nie pokazuj jeżeli tylko jedna */
    function getTabListTSX() {
        if (!specificationPageObjList[menuItemObj.id] ||
            specificationPageObjList[menuItemObj.id].length < 2
        ) {
            return null;
        } else {
            return <SoftBox
                alignItems={"left"}
            > <Tabs orientation={"horizontal"}
                    value={tabValue}
                    onChange={handleSetTabValueFunction}>
                {
                    specificationPageObjList[menuItemObj.id]
                        .filter((specificationObj) => {
                                return specificationObj.isVisible;
                            }
                        ).map((specificationObj, index) => {
                            return <Tab
                                key={index}
                                label={globalConfigParamObj.isMobile &&
                                index !== tabValue
                                    ? '' : specificationObj.label} // nie pokazuj reszty
                                icon={specificationObj.icon}
                                value={specificationObj.value}
                                sx={{
                                    paddingRight: 2
                                }}
                            />
                        }
                    )
                }
            </Tabs>
            </SoftBox>;
        }
    }

    return (
        <>
            <CtxClubDouble.Provider value={{
                storeOrigCommentObj: storeOrigCommentObj,
                setStoreOrigCommentObj: setStoreOrigCommentObj,
                storeMyAcademyNotifCounter: storeMyAcademyNotifCounter,
                setStoreMyAcademyNotifCounter: setStoreMyAcademyNotifCounter,
                urlAnchorPostId: urlAnchorPostId
            }}>

                    {getSelectorPositionJSX('showcase-a')}
                    <DashboardLayout>

                        <AppBar
                            // @ts-ignore
                            position={absolute ? "absolute" : navbarType}
                            color="inherit"
                            sx={
                                (theme) => navbar(
                                    theme,
                                    {
                                        transparentNavbar, absolute, light
                                    }
                                )
                            }
                        >
                            <Toolbar sx={(theme) => navbarContainer(theme)}>
                                <SoftBox color="inherit" mb={{xs: 1, md: 0}}
                                         sx={(theme: any) => navbarRow(theme, {isMini})}
                                >
                                    {/*<Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light}/>*/}
                                    <Icon fontSize="medium"
                                        /*sx={navbarDesktopMenu}*/
                                          onClick={handleMiniSidenav}>
                                        {miniSidenav ? "menu_open" : "menu"}
                                    </Icon>
                                    {getSelectorPositionJSX('top-a')}


                                    {/*  <SoftBox pr={1}>
                        <SoftInput
                            placeholder="Type here..."
                            icon={{component: "search", direction: "left"}}
                            label={"id1"}
                            id={"id1"}
                            name={"id1"}
                            type={"text"}
                        />
                    </SoftBox>*/}
                                    {getSelectorPositionJSX('navigation')}
                                </SoftBox>


                            </Toolbar>
                        </AppBar>

                        <SoftBox
                            display="flex"
                            flexDirection={"row"}
                            justifyContent="space-evenly"
                            alignItems="center"
                        >
                            {!globalConfigParamObj.isMobile ? getTabListTSX() : getTabListTSX()
                            }
                            {getSelectorPositionJSX('maintop-a')}
                        </SoftBox>
                        <SoftBox mt={2}>
                            {tabValue === 0 && getTabBody1()}
                            {tabValue === 1 && getTabBody2()}
                            {tabValue === 2 && getTabBody3()}
                            {tabValue === 3 && getTabBody4()}
                            {tabValue === 4 && getTabBody5()}
                            {tabValue === 5 && getTabBody6()}
                            {tabValue === 101 && getTabGalleryRankAwardNew()}

                        </SoftBox>
                        <SoftBox
                            display="flex"
                            flexDirection={"column"}
                            justifyContent="space-evenly"
                            alignItems="center"
                        >
                            {getSelectorPositionJSX('mainbottom-a')}
                            {getSelectorPositionJSX('bottom-a')}
                        </SoftBox>
                        {getSelectorPositionJSX('module-footer-a')}
                        {
                            (menuItemObj)
                                ?
                                <AfdConfigurator
                                    menuItemObj={menuItemObj}
                                    redirectionObj={redirectionObj}
                                    userInfoObj={userInfoObj}
                                    routeCategory={routeCategory}
                                    selectorTSX={getSelectorPositionJSX('drawer')}
                                /> : null
                        }
                        {/*TODO*/}
                        {
                            menuItemObj.id != 617 ?
                                configButtonTSX : null}

                    </DashboardLayout>
            </CtxClubDouble.Provider>
        </>
    )
}

export default RtClubDouble;
