
import React from "react";
import Iframe from 'react-iframe'

const ElementMovieNoborderNotable = ({row,
                                         controllerInfoObj
                                     }: any) => {
    var video_id = controllerInfoObj.const_param1;
    var video_type = controllerInfoObj.const_param2;
    var welcome_text = controllerInfoObj.const_param3;
    var content_intro_text = controllerInfoObj.const_param4;
    var element_module1 = controllerInfoObj.element_module1;
    /*var dict_code = JRequest.getVar('dict_code');*/
    var instructor_letter = controllerInfoObj.const_param5;
    if (!instructor_letter) instructor_letter = 'M';

    var url = 'https://www.youtube.com/embed/' + video_id;
    var allowfullscreen = true;
    var frameborder = 0;

    return (
       <>
           <Iframe url={url}
                   id={video_id}
                   height="550px"
                   width="100%"
                   className="myClassname"
                   display="block"
                   allowFullScreen={allowfullscreen}
                   frameBorder={frameborder}
                   position="relative"
                   styles={{
                       "min-height": "30vw"
                   }}
           />
           {/*<div>
               &lt;iframe width="{row.width}" height="{row.height}"
               src="//www.youtube.com/embed/{row.youtube_id}?rel=0&amp;amp;vq={row.quality}&amp;amp;wmode=opaque"
               frameborder="0" allowfullscreen="" /&gt;

           </div>*/}
       </>
            );
        }

export default ElementMovieNoborderNotable;
