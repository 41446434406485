import IAxiosPostData from "../../type/axios/IAxiosPostData";
import RestApiService, {API_URL} from "./RestApiService";

export class RestApiChapterService extends RestApiService {

    constructor() {
        super();
    }


    static setQuestionAndAnswerAdminInsert(
        comment: string,
        chapterIssueCode: string,
        questionSeq: number
    ): IAxiosPostData {

        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: {
                "set_question_and_answer_admin_insert": 1,
                "comment": comment,
                "chapter_issue_code": chapterIssueCode,
                "question_seq": questionSeq
            }
        };

        return postDataObj;
    }

    static setQuestionAnswerUserLogInsert(
        answerId: number,
        userId: number,
        answerIsTrue: number
    ): IAxiosPostData {

        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: {
                "set_question_answer_user_log_insert": 1,
                "answer_id": answerId,
                "user_id": userId,
                "answer_is_true": answerIsTrue
            }
        };

        return postDataObj;
    }

    static setForumChapterIssueItemInsert(
        chapterIssueCode: string,
        issueItemType: string,
        issueItemTitle: string,
        issueItemContent: string,
        issueItemUrlOrId: string
    ): IAxiosPostData {

        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: {
                "set_forum_chapter_issue_item_insert": 1,
                "chapter_issue_code": chapterIssueCode,
                "issue_item_type": issueItemType,
                "issue_item_title": issueItemTitle,
                "issue_item_content": issueItemContent,
                "issue_item_url_or_id": issueItemUrlOrId
            }
        };

        return postDataObj;
    }


    /* Ocena TUTORIAL */
    static setMessageRating(
        messageId: number,
        selectedUserId: number,
        subscriberId: number,
        ratingStatus: number
    ): IAxiosPostData {

        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: {
                "set_message_rating": 1,
                "message_id": messageId,
                "selected_user_id": selectedUserId,
                "subscriber_id": subscriberId,
                "rating_status": ratingStatus
            }
        };

        return postDataObj;
    }


    static setIssueRatingUrl(
        rating: number,
        afdIssueCode: string,
        afdIssueRatingType: string,
        afdCurrentUserId: number
    ): IAxiosPostData {

        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: {
                custom_call: 'set_forum_chapter_issue_rating_insert',
                chapter_issue_code: afdIssueCode,
                user_id: afdCurrentUserId.toString(),
                rating_type: afdIssueRatingType,
                rating_value: rating.toString()
            }
        };
        return postDataObj;

    }


}
