import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom"; // npm i react-router-dom --save
import AfdContainer from "../component/AfdContainer";
import IContainerQuery from "../type/IContainerQuery";
import IParamObj from "../type/IParamObj";
import ISelector from "../type/ISelector";
import RestApiService from "../service/restapi/RestApiService";
import RestApiCommonService from "../service/restapi/RestApiCommonService";
import RestApiAmocourseService from "../service/restapi/RestApiAmocourseService";
import {debugLog, debugDir} from "../util/UtilReact";

import AfdLoadingFullpage from "../component/AfdLoadingFullpage";
import {processContainer, processElement, setCalculateTotal} from "../util/UtilReact";
import RestApiAuthorizationService from "../service/restapi/RestApiAuthorizationService";
import IAmocourseTotalArray from "../type/amocourse/IAmocourseTotalArray";
import IAmocoursePriceInfo from "../type/amocourse/IAmocoursePriceInfo";
import CtxAmocourse from "../context/CtxAmocourse";
import IRQOutputList from "../type/rq/IRQOutputList";
import IGalleryImageInfo from "../type/gallery/IGalleryImageInfo";
/* take the container list and slect those which are  relevant for this position *
 */
const AfdSelectorGallery = ({
                                menuItemObj: menuItemObj,
                                userInfoObj: userInfoObj,
                                position: position,
                                containerAssocList: containerAssocList,
                                redirectionObj: redirectionObj,
                                formikGantry: formikGantry,
                                setFormikParamObj: setFormikParamObj
                            }: ISelector) => {

    const useQuery = () => new URLSearchParams(useLocation().search);
    const queryHookObj = useQuery();
    /* tylko dla 471 */
    let imageId: number = (redirectionObj?.image_id ? redirectionObj?.image_id : -1);
    if (menuItemObj.id == 471) {
        imageId = queryHookObj.get('image_id') && menuItemObj.id == 471 ? Number.parseInt(queryHookObj.get('image_id') as string) : -1; //1307668;
    }


    var rqGalleryImageInfoRQ: IRQOutputList | null = null;


    rqGalleryImageInfoRQ = RestApiCommonService.getGalleryImageInfoRQ(
        imageId,
        userInfoObj?.user_id,
        (menuItemObj.id == 62 ||
            menuItemObj.id == 471
        )
    );

    const [paramObj, setParamObj] = useState<IParamObj>({});
    const [isDataReady, setIsDataReady] = useState(false);

    /*62*/
    useEffect(() => {
            let internalImageId = (redirectionObj?.image_id ? redirectionObj?.image_id : -1)
            if (rqGalleryImageInfoRQ)
                if (!rqGalleryImageInfoRQ.isIdle &&
                    !rqGalleryImageInfoRQ.isLoading) {
                    {
                        let internalImageInfoObj: IGalleryImageInfo = processContainer(rqGalleryImageInfoRQ.data.data[0]);

                        /* Wydajnościowo - tylko DICT EXPLORE
                        - inaczej tworzy puste CACHE
                        */

                    /*    if (position === 'picture-image_big' ||
                            position === 'picture-recom-below' ||
                            position === 'REACT-split-left' ||
                            position === 'REACT-split-right' ||
                            position === 'REACT-comment-below' ||
                            position === 'REACT-split-center-top' ||
                            position === 'REACT-split-center-body' ||
                            position === 'REACT-favourite-body' ||
                            position === 'REACT-image-delete-body' ||
                            position === 'REACT-info-body' ||
                            position === 'REACT-moderation-body' ||
                            position === 'REACT-comment-below-ep' ||
                            position === 'top-a' ||
                            position === 'body' ||
                            position === 'maintop-a' ||
                            position === 'admin-instructor-toolbar' ||
                            position === 'drawer' ||
                            position === 'showcase-a'

                        ) {
                            setParamObj({
                                position_param0: internalImageInfoObj.id?.toString(),
                                paramGalleryImageObj: internalImageInfoObj,
                            });
                            setIsDataReady(true);
                        } else {*/
                            /* TODO: tak naprawdę jedne dane dla wszystkich */
                            setParamObj({
                                position_param0: internalImageInfoObj?.id?.toString(),
                                paramGalleryImageObj: internalImageInfoObj,
                            });
                            setIsDataReady(true);
                        /*}*/


                    }
                }
        },
        [
            rqGalleryImageInfoRQ,
            rqGalleryImageInfoRQ?.isIdle,
            rqGalleryImageInfoRQ?.isLoading,
            redirectionObj?.image_id
        ]
    );

    /* check if any containers exist for the position */
    return (
        <>
            {
                (typeof containerAssocList !== 'undefined'
                    && typeof paramObj !== "undefined"
                    && isDataReady === true
                        ? containerAssocList.map((element: IContainerQuery) => (
                            <AfdContainer
                                key={element.sequence}
                                menuItemObj={menuItemObj}
                                userInfoObj={userInfoObj}
                                position={position}
                                controllerInfoObj={element}
                                paramObj={paramObj}
                                redirectionObj={redirectionObj}
                                formikGantry={formikGantry}

                            />
                        )) :
                        <AfdLoadingFullpage
                            componentCategory="AfdSelectorGallery"
                            componentInfo={"p:" + position + "_m:" + menuItemObj.id + "_u:" + userInfoObj.user_id}
                        />
                )
            }
        </>
    );
}

export default AfdSelectorGallery;
