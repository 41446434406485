import React, {useEffect} from "react";

import Grid from "@mui/material/Grid";
import SoftBox from "../components/SoftBox";
import IFormFieldInfo from "../type/form/IFormFieldInfo";
import ElementRegistrationFormInstrSelect from "./field/ElementRegistrationFormInstrSelect";
import ElementGlobalNgFieldRow from "./field/ElementGlobalNgFieldRow";
import ElementFormFieldPassword from "./field/ElementFormFieldPassword";
import ElementSimpleFormFieldTextarea from "./field/ElementSimpleFormFieldTextarea";
import ElementGlobalNgFieldCheckbox from "./field/ElementGlobalNgFieldCheckbox";
import ElementJwallpapersNgFieldCheckbox from "./field/ElementJwallpapersNgFieldCheckbox";
import GalleryCommentTextarea from "./field/GalleryCommentTextarea";
import ElementGlobalFieldButton from "./field/ElementGlobalFieldButton";
import ElementGlobalFieldInputButton from "./field/ElementGlobalFieldInputButton";
import ElementFormGalleryImageAuthorize from "./field/ElementFormGalleryImageAuthorize";

import parse from "html-react-parser";
import {HTMLReactParserOptions, Element} from 'html-react-parser';


import {camelize, capitalize} from "../util/Util";
import IParamObj from "../type/IParamObj";
import IFormFormikGantry from "../type/form/IFormFormikGantry";
import SoftTypography from "../components/SoftTypography";
import ElementComprofilerRegistrationFieldCheckbox from "./field/ElementComprofilerRegistrationFieldCheckbox";
import ElementGlobalNewsletterPrefFull from "./field/ElementGlobalNewsletterPrefFull";
import ElementGlobalNewsletterPrefMini from "./field/ElementGlobalNewsletterPrefMini";
import IAttributeCallback from "../type/form/IAttributeCallback";
import ElementAmocourseRegInstallmentRadio from "./field/ElementAmocourseRegInstallmentRadio";
import ElementGalleryCommentSubmit from "./field/ElementGalleryCommentSubmit";
import IRQOutputList from "../type/rq/IRQOutputList";
import RestApiCommonService from "../service/restapi/RestApiCommonService";
import RestApiAmocourseService from "../service/restapi/RestApiAmocourseService";
import IRQOutputSingle from "../type/rq/IRQOutputSingle";
import {rqOptionStaticObj} from "../service/rqOptionStaticObj";
import {debugLog} from "../util/UtilReact";
import FormNewbbPostTextarea from "./field/FormNewbbPostTextarea";
import ElementSimpleFormFieldOption from "./field/ElementSimpleFormFieldOption";
import IAttributeCallbackOptionObjFunc from "../type/form/IAttributeCallbackOptionObjFunc";
import {getFieldGroupIsPersistent} from "../util/UtilTS";
import ElementFormProfileUserNewsletter from "./field/ElementFormProfileUserNewsletter";

interface IProp {
    paramObj: IParamObj
    formFieldObj: IFormFieldInfo,
    formikGantry: IFormFormikGantry,
    parserOptions?: HTMLReactParserOptions,
    attributeCallback?: IAttributeCallback,
}

const ElementFormFieldGroupRow = ({
                                      paramObj,
                                      formFieldObj: formFieldObj,
                                      parserOptions,
                                      formikGantry: formikGantry,
                                      attributeCallback: attributeCallback,
                                  }: IProp) => {


    /* Domyślne wartości jeżeli funkcja przypisana
    - REST pobiera wartości z bazy dla tabeli i kolumny
    - tylko dla: jwallpaper_authorize
         - normalnie nie chcesz napisywać wartosci domyślnych ???
    * */
    var rqGlobalFormFieldValue: IRQOutputSingle | null = null;
    let selectObjectValue: number | null = null;

    if (formFieldObj.field_group === 'jwallpaper_authorize')    {
        selectObjectValue = paramObj.paramGalleryImageObj?.id ? paramObj.paramGalleryImageObj?.id : -1
    }

    if (getFieldGroupIsPersistent(formFieldObj.field_group))    { // TODO
        var current_user_id: number = paramObj.position_param0 ? Number.parseInt(paramObj.position_param0) : -1;
        selectObjectValue = current_user_id;
    }


        paramObj.paramGalleryImageObj?.id ? paramObj.paramGalleryImageObj?.id : -1;

    /* UWAGA: musi być wypełnione to pole, aby setFieldValue działało / TODO */
    if (formFieldObj.ng_init_function_name) {
        if (selectObjectValue) {
            rqGlobalFormFieldValue = RestApiAmocourseService.getGlobalFormFieldValueRQ(formFieldObj.field_group,
                formFieldObj.field_name,
                selectObjectValue
            );
            debugLog('RQ FORMIK VALUES' + JSON.stringify(formikGantry.values));

        }
    }


    useEffect(() => {
        /* only if actually defined to load */
            if (rqGlobalFormFieldValue) {
                if (!rqGlobalFormFieldValue.isLoading
                    && formikGantry.setFieldValue
                ) {
                    if (formFieldObj.field_type === 'checkbox') {
                        formikGantry.setFieldValue(
                            formFieldObj.field_name,
                            (rqGlobalFormFieldValue.data.data.loadResult ? ['on'] : [])
                        );
                    } else if (
                        formFieldObj.field_type === 'textarea' ||
                        formFieldObj.field_type === 'text' ||
                        formFieldObj.field_type === 'email' ||
                        formFieldObj.field_type === 'password' ||
                        formFieldObj.field_type === 'number'
                    ) {
                        formikGantry.setFieldValue(
                            formFieldObj.field_name,
                            (rqGlobalFormFieldValue.data.data.loadResult ? rqGlobalFormFieldValue.data.data.loadResult : '')
                        );
                /*    } else if (formFieldObj.field_group == 'profile_user_newsletter') {
                        formikGantry.setFieldValue(
                            formFieldObj.field_name,
                            (rqGlobalFormFieldValue.data.data.loadResult == 1 ? ['on'] : [])
                        );*/
                    } else {
                        formikGantry.setFieldValue(
                            formFieldObj.field_name,
                            (rqGlobalFormFieldValue.data.data.loadResult ? rqGlobalFormFieldValue.data.data.loadResult : '')
                        );
                    }
                }
            }
        }
        ,
        [rqGlobalFormFieldValue && rqGlobalFormFieldValue.isLoading]
    );


    const components: any = {
        ElementGlobalNgFieldRow,
        ElementSimpleFormFieldTextarea,
        GalleryCommentTextarea,
        ElementGlobalNgFieldCheckbox,
        ElementJwallpapersNgFieldCheckbox,
        ElementGlobalFieldButton,
        ElementGlobalFieldInputButton,
        ElementRegistrationFormInstrSelect,
        ElementComprofilerRegistrationFieldCheckbox,
        ElementGlobalNewsletterPrefFull,
        ElementGlobalNewsletterPrefMini,
        ElementAmocourseRegInstallmentRadio,
        ElementFormFieldPassword,
        ElementGalleryCommentSubmit,
        ElementFormGalleryImageAuthorize,
        FormNewbbPostTextarea,
        ElementSimpleFormFieldOption,
        ElementFormProfileUserNewsletter,
        /*
        ElementFieldEbookDelim,
        ElementGlobalFieldHidden,
        ElementGlobalNgFieldDelim,
        ElementRegistrationFormFieldHidden,
        ElementVoucherNgFieldRadio,
        */
    }
    if (formFieldObj.template_module === null) {
        formFieldObj.template_module = "ELEMENT_GLOBAL_NG_FIELD_ROW";
    }

    let camelized = capitalize(camelize(formFieldObj.template_module?.toLowerCase()));
    if (components[camelized]) {
        let Element = components[camelized];
        let isDisplay = true;
        let isDisabled = (formFieldObj.content_ng_disabled === "true" ? true : false);
        let onClickFunction: ((arg0: void) => any) | undefined = () => {
        };
        let tagIdFunction: ((arg0: void | IFormFormikGantry) => string) | undefined;
        let optionObjListFunction: IAttributeCallbackOptionObjFunc;


        if (attributeCallback && attributeCallback[formFieldObj.field_name]) {
            isDisplay = attributeCallback[formFieldObj.field_name].display(formikGantry); // musisz przekazać formikGantry tutaj, aby dynamicznie sprawdzać
            isDisabled = attributeCallback[formFieldObj.field_name].disabled(formikGantry); // musisz przekazać formikGantry tutaj, aby dynamicznie sprawdzać
            if (attributeCallback[formFieldObj.field_name].onClickFunction) {
                onClickFunction = attributeCallback[formFieldObj.field_name].onClickFunction;
            }
            if (attributeCallback[formFieldObj.field_name].tagIdFunction) {
                tagIdFunction = attributeCallback[formFieldObj.field_name].tagIdFunction;
            }
            if (formFieldObj.field_type == 'option' &&
                attributeCallback[formFieldObj.field_name].optionObjList
            ) {
                optionObjListFunction = attributeCallback[formFieldObj.field_name].optionObjList;
            }
        }
        /* On CHANGE mechanbism */
        /*useEffect(() => {
            if (!isDisabled && isDisplay) {
                if (formFieldObj.ng_change_function_name)   {
                    debugLog("VALUES CHANGED: " + formFieldObj.field_name + " " + formikGantry.values[formFieldObj.field_name]);
                }
            }
        },
            [formikGantry.values[formFieldObj.field_name]]
        );*/


        if (isDisplay) {

            return (
                <>
                    <SoftBox mb={{xs: 0.5, sm: 0.5, md: 1, lg: 1, xl: 1}}
                    >
                        <Element
                            paramObj={paramObj}
                            formFieldObj={formFieldObj}
                            formikGantry={formikGantry}
                            parserOptions={parserOptions}
                            disabled={isDisabled}
                            onClick={onClickFunction}
                            tagIdFunction={tagIdFunction}
                            optionObjListFunction={optionObjListFunction}
                        />
                    </SoftBox>

                    {/*<div className="divTable">
               <div className="divTableBody">
                   <div className="divTableRow">
                       <div className="divTableCell content_label">&lt;label
                           for="{row.field_name}"&gt;{row.field_label} &lt;/label&gt;
                           <span className="content_required">{row.content_required}</span>
                       </div>
                       <div className="divTableCell content_input">
                           &lt;input id="{row.field_name}" name="{row.field_name}" ng-model="formArray.{row.field_name}"
                           ng-minlength="{row.ng_minlength}" ng-required="{row.ng_required}"
                           ng-change="{row.ng_change_function_name}('{row.field_name}')"
                           ng-blur="{row.ng_blur_function_name}('{row.field_name}')"
                           placeholder="{row.field_placeholder}" type="{row.field_type}"
                           value="{row.field_default_value}" /&gt;<span className="form_span_tip">{row.field_tip}</span>
                           <span className="content_ng_validation_yes">{row.content_ng_validation_yes_template}</span>


                           <span className="content_ng_validation_no">{row.content_ng_validation_no_template}</span>

                       </div>
                   </div>
               </div>
           </div>*/}

                </>
            );
        }
    }
}

export default ElementFormFieldGroupRow;
