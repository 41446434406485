import ICtxClubChapter from "../../../type/context/ICtxClubChapter";
import React, {Suspense, useContext} from "react";
import AfdLoadingFullpage from "../../AfdLoadingFullpage";
import Emptybox from "../../emptybox/Emptybox";
import AfdContainerCard from "../../AfdContainerCard";

import useCHContainerData from "../../../hook/useCHContainerData";
import useCHGetContextSoftUI from "../../../hook/useCHGetContextSoftUI";
import IPropContainer from "../../../type/IPropContainer";
import ICtxGlobalConfig from "../../../type/context/ICtxGlobalConfig";
import SoftBox from "../../../components/SoftBox";
import CtxClubChapter from "../../../context/CtxClubChapter";
import SoftButton from "../../../components/SoftButton";
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import {getCtxIsIssueLast} from "../../../util/UtilReact";
import {Link} from "react-router-dom";
import SoftAlert from "../../../components/SoftAlert";
import SoftTypography from "../../../components/SoftTypography";

const ElementForumChapterItemListButton = React.lazy(() => import("./ElementForumChapterItemListButton")); // page_item_id: 810

interface IContainerClubForumChapterItemButton {
    container_title: string;
    discuss_url: string | null;
}

//{containerRow.post_count}
const GetClubForumChapterItemButton = ({
                                           controllerInfoObj: controllerInfoObj,
                                           userInfoObj: userInfoObj,
                                           paramObj: paramObj
                                       }: IPropContainer) => {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const ctxChapterFunctionObj = useContext<ICtxClubChapter>(CtxClubChapter);
    let isIssueLast: boolean = getCtxIsIssueLast(ctxChapterFunctionObj);
    if (!isIssueLast) return null;

    const components: any = {
        ElementForumChapterItemListButton,

    }
    var real_forum_id: number = paramObj.position_param8 != null ? Number.parseInt(paramObj.position_param8) : ctxChapterFunctionObj.storeNewbbForumInfoObj?.forum_id || -1;
    var item_type: string = controllerInfoObj.const_param1 ? controllerInfoObj.const_param1 : '';
    let isAdmin: boolean = paramObj.position_param4 ? paramObj.position_param4 == 'Y' : false;

    /* Nawet jeżeli nie ma nic na liśćie- wyświetlaj przycisk pracy forum = UNI
    */
    var [elementObjList, getElementObjList, containerInfoObj, setContainerInfoObj] = useCHContainerData<any, IContainerClubForumChapterItemButton>(controllerInfoObj,
        {},
        {
            user_id: userInfoObj.user_id,
            item_type: item_type,
            real_forum_id: real_forum_id,
        }
    );

    let ElementComponent: React.JSXElementConstructor<any>;
    if (controllerInfoObj.element1CamelName !== undefined) {
        ElementComponent = components[controllerInfoObj.element1CamelName];


    }

    function getContainerContent() {
        let row = containerInfoObj;
        if (controllerInfoObj.containerName === 'CONTAINER_FORUM_CHAPTER_ITEM_BUTTON') {
            return (
                <SoftBox
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    {
                        containerInfoObj.discuss_url ? <SoftButton
                            component={Link}
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<ChatOutlinedIcon/>}
                            /*fullWidth={true}*/
                            to={containerInfoObj.discuss_url}
                        >
                            Idź do tematu pracy domowej...
                        </SoftButton> :
                        <SoftAlert
                            color={"error"}
                        >
                            <SoftTypography
                                variant="caption"
                                color="white"
                                align="center">
                                Błąd: Nie znaleziono tematu pracy domowej dla użytkownika: {userInfoObj.user_id}. Skontaktuj się z biurem...
                            </SoftTypography>
                        </SoftAlert>
                        }
                </SoftBox>
            );
        }
    }

    return (
        <>
            <Suspense fallback={<AfdLoadingFullpage
                componentCategory="AfdController_Suspense"
                controllerInfoObj={controllerInfoObj}
            />}></Suspense>
            {
                (containerInfoObj) ?
                    (
                        <AfdContainerCard
                            controllerInfoObj={controllerInfoObj}
                            userInfoObj={userInfoObj}
                            paramObj={paramObj}
                        >
                            {getContainerContent()}
                        </AfdContainerCard>
                    ) : (<Emptybox
                        controllerInfoObj={controllerInfoObj}
                        userInfoObj={userInfoObj}
                        paramObj={paramObj}
                    />)
            }
        </>
    );
}

export default GetClubForumChapterItemButton;
