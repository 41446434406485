const  afdColorObjList = {
    "darkred": ['4a0b05', '4d0b03', '4c0b05','5c201b','855a57', 'c4acb0'],
    "darkorange": ['7d3b10','7c3b10','7a3c11', '884d22', 'a57d5d','d1bfb0'],
    "orange": ['a35f1d','a35e1d', 'a45e1d','ac6e2b','be945e','dcccb9'],
    "yellow": ['c18d31','c18d32','c18d32','c79840','d4b16c','e8d7b8'],
    "grey": ['2d190f', '2e1a0e', '2c1b10','413226','736962','bab7b2'],
    "candy": ['982e10','982e10', '982e10','a03f21','b47059','d5b8ae'],
    "red": ['841a0a', '841a0a', '841a0a','8c281e','8c281e','d0b0ae'],
    "green": ['4f9146', '4f9146','4f9146','68b02a','68b02a','68b02a'] // TODO
}

function getAfdColor(
    color: "darkred"|"darkorange"|"orange"|"yellow"|"grey"|"candy"|"red"|"green",
    index: number
)  {
    // @ts-ignore
    return '#'+afdColorObjList[color][index];
}

export  {getAfdColor};

"#a57d5d"
