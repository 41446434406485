import {forwardRef} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for SoftButton
import SoftButtonRoot from "./SoftButtonRoot";

/*
// Setting default values for the props of SoftButton
SoftButton.defaultProps = {
  size: "medium",
  variant: "contained",
  color: "white",
  circular: false,
  iconOnly: false,
};

// Typechecking props for the SoftButton
SoftButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
};*/

interface ISoftButtonAttr {
    color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "white";
    fullWidth?: boolean;
    variant?: "text" | "contained" | "outlined" | "gradient";
    disabled?: boolean;
    size?: "small" | "medium" | "large";
    startIcon?: string | React.ReactNode;
    endIcon?: string | React.ReactNode;
    href?: string;
    target?: string;
    rel?: string;
    circular?: boolean;
    iconOnly?: boolean;
    component?: any;
    to?: string;
    children: any;
    autoFocus?: boolean;
    onClick?: any;
    sx?: any;
    isSubmit?: boolean;
};


/*BUGFIX: added type */
const SoftButton = forwardRef(
    (
        {
            color,
            variant,
            size,
            isSubmit,
            circular,
            iconOnly,
            children, ...rest
        }: ISoftButtonAttr,
        ref: any
    ) => (
        <SoftButtonRoot
            {...rest}
            ref={ref}
            type={(isSubmit ? "submit" : "button")}
            color="primary"
            variant={variant === "gradient" ? "contained" : variant}
            size={size}
            // @ts-ignore
            ownerState={{color, variant, size, circular, iconOnly}}
        >
            {children}
        </SoftButtonRoot>
    )
);


export default SoftButton;
