
/*// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  color: "info",
  active: false,
  noCollapse: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
};*/

import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";

interface IProp {
    color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
    icon: any;
    name: string;
    children?: any;
    active?: boolean;
    noCollapse?: boolean;
    open?: boolean;
    onClick?: any;
}


// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
} from "../../examples/Sidenav/styles/sidenavCollapse";

import {useCHGetContextSoftUI} from "../../context/CtxSoftUIGlobalSetupByRdx";

function SidenavCollapse({ icon = "info",
                           name,
                           children = false,
                           active = false,
                           noCollapse = false,
                           open = false,
                           ...rest }: IProp
) {
    const [controllerParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const { miniSidenav, transparentSidenav, sidenavColor } = controllerParamObj;


    return (
    <>
      <ListItem component="li">
        <SoftBox {...rest} sx={
            (theme: any) => collapseItem(theme, { active, transparentSidenav })}>
          <ListItemIcon
            sx={(theme) => collapseIconBox(theme, { active, transparentSidenav, sidenavColor })}
          >
            {typeof icon === "string" ? (
                /*// @ts-ignore*/
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) => collapseText(theme, { miniSidenav, transparentSidenav, active })}
          />

          <Icon
            sx={(theme) =>
              collapseArrow(theme, { noCollapse, transparentSidenav, miniSidenav, open })
            }
          >
            expand_less
          </Icon>
        </SoftBox>
      </ListItem>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

export default SidenavCollapse;
