/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import {createContext} from "react";
import useCHGetContextSoftUI from "../../hook/useCHGetContextSoftUI";
import {
  setDirection,
  setFixedNavbar,
  setLayout,
  setMiniSidenav,
  setOpenConfigurator,
  setSidenavColor,
  setTransparentNavbar,
  setTransparentSidenav
} from "../../redux_reducer/util/dispatchShortcut";

// The Soft UI Dashboard PRO React main context
export const CtxSoftUIGlobalSetupByRdx = createContext(null);

// Setting custom name for the context which is visible on react dev tools
CtxSoftUIGlobalSetupByRdx.displayName = "SoftUIContext";


export {
  useCHGetContextSoftUI,
  setMiniSidenav,
  setTransparentSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
};
