import { forwardRef } from "react";

type variantEnumType = "contained" | "gradient";

interface ISoftBox {
    variant?: string;
      bgColor?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "white" | "transparent" | "primary.secondary" | "grey-100";
      color?: string;
      opacity?: number;
      borderRadius?: any;
      borderBottom?: any;
          href?: string;
          target?: string;
          rel?: string;
          id?: number | string;
          flexShrink?: number;
            flexGrow?: number;
      display?: any;
      lineHeight?: number;
      position?: string;
      pt?: number  | IResp;
      pb?: number  | IResp | string;
      pl?: number | IRespString;
      pr?: number  | IResp;
      px?: number | IResp;
      py?: number  | IResp;
      p?: number  | IResp | string;
      m?: number   | IResp | "auto";
        mb?: number | IResp  | "auto";
        mt?: number  | IResp  | "auto";
        ml?: number  | IResp  | "auto";
      mr?: number   | IResp  | "auto";
      my?: number   | IResp  | "auto";
      mx?: number | IResp  | "auto";
      xs?: any;
      sx?: any;
      width?: string | IRespString;
      height?: string;
        minWidth?: string;
        maxWidth?: string;
        minHeight?: string;
        maxHeight?: string;
      fontSize?: string;
      src?: string;
      title?: string;
      name?: string | number;
      alt?: string;
        /*https://www.creative-tim.com/learning-lab/react/box-shadows/soft-ui-dashboard/?_ga=2.91841497.236560796.1669382508-1183879428.1664180721*/
      shadow?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "inset" | "none";
      justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly" | "baseline";
      flexDirection?: any;
      flexWrap?: any;

      alignItems?: IRespString | "flex-start" | "flex-end" | "center" | "stretch" | "baseline" | "left" | "right" | "top" | "normal" | "self-start" | "self-end" | "start" | "end" | "first baseline" | "last baseline" | "safe center" | "unsafe center" | "inherit" | "initial" | "unset";
      overflow?: string;
      overflowX?: string;
        overflowY?: string;
      right?: number | string | IRespString;
      left?: number | string | IRespString;
      bottom?: number | string | IRespString;
      top?: number | string | IRespString;
      zIndex?: number;
      component?: any;
        onClick?: any;
      children?: any;
      rest?: any;
      textAlign?: string;
      border?: any;
      xmlns?: string;
      xmlnsXlink?: string;
      viewBox?: string;
      preserveAspectRatio?: string;
      shapeRendering?: string;
      to?: string;
      className?: string;
       onMouseEnter?: any;
        onMouseLeave?: any;
}


// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for SoftBox
import SoftBoxRoot from "./SoftBoxRoot";
import IResp from "../../type/resp/IResp";
import IRespString from "../../type/resp/IRespString";

const SoftBox = forwardRef(
  (
        { variant ="contained",
          bgColor = "transparent",
          color = "dark",
          opacity = 1,
          borderRadius = "none",
          shadow = "none",
          ...rest }: ISoftBox,
        ref: any) => (
    <SoftBoxRoot
      {...rest}
      ref={ref}
        // @ts-ignore
      ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow }}
    />
  )
);
/*

// Setting default values for the props of SoftBox
SoftBox.defaultProps = {
  variant: "contained",
  bgColor: "transparent",
  color: "dark",
  opacity: 1,
  borderRadius: "none",
  shadow: "none",
};

// Typechecking props for the SoftBox
SoftBox.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  bgColor: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.number,
  borderRadius: PropTypes.string,
  shadow: PropTypes.string,
};
*/

export default SoftBox;
