import React, {forwardRef} from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";

// custom styles for the NotificationItem
import {menuItem, menuImage} from "../../../examples/Items/NotificationItem/styles";
import IElementMyAcademyNotifLine from "../../../type/my_academy/IElementMyAcademyNotifLine";
import RestApiGalleryService from "../../../service/restapi/RestApiGalleryService";
import RestApiCommonService from "../../../service/restapi/RestApiCommonService";
import IUserInfo from "../../../type/IUserInfo";
import {useNavigate} from "react-router-dom";
import linkDictionary from "../../../linkDictionary";
import ICtxClubDouble from "../../../type/context/ICtxClubDouble";
import CtxClubDouble from "../../../context/CtxClubDouble";

/*
// Setting default values for the props of NotificationItem
NotificationItem.defaultProps = {
    color: "dark",
};

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    image: PropTypes.node.isRequired,
    title: PropTypes.arrayOf(PropTypes.string).isRequired,
    contentWhen: PropTypes.string.isRequired,
};*/

interface IProp {
    notificationObj: IElementMyAcademyNotifLine;
    pageItemGroup: string;
    userInfoObj: IUserInfo;
    color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
    redirectionType: "internal" | "external";
    image: React.ReactNode;
    title: string[];
    contentWhen: string;
    onClick?: (element: any) => void;
}


const NotificationItem = forwardRef((
    {
        notificationObj,
        pageItemGroup,
        userInfoObj,
        color = "dark",
        redirectionType,
        image,
        title,
        contentWhen,
        ...rest
    }: IProp,
    ref) => {
    const ctxGalleryDouble: ICtxClubDouble = React.useContext(CtxClubDouble);

    const navigate = useNavigate();

    /*Kliknięcie na pozycję*/
    const handleNotifItemClick = (event: React.MouseEvent<HTMLElement>) => {
        console.log(event.currentTarget.id);
        /* Odnzacz wszystkie pozycje MY Academy */

        RestApiCommonService.getPromise(RestApiGalleryService.setMyAcademyTablePerfUserMarkAllUrl(
            notificationObj.perf_user_id,
            pageItemGroup,
        )).then((response) => {
            console.log(response);
            ctxGalleryDouble.setStoreMyAcademyNotifCounter({
                ...ctxGalleryDouble.storeMyAcademyNotifCounter,
                [pageItemGroup]: 0,
            });
            /*Przekieruj po odznaczeniu wszystkich*/
            if (redirectionType === "internal") {
                navigate(notificationObj.academy_wall_url);
            } else {
                window.location.href = 'https://www.akademia-fotografii-dzieciecej.pl/' + notificationObj.academy_wall_url;
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        // @ts-ignore
        <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}
                  id={notificationObj.client_track_id}
                  onClick={handleNotifItemClick}
        >
            <SoftBox
                width="3rem" // 2.25rem
                height="3rem" // 2.25rem
                mt={0.25}
                mr={2}
                mb={0.25}
                borderRadius="lg"
                sx={
                    (theme: any) => menuImage(theme, {color})
                }
            >
                {image}
            </SoftBox>
            <SoftBox>
                <SoftTypography variant="button"
                    /*textTransform="capitalize"*/
                                fontWeight="regular">
                    <SoftTypography
                        display={"inline-block"}
                        variant={"button"}
                        fontWeight="medium"
                        pr={"1ch"}
                    >{title[0]}</SoftTypography>
                    {title[1]}
                    <SoftTypography
                        display={"block"}
                        variant={"button"}
                        fontWeight="medium"
                        sx={{
                            width: "30ch",
                            overflow: "hidden",
                            textOverflow: "ellipsis",

                        }}
                    >{title[2]}</SoftTypography>
                </SoftTypography>
                <SoftTypography
                    variant="caption"
                    color="secondary"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 0.5,
                    }}
                >
                    <SoftTypography variant="button" color="secondary">
                        <Icon
                            sx={{
                                lineHeight: 1.2,
                                mr: 0.5,
                            }}
                        >
                            watch_later
                        </Icon>
                    </SoftTypography>
                    {contentWhen}
                </SoftTypography>
            </SoftBox>
        </MenuItem>
    )
});


export default NotificationItem;
