import { useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../components/SoftBox";

// Setting default values for the props of AfdDefaultNavbarMenu
/*
AfdDefaultNavbarMenu.defaultProps = {
  placement: "bottom-start",
  style: {},
};

// Typechecking props for the AfdDefaultNavbarMenu
AfdDefaultNavbarMenu.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
  placement: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};
*/

interface IProp {
    open: boolean;
    close: () => void;
    placement?: "bottom-start" | "bottom-end" | "top-start" | "top-end";
    children?: any[] | any;
    style?: React.CSSProperties;
}

function AfdDefaultNavbarMenu({
                               open,
                               close,
                               placement = "bottom-start",
                               children,
                               style = {} }: IProp) {
  const [anchor, setAnchor] = useState(false);

  const openMenu = () => setAnchor(open);
  const closeMenu = () => setAnchor(false);



    return (
    <Popper
        // @ts-ignore
      anchorEl={anchor || open}
      popperRef={null}
      open={Boolean(anchor) || Boolean(open)}
      placement={placement}
      transition
      style={{ zIndex: 10, ...style }}
    >
      {({ TransitionProps }) => (
          // @ts-ignore
        <Grow {...TransitionProps} sx={{ transformOrigin: "left top" }}>
          <SoftBox
            bgColor="white"
            shadow="lg"
            borderRadius="xl"
            p={2}
            onMouseEnter={openMenu}
              // @ts-ignore
            onMouseLeave={(close, closeMenu)}
          >
            {children}
          </SoftBox>
        </Grow>
      )}
    </Popper>
  );
}


export default AfdDefaultNavbarMenu;
