import {createContext} from "react";

import IAmocoursePriceArray from "../type/amocourse/IAmocoursePriceInfo";
import ICtxAmocourse from "../type/context/ICtxAmocourse";

/*
    The problem here is that you need to help TypeScript understand the types of the objects you're dealing with.
    https://blog.logrocket.com/how-to-use-react-context-typescript/
 */
export const ctxPriceArrayDefaultValue: ICtxAmocourse = {
    storePriceArray: null,
    setStorePriceArray: () => {},
    storeRegProviderObj: null,
    setStoreRegProviderObj: () => {},
    };
const CtxAmocourse = createContext<ICtxAmocourse>(ctxPriceArrayDefaultValue);

export default CtxAmocourse;
