import ElementFormFieldGroupRow from "./ElementFormFieldGroupRow"; // page_item_id: 154

import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {lazy, Suspense} from "react";

import RestApiService from "../service/restapi/RestApiService";
import RestApiCommonService from "../service/restapi/RestApiCommonService";
import {processContainer, processElement} from "../util/UtilReact";

import IFormFieldInfo from "../type/form/IFormFieldInfo";
import {HTMLReactParserOptions, Element} from 'html-react-parser';
import IParamObj from "../type/IParamObj";
import IFormFormikGantry from "../type/form/IFormFormikGantry";
import * as Yup from "yup";
import IFormFormikParamObj from "../type/form/IFormFormikParamObj";

/* REDUX ponieważ CONTEXT jeżeli wartości zawsze ustawiane dla drzewa, inaczej REDUX */
import {useDispatch} from "react-redux";
import {actFormikParamObjUpdate, actPromocodeArrayUpdate, RDX_SWITCH_DICTIONARY} from "../redux_action/rdxAction";
import IAttributeCallback from "../type/form/IAttributeCallback";
import {
    ASYNC_VALIDATION_TIMEOUT_IN_MS,
    validateUsernameFunc, /*validatePromocodeFunc*/
} from "../form/validation/validationFunction";
import {debounce} from "lodash";
import RestApiAmocourseService from "../service/restapi/RestApiAmocourseService";
import IRQOutputList from "../type/rq/IRQOutputList";
import IAmocoursePromocodeInfo from "../type/amocourse/IAmocoursePromocodeInfo";
import {CircularProgress} from "@mui/material";
import ICtxClubNewbb from "../type/context/ICtxClubNewbb";
import CtxClubNewbb from "../context/CtxClubNewbb";
import ICtxYupSchema from "../type/context/ICtxYupSchema";
import CtxYupSchemaObj from "../context/CtxYupSchemaObj";

interface IProp {
    controllerInfoObj: any;
    userInfoObj: any;
    paramObj: IParamObj;
    field_group: string,
    formikGantry: IFormFormikGantry;
    parserOptions?: HTMLReactParserOptions;
    attributeCallback: IAttributeCallback;
    fieldGroupInstanceKey?: string; // jeżeli występuje wiele tych samych FIELD GROUP  - nie chcemy przeładowywać wszystkich, ale konkretne
}

export interface IValidationSchemaShapeObj {
    [key: string]: any;
}

const GetFormFieldGroup = ({
                               controllerInfoObj: controllerInfoObj,
                               userInfoObj: userInfoObj,
                               paramObj: paramObj,
                               field_group: field_group,
                               formikGantry: formikGantry,
                               parserOptions: parserOptions,
                               attributeCallback: attributeCallback,
                               fieldGroupInstanceKey: fieldGroupInstanceKey,

                           }: IProp) => {
    const ctxNewbbFunctionObj: ICtxClubNewbb = useContext<ICtxClubNewbb>(CtxClubNewbb);
    const ctxYupSchemaObj: ICtxYupSchema = useContext<ICtxYupSchema>(CtxYupSchemaObj);
    const components: any = {
        ElementFormFieldGroupRow,
    }

    var containerDefaultRow: any;
    const dispatchFunction = useDispatch();

    /*yup.mixed;
    yup.string;
    yup.number;
    yup.boolean; // also aliased as yup.bool
    yup.date;
    yup.object;
    yup.array;*/


    const validatePromocodeFunc = async (valueObj: {
        course_event_id: number;
        user_id: number;
        promocode: string;
    }, resolve: any) => {

        try {
            RestApiCommonService.getPromise(RestApiAmocourseService.getAmocoursePromocodePostRegExcInfo(
                valueObj.course_event_id,
                valueObj.user_id,
                valueObj.promocode
            )).then((response) => {
                let promocodeInfoObj: IAmocoursePromocodeInfo = response.data;
                /* Validacja dopiero po wprowadzeniu kodu */
                if (typeof valueObj.promocode === 'undefined' ||
                    valueObj.promocode === '') return resolve(true);

                if (promocodeInfoObj &&
                    promocodeInfoObj?.post_reg_exception_name != null
                ) {
                    dispatchFunction(actPromocodeArrayUpdate(promocodeInfoObj));
                    // The username is available
                    /*$scope.setCalculateTotal();*/
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
        } catch (error) {
            resolve(false);
        }
    }

    const validateUsernameDebounceFunc = validateUsernameFunc;
    /*debounce(validateUsernameFunc, ASYNC_VALIDATION_TIMEOUT_IN_MS);*/

    /* Zamiast ELEMNET - pobierz listę FIELD_GROUP */
    var [elementFieldObjList, setElementFieldObjList] = useState<IFormFieldInfo[]>([]);
    const getFormFieldInfoRQ: IRQOutputList = RestApiCommonService.getFormFieldInfoRQ(field_group, undefined, fieldGroupInstanceKey);

    function getYupSchemaObj(internalFieldObjList: IFormFieldInfo[]): IValidationSchemaShapeObj {
        let internalYupSchemaObj: IValidationSchemaShapeObj = {};

        /* To jest przekazywane i wołane niezależnie dla PROMOCODE */
        function factoryAmocoursePromocodeCallback() {
            return (promocodeValue: string) => {
                if (promocodeValue &&
                    promocodeValue.length > 0) {
                    return new Promise((resolve) => {
                        validatePromocodeFunc({
                            promocode: promocodeValue,
                            course_event_id: Number(paramObj.position_param0),
                            user_id: userInfoObj.user_id,
                        }, resolve);
                    });
                } else {
                    /* Jeżeli kod nie podany */
                    return Promise.resolve(true);
                }
            };
        }

        function factoryUserameCallback() {
            return (usernameValue: string) => {
                /* Dla pustego */
                if (!usernameValue || usernameValue.length === 0) {
                    return Promise.resolve(false);
                } else {
                    return new Promise((resolve) => {
                        validateUsernameDebounceFunc(usernameValue, resolve);
                    });
                }
            };
        }

        internalFieldObjList.forEach((fieldObj: IFormFieldInfo) => {
                /* Generowanie obiektu statycznego jako CONFIG dla YUP
                 - Wszystkie opcje w xx_amocourse_form_field
                 */
                switch (fieldObj.field_type) {
                    case "textarea":
                        internalYupSchemaObj[fieldObj.field_name] = Yup.string();
                        break;
                    /*case "checkbox": internalYupSchemaObj[fieldObj.field_name] = Yup.boolean(); break;*/


                    case "checkbox":
                        internalYupSchemaObj[fieldObj.field_name] = Yup.mixed();
                        break; // zwraca on w tablicy
                    /* BUGFIX: nie możę być typu ARRAY poniewaz przy walidakcji BUG FORMIK: pakuje wiadomości z validate do gantry.values
                   - przechowuje je potem w LOCAL STORAGE przeglądarki, które są zachowane nawet pomiędzy INCOGNITO i nie INCOGNITO
                   - być może zła konfiguracja samego FIELD że jest taki przeciek
                   - można walidację zaimplementować za pomocą YUP TEST???

                           case "checkbox": internalYupSchemaObj[fieldObj.field_name] =
                               Yup.array().of(
                                   Yup.string().oneOf(["on"])
                               ).min(1, 'Checkbox is required');
                               break; // zwraca on w tablicy*/
                    case "button":
                        internalYupSchemaObj[fieldObj.field_name] = Yup.string();
                        break;
                    case "number":
                        internalYupSchemaObj[fieldObj.field_name] = Yup.number();
                        break;
                    case "submit":
                        internalYupSchemaObj[fieldObj.field_name] = Yup.string();
                        break;
                    case "hidden":
                        internalYupSchemaObj[fieldObj.field_name] = Yup.string();
                        break;
                    case "text":
                        internalYupSchemaObj[fieldObj.field_name] = Yup.string();
                        break;
                    case "delimiter":
                        internalYupSchemaObj[fieldObj.field_name] = Yup.string();
                        break;
                    case "option":
                        internalYupSchemaObj[fieldObj.field_name] = Yup.string();
                        break;
                    case "radio":
                        internalYupSchemaObj[fieldObj.field_name] = Yup.string();
                        break;
                    case "email":
                        let VALIDATION_NO_TEMPLATE = `Podaj email  we właściwym formacie...`; //${fieldObj.field_label}
                        internalYupSchemaObj[fieldObj.field_name] = Yup.string().email(VALIDATION_NO_TEMPLATE);
                        break;
                    case "password":
                        let VALIDATION_PASSWORD_NO_TEMPLATE = `Podaj hasło...`; //${fieldObj.field_label}
                        internalYupSchemaObj[fieldObj.field_name] = Yup.string();
                        break;

                }


                if (fieldObj.field_is_required == 'true') {
                    /*internalYupSchemaObj = {
                        ...internalYupSchemaObj,
                        [fieldObj.field_name]: Yup.string().email("Email"),
                    }*/
                    /* Dodaj do istniejącego */
                    internalYupSchemaObj[fieldObj.field_name] = internalYupSchemaObj[fieldObj.field_name].required(
                        "Pole " + fieldObj.field_label + " jest wymagane."
                    );

                }
                if (fieldObj.ng_minlength && fieldObj.ng_minlength > 0) {
                    internalYupSchemaObj[fieldObj.field_name] = internalYupSchemaObj[fieldObj.field_name].min(fieldObj.ng_minlength,
                        "Minimalna długość to " + fieldObj.ng_minlength + " znaków."
                    );
                }

                /* ASYNCH *?
                 */
                if (fieldObj.field_group === 'account_create_system') {
                    if (fieldObj.field_name === 'username') {
                        internalYupSchemaObj[fieldObj.field_name] = internalYupSchemaObj[fieldObj.field_name].test(
                            'username-unique',
                            'Nazwa użytkownika jest już zajęta.',
                            factoryUserameCallback()
                        );
                    }
                }
                if (fieldObj.field_name === 'password__verify') {
                    let VALIDATION_PASSWORD_NO_TEMPLATE = `Hasła wpisane w obu polach muszą być identyczne...`; //${fieldObj.field_label}

                    /* Dodaj do istniejących */
                    internalYupSchemaObj[fieldObj.field_name] = internalYupSchemaObj[fieldObj.field_name].oneOf([Yup.ref("password"), null], VALIDATION_PASSWORD_NO_TEMPLATE);
                }
                if (fieldObj.field_group === 'amocourse_reg_about') {
                    if (fieldObj.field_name === 'promocode') {
                        internalYupSchemaObj[fieldObj.field_name] = internalYupSchemaObj[fieldObj.field_name].test(
                            'amocourse-reg-about-promocode',
                            'Nieprawidłowy kod promocyjny.',
                            factoryAmocoursePromocodeCallback()
                        );
                    }
                }
                if (fieldObj.field_group === 'newbb_topic_post') {
                    /* Działa ! :)
                    - BUGFIX: walidacja musi być tylko tam, gdzie pola faktycznie istnieją
                    * */
                    if (fieldObj.field_name === 'post_new_topic_title'
                        && ctxNewbbFunctionObj.storeNewbbForumInfoObj?.system_type == 'SYSTEM_TYPE_UNI'
                        && controllerInfoObj.position_code == 'body'
                    ) {
                        /*
                        TODO- walidacja jest globalna i jeżeli masz wiele formularzy włączonych to kaplica !!!
                        internalYupSchemaObj[fieldObj.field_name] = internalYupSchemaObj[fieldObj.field_name].when('post_topic_select', {
                             is: (val: number) => val == -1,
                             then: Yup.string().required('Wpisz tytuł tematu lub wybierz jeden z listy...'),
                             otherwise: Yup.string(),
                         });*/
                    }
                }
            }
        );
        /*internalFormikParamObj['validationSchema'] = Yup.object().shape({
                       email: Yup.string()
                           .email("AFD: Email")
                           .required('This is required'),
                   });*/
        /*debugLog('internalFormikParamObj', JSON.stringify(internalYupSchemaObj));*/

        return internalYupSchemaObj;
    }

    useEffect(() => {
        if (!getFormFieldInfoRQ.isLoading) {
            let internalFieldObjList = getFormFieldInfoRQ.data.data;
            /* BUGFIX: na poziomie GANTRY ustawiaj CTX aby nie przechowywać zmiennych FORMIK pomiędzy formularzami
            - to nadal nie rozwiązuje problemu, kiedy to samo GANTRY, ale wystarczy na razie
             */
            /*dispatchFunction(actFormikParamObjUpdate(
                null,
                getYupSchemaObj(internalFieldObjList)
            ));*/
            setElementFieldObjList(processElement(internalFieldObjList, controllerInfoObj));
        }

    }, [
        getFormFieldInfoRQ.isLoading ? 1 : 0,
        field_group
    ]);

    /* BUGFIX: separate useEffect because want to make sure that all elementFieldObjList is SET
     */
    useEffect(() => {
            if (elementFieldObjList && elementFieldObjList?.length > 0) {
                /* Połącz WALIDACJE z wielu FIELD_GROUP */
                ctxYupSchemaObj.setStoreValidationSchemaShapeObj((prevState: IValidationSchemaShapeObj) => {
                    let newSchemaObj: IValidationSchemaShapeObj = getYupSchemaObj(elementFieldObjList)
                    /* BUGFIX: Object.assign does not work, but this does */
                    let combinedSchemaObj: IValidationSchemaShapeObj = {
                        ...prevState,
                        ...newSchemaObj
                    }
                    return combinedSchemaObj;
                });
            }
        },
        [
            (elementFieldObjList && elementFieldObjList?.length > 0)
        ]
    );


    function getContainerContent() {

        return (
            <>
                {
                    formikGantry && elementFieldObjList.map((element: IFormFieldInfo, index: number) => (
                        // @ts-ignore
                        <ElementFormFieldGroupRow
                            key={element.id}
                            paramObj={paramObj}
                            formFieldObj={element}
                            formikGantry={formikGantry}
                            /*// @ts-ignore*/
                            parserOptions={parserOptions}
                            attributeCallback={attributeCallback}
                        />
                    ))
                }
            </>
        );
    }

    return (
        <>
            <Suspense fallback={<div>Loading... </div>}>
            </Suspense>
            {
                (elementFieldObjList?.length > 0) ?
                    (
                        getContainerContent()
                    ) : (<div>
                        <CircularProgress color="inherit"/>
                    </div>)
            }
        </>
    );
}

export default GetFormFieldGroup;
