import {IValueProfileUserFoto} from "../type/value_initial/IValueProfileUserFoto";
import {IValueProfileUserInfo} from "../type/value_initial/IValueProfileUserInfo";
import {IValueAccountCreateLegal} from "../type/value_initial/IValueAccountCreateLegal";
import {IValueProfileUserWWW} from "../type/value_initial/IValueProfileUserWWW";
import {IValueProfileUserNotification} from "../type/value_initial/IValueProfileUserNotification";
import {IValueProfileUserDict} from "../type/value_initial/IValueProfileUserDict";
import {IValueProfileUserNewsletter} from "../type/value_initial/IValueProfileUserNewsletter";
import {IValueProfileUserSharing} from "../type/value_initial/IValueProfileUserSharing";
import IValueSitePasswordReset from "../type/value_initial/IValueSitePasswordReset";
import IValueSiteContactUsMessage from "../type/value_initial/IValueSiteContactUsMessage";
import IValueChatbotForm from "../type/value_initial/IValueChatbotForm";

export interface IFormikProfileInitialValues {
    profile_user_foto: IValueProfileUserFoto;
    profile_user_info: IValueProfileUserInfo;
    account_create_legal: IValueAccountCreateLegal;
    profile_user_www: IValueProfileUserWWW;
    profile_user_notification: IValueProfileUserNotification;
    profile_user_dict: IValueProfileUserDict;
    profile_user_newsletter: IValueProfileUserNewsletter;
    profile_user_sharing: IValueProfileUserSharing;
    site_password_reset: IValueSitePasswordReset,
    site_contact_us: IValueSiteContactUsMessage,
    chatbot_form: IValueChatbotForm
}

export const formikProfileInitialValues: IFormikProfileInitialValues = {
    profile_user_info: {
        "cb_miasto": "",
        "cb_modelaparatu": "",
        "firstname": "",
        "email": "",
        "cb_user_experience": "",
        "password__client": "",
        "username": "",
    },
    profile_user_foto: {
        "cb_sesje_city": "",
        "cb_sesje_selfdesc": "",
        "cb_sesje_title": "",
        "cb_user_experience": "",
        "email": "",
        "firstname": "",
        "lastname": "",
    },
    account_create_legal: {
        "acceptedterms": [],
        "cb_law_commercial": [],
        "cb_law_electronic": [],
    },
    profile_user_www: {
        "cb_profile_fanpage_url": "",
        "cb_profile_blog_url": "",
        "cb_profile_insta_url": "",
        "cb_profile_social_name": "",
        "cb_profile_website_url": "",
    },
    profile_user_notification: {
        "cb_emailing_ban_is_disabled": [],
        "cb_is_course_instr_reply_email": [],
        "cb_is_course_instr_reply_sms": []
    },
    profile_user_dict: {
        "user_dict_age2": [],
        "user_dict_age1": [],
        "user_dict_age3": [],
    },
    profile_user_newsletter: {
        "newsletter_mobile": [],
        "age_0_0": [],
        "newsletter_dslr": [],
        "newsletter_photoshop": [],
        "newsletter_process": [],
        "age_0_1": [],
        "age_12_100": [],
        "age_1_2": [],
        "age_2_4": [],
        "age_4_12": [],
    },
    profile_user_sharing: {
        "cb_sec_is_image_friend_share": [],
        "cb_city_is_first_name": [],
        "cb_sec_is_before_after": [],
        "cb_sec_is_course_info": [],
        "cb_sec_is_firend_list": [],
        "cb_sec_is_user_spec": [],
        "cb_sec_is_public_image": [],
        "cb_sec_is_future_reg": [],
    },
    site_password_reset: {
        "password_reset_email": ""
    },
    site_contact_us: {
        "site_contact_us_email": "",
        "site_contact_us_image_id": -1,
        "site_contact_us_title": "",
        "site_contact_us_group_id": -1,
        "site_contact_us_message_text": "",
        "site_contact_us_url": ""
    },
    chatbot_form: {
        "chatbot_question": "",
    },
}
