import RestApiService from "./RestApiService";
import {bin2hex, afdObjectToUrlString} from './../../util/Util';
import {API_URL} from "./RestApiService";
import IAmocourseRegInsert from "../../type/amocourse/IAmocourseRegInsert";
import IAxiosPostData from "../../type/axios/IAxiosPostData";
import IRQOutputList from "../../type/rq/IRQOutputList";
import IRQOutputSingle from "../../type/rq/IRQOutputSingle";
import IRQQueryKey from "../../type/rq/IRQQueryKey";
import {useQuery} from "react-query";
import {rqOptionStaticObj} from "../rqOptionStaticObj";

class RestApiContactEnhService extends RestApiService {

    constructor() {
        super();
    }

    /* Potrzebujemy takżę FIELD GROUP, aby de facto dowiedzieć się jakie ID poszczególnych FIELD
    - to mapwanie zapisane jest w tabeli: xx_amocourse_field_group
     */
    static setContactEnhancedMessageInsert(contactId: number,
                                           userId: number,
                                           formDatetime: string,
                                           fieldGroup: string,
                                           formFieldValueObjList?: {
                                               field_name: string,
                                               field_value: string
                                           }[],
                                           xmProcedureName?: string,
    ): string {
        const myUrl = new URL(API_URL);

        myUrl.searchParams.append("contact_message_insert", '1');
        myUrl.searchParams.append("contact_id", contactId.toString());
        myUrl.searchParams.append("user_id", userId.toString());
        myUrl.searchParams.append("form_datetime", formDatetime);
        myUrl.searchParams.append("field_group", fieldGroup);
        if (typeof formFieldValueObjList !== 'undefined') {
            myUrl.searchParams.append("form_field_value_obj_list", JSON.stringify(formFieldValueObjList));
        }
        if (typeof xmProcedureName !== 'undefined') {
            myUrl.searchParams.append("xm_procedure_name", xmProcedureName);
        }

        return myUrl.href;
    }

    static setContactEnhancedMessageMaterialized(
        procedureName: string,
        message_id: number,
    ): string {

        const myUrl = new URL(API_URL);

        myUrl.searchParams.append("contact_message_materialized", '1');
        myUrl.searchParams.append("procedure_name", procedureName);
        myUrl.searchParams.append("message_id", message_id.toString());

        return myUrl.href;
    }


    static setContactEnhancedMessageDelete(
        userId: number,
        messageId: number,
    ): string {
        const myUrl = new URL(API_URL);

        myUrl.searchParams.append("contact_message_delete", '1');
        myUrl.searchParams.append("user_id", userId.toString());
        myUrl.searchParams.append("id", messageId.toString());

        return myUrl.href;
    }


}


export default RestApiContactEnhService;
