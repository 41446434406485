import React, {useEffect} from 'react';
import SoftBox from "../components/SoftBox";
import SoftTypography from "../components/SoftTypography";
import {CtxSoftUIGlobalSetupByRdx} from "../context/CtxSoftUIGlobalSetupByRdx";
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {FormikHelpers, useFormik} from "formik";
import RestApiService from "../service/restapi/RestApiService";
import RestApiCommonService from "../service/restapi/RestApiCommonService";
import IContainerQuery from "../type/IContainerQuery";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// react Query
//import { QueryClient, QueryClientProvider } from "react-query";
interface IProp {
    controllerInfoObj: IContainerQuery;
}

function AfdContainerDiag({
                              controllerInfoObj: controllerInfoObj,
                          }: IProp) {
    const rdxGlobalSetupObj: any = React.useContext(CtxSoftUIGlobalSetupByRdx);
    const [containerCard, setContainerCard] = React.useState(null);



    const formikForm = useFormik(
        {
            initialValues: {
                container_card: controllerInfoObj.container_card,
                module_level: controllerInfoObj.module_level,
            },
            children: undefined,
            component: undefined,
            initialErrors: undefined,
            initialTouched: undefined,
            innerRef: undefined,
            isInitialValid: undefined,
            onSubmit<Values>(values: Values, formikHelpers: FormikHelpers<Values>): void | Promise<any> {
                RestApiService.getPromise(RestApiCommonService.setFieldUpdateUrl(
                    'container_card',
                    'xx_club_page_module_2_page_sets',
                    'container_id',
                    controllerInfoObj.container_id.toString(),
                    (formikForm.values.container_card ? formikForm.values.container_card : '')
                )).then(
                    (response) => {
                        if (response.status === 200) {
                            toast.success("Container card updated");
                        } else {
                            toast.error("Container card update failed");
                        }
                    }
                );
            },
        });

    /* Update card */
/*    useEffect(
        () => {
            debugLog("AfdContainerDiag: useEffect: controllerInfoObj.container_card: " + formikForm.values.container_card);


        },[formikForm.values.container_card]
    )*/

    function renderDiagnostics(isUIAdminDiag: boolean)  {
        if (isUIAdminDiag === true) {
            return (
                <SoftBox py={1.5} px={0}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <Grid>
                            <Grid item xs={3} md={3} lg={3}>
                            <InputLabel id="demo-simple-select-label">container_card</InputLabel>
                                <Select
                                    id="container_card"
                                    name={"container_card"}
                                    value={formikForm.values.container_card}
                                    label="container_card"
                                    onChange={formikForm.handleChange}
                                    variant={"filled"}
                                >
                                    <MenuItem value="null">None</MenuItem>
                                    <MenuItem value={"AfdCard"}>AfdCard</MenuItem>
                                    <MenuItem value={"AfdCardDark"}>AfdCardDark</MenuItem>
                                </Select></Grid>
                            <Grid item xs={3} md={3} lg={3}><InputLabel id="demo-simple-select-label">module_level</InputLabel>
                                <Select
                                    id="module_level"
                                    name={"module_level"}
                                    value={formikForm.values.module_level}
                                    label="module_level"
                                    onChange={formikForm.handleChange}
                                    variant={"filled"}
                                >
                                    <MenuItem value="null">None</MenuItem>
                                    <MenuItem value={"AfdCard"}>AfdCard</MenuItem>
                                    <MenuItem value={"AfdCardDark"}>AfdCardDark</MenuItem>
                                </Select></Grid>
                        </Grid>
                    </FormControl>


                    <pre>
                    <SoftTypography>
                Function name: {controllerInfoObj.php_function_code}
                        / {controllerInfoObj.position_code}
                        / {controllerInfoObj.sequence}
                        / {controllerInfoObj.element_module1}
                        /  {controllerInfoObj.page_item_id}
                        {/*<hr></hr>*/}
                    </SoftTypography>
                        </pre>
                    {/*// @ts-ignore*/}
                    <Button onClick={formikForm.handleSubmit}>Update</Button>
                </SoftBox>
            );
        }   else {
            return '';
        }
    }


    return (
        <div>
            {(rdxGlobalSetupObj != undefined) ? renderDiagnostics(rdxGlobalSetupObj[0].isUIAdminDiag) : ''}
        </div>
    );
};

export default AfdContainerDiag;
