import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";
import {Link} from "react-router-dom";
import Icon from "@mui/material/Icon";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import React from "react";
import IUserInfo from "../../../type/IUserInfo";

interface IProp {
    action:
        {
            type: "external" | "internal";
            route: string;
            label:
                string;
            color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark",
        },
    isMenuMobile: boolean,
    userInfoObj: IUserInfo;
};

export function GlobalMenuActionButton({
                                           action: action,
                                           isMenuMobile: isMenuMobile,
                                           userInfoObj: userInfoObj
                                       }: IProp
) {
    if ((isMenuMobile && userInfoObj.user_id > 0) ||
        !isMenuMobile) {
        return <>
            {action &&
                (action.type === "internal" ? (
                        <SoftBox
                        >
                            <SoftButton
                                component={Link}
                                to={action.route}
                                variant="gradient"
                                /*color={action.color ? action.color : "info"}*/
                                color={"primary"}
                                circular
                                size={isMenuMobile ? "small" : "medium"}
                            >

                                {action.label}
                                <SoftBox
                                    px={0.5}
                                    pt={0.5}
                                    color={"white"}
                                ><Icon
                                    fontSize={isMenuMobile ? "medium" : "large"}
                                    sx={{}}
                                >
                                    <CameraAltRoundedIcon/>
                                </Icon></SoftBox>
                            </SoftButton>
                        </SoftBox>
                    ) : (
                        <SoftBox
                        >
                            <SoftButton
                                component="a"
                                href={action.route}
                                target="_self"
                                rel="noreferrer"
                                variant="gradient"
                                color={"primary"}
                                /*size="small"*/
                                size={isMenuMobile ? "small" : "medium"}
                                circular
                            >


                                {action.label}
                                <SoftBox
                                    px={0.5}
                                    pt={0.5}
                                    color={"white"}
                                ><Icon
                                    fontSize={isMenuMobile ? "medium" : "large"}
                                    sx={{}}
                                >
                                    <CameraAltRoundedIcon/>
                                </Icon></SoftBox>
                            </SoftButton>
                        </SoftBox>
                    )
                )}
        </>;
    } else {
        return <></>
    }
}

