import React from 'react';
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";

function AfdCheckboxSelect({
                                    id,
                                    name,
                                   title,
                               checked: checked,
                               handleChecked: handleChecked,
                           }: any) {
    return (
        <><SoftBox mt={3} mb={2} lineHeight={1}>
            <SoftTypography variant="h6">{title}</SoftTypography>
            <Switch
                id={id}
                name={name}
                checked={checked} onChange={handleChecked}/>
        </SoftBox>
            </>
    );
}

export default AfdCheckboxSelect;
