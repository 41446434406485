import ReactTooltip from "react-tooltip";
import React from "react";
import AfdInstructorTooltip  from "./content/AfdInstructorTooltip";
import AfdUserTooltip from "./content/AfdUserTooltip";
import AfdImageTooltip from "./content/AfdImageTooltip";

interface IProp {
    tooltipId: string,
    userId: number;
    imageInfoObj: {
        title: string,
        description?: string,
        image_upload_date?: string,
    }
}


/*https://www.npmjs.com/package/react-tooltip*/
function AfdTooltipImage(
    {
        tooltipId = 'id_none',
        userId,
        imageInfoObj
    }: IProp
) {


    function getElementTooltip() {
            return <AfdImageTooltip
                        authorUserId={userId}
                        imageInfoObj={imageInfoObj}
            />
    }


    return <ReactTooltip
        id={tooltipId}
        textColor={"#000000"}
        /*backgroundColor={"white"}*/
        type={"light"} //dark, success, warning, error, info, light
        delayShow={100}
        delayHide={100}
        getContent={(dataTip: any) =>
            getElementTooltip()
        }
    >
        {/**/}
    </ReactTooltip>;
}

export default AfdTooltipImage;
