import IRdxState from "../type/reducer_state/IRdxState";
import IAppObj from "../type/context/IAppObj";
import IComprofilerIndentifyResponse from "../type/comprofiler/IComprofilerIndentifyResponse";
import {isDev} from "../util/isDev";
import RestApiService from "../service/restapi/RestApiService";
import RestApiAuthorizationService from "../service/restapi/RestApiAuthorizationService";
import {debugLog} from "../util/UtilReact";
import {identifyConsoleStyle} from "../util/identifyConsoleStyle";
import {Dispatch} from "react";
import {RDX_SWITCH_DICTIONARY} from "../redux_action/rdxAction";
import {useMutation, MutationFunction, UseMutationResult, MutateOptions} from 'react-query';


/* Wyślij zapytanie do serwera o identyfikację użytkownika oraz co najmniej SESSION_ID
- session_id - zwracane przez COMPROFILER
- cookie_name - nazwa ciasteczka REACT - dodatkowe
* */

// Define the type for the data you expect to be returned from the API

export default function useCHComprofilerSessionIdentifyRQ(): UseMutationResult<IComprofilerIndentifyResponse> {
    // Define your mutation function
    const postSiteIdentifyMutationFunction = async (data: any) => {
        try {
            // Call the RestApiService method and return the result
            const { identifyCookieName } = data;
            const response  = await RestApiService.getPromise('', 'POST', RestApiAuthorizationService.setPostSiteIdentify(identifyCookieName));
                console.log("Indentification RQ response: ", JSON.stringify(response));
            return response.data; // assuming the API response data is returned
        } catch (error) {
            throw new Error('Failed to post site identify');
        }
    };

    // Use the useMutation hook to create the mutation
    let mutation: UseMutationResult<IComprofilerIndentifyResponse> = useMutation(postSiteIdentifyMutationFunction);
    const {mutate, status, isLoading, isIdle, isSuccess, isError, error, data} = mutation;
    return mutation
};

