import ICtxGlobalConfig from "../../../type/context/ICtxGlobalConfig";

const ElementAfdGlobalFooterLinks = React.lazy(() => import("./ElementAfdGlobalFooterLinks")); // page_item_id: 742

import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";

import React, {useEffect, useState} from "react";
import {lazy, Suspense} from "react";
import RestApiService from "../../../service/restapi/RestApiService";
import RestApiCommonService from "../../../service/restapi/RestApiCommonService";
import {camelize, capitalize} from "../../../util/Util";
import {processContainer, processElement} from "../../../util/UtilReact";
import Emptybox from "../../emptybox/Emptybox";
import AfdLoadingFullpage from "../../AfdLoadingFullpage";
import AfdContainerCard from "../../AfdContainerCard";
import Grid from "@mui/material/Grid";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import useCHGetContextSoftUI from "../../../hook/useCHGetContextSoftUI";
import buildInfoObj from "../../../buildInfoObj";
import useCHComprofilerSessionIdentifyRQ from "../../../hook/useCHComprofilerSessionIdentifyRQ";
import {UseMutationResult} from "react-query";
import IComprofilerIndentifyResponse from "../../../type/comprofiler/IComprofilerIndentifyResponse";
import SoftButton from "../../../components/SoftButton";

/*ng-click="psedudoScrollNextPage()"*/
//{containerRow.post_count}
const GetSiteGlobalFooter = ({
                                 controllerInfoObj: controllerInfoObj,
                                 userInfoObj: userInfoObj,
                                 paramObj: paramObj
                             }: any) => {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const components: any = {
        ElementAfdGlobalFooterLinks,

    }
    var ElementComponent = components[controllerInfoObj.element1CamelName];
    var containerDefaultRow: any;

    let identifyMutationRQ: UseMutationResult<IComprofilerIndentifyResponse> = useCHComprofilerSessionIdentifyRQ();

    /*Pobierz dane element*/
    const [elementObjList, setElementObjList] = useState([]);
    const [containerInfoObj, setContainerInfoObj] = useState(containerDefaultRow);
    useEffect(() => {
        Promise.all(
            [RestApiService.getPromise(RestApiCommonService.getElementQueryUrl(
                controllerInfoObj,
                {
                    type: 0,
                    object2_id: 0
                },
                0,
                0
            )),
                RestApiService.getPromise(RestApiCommonService.getContainerQueryUrl(
                    controllerInfoObj,
                    {
                        object_id: 0,
                        object2_id: 0
                    },
                    0,
                    0
                ))
            ]).then((responseArray) => {
            setElementObjList(processElement(responseArray[0].data, controllerInfoObj));
            setContainerInfoObj(processContainer(responseArray[1].data[0], controllerInfoObj));
        });


    }, []);

    /* Once per footer RENDER
    - musi być w useEffect -  woła w nieskończoność tworzą nowe sesje ???
    *  */
    useEffect(() => {
        if (identifyMutationRQ) {
            identifyMutationRQ.mutate({
                identifyCookieName: ''
            });
        }
    }
        , []);

    <SoftBox mr={3} color="secondary">
        <FacebookIcon fontSize="small"/>
    </SoftBox>

    let specificationObjList = [
        {
            name: "facebook",
            icon: <FacebookIcon fontSize="large"/>,
            url: "https://www.facebook.com/Akademia.Fotografii.Dzieciecej"
        },
        {
            name: "instagram",
            icon: <InstagramIcon fontSize="large"/>,
            url: "https://www.instagram.com/akademia_fotografii_dzieciecej/"
        },
        /*        {
                    name: "twitter",
                    icon: <TwitterIcon fontSize="small" />
                },
                {
                    name: "pinterest",
                    icon: <PinterestIcon fontSize="small" />
                },
                {
                    name: "linkedin",
                    icon: <LinkedInIcon fontSize="small" />
                },*/
    ];


    function getContainerContent() {
        let row = containerInfoObj;

        if (controllerInfoObj.containerName === 'CONTAINER_AFD_GLOBAL_FOOTER') {
            return (
                <>
                    {/*// @ts-ignore*/}
                    <SoftBox mt={{xs: 3, sm: 3, md: 5, lg: 8, xl: 8}}
                    >
                        {/*// @ts-ignore*/}
                        <SoftBox component="footer"
                                 py={{xs: 3, sm: 3, md: 5, lg: 6, xl: 6}}
                        >
                            <Grid container justifyContent="center">
                                <Grid item xs={10} lg={8}>


                                    {/*// @ts-ignore*/}
                                    <SoftBox display="flex" justifyContent="center" flexWrap="wrap" mb={3}>
                                        {
                                            elementObjList.map((element: any) => (
                                                <ElementComponent
                                                    key={element.client_track_id}
                                                    row={element}
                                                    controllerInfoObj={controllerInfoObj}
                                                />
                                            ))
                                        }
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    {/*// @ts-ignore*/}
                                    <SoftBox display="flex" justifyContent="center" mt={1}
                                             mb={{xs: 1, lg: 3}}
                                    >
                                        {
                                            specificationObjList.map((specificationObj: any) => (
                                                    <SoftBox
                                                        component={"a"}
                                                        key={specificationObj.name}
                                                        href={specificationObj.url}
                                                        mr={3} color="secondary"
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        {specificationObj.icon}
                                                    </SoftBox>
                                                )
                                            )
                                        }

                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} lg={8} sx={{
                                    textAlign: "center",
                                    lineHeight: "1.4rem",
                                }}>
                                    {/*// @ts-ignore*/}
                                    <SoftTypography variant={globalConfigParamObj.isMobile ? "caption" : "body2"}
                                                    color="secondary"
                                    >
                                        <span>Akademia Fotografii Dziecięcej prowadzi kursy fotografii dziecięcej dla Mam w całej Polsce przez Internet<br/></span>
                                        <span>Korzystanie z serwisu na podstawie akceptacji <a
                                            href={row.global_term_url}
                                            title="Regulamin serwisu Akademia Fotografii Dziecięcej">Regulaminu</a> oraz <a
                                            href={row.privacy_policy_url}
                                            title="Polityka prywatności serwisu Akademia Fotografii Dziecięcej">Polityki prywatności</a><br/></span>
                                        <span><span>Administratorem danych osobowych jest firma {row.company_name}, <br/>{row.company_address} (Company No. {row.company_nip})</span><br/><span>zarejestrowana w {row.company_country_code}.</span></span>
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                        </SoftBox>

                    </SoftBox>
                </>
            );
        }
    }

    return (
        <>
            <Suspense fallback={<AfdLoadingFullpage
                componentCategory="AfdController_Suspense"
                controllerInfoObj={controllerInfoObj}
            />}>
            </Suspense>
            {
                (elementObjList?.length > 0 && containerInfoObj) ?
                    (
                        <><AfdContainerCard
                            controllerInfoObj={controllerInfoObj}
                            userInfoObj={userInfoObj}
                            paramObj={paramObj}
                            elementObjList={elementObjList}
                        >
                            {getContainerContent()}
                        </AfdContainerCard>

                            <SoftBox
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={"flex-end"}
                            >
                                <SoftBox
                                    onClick={() => {
                                        identifyMutationRQ.mutate(
                                            {
                                            }
                                        );

                                    }
                                    }
                                ><SoftTypography variant={"caption"}
                                                 fontWeight={"light"}
                                                 color={"secondary"}
                                                 sx={{
                                                     textDecoration: "underline",
                                                 }}
                                >
                                    <p>Build info: {buildInfoObj.buildVersion} / {buildInfoObj.buildDate}</p>
                                    <p>{JSON.stringify(identifyMutationRQ.data)}</p>
                                </SoftTypography></SoftBox>
                            </SoftBox>
                        </>

                    ) : (<Emptybox
                        controllerInfoObj={controllerInfoObj}
                        userInfoObj={userInfoObj}
                        paramObj={paramObj}
                        elementObjList={elementObjList}
                    />)
            }
        </>
    );
}

export default GetSiteGlobalFooter;
