import React from 'react';

import IJos4Menu from "../../../type/IJos4Menu";
import IRedirectionInfo from "../../../type/IRedirectionInfo";
import {useCHGetContextSoftUI} from "../../../context/CtxSoftUIGlobalSetupByRdx";
import {FormikHelpers, useFormik} from "formik";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import {InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import parse from "html-react-parser";
import {xx_page_banner} from "../../../util/GetMenuPageBanner";
import RestApiService from "../../../service/restapi/RestApiService";
import RestApiCommonService from "../../../service/restapi/RestApiCommonService";
import {toast} from "react-toastify";
import ICtxGlobalConfig from "../../../type/context/ICtxGlobalConfig";

interface IProp {
    menuItemObj: IJos4Menu;
    redirectionObj?: IRedirectionInfo;
    routeCategory: string;
}

function AfdConfiguratorMenu({
                                 menuItemObj,
                                 redirectionObj,
                                 routeCategory
                             }: IProp) {
    // @ts-ignore
    const [controlerParamObj, rdxConfigDispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();



    const menuFormikForm = useFormik(
        {
            initialValues: {
                xx_page_banner: menuItemObj.xx_page_banner
            },
            children: undefined,
            component: undefined,
            initialErrors: undefined,
            initialTouched: undefined,
            innerRef: undefined,
            isInitialValid: undefined,
            onSubmit<Values>(values: Values, formikHelpers: FormikHelpers<Values>): void | Promise<any> {
                RestApiService.getPromise(RestApiCommonService.setFieldUpdateUrl(
                    'xx_page_banner',
                    'jos4_menu',
                    'id',
                    menuItemObj.id.toString(),
                    (menuFormikForm.values.xx_page_banner ? menuFormikForm.values.xx_page_banner : 'null')
                )).then(
                    (response) => {
                        if (response.status === 200) {
                            toast.success("Container card updated");
                        } else {
                            toast.error("Container card update failed");
                        }
                    }
                );
            },
        });

    return (
        <>

            <SoftBox pt={1} pb={2}>
                <SoftTypography variant="h5">MenuItemObj:</SoftTypography>

                <pre>
                  <SoftTypography variant={"body2"}>route_category: <strong>{routeCategory}</strong></SoftTypography>
                  <SoftTypography variant={"body2"}>item_id: <strong>{menuItemObj.id}</strong></SoftTypography>
                  <SoftTypography
                      variant={"body2"}>xx_react_gantry: <strong>{menuItemObj.xx_react_gantry}</strong></SoftTypography>
                  <SoftTypography
                      variant={"body2"}>xx_page_banner: <strong>{menuItemObj.xx_page_banner}</strong></SoftTypography>

              </pre>

            </SoftBox>


            <InputLabel id="demo-simple-select-label">xx_page_banner</InputLabel>
            <Select
                id="xx_page_banner"
                name={"xx_page_banner"}
                value={menuFormikForm.values.xx_page_banner}
                label="xx_page_banner"
                onChange={menuFormikForm.handleChange}
                variant={"filled"}
            >
                <MenuItem value="null">None</MenuItem>
                {
                    Object.keys(xx_page_banner).map((key) => {
                        return (
                            <MenuItem key={key} value={key}>{key}</MenuItem>
                        )
                    })
                }
            </Select>
            {/*// @ts-ignore*/}
            <Button onClick={menuFormikForm.handleSubmit}>Update</Button>
        </>
    );
}

export default AfdConfiguratorMenu;
