


function is_numeric(str) {
    return !isNaN(str) && parseInt(str) == str;
}

function getExtractColumn(arrayOfObject, columnName) {
    return arrayOfObject.map(x => x[columnName])
}


function camelize(text) {
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
    return text.substr(0, 1).toLowerCase() + text.substr(1);
}

function capitalize(phrase)   {
    const capitalStr = phrase.charAt(0).toUpperCase() + phrase.slice(1);
    return capitalStr;
}

function xx_addurl(urlpath)
{
    let newtext = "\r\n[ZDJECIE]"+urlpath+"[/ZDJECIE]";
    document.getElementById('message').value += newtext;
}



function xx_replace_body()
{
    let newtext = document.getElementById('message').value;
    newtext = newtext.replace(/<strong>/g, "[b]");
    newtext = newtext.replace(/<\/strong>/g, "[/b]");
    newtext = newtext.replace(/<em>/g, "[i]");
    newtext = newtext.replace(/<\/em>/g, "[/i]");
    document.getElementById('message').value = newtext;
}


/* BUG JS: Mnożenie floating point  daje nieprecyzyjny wynik:
https://stackoverflow.com/questions/1458633/how-to-deal-with-floating-point-number-precision-in-javascript
*/
function multiple100(uploadForm) {

    /* BUGFIX:
                var crc_string = uploadForm.p24_session_id.value
            + '|' + uploadForm.p24_id_sprzedawcy.value
            + '|' + uploadForm.p24_kwota.value
            + '|' + 'f2e8ddce8bd8fa50';
    */

    /* 	var crc_string = uploadForm.p24_session_id.value
            + '|' + uploadForm.p24_id_sprzedawcy.value
            + '|' + uploadForm.p24_kwota.value
            + '|' + uploadForm.p24_account_crc.value;
            console.log(crc_string);
        */

    //var current_value =  parseFloat(uploadForm.p24_kwota.value);

    // uploadForm.p24_crc = CryptoJS.MD5(crc_string);
    //console.log(uploadForm.p24_crc);
    //uploadForm.p24_kwota.value = parseFloat(current_value * 100).toFixed(2);
    //console.log(current_value);
    //console.log(uploadForm.p24_kwota.value);
    uploadForm.submit();

}


/* Działająca funkcja:
var param_user_id = getURLParameter("user_id");
if (param_user_id !== 'null') {
	var elem_m4j_147 = document.getElementById('m4j-147');
	elem_m4j_146.value = getURLParameter("bl_date");

*/


function getURLParameter(name, url = null) {
    console.log(location.search);
    let locationUrl;
    if (url === null) {
         locationUrl = location.search;
    } else {
         locationUrl = url;
    }
    return decodeURI(
        (RegExp(name + '=' + '(.+?)(&|$)').exec(location.search)||[,null])[1]
    );
}
                function getURLParameter2(sUrl, sParam) {
                    let sPageURL = sUrl.substring(sUrl.indexOf('?') + 1);
                    let sURLVariables = sPageURL.split('&');
                    for (let i = 0; i < sURLVariables.length; i++) {
                        let sParameterName = sURLVariables[i].split('=');
                        if (sParameterName[0] == sParam) {
                            return sParameterName[1];
                        }
                    }
                }


/*function JSONConvertToNumber(JSONArray)	{
	[].forEach.call(JSONArray, function(inst, i){
		// Iterate through all the keys
		[].forEach.call(Object.keys(inst), function(y){
			// Check if string is Numerical string
			if(!isNaN(JSONArray[i][y]))
			//Convert to numerical value
				JSONArray[i][y] = +JSONArray[i][y];
		});

	});
}*/

/* Sprawdzenie JSON list REPEAT DUPES
* OLD: /* To jest obiekt z atrubutami
 && typeof newItems[i] === 'object' && newItems[i] !== null
if(!isNaN(parseInt(newItems[i].client_track_id)) && newItems[i].client_track_id > 0)	{
	newItems[i].client_track_id = parseInt(newItems[i].client_track_id);
	debugDir(newItems[i]);
}
* */
function clientTrackIdConvert(objectList, searchField)	{

    for (var i=0 ; i < objectList.length ; i++)
    {
//		console.log(i);

        if(!isNaN(parseInt(objectList[i][searchField])))	{
            objectList[i][searchField] = parseInt(objectList[i][searchField]);
        }
    }
    return objectList;
}

/* Zamień na numer */
function setConvertString2Num(objRow, searchField)	{
    if(!isNaN(parseInt(objRow[searchField])))	{
        objRow[searchField] = parseInt(objRow[searchField]);
    }
    return objRow;
}

/* Sprawdzenie JSON list REPEAT DUPES */
function isInJSONRowList(objectList, searchField, searchVal)	{
    var results = [];
    for (var i=0 ; i < objectList.length ; i++)
    {
        if (objectList[i][searchField] == searchVal) {
            results.push(objectList[i]);
            return true;
        }
    }
    return false;
}

/* Policz ile razy wartość dana w set */
function getSearchFieldValueCount(objectList, searchField, searchVal)	{
    var counter = 0;
    for (var i=0 ; i < objectList.length ; i++)
    {
        if (objectList[i][searchField] == searchVal) {
            counter ++;
        }
    }
    return counter;
}

/* Przeszukaj tablicę i jeżeli zmieniony TIMESTAMP  - dołącz informacje BOOL
TODO: jest problem z tą funkcją ponieważ przy sortowaniu DESC
jeżeli zmienia się pole, to nad nim powinna się wyświetlać data DESC wiersza (i - 1)
ale tej nie mamy bo operujemy tylko na bieżącym objectList nowo dodanym a nie wszystkich ctrl1.rows
- obejście: w SQL kolumna DESC zawiera okres kolejny
 */
function setTimestampNewMark(objectList, timestampColumnName, timeDescColumnName, previousTimestampValue)	{

    var sortMode = 'DESC';
    var results = [];
    if (!(typeof objectList[0][timestampColumnName] === 'undefined')) {
        for (var i=0 ; i < objectList.length ; i++)
        {
            /* Jeżeli za pierwszym razem to nie cchem od razu daty wyświetlać na liście */
            if (previousTimestampValue === null) previousTimestampValue = objectList[i][timestampColumnName];
            if (
                objectList[i][timestampColumnName] !== previousTimestampValue) {
                if (sortMode == 'DESC')	{
                    objectList[i]['isTimestampNew'] = true;
                    objectList[i]['isTimestampCssClass'] = 'content_new_timestamp';
                    /*objectList[i][timeDescColumnName] = objectList[i-1][timeDescColumnName];
                    debugDir(objectList[i]);
                    debugDir(objectList[i-1]);*/
                }

                /* Przypisz nową wartość do obiektu*/
                previousTimestampValue = objectList[i][timestampColumnName];
            } else {
                objectList[i]['isTimestampNew'] = false;
                objectList[i]['isTimestampCssClass'] = '';
            }
        }

    } else {

        console.log("ERROR: No TIMESTAMP column FOUND" + timestampColumnName);
    }
    return objectList;

}

function getUndefinedToNoString(paramValue) {
    if (typeof paramValue == 'undefined') {
        paramValue = '';
    }
    return paramValue;
}

function afdObjectToUrlString(paramObject)	{
    var qs = Object.keys(paramObject)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(getUndefinedToNoString(paramObject[key]))}`)
        .join('&');
    return qs;
}

/* łączy dwie tablice OBJECT w jedną
- SERWICE zwraca de facto w reponse JSON tablicę obiektów
 */
function afdObjectArrayMerge (sourceObjectArray, destinationObjectArray, column_name)   {
    if (!Array.isArray(sourceObjectArray) || !sourceObjectArray.length) {
        /* Pierwsza tablica */
        sourceObjectArray = destinationObjectArray;
    } else {

        var counter = 0;
        for (var destinationObjectArrayIndex in destinationObjectArray) {
            counter = 0;
            for (var sourceObjectArrayIndex in sourceObjectArray)
                if (sourceObjectArray[sourceObjectArrayIndex][column_name] == destinationObjectArray[destinationObjectArrayIndex][column_name]) {
                    /* Znaleziono rekord z tabeli SOURCE */
                    counter++;
                }
            /* Jeżeli nie znaleziono */
            if (counter == 0) {
                sourceObjectArray.push(destinationObjectArray[destinationObjectArrayIndex]);
            }
        }
    }
    return sourceObjectArray;
    /*console.log(sourceObjectArray);*/
}


/* Przepisuje wiersz = obiekt pobrany z JSON do tablicy MODEL */
function afdObjectArrayToModel (sourceObjectArray, destinationModelArray, column_name)   {


    var sourceObjectArrayIndex = 0;
    for (var destinationModelFieldName in destinationModelArray) {
        sourceObjectArrayIndex = 0;/* Zawsze 0 ponieważ pierwszy wiersz */
        console.log(destinationModelArray[destinationModelFieldName]);
        //destinationModelArray[destinationModelArrayIndex][column_name] = sourceObjectArray[sourceObjectArrayIndex][column_name];

    }
    //return destinationModelArray;
}


function afdObjectArraySort(sourceObjectArray, isReverse = false)	{
    function afdCompare(a,b) {
        if (a.client_track_id < b.client_track_id)
            return -1;
        if (a.client_track_id > b.client_track_id)
            return 1;
        return 0;
    }

    /* Odwrotnie parametry */
    function afdCompareReverse(b,a) {
        return afdCompare(a,b);
    }

    if (!isReverse) {
        sourceObjectArray.sort(afdCompare);
        console.log('PUSHER: SORT: Ascending');
    } else {
        sourceObjectArray.sort(afdCompareReverse);
        console.log('PUSHER: SORT: Descending');
    }

    return sourceObjectArray;
}

function hex2bin (s) {
    //  discuss at: https://locutus.io/php/hex2bin/
    // original by: Dumitru Uzun (https://duzun.me)
    //   example 1: hex2bin('44696d61')
    //   returns 1: 'Dima'
    //   example 2: hex2bin('00')
    //   returns 2: '\x00'
    //   example 3: hex2bin('2f1q')
    //   returns 3: false

    var ret = [];
    var i = 0;
    var l;

    s += '';

    for (l = s.length; i < l; i += 2) {
        var c = parseInt(s.substr(i, 1), 16);
        var k = parseInt(s.substr(i + 1, 1), 16);
        if (isNaN(c) || isNaN(k)) return false;
        ret.push((c << 4) | k);
    }

    return String.fromCharCode.apply(String, ret);
}

function bin2hex(s){
    // Converts the binary representation of data to hex
    //
    // version: 812.316
    // discuss at: http://phpjs.org/functions/bin2hex
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   bugfixed by: Onno Marsman
    // +   bugfixed by: Linuxworld
    // *     example 1: bin2hex('Kev');
    // *     returns 1: '4b6576'
    // *     example 2: bin2hex(String.fromCharCode(0x00));
    // *     returns 2: '00'
    var v,i, f = 0, a = [];
    s += '';
    f = s.length;

    for (i = 0; i<f; i++) {
        a[i] = s.charCodeAt(i).toString(16).replace(/^([\da-f])$/,"0$1");
    }

    return a.join('');
}

function decodeHTMLEntities(xxxtext) {
    var textAreaxxx = document.createElement('textareaxxx');
    textAreaxxx.innerHTML = xxxtext;
    return textAreaxxx.value;
}

/* Inne podejście
 - funkcja zwracająca funkcję:
 decodeEntities2()($scope.row.button_title)
* */
function decodeEntities2() {
    // this prevents any overhead from creating the object each time
    var element = document.createElement('div');

    function decodeHTMLEntities (str) {
        if(str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }

        return str;
    }

    return decodeHTMLEntities;
}


function getDiffMinute(dt2, dt1)
{

    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
}

function getNowDateFormatMySQL()   {
    var date;
    date = new Date();
    date = date.getUTCFullYear() + '-' +
        ('00' + (date.getUTCMonth()+1)).slice(-2) + '-' +
        ('00' + date.getUTCDate()).slice(-2) + ' ' +
        ('00' + date.getUTCHours()).slice(-2) + ':' +
        ('00' + date.getUTCMinutes()).slice(-2) + ':' +
        ('00' + date.getUTCSeconds()).slice(-2);
    return date;
}

function getConvertMySQLDatetimeToDate(datetime: string): string {
    const date = new Date(datetime);
    const days = ["Nie", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"];
    const months = ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"];
    return `${days[date.getUTCDay()]}, ${date.getUTCDate()}-${months[date.getUTCMonth()]}-${date.getUTCFullYear()}`;
}

function getConvertMySQLDatetimeToDatetime(datetime: string): string {
    const date = new Date(datetime);
    const days = ["Nie", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"];
    const months = ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"];
    return `${days[date.getUTCDay()]}, ${date.getUTCDate()}-${months[date.getUTCMonth()]}-${date.getUTCFullYear()} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`;
}


function getPhpbbTextBetweenTags(text, startTag, endTag) {
    const startIndex = text.indexOf(startTag) + startTag.length;
    const endIndex = text.indexOf(endTag);
    const startTagEndIndex = text.indexOf("]", startIndex) + 1;
    const endTagStartIndex = text.indexOf("]", endIndex) + 1;
    return text.slice(startTagEndIndex, endIndex);
}

function getPhpbbReplaceMultipleTagsSingleClass(text, tag, className) {
    text = text.replace(new RegExp(`\\[${tag}(.*?)\\]`, "g"), `<div class="${className}">`);
    text = text.replace(new RegExp(`\\[/${tag}(.*?)\\]`, "g"), "</div>");
    return text;
}

function getPhpbbReplaceMultipleTagsEachClass(text, tag) {
    text = text.replace(new RegExp(`\\[${tag}:(.*?)\\]`, "g"), (match, p1) => `<div class="${p1}">`);
    text = text.replace(new RegExp(`\\[/${tag}\\]`, "g"), "</div>");
    return text;
}





export {
    is_numeric,
    camelize, capitalize, bin2hex, decodeEntities2, decodeHTMLEntities,
    afdObjectToUrlString,
    getURLParameter,
    getURLParameter2,
        getDiffMinute, hex2bin, afdObjectArrayMerge, afdObjectArraySort, afdObjectArrayToModel,
    getExtractColumn,
    getNowDateFormatMySQL,
    getPhpbbTextBetweenTags, getPhpbbReplaceMultipleTagsSingleClass, getPhpbbReplaceMultipleTagsEachClass,
    getConvertMySQLDatetimeToDate,
    getConvertMySQLDatetimeToDatetime
};
