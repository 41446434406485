import {camelize, capitalize} from "../util/Util";
import React, {
    Component,
    Dispatch,
    JSXElementConstructor,
    lazy,
    SetStateAction,
    Suspense, useContext,
    useEffect,
    useState
} from "react";
import {debugLog, debugDir} from "../util/UtilReact";
import AfdExceptionComponent from "../AfdExceptionComponent";
import RestApiService from "../service/restapi/RestApiService";
import RestApiCommonService from "../service/restapi/RestApiCommonService";

import CtxAppObj from "../context/CtxApp";
//import ContainerSiteOpinionTable from "./container/ContainerSiteOpinionTable";

import SoftBox from "../components/SoftBox";
import SoftTypography from "../components/SoftTypography";

import IJos4Menu from "../type/IJos4Menu";
import IContainerQuery from "../type/IContainerQuery";
import IAppObj from "../type/context/IAppObj";
import IRedirectionInfo from "../type/IRedirectionInfo";
import IParamObj from "../type/IParamObj";
import {CtxSoftUIGlobalSetupByRdx} from "../context/CtxSoftUIGlobalSetupByRdx";
import AfdContainerDiag from "./AfdContainerDiag";

/*const GetSiteGlobalMenu = React.lazy(() => import('./controller/get_site_global_menu/GetSiteGlobalMenu'));
const GetSiteGlobalFooter = React.lazy(() => import('./controller/get_site_global_footer/GetSiteGlobalFooter'));*/
import GetSiteGlobalMenu from "./controller/get_site_global_menu/GetSiteGlobalMenu";
import GetSiteGlobalFooter from "./controller/get_site_global_footer/GetSiteGlobalFooter";
import GetSitePartnerList from "./controller/get_site_partner_list/GetSitePartnerList";
import GetSiteContainerTutorialBody
    from "./controller/_immediate/get_site_container_tutorial_body/GetSiteContainerTutorialBody";
import GetSiteWelcomeVideoGlobal from "./controller/_immediate/get_site_welcome_video_global/GetSiteWelcomeVideoGlobal";
import GetSiteRegintroCourseEventNext
    from "./controller/_immediate/get_site_regintro_course_event_next/GetSiteRegintroCourseEventNext";
import GetSiteNewsletterButtonForm
    from "./controller/_immediate/get_site_newsletter_button_form/GetSiteNewsletterButtonForm";
import GetSiteMainpageMainFacebook
    from "./controller/_immediate/get_site_mainpage_main_facebook/GetSiteMainpageMainFacebook";
import GetClubPageItemSummary
    from "./controller/_immediate/get_club_page_item_summary/GetClubPageItemSummary";
import IFormFormikParamObj from "../type/form/IFormFormikParamObj";


import GetSiteMainpageMessage from "./controller/_immediate/get_site_mainpage_message/GetSiteMainpageMessage";
import GetSiteMainpageImageToolbar
    from "./controller/_immediate/get_site_mainpage_image_toolbar/GetSiteMainpageImageToolbar";
import GetSiteWhoIsOnline from "./controller/_immediate/get_site_who_is_online/GetSiteWhoIsOnline";
import AfdLoadingFullpage from "./AfdLoadingFullpage";
import IUserInfo from "../type/IUserInfo";
import ISelectorPosition from "../type/ISelectorPosition";
import IFormFormikGantry from "../type/form/IFormFormikGantry";
import AfdAlertGoAndLogin from "./error_boundary/AfdAlertGoAndLogin";
import parse from "html-react-parser";
import ICtxGlobalConfig from "../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../hook/useCHGetContextSoftUI";
import GetClubForumChapterItemButton
    from "./controller/get_club_forum_chapter_item_button/GetClubForumChapterItemButton";
import GetClubLearnHomeworkCurrent from "./controller/get_club_learn_homework_current/GetClubLearnHomeworkCurrent";
import {isDev} from "../util/isDev";


/*Each dynamic import statement will result in a different bundle.
You can make sure that two (2) or more dynamic imports get included in the same bundle by adding a nickname
to a bundle and using the same nickname on the contents that you want to be grouped together.*/
const GetSitePageGrid = React.lazy(() => import('./controller/get_site_page_grid/GetSitePageGrid'));
const GetSiteAmocourseRegFormFieldList = React.lazy(() => import('./controller/get_site_amocourse_reg_form_field_list/GetSiteAmocourseRegFormFieldList'));
const GetSiteAmocourseRegFormLegal = React.lazy(() => import('./controller/get_site_amocourse_reg_form_legal/GetSiteAmocourseRegFormLegal'));
const GetSiteContactUs = React.lazy(() => import('./controller/get_site_contact_us/GetSiteContactUs'));
const GetSiteContainerTutorialList = React.lazy(() => import('./controller/get_site_container_tutorial_list/GetSiteContainerTutorialList'));
const GetSiteCourseDescRule = React.lazy(() => import('./controller/get_site_course_desc_rule/GetSiteCourseDescRule'));
const GetSiteCourseDescSyllabusText = React.lazy(() => import('./controller/get_site_course_desc_syllabus_text/GetSiteCourseDescSyllabusText'));
const GetSiteCourseDescImageGrid = React.lazy(() => import('./controller/get_site_course_desc_image_grid/GetSiteCourseDescImageGrid'));
const GetSiteEvolutionList = React.lazy(() => import('./controller/get_site_evolution_list/GetSiteEvolutionList'));
const GetSiteOpinionTable = React.lazy(() => import('./controller/get_site_opinion_table/GetSiteOpinionTable'));
const GetSiteCourseCatalogSelectColumn = React.lazy(() => import('./controller/get_site_course_catalog_select_column/GetSiteCourseCatalogSelectColumn'));
const GetSiteTutorialHelpTopList = React.lazy(() => import('./controller/get_site_tutorial_help_top_list/GetSiteTutorialHelpTopList'));
const GetSitePresentationGlobalAll = React.lazy(() => import('./controller/get_site_presentation_global_all/GetSitePresentationGlobalAll'));
const GetSiteCourseCatalogMain = React.lazy(() => import('./controller/get_site_course_catalog_main/GetSiteCourseCatalogMain'));
const GetSiteAmocourseRegList = React.lazy(() => import('./controller/get_site_amocourse_reg_list/GetSiteAmocourseRegList'));
const GetClubPaymentCourseList = React.lazy(() => import('./controller/get_club_payment_course_list/GetClubPaymentCourseList'));
const GetSiteChatbot = React.lazy(() => import('./controller/get_site_chatbot_form/GetSiteChatbot'));
const GetClubGalleryImageBigThumb = React.lazy(() => import('./controller/get_club_gallery_image_big_thumb/GetClubGalleryImageBigThumb'));
const GetClubGalleryPictureUserInfo = React.lazy(() => import('./controller/get_club_gallery_picture_user_info/GetClubGalleryPictureUserInfo'));
const GetClubDashboardNavbar = React.lazy(() => import('./controller/get_club_dashboard_navbar/GetClubDashboardNavbar'));
const GetClubGalleryImageTagList = React.lazy(() => import('./controller/get_club_gallery_image_tag_list/GetClubGalleryImageTagList'));
const GetSiteGlobalNewsletterMessage = React.lazy(() => import('./controller/get_site_global_newsletter_message/GetSiteGlobalNewsletterMessage'));
const GetClubGalleryUserRank = React.lazy(() => import('./controller/get_club_gallery_user_rank/GetClubGalleryUserRank'));
const GetClubUserProfileAvatarSelect = React.lazy(() => import('./controller/get_club_user_profile_avatar_select/GetClubUserProfileAvatarSelect'));

const GetClubNewbbUniFinalPreview = React.lazy(() => import('./controller/get_club_newbb_uni_final_preview/GetClubNewbbUniFinalPreview'));


const GetClubForumChapterAssessSurvey = React.lazy(() => import('./controller/get_club_forum_chapter_assess_survey/GetClubForumChapterAssessSurvey'));
const GetClubForumChapterAssessDiploma = React.lazy(() => import('./controller/get_club_forum_chapter_assess_diploma/GetClubForumChapterAssessDiploma'));

const GetBackendLexIntentUpload = React.lazy(() => import('./controller/get_backend_lex_intent_upload/GetBackendLexIntentUpload'));
const GetClubProfileFormFieldListNg = React.lazy(() => import('./controller/get_club_profile_form_field_list_ng/GetClubProfileFormFieldListNg'));
const GetClubUserProfileMailingExcl = React.lazy(() => import('./controller/get_club_user_profile_mailing_excl/GetClubUserProfileMailingExcl'));
const GetClubMedalDownload = React.lazy(() => import('./controller/get_club_medal_download/GetClubMedalDownload'));
const GetClubUserProfileRodoDataTable = React.lazy(() => import('./controller/get_club_user_profile_rodo_data_table/GetClubUserProfileRodoDataTable'));
const GetClubUserProfileFieldDelim = React.lazy(() => import('./controller/get_club_user_profile_field_delim/GetClubUserProfileFieldDelim'));
const GetSiteUserProfileImageRestore = React.lazy(() => import('./controller/get_site_user_profile_image_restore/GetSiteUserProfileImageRestore'));
const GetClubContactFriendListType = React.lazy(() => import('./controller/get_club_contact_friend_list_type/GetClubContactFriendListType'));
const GetClubPretendEpReviewAll = React.lazy(() => import('./controller/get_club_pretend_ep_review_all/GetClubPretendEpReviewAll'));
const GetClubGlobalWorksheetTable = React.lazy(() => import('./controller/get_club_global_worksheet_table/GetClubGlobalWorksheetTable'));

const GetClubGalleryRankList = React.lazy(() => import('./controller/get_club_gallery_rank_list/GetClubGalleryRankList'));
const GetClubGalleryNewList = React.lazy(() => import('./controller/get_club_gallery_new_list/OLD_GetClubGalleryNewList'));
const GetClubGalleryAwardList = React.lazy(() => import('./controller/get_club_gallery_award_list/OLD_GetClubGalleryAwardList'));

const GetClubImageUserVisitNot = React.lazy(() => import('./controller/get_club_image_user_visit_not/GetClubImageUserVisitNot'));
const GetClubGalleryImageRodoLog = React.lazy(() => import('./controller/get_club_gallery_image_rodo_log/GetClubGalleryImageRodoLog'));
const GetClubImageUserStream = React.lazy(() => import('./controller/get_club_image_user_stream/GetClubImageUserStream'));
const GetClubUserDictList = React.lazy(() => import('./controller/get_club_user_dict_list/GetClubUserDictList'));

const GetSiteMainpageMainSlideshow = React.lazy(() => import('./controller/_immediate/get_site_mainpage_main_slideshow/GetSiteMainpageMainSlideshow'));
const GetClubFileUpload = React.lazy(() => import('./controller/get_club_file_upload/GetClubFileUpload'));
const GetClubGalleryPosterDownload = React.lazy(() => import('./controller/get_club_gallery_poster_download/GetClubGalleryPosterDownload'));
const GetClubNewbbDrawerCourseAll = React.lazy(() => import('./controller/get_club_newbb_drawer_course_all/GetClubNewbbDrawerCourseAll'));

const GetClubBgMyMain = React.lazy(() => import('./controller/get_club_bg_my_main/GetClubBgMyMain'));
const GetClubBgUserRank = React.lazy(() => import('./controller/get_club_bg_user_rank/GetClubBgUserRank'));

const GetClubProfileAvatarUpload = React.lazy(() => import('./controller/get_club_profile_avatar_upload/GetClubProfileAvatarUpload'));
const GetClubSupermumPretendUserList = React.lazy(() => import('./controller/get_club_supermum_pretend_user_list/GetClubSupermumPretendUserList'));

const GetClubMyAcademyCourseList = React.lazy(() => import('./controller/get_club_my_academy_course_list/GetClubMyAcademyCourseList'));


const GetClubUserProfileComprofilerView = React.lazy(() => import('./controller/get_club_user_profile_comprofiler_view/GetClubUserProfileComprofilerView'));
const GetClubProfileHistory = React.lazy(() => import('./controller/get_club_profile_history/GetClubProfileHistory'));
const GetSiteUserProfileInfo = React.lazy(() => import('./controller/get_site_user_profile_info/GetSiteUserProfileInfo'));
const GetClubProfileEpImageList = React.lazy(() => import('./controller/get_club_profile_ep_image_list/GetClubProfileEpImageList'));
const GetClubUserProfileLinkList = React.lazy(() => import('./controller/get_club_user_profile_link_list/GetClubUserProfileLinkList'));
const GetClubMyPostsSent = React.lazy(() => import('./controller/get_club_my_posts_sent/GetClubMyPostsSent'));
/*const GetSiteUserProfileSpec = React.lazy(() => import('./controller/get_site_user_profile_spec/GetSiteUserProfileSpec'));
const GetSiteUserProfileCourse = React.lazy(() => import('./controller/get_site_user_profile_course/GetSiteUserProfileCourse'));*/
const GetSiteSetNewsletterRating = React.lazy(() => import('./controller/get_site_set_newsletter_rating/GetSiteSetNewsletterRating'));
const GetSiteNewsletterRatingWelcome = React.lazy(() => import('./controller/get_site_newsletter_rating_welcome/GetSiteNewsletterRatingWelcome'));

const GetSiteUserProfileSpec = React.lazy(() => import('./controller/get_site_user_profile_spec/GetSiteUserProfileSpec'));
const GetSiteUserProfileInfoAward = React.lazy(() => import('./controller/get_site_user_profile_info_award/GetSiteUserProfileInfoAward'));
const GetClubUserProfileSlideshow = React.lazy(() => import('./controller/get_club_user_profile_slideshow/GetClubUserProfileSlideshow'));
const GetClubUserProfileSideMenu = React.lazy(() => import('./controller/get_club_user_profile_side_menu/GetClubUserProfileSideMenu'));
const GetSiteUserProfileStrip = React.lazy(() => import('./controller/get_site_user_profile_strip/GetSiteUserProfileStrip'));
const GetSiteUserProfileCourse = React.lazy(() => import('./controller/get_site_user_profile_course/GetSiteUserProfileCourse'));
const GetSitePasswordReset = React.lazy(() => import('./controller/get_site_password_reset/GetSitePasswordReset'));

const GetClubNewbbInstructorAbsence = React.lazy(() => import('./controller/get_club_newbb_instructor_absence/GetClubNewbbInstructorAbsence'));
const GetClubNewbbPaginationToast = React.lazy(() => import('./controller/get_club_newbb_pagination_toast/GetClubNewbbPaginationToast'));
const GetClubNewbbViewTopic = React.lazy(() => import('./controller/get_club_newbb_view_topic/GetClubNewbbViewTopic'));
const GetClubNewbbDrawerList = React.lazy(() => import('./controller/get_club_newbb_drawer_list/GetClubNewbbDrawerList'));
const GetClubP52YourThisYear = React.lazy(() => import('./controller/get_club_p52_your_this_year/GetClubP52YourThisYear'));

const GetBackendInstructorEmptyreg = React.lazy(() => import('./controller/get_backend_instructor_emptyreg/GetBackendInstructorEmptyreg'));
const GetBackendInstructorNewEntry = React.lazy(() => import('./controller/get_backend_instructor_new_entry/GetBackendInstructorNewEntry'));
const GetBackendInstructorNewEntryNoNomin = React.lazy(() => import('./controller/get_backend_instructor_new_entry_no_nomin/GetBackendInstructorNewEntryNoNomin'));
const GetBackendInstructorPanelGrid = React.lazy(() => import('./controller/get_backend_instructor_panel_grid/GetBackendInstructorPanelGrid'));
const GetBackendInstructorSummary = React.lazy(() => import('./controller/get_backend_instructor_summary/GetBackendInstructorSummary'));
const GetBackendInstructorSummaryUser = React.lazy(() => import('./controller/get_backend_instructor_summary_user/GetBackendInstructorSummaryUser'));
const GetBackendMarketingBannerIexp = React.lazy(() => import('./controller/get_backend_marketing_banner_iexp/GetBackendMarketingBannerIexp'));
const GetBackendPretendReviewList = React.lazy(() => import('./controller/get_backend_pretend_review_list/GetBackendPretendReviewList'));

const GetSiteDictGridDictDesc = React.lazy(() => import('./controller/get_site_dict_grid_dict_desc/GetSiteDictGridDictDesc'));
const GetSiteDictGridCourseList = React.lazy(() => import('./controller/get_site_dict_grid_course_list/GetSiteDictGridCourseList'));
const GetSiteDictImageGrid = React.lazy(() => import('./controller/get_site_dict_image_grid/GetSiteDictImageGrid'));
const GetSiteDictGrid = React.lazy(() => import('./controller/get_site_dict_grid/GetSiteDictGrid'));

const GetClubPackageUserList = React.lazy(() => import('./controller/get_club_package_user_list/GetClubPackageUserList'));
const GetClubPackageCurrent = React.lazy(() => import('./controller/get_club_package_current/GetClubPackageCurrent'));
const GetClubPackagePromotionBox = React.lazy(() => import('./controller/get_club_package_promotion_box/GetClubPackagePromotionBox'));

const GetClubGlobalPageTitle = React.lazy(() => import('./controller/get_club_global_page_title/GetClubGlobalPageTitle'));
const GetClubUserProfileUserDelete = React.lazy(() => import('./controller/get_club_user_profile_user_delete/GetClubUserProfileUserDelete'));

const GetClubForumCourseSideMenu = React.lazy(() => import('./controller/get_club_forum_course_side_menu/GetClubForumCourseSideMenu'));
const GetClubForumChapterGridHeader = React.lazy(() => import('./controller/get_club_forum_chapter_list_grid_header/GetClubForumChapterListGridHeader'));
const GetClubGroupSlideshow = React.lazy(() => import('./controller/get_club_group_slideshow/GetClubGroupSlideshow'));

const GetSiteBlogImageList = React.lazy(() => import('./controller/get_site_blog_image_list/GetSiteBlogImageList'));
const GetSiteBlogLogo = React.lazy(() => import('./controller/get_site_blog_logo/GetSiteBlogLogo'));
const GetSiteBlogMenu = React.lazy(() => import('./controller/get_site_blog_menu/GetSiteBlogMenu'));
const GetSiteBlogPostFooter = React.lazy(() => import('./controller/get_site_blog_post_footer/GetSiteBlogPostFooter'));
const GetSiteBlogPostList = React.lazy(() => import('./controller/get_site_blog_post_list/GetSiteBlogPostList'));
const GetSiteBlogPostView = React.lazy(() => import('./controller/get_site_blog_post_view/GetSiteBlogPostView'));

/* NEWBB Chapter */
const GetClubForumChapterRecomList = React.lazy(() => import('./controller/get_club_forum_chapter_recom_list/GetClubForumChapterRecomList'));
const GetClubForumChapterItemList = React.lazy(() => import('./controller/get_club_forum_chapter_item_list/GetClubForumChapterItemList'));
const GetClubForumChapterRecommend = React.lazy(() => import('./controller/get_club_forum_chapter_recommend/GetClubForumChapterRecommend'));
const GetClubForumChapterContent = React.lazy(() => import('./controller/get_club_forum_chapter_content/GetClubForumChapterContent'));
const GetClubForumChapterContentArrow = React.lazy(() => import('./controller/get_club_forum_chapter_content_arrow/GetClubForumChapterContentArrow'));
const GetClubForumChapterFileList = React.lazy(() => import('./controller/get_club_forum_chapter_file_list/GetClubForumChapterFileList'));
const GetClubForumChapterAssetListAll = React.lazy(() => import('./controller/get_club_forum_chapter_asset_list_all/GetClubForumChapterAssetListAll'));
const GetClubForumChapterContentUserSummary = React.lazy(() => import('./controller/get_club_forum_chapter_content_user_summary/GetClubForumChapterContentUserSummary'));
const GetClubChapter2UniSem = React.lazy(() => import('./controller/get_club_chapter_2_uni_sem/GetClubChapter2UniSem'));

const GetClubForumDiscussionGridHeader = React.lazy(() => import('./controller/get_club_forum_discussion_grid_header/GetClubForumDiscussionGridHeader'));
const GetClubForumDiscussionGrid = React.lazy(() => import('./controller/get_club_forum_discussion_grid/GetClubForumDiscussionGrid'));
const GetSiteAccountCreateConfirm = React.lazy(() => import('./controller/get_site_account_create_confirm/GetSiteAccountCreateConfirm'));
// const GetClubP52YourThisYear = React.lazy(() => import('./controller/get_club_p52_your_this_year/GetClubP52YourThisYear'));

const GetClubGalleryPublicList = React.lazy(() => import('./controller/get_club_gallery_public_list/GetClubGalleryPublicList'));
const GetClubGalleryStatList = React.lazy(() => import('./controller/get_club_gallery_stat_list/GetClubGalleryStatList'));

const GetClubForumChapterGrid = React.lazy(() => import('./controller/get_club_forum_chapter_list_grid/GetClubForumChapterListGrid'));
const GetClubGroupTimetable = React.lazy(() => import('./controller/get_club_group_timetable/GetClubGroupTimetable'));

const GetClubUniSemVolumeList = React.lazy(() => import('./controller/get_club_uni_sem_volume_list/GetClubUniSemVolumeList'));
const GetClubUniSemVolumeReg = React.lazy(() => import('./controller/get_club_uni_sem_volume_reg/GetClubUniSemVolumeReg'));
const GetClubUniEvolutionUserSummary = React.lazy(() => import('./controller/get_club_uni_evolution_user_summary/GetClubUniEvolutionUserSummary'));

const GetClubGalleryImageInfo = React.lazy(() => import('./controller/get_club_gallery_picture_image_info/GetClubGalleryPictureImageInfo'));
const GetClubGalleryImageReport = React.lazy(() => import('./controller/get_club_gallery_picture_image_report/GetClubGalleryPictureImageReport'));
const GetClubUserImageAdminModerationBox = React.lazy(() => import('./controller/get_club_user_image_admin_moderation_box/GetClubUserImageAdminModerationBox'));
const GetClubUserImageEpModerationBox = React.lazy(() => import('./controller/get_club_user_image_ep_moderation_box/GetClubUserImageEpModerationBox'));
const GetClubGalleryCommentDrawer = React.lazy(() => import('./controller/get_club_gallery_comment_drawer/GetClubGalleryCommentDrawer'));
const GetClubGalleryFileUploadInfoUpdateNg = React.lazy(() => import('./controller/get_club_gallery_file_upload_info_update_ng/GetClubGalleryFileUploadInfoUpdateNg'));
const GetClubGalleryImageFbRecom = React.lazy(() => import('./controller/get_club_gallery_image_fb_recom/GetClubGalleryImageFbRecom'));
const GetClubImageTagDictList = React.lazy(() => import('./controller/get_club_image_tag_dict_list/GetClubImageTagDictList'));
const GetClubAdminChatNextImageSelect = React.lazy(() => import('./controller/get_club_admin_chat_next_image_select/GetClubAdminChatNextImageSelect'));
const GetClubGalleryCommentPlugin = React.lazy(() => import('./controller/get_club_gallery_comment_plugin/GetClubGalleryCommentPlugin'));
const GetClubPictureUserBlogLastPost = React.lazy(() => import('./controller/get_club_picture_user_blog_last_post/GetClubPictureUserBlogLastPost'));
const GetSiteGalleryDictExplore = React.lazy(() => import('./controller/get_site_gallery_dict_explore/GetSiteGalleryDictExplore'));

const GetClubGallerySupport = React.lazy(() => import('./controller/get_club_gallery_support/GetClubGallerySupport'));
const GetClubGalleryLatestCommentNg = React.lazy(() => import('./controller/get_club_gallery_latest_comment_ng/GetClubGalleryLatestCommentNg'));
const GetClubGalleryPictureImageInfo = React.lazy(() => import('./controller/get_club_gallery_picture_image_info/GetClubGalleryPictureImageInfo'));
const GetClubGalleryPictureImageReport = React.lazy(() => import('./controller/get_club_gallery_picture_image_report/GetClubGalleryPictureImageReport'));

const GetClubCrmUserRegistrationAll = React.lazy(() => import('./controller/get_club_crm_user_registration_all/GetClubCrmUserRegistrationAll'));
const GetClubStartSlideshow = React.lazy(() => import('./controller/get_club_start_slideshow/GetClubStartSlideshow'));

const GetClubLearnLostAttackTimetable = React.lazy(() => import('./controller/get_club_learn_lost_attack_timetable/GetClubLearnLostAttackTimetable'));
const GetClubPageItemHeader = React.lazy(() => import('./controller/get_club_page_item_header/GetClubPageItemHeader'));
const GetClubPageLearnChapterCurrentList = React.lazy(() => import('./controller/get_club_page_learn_chapter_current_list/GetClubPageLearnChapterCurrentList'));
const GetClubPretendUserSummary = React.lazy(() => import('./controller/get_club_pretend_user_summary/GetClubPretendUserSummary'));
const GetClubProgressBar = React.lazy(() => import('./controller/get_club_progress_bar/GetClubProgressBar'));
const GetClubProgressBarYourFriend = React.lazy(() => import('./controller/get_club_progress_bar_your_friend/GetClubProgressBarYourFriend'));
const GetClubUserGroupCloseRescue = React.lazy(() => import('./controller/get_club_user_group_close_rescue/GetClubUserGroupCloseRescue'));
const GetSiteMediaPageItemSlideshow = React.lazy(() => import('./controller/get_site_media_page_item_slideshow/GetSiteMediaPageItemSlideshow'));

const GetSiteTutorialVideo = React.lazy(() => import('./controller/get_site_tutorial_video/GetSiteTutorialVideo'));
const GetClubUserImageNominMissTutorial = React.lazy(() => import('./controller/get_club_user_image_nomin_miss_tutorial/GetClubUserImageNominMissTutorial'));
const GetClubUserImageEpMissTutorial = React.lazy(() => import('./controller/get_club_user_image_ep_miss_tutorial/GetClubUserImageEpMissTutorial'));
const GetClubGalleryImageBigNav = React.lazy(() => import('./controller/get_club_gallery_image_big_nav/GetClubGalleryImageBigNav'));
const GetClubGalleryImageDuringCourse = React.lazy(() => import('./controller/get_club_gallery_image_during_course/GetClubGalleryImageDuringCourse'));
const GetClubUserTaskList = React.lazy(() => import('./controller/get_club_user_task_list/GetClubUserTaskList'));

const GetClubPhpbbEditorImageSubmit = React.lazy(() => import('./sourcerer/newbb/get_phpbb_editor_image_submit/GetPhpbbEditorImageSubmit'));

const GetSiteRegintroUserList = React.lazy(() => import('./controller/get_site_regintro_user_list/GetSiteRegintroUserList'));
const GetSiteEvolutionUserList = React.lazy(() => import('./controller/get_site_evolution_user_list/GetSiteEvolutionUserList'));
const GetSiteInstructorAchieveList = React.lazy(() => import('./controller/get_site_instructor_achieve_list/GetSiteInstructorAchieveList'));
const GetSiteCourseDescArticle = React.lazy(() => import('./controller/get_site_course_desc_article/GetSiteCourseDescArticle'));
const GetSiteCoursePathCourse = React.lazy(() => import('./controller/get_site_course_path_course/GetSiteCoursePathCourse'));
const GetSiteGiftCouponIntro = React.lazy(() => import('./controller/get_site_gift_coupon_intro/GetSiteGiftCouponIntro'));
const GetSiteContainerInstructorDesc = React.lazy(() => import('./controller/get_site_container_instructor_desc/GetSiteContainerInstructorDesc'));
const GetSiteWelcomeGlobal = React.lazy(() => import('./controller/get_site_welcome_global/GetSiteWelcomeGlobal'));
const GetSiteCourseDescRegistrationLink = React.lazy(() => import('./controller/get_site_course_desc_registration_link/GetSiteCourseDescRegistrationLink'));
const GetSiteSupermumList = React.lazy(() => import('./controller/get_site_supermum_list/GetSiteSupermumList'));
const GetSiteChatNext = React.lazy(() => import('./controller/get_site_chat_next/GetSiteChatNext'));
const GetSiteBaImageGrid = React.lazy(() => import('./controller/get_site_ba_image_grid/GetSiteBaImageGrid'));
const GetSiteCourseDescCoursePath = React.lazy(() => import('./controller/get_site_course_desc_course_path/GetSiteCourseDescCoursePath'));
const GetSiteCityRelayAll = React.lazy(() => import('./controller/get_site_city_relay_all/GetSiteCityRelayAll'));
const GetSiteVoucherPsysPayment = React.lazy(() => import('./controller/get_site_voucher_psys_payment/GetSiteVoucherPsysPayment'));
const GetSiteRegintroTimeConstraint = React.lazy(() => import('./controller/get_site_regintro_time_constraint/GetSiteRegintroTimeConstraint'));
const GetSiteCourseDescLastStudent = React.lazy(() => import('./controller/get_site_course_desc_last_student/GetSiteCourseDescLastStudent'));
const GetSiteBeforeAfter = React.lazy(() => import('./controller/get_site_before_after/GetSiteBeforeAfter'));
const GetSiteAmocourseLiveChatNoFooter = React.lazy(() => import('./controller/get_site_amocourse_live_chat_no_footer/GetSiteAmocourseLiveChatNoFooter'));
const GetSiteCourseEventNext = React.lazy(() => import('./controller/get_site_course_event_next/GetSiteCourseEventNext'));
const GetSiteRemarketingRecomm = React.lazy(() => import('./controller/get_site_remarketing_recomm/GetSiteRemarketingRecomm'));
const GetSiteVoucherPsysConfirmation = React.lazy(() => import('./controller/get_site_voucher_psys_confirmation/GetSiteVoucherPsysConfirmation'));
const GetSiteSupermumGrid = React.lazy(() => import('./controller/get_site_supermum_grid/GetSiteSupermumGrid'));
const GetSiteScrollArrow = React.lazy(() => import('./controller/get_site_scroll_arrow/GetSiteScrollArrow'));
const GetSiteCoursePathDescWay = React.lazy(() => import('./controller/get_site_course_path_desc_way/GetSiteCoursePathDescWay'));
const GetSiteGridRankDiploma = React.lazy(() => import('./controller/get_site_grid_rank_diploma/GetSiteGridRankDiploma'));
const GetSiteCourseDescInvitation = React.lazy(() => import('./controller/get_site_course_desc_invitation/GetSiteCourseDescInvitation'));
const GetSiteVoucherWelcomeGlobal = React.lazy(() => import('./controller/get_site_voucher_welcome_global/GetSiteVoucherWelcomeGlobal'));
const GetSiteAmocourseRegFormPriceInfo = React.lazy(() => import('./controller/get_site_amocourse_reg_form_price_info/GetSiteAmocourseRegFormPriceInfo'));
const GetSiteNewsletterPopupModal = React.lazy(() => import('./controller/get_site_newsletter_popup_modal/GetSiteNewsletterPopupModal'));
const GetSiteCourseDescWhat = React.lazy(() => import('./controller/get_site_course_desc_what/GetSiteCourseDescWhat'));
const GetSitePageFunnel = React.lazy(() => import('./controller/get_site_page_funnel/GetSitePageFunnel'));
const GetSiteAmocourseRegFormSecurity = React.lazy(() => import('./controller/get_site_amocourse_reg_form_security/GetSiteAmocourseRegFormSecurity'));
const GetSiteAmocourseRegFormBusymum = React.lazy(() => import('./controller/get_site_amocourse_reg_form_busymum/GetSiteAmocourseRegFormBusymum'));
const GetSiteCourseEventList = React.lazy(() => import('./controller/get_site_course_event_list/GetSiteCourseEventList'));
const GetSiteFaqContactUsPhone = React.lazy(() => import('./controller/get_site_faq_contact_us_phone/GetSiteFaqContactUsPhone'));

const GetClubWhoIsOnline = React.lazy(() => import('./controller/get_club_who_is_online/GetClubWhoIsOnline'));
const GetSiteContactEnchancedAction = React.lazy(() => import('./controller/get_site_contact_enchanced_action/GetSiteContactEnchancedAction'));
const GetClubHeaderNotificationBanner = React.lazy(() => import('./controller/get_club_header_notification_banner/GetClubHeaderNotificationBanner'));
const GetClubP52Introduction = React.lazy(() => import('./controller/get_club_p52_introduction/GetClubP52Introduction'));
const GetClubEbookDownload = React.lazy(() => import('./controller/get_club_ebook_download/GetClubEbookDownload'));
const GetClubStartpageWelcomeMessage = React.lazy(() => import('./controller/get_club_startpage_welcome_message/GetClubStartpageWelcomeMessage'));
const GetClubGlobalWallDrawer = React.lazy(() => import('./controller/get_club_global_wall_drawer/GetClubGlobalWallDrawer'));

const GetSiteCourseDescFeatureList = React.lazy(() => import('./controller/get_site_course_desc_feature_list/GetSiteCourseDescFeatureList'));
const GetSiteInstructorAchieveUser = React.lazy(() => import('./controller/get_site_instructor_achieve_user/GetSiteInstructorAchieveUser'));
const GetSiteCourseDescStatAvatarList = React.lazy(() => import('./controller/get_site_course_desc_stat_avatar_list/GetSiteCourseDescStatAvatarList'));
const GetSiteCourseDescTerm = React.lazy(() => import('./controller/get_site_course_desc_term/GetSiteCourseDescTerm'));
const GetSiteAmocourseRegFormHeader = React.lazy(() => import('./controller/get_site_amocourse_reg_form_header/GetSiteAmocourseRegFormHeader'));
const GetSiteAmocourseRegFormInPackage = React.lazy(() => import('./controller/get_site_amocourse_reg_form_in_package/GetSiteAmocourseRegFormInPackage'));
const GetSiteEvolutionUserStrip = React.lazy(() => import('./controller/get_site_evolution_user_strip/GetSiteEvolutionUserStrip'));
const GetSiteEvolutionUserBaView = React.lazy(() => import('./controller/get_site_evolution_user_ba_view/GetSiteEvolutionUserBaView'));
const GetSiteCourseDescRecom = React.lazy(() => import('./controller/get_site_course_desc_recom/GetSiteCourseDescRecom'));
const GetSiteCourseDescStatInfo = React.lazy(() => import('./controller/get_site_course_desc_stat_info/GetSiteCourseDescStatInfo'));
const GetSiteCourseDescRecomGallery = React.lazy(() => import('./controller/get_site_course_desc_recom_gallery/GetSiteCourseDescRecomGallery'));
const GetSiteAmocourseRegFormFeature = React.lazy(() => import('./controller/get_site_amocourse_reg_form_feature/GetSiteAmocourseRegFormFeature'));
const GetSiteInstructorDescMainMessage = React.lazy(() => import('./controller/get_site_instructor_desc_main_message/GetSiteInstructorDescMainMessage'));
const GetSiteInstructorMainpageStat = React.lazy(() => import('./controller/get_site_instructor_mainpage_stat/GetSiteInstructorMainpageStat'));
const GetSiteNewsletterFooterButton = React.lazy(() => import('./controller/get_site_newsletter_footer_button/GetSiteNewsletterFooterButton'));
const GetSiteCelebrationMainpageBottomMsg = React.lazy(() => import('./controller/get_site_celebration_mainpage_bottom_msg/GetSiteCelebrationMainpageBottomMsg'));
const GetSiteCourseDescEp2Nomin = React.lazy(() => import('./controller/get_site_course_desc_ep_2_nomin/GetSiteCourseDescEp2Nomin'));
const GetSiteAmocourseRegFormClapping = React.lazy(() => import('./controller/get_site_amocourse_reg_form_clapping/GetSiteAmocourseRegFormClapping'));
const GetSiteTutorialRelatedCourse = React.lazy(() => import('./controller/get_site_tutorial_related_course/GetSiteTutorialRelatedCourse'));
const GetSiteCourseArticleDescVideoIntro = React.lazy(() => import('./controller/get_site_course_article_desc_video_intro/GetSiteCourseArticleDescVideoIntro'));
const GetSiteCourseDescNextCourse = React.lazy(() => import('./controller/get_site_course_desc_next_course/GetSiteCourseDescNextCourse'));
const GetSiteCourseDescMainMessage = React.lazy(() => import('./controller/get_site_course_desc_main_message/GetSiteCourseDescMainMessage'));
const GetSiteCourseDescEquip = React.lazy(() => import('./controller/get_site_course_desc_equip/GetSiteCourseDescEquip'));
const GetSiteCourseDescBanner = React.lazy(() => import('./controller/get_site_course_desc_banner/GetSiteCourseDescBanner'));
const GetSiteInstructorProtfolio = React.lazy(() => import('./controller/get_site_instructor_protfolio/GetSiteInstructorProtfolio'));
const GetSiteGlobalSocialPixelEvent = React.lazy(() => import('./controller/get_site_global_social_pixel_event/GetSiteGlobalSocialPixelEvent'));

const GetClubUserNopayAlert = React.lazy(() => import('./controller/get_club_user_nopay_alert/GetClubUserNopayAlert'));
const GetClubUserCouponActive = React.lazy(() => import('./controller/get_club_user_coupon_active/GetClubUserCouponActive'));
const GetClubSfdForm = React.lazy(() => import('./controller/get_club_sfd_form/GetClubSfdForm'));
const GetClubRegisterNextAddRecom = React.lazy(() => import('./controller/get_club_register_next_add_recom/GetClubRegisterNextAddRecom'));
const GetClubInstructorList = React.lazy(() => import('./controller/get_club_instructor_list/GetClubInstructorList'));
const GetClubInstructorFullDescription = React.lazy(() => import('./controller/get_club_instructor_full_description/GetClubInstructorFullDescription'));
const GetClubGalleryImageFbComment = React.lazy(() => import('./controller/get_club_gallery_image_fb_comment/GetClubGalleryImageFbComment'));
const GetClubBusymumCalendar = React.lazy(() => import('./controller/get_club_busymum_calendar/GetClubBusymumCalendar'));

const GetSiteGlobalNewsletterForm = React.lazy(() => import('./controller/get_site_global_newsletter_form/GetSiteGlobalNewsletterForm'));
const GetSiteGlobalNewsletterFormButton = React.lazy(() => import('./controller/get_site_global_newsletter_form_button/GetSiteGlobalNewsletterFormButton'));

const GetSiteGlobalPrivacyPolicy = React.lazy(() => import('./controller/get_site_global_privacy_policy/GetSiteGlobalPrivacyPolicy'));
const GetSiteLoginForm = React.lazy(() => import('./controller/get_site_login_form/GetSiteLoginLogoutForm'));
const GetSiteGlobalNewsletterWelcomeChat = React.lazy(() => import('./controller/get_site_global_newsletter_welcome_chat/GetSiteGlobalNewsletterWelcomeChat'));


const GetSiteAmocourseRegConfirm = React.lazy(() => import('./controller/get_site_amocourse_reg_confirm/GetSiteAmocourseRegConfirm'));
const GetSiteAmocourseConfirmPayment = React.lazy(() => import('./controller/get_site_amocourse_confirm_payment/GetSiteAmocourseConfirmPayment'));
const GetSiteAmocourseConfirmClubReg = React.lazy(() => import('./controller/get_site_amocourse_confirm_club_reg/GetSiteAmocourseConfirmClubReg'));
const GetSiteAmocourseConfirmTerm = React.lazy(() => import('./controller/get_site_amocourse_confirm_term/GetSiteAmocourseConfirmTerm'));

const GetSiteTutorialSocialLike = React.lazy(() => import('./controller/get_site_tutorial_social_like/GetSiteTutorialSocialLike'));
const GetSitePresentationWorld = React.lazy(() => import('./controller/get_site_presentation_world/GetSitePresentationWorld'));
const GetSiteSetNewsletterMailingExcl = React.lazy(() => import('./controller/get_site_set_newsletter_mailing_excl/GetSiteSetNewsletterMailingExcl'));


/* dynamically import and insert Component based on input info */
//const ContainerSiteOpinionTable = lazy(() => import('./container/ContainerSiteOpinionTable'));

/* SWITCH: Directly it's not allowed, because we can't put any statement inside JSX.
 You can do one thing, put the code (switch logic)
 inside a function and call that function, and return the correct component from that.
 https://reactjs.org/docs/introducing-jsx.html#embedding-expressions-in-jsx

 https://www.akademia-fotografii-dzieciecej.pl/components/php_restapi/index.php?query_call=instructor_letter_info&instructor_letter=Nhttps://www.akademia-fotografii-dzieciecej.pl/components/php_restapi/index.php?query_call=instructor_letter_info&instructor_letter=N
 */
interface IAfdContainerState {
    element1ObjList: any[];
}

interface IProps {
    key: number;
    menuItemObj: IJos4Menu;
    userInfoObj: IUserInfo;
    position: ISelectorPosition;
    controllerInfoObj: IContainerQuery;
    paramObj: IParamObj;
    redirectionObj?: IRedirectionInfo;
    formikGantry?: IFormFormikGantry;
}

const AfdContainer = ({
                          menuItemObj,
                          userInfoObj,
                          position,
                          controllerInfoObj,
                          paramObj,
                          redirectionObj,
                          formikGantry,
                      }: IProps) => {
    const [element1ObjList, setElement1ObjList] = useState([]);
    const appObj: IAppObj = React.useContext(CtxAppObj);
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();

    const lazyComponents: {
        [key: string]:
            JSXElementConstructor<any>
    } = {
        GetSiteWelcomeGlobal,
        GetClubUserNopayAlert,
        GetClubUserCouponActive,
        GetClubSfdForm,
        GetClubRegisterNextAddRecom,
        GetClubInstructorList,
        GetClubInstructorFullDescription,
        GetClubGalleryImageFbComment,
        GetClubBusymumCalendar,
        GetSiteVoucherWelcomeGlobal,
        GetSiteVoucherPsysPayment,
        GetSiteVoucherPsysConfirmation,
        GetSiteTutorialRelatedCourse,
        GetSiteTutorialHelpTopList,
        GetSiteSupermumList,
        GetSiteSupermumGrid,
        GetSiteScrollArrow,
        GetSiteRemarketingRecomm,
        GetSiteRegintroUserList,
        GetSiteRegintroTimeConstraint,
        GetSiteRegintroCourseEventNext,
        GetSitePresentationGlobalAll,
        GetSitePageGrid,
        GetSitePageFunnel,
        GetSiteOpinionTable,
        GetSiteNewsletterPopupModal,
        GetSiteNewsletterButtonForm,
        GetSiteInstructorProtfolio,
        GetSiteInstructorMainpageStat,
        GetSiteInstructorDescMainMessage,
        GetSiteInstructorAchieveUser,
        GetSiteInstructorAchieveList,
        GetSiteGridRankDiploma,
        GetSiteGlobalSocialPixelEvent,
        GetSiteGiftCouponIntro,
        GetSiteFaqContactUsPhone,
        GetSiteEvolutionUserStrip,
        GetSiteEvolutionUserList,
        GetSiteEvolutionUserBaView,
        GetSiteNewsletterFooterButton,
        GetSiteEvolutionList,
        GetSiteCoursePathDescWay,
        GetSiteCoursePathCourse,
        GetSiteCourseEventNext,
        GetSiteCourseEventList,
        GetSiteCourseDescWhat,
        GetSiteCourseDescTerm,
        GetSiteCourseDescSyllabusText,
        GetSiteCourseDescStatInfo,
        GetSiteCourseDescStatAvatarList,
        GetSiteCourseDescRule,
        GetSiteCourseDescRegistrationLink,
        GetSiteCourseDescRecomGallery,
        GetSiteCourseDescRecom,
        GetSiteCourseDescNextCourse,
        GetSiteCourseDescMainMessage,
        GetSiteCourseDescLastStudent,
        GetSiteCourseDescInvitation,
        GetSiteCourseDescImageGrid,
        GetSiteCourseDescFeatureList,
        GetSiteCourseDescEquip,
        GetSiteCourseDescEp2Nomin,
        GetSiteAmocourseRegFormFieldList,
        GetSiteAmocourseRegFormHeader,
        GetSiteCourseDescBanner,
        GetSiteAmocourseRegFormClapping,
        GetSiteCelebrationMainpageBottomMsg,
        GetSiteCourseArticleDescVideoIntro,
        GetSiteAmocourseRegFormFeature,
        GetSiteAmocourseRegFormInPackage,
        GetSiteAmocourseRegFormBusymum,
        GetSiteAmocourseRegFormSecurity,
        GetSiteAmocourseRegFormPriceInfo,
        GetSiteAmocourseLiveChatNoFooter,
        GetSiteBeforeAfter,
        GetSiteCityRelayAll,
        GetSiteCourseDescCoursePath,
        GetSiteBaImageGrid,
        GetSiteChatNext,
        GetSiteContainerInstructorDesc,
        GetSiteCourseDescArticle,
        GetSiteAmocourseRegList,
        GetSiteCourseCatalogMain,
        GetSiteCourseCatalogSelectColumn,
        GetSiteContainerTutorialList,
        GetSiteContactUs,
        GetSiteAmocourseRegFormLegal,
        GetSiteGlobalNewsletterForm,
        GetSiteGlobalNewsletterFormButton,
        GetSiteGlobalPrivacyPolicy,
        GetSiteLoginForm,
        GetSiteGlobalNewsletterWelcomeChat,
        GetSiteAmocourseRegConfirm,
        GetSiteAmocourseConfirmPayment,
        GetSiteAmocourseConfirmClubReg,
        GetSiteAmocourseConfirmTerm,
        GetSiteTutorialSocialLike,
        GetSitePresentationWorld,
        GetClubPaymentCourseList,
        GetClubGalleryImageBigThumb,
        GetClubGalleryPictureUserInfo,
        GetClubImageUserVisitNot,
        GetClubGalleryImageRodoLog,
        GetClubUserImageNominMissTutorial,
        GetClubUserImageEpMissTutorial,
        GetClubGalleryImageBigNav,
        GetClubGalleryImageDuringCourse,
        GetClubGalleryImageInfo,
        GetClubGalleryImageReport,
        GetClubUserImageAdminModerationBox,
        GetClubUserImageEpModerationBox,
        GetClubGalleryCommentDrawer,
        GetClubGalleryFileUploadInfoUpdateNg,
        GetClubGalleryImageFbRecom,
        GetClubImageTagDictList,
        GetClubAdminChatNextImageSelect,
        GetClubGalleryCommentPlugin,
        GetClubPictureUserBlogLastPost,
        GetClubGallerySupport,
        GetClubGalleryLatestCommentNg,
        GetClubGalleryPictureImageInfo,
        GetClubGalleryPictureImageReport,
        GetClubDashboardNavbar,
        GetClubImageUserStream,
        GetSiteBlogImageList,
        GetSiteBlogLogo,
        GetSiteBlogMenu,
        GetSiteBlogPostFooter,
        GetSiteBlogPostList,
        GetSiteBlogPostView,
        GetClubGalleryImageTagList,
        GetSiteGalleryDictExplore,
        GetSiteMainpageMainSlideshow,
        GetClubGalleryPublicList,
        GetClubLearnLostAttackTimetable,
        GetClubPageItemHeader,
        GetClubPageLearnChapterCurrentList,
        GetClubPretendUserSummary,
        GetClubProgressBar,
        GetClubProgressBarYourFriend,
        GetClubUserGroupCloseRescue,
        GetSiteMediaPageItemSlideshow,
        GetClubWhoIsOnline,
        GetSiteContactEnchancedAction,
        GetClubHeaderNotificationBanner,
        GetClubP52Introduction,
        GetClubEbookDownload,
        GetClubStartpageWelcomeMessage,
        GetClubGlobalWallDrawer,
        GetClubGalleryStatList,
        GetClubFileUpload,
        GetClubGalleryPosterDownload,
        GetClubNewbbInstructorAbsence,
        GetClubNewbbPaginationToast,
        GetClubNewbbViewTopic,
        GetClubNewbbDrawerList,
        GetClubPhpbbEditorImageSubmit,
        GetClubForumChapterGrid,
        GetClubGroupTimetable,
        GetClubGlobalPageTitle,
        GetClubPackageUserList,
        GetClubPackageCurrent,
        GetClubPackagePromotionBox,
        GetClubCrmUserRegistrationAll,
        GetClubForumDiscussionGridHeader,
        GetClubForumDiscussionGrid,
        GetClubNewbbDrawerCourseAll,
        GetClubP52YourThisYear,
        GetBackendInstructorEmptyreg,
        GetBackendInstructorNewEntry,
        GetBackendInstructorNewEntryNoNomin,
        GetBackendInstructorPanelGrid,
        GetBackendInstructorSummary,
        GetSiteChatbot,
        GetBackendLexIntentUpload,
        GetClubForumCourseSideMenu,
        GetClubForumChapterGridHeader,
        GetClubGroupSlideshow,
        GetClubStartSlideshow,
        GetClubUniSemVolumeList,
        GetClubUniSemVolumeReg,
        GetClubUniEvolutionUserSummary,
        GetClubUserProfileComprofilerView,
        GetClubProfileHistory,
        GetSiteUserProfileInfo,
        GetClubProfileEpImageList,
        GetClubUserProfileLinkList,
        GetClubMyPostsSent,
        GetSiteUserProfileSpec,
        GetSiteUserProfileInfoAward,
        GetClubUserProfileSlideshow,
        GetClubUserProfileSideMenu,
        GetSiteUserProfileStrip,
        GetSiteUserProfileCourse,
        GetClubUserProfileUserDelete,
        GetClubProfileFormFieldListNg,
        GetClubUserProfileMailingExcl,
        GetClubMedalDownload,
        GetClubUserProfileRodoDataTable,
        GetClubUserProfileFieldDelim,
        GetSiteUserProfileImageRestore,
        GetClubProfileAvatarUpload,
        GetClubUserTaskList,
        GetClubContactFriendListType,
        GetClubUserDictList,
        GetClubPretendEpReviewAll,
        GetClubGlobalWorksheetTable,
        GetClubMyAcademyCourseList,
        GetSiteGlobalNewsletterMessage,
        GetSiteAccountCreateConfirm,
        GetBackendInstructorSummaryUser,
        GetClubGalleryRankList,
        GetClubGalleryNewList,
        GetClubGalleryAwardList,
        GetClubGalleryUserRank,
        GetClubSupermumPretendUserList,
        GetSitePartnerList,
        GetClubUserProfileAvatarSelect,
        GetSiteSetNewsletterRating,
        GetSiteNewsletterRatingWelcome,
        GetClubForumChapterRecomList,
        GetClubForumChapterItemList,
        GetClubForumChapterRecommend,
        GetSitePasswordReset,
        GetClubBgMyMain,
        GetClubBgUserRank,
        GetClubNewbbUniFinalPreview,
        GetClubForumChapterContent,
        GetClubForumChapterContentArrow,
        GetClubForumChapterItemButton,
        GetClubForumChapterFileList,
        GetClubForumChapterAssetListAll,
        GetClubForumChapterAssessSurvey,
        GetClubForumChapterAssessDiploma,
        GetClubForumChapterContentUserSummary,
        GetClubChapter2UniSem,
        GetClubLearnHomeworkCurrent,
        GetSiteTutorialVideo,
        GetSiteDictGridDictDesc,
        GetSiteDictGridCourseList,
        GetSiteDictImageGrid,
        GetBackendMarketingBannerIexp,
        GetSiteDictGrid,
        GetBackendPretendReviewList,
        GetSiteSetNewsletterMailingExcl
    };


    const immediateComponents: {
        [key: string]:
            JSXElementConstructor<any>
    } = {
        GetSiteGlobalMenu,
        GetSiteGlobalFooter,
        GetSiteContainerTutorialBody,
        GetSiteMainpageMainFacebook,
        GetSiteWelcomeVideoGlobal,
        GetSiteMainpageMessage,
        GetSiteMainpageImageToolbar,
        GetSiteWhoIsOnline,
        GetClubPageItemSummary,
    };

    function getPropertyValue<T>(obj: T, propertyName: string): string {
        const value = (obj as Record<string, any>)[propertyName];
        return String(value);
    }

    const getJSXElementByName = (inputName: string): JSXElementConstructor<any> | undefined => {
        const componentName = inputName;
        if (componentName in immediateComponents) {
            return immediateComponents[componentName];
        } else if (componentName in lazyComponents) {
            return lazyComponents[componentName];
        }
    };

    /*dodatkowe parametry:*/
    let containerPageSetColumnName = userInfoObj.page_set_code + '_container_module';
    let containerTemplateName = getPropertyValue(controllerInfoObj, containerPageSetColumnName);
    // controllerInfoObj[containerPageSetColumnName as keyof typeof this.props.controllerInfoObj];

    /* IMPORTAMT: set additional CALC variables used in Container below */
    if (controllerInfoObj.element_module1) {
        controllerInfoObj.element1CamelName = capitalize(camelize(controllerInfoObj.element_module1?.toLowerCase()));
    }
    controllerInfoObj.containerName = controllerInfoObj[containerPageSetColumnName as keyof typeof controllerInfoObj];


    debugLog('AfdContainer/containerPageSetColumnName: ' + containerPageSetColumnName + ' Template name: ' + containerTemplateName);
    let containerClassName = "level_client " + controllerInfoObj.php_function_code + " is_mobile_" + controllerInfoObj.is_mobile + " " + controllerInfoObj.container_css_layout + " " + controllerInfoObj.container_css_class;
    let containerContentClassName: string = "container_content " + controllerInfoObj.element_module1?.toLowerCase();
    let anchorName = "anchor_" + controllerInfoObj.php_function_code;

    const phpFunctionCamelName: string = capitalize(
        camelize(controllerInfoObj.php_function_code?.toLowerCase())
    );

    const getControllerComponentTSX = (): JSX.Element => {
        try {
            let finalComponent: any = getJSXElementByName(phpFunctionCamelName);
            if (!finalComponent) {
                throw new Error(
                    'AfdContainer/getControllerComponentTSX: Nie znaleziono komponentu: ' +
                    phpFunctionCamelName
                );
            }
            return finalComponent;
        } catch (e) {
            return <></>; // return AfdExceptionComponent;
        }

    };
    let ControllerComponentTSX: JSX.Element = getControllerComponentTSX();

    useEffect(() => {
        return () => {
            // componentWillUnmount
        };
    }, []);

    const getCheckMobile = () => {
        return (
            controllerInfoObj.is_mobile == 1 ||
            !globalConfigParamObj ||
            (controllerInfoObj.is_mobile == 0 &&
                !globalConfigParamObj.isMobile)
        );
    };

    const getCheckUserLogged = (): boolean => {
        return !(userInfoObj.user_id === 0 && menuItemObj.access > 0);
    };

    const getCheckPageSetCodeContainer = (): boolean => {
        return (
            !!(ControllerComponentTSX) &&
            !!containerTemplateName
        );
    };

    const getCheckIsAdminTestMode = (): boolean => {
        return (controllerInfoObj.is_test_mode == 1);
    }



    function getAdminMessageDebugSilentTSX(
        controllerInfoObj: IContainerQuery,
        userInfoObj: IUserInfo,
        containerTemplateName: string,
        messageText: string
    ) {

        const setDebugSilent = (diagInfo: string) => {
            debugLog(
                'AfdContainer/getDebugCommentRender: ' +
                diagInfo +
                controllerInfoObj.php_function_code +
                ' ' +
                controllerInfoObj.page_item_id +
                ' ' +
                controllerInfoObj.sequence
            );
            return <></>;
        };

        if (userInfoObj.user_id === 714
            || appObj.isDiagnostics
            || isDev()
        ) {
            return <SoftBox
                color="info"
                id={
                    'No container: ' +
                    controllerInfoObj.php_function_code +
                    ' ' +
                    controllerInfoObj.page_item_id +
                    ' ' +
                    controllerInfoObj.sequence
                }
                sx={{
                    display: 'block',
                    border: '1px solid #ccc',
                    radius: '5px',
                    padding: '5px',
                    margin: '5px',
                }}
            >
                Diag: no container for:
                <SoftTypography variant="caption"
                                sx={{display: 'block'}}
                >
                    Container ID: {controllerInfoObj.container_id}
                </SoftTypography>
                <SoftTypography variant="caption"
                                sx={{display: 'block'}}
                >
                    Php function code: {controllerInfoObj.php_function_code}
                </SoftTypography>
                <SoftTypography variant="caption"
                                sx={{display: 'block'}}
                >
                    Page item id: {controllerInfoObj.page_item_id}
                </SoftTypography>
                <SoftTypography variant="caption"
                                sx={{display: 'block'}}
                >
                    Sequence: {controllerInfoObj.sequence}
                </SoftTypography>
                <SoftTypography variant="caption"
                                sx={{display: 'block'}}
                >
                    Container template: {containerTemplateName}
                </SoftTypography>
                <SoftTypography variant="caption"
                                color={'error'}
                                sx={{display: 'block'}}
                >
                    Message: {messageText}
                </SoftTypography>
            </SoftBox>;
        }

        setDebugSilent(messageText);
    }

    if (getCheckUserLogged()) {


        return (
            <>
                <Suspense
                    fallback={
                        <AfdLoadingFullpage componentCategory="AfdContainer_Suspense"/>
                    }
                >
                    <a data-name={anchorName}/>
                    <AfdContainerDiag controllerInfoObj={controllerInfoObj}/>
                    <span className={containerContentClassName}>
            {getCheckPageSetCodeContainer() ? (
                getCheckMobile() ? (
                        <>{
                            // @ts-ignore
                            getCheckIsAdminTestMode() ?
                                getAdminMessageDebugSilentTSX(
                                    controllerInfoObj,
                                    userInfoObj,
                                    containerTemplateName,
                                    'Container is in Admin TEST MODE'
                                )
                                // @ts-ignore
                                : (ControllerComponentTSX && <ControllerComponentTSX
                                        controllerInfoObj={controllerInfoObj}
                                        userInfoObj={userInfoObj}
                                        paramObj={paramObj}
                                        menuItemObj={menuItemObj}
                                        redirectionObj={redirectionObj}
                                        formikGantry={formikGantry}
                                    />
                                )

                        }</>
                    ) :
                    getAdminMessageDebugSilentTSX(
                        controllerInfoObj,
                        userInfoObj,
                        containerTemplateName,
                        'No mobile container'
                    )
            ) : getAdminMessageDebugSilentTSX(
                controllerInfoObj,
                userInfoObj,
                containerTemplateName,
                'No content for page set code'
            )}
          </span>
                </Suspense>
            </>
        );
    } else {
        return (
            <AfdAlertGoAndLogin
                templateTSX={parse(
                    '<p>Nie jesteś zalogowana lub Twoja sesja zakończyła się.</p> Zaloguj się, aby móc dodawać komentarze'
                )}
                diagInfo={'diag_id:' + menuItemObj.id + '_a:' + menuItemObj.access}
            />
        );
    }
};

export default AfdContainer;
