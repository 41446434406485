/*Pobierz dane element*/
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import RestApiService from "../service/restapi/RestApiService";
import RestApiCommonService from "../service/restapi/RestApiCommonService";
import {processContainer, processElement} from "../util/UtilReact";
import IRQOutputList from "../type/rq/IRQOutputList";
import {QueryObserverBaseResult, QueryObserverResult} from "react-query";
import IElementForumDecodeInfoCurrent from "../type/newbb/IElementForumDecodeInfoCurrent";
import IUserInfo from "../type/IUserInfo";
import IElementNewbbTopicPost from "../type/newbb/IElementNewbbTopicPost";
import IElementNewbbTopicInfo from "../type/newbb/IElementNewbbTopicInfo";


export default function useCHTopicDecodeInfoCurrent(
    {
        topic_id,
        userInfoObj,
        isEnabled = true,
    }: {
        topic_id: number,
        userInfoObj: IUserInfo,
        isEnabled?: boolean,
    }
): [
    IElementNewbbTopicInfo[],
    Dispatch<SetStateAction<IElementNewbbTopicInfo[]>>,
    (x?: any) => Promise<QueryObserverResult<any, unknown>>,
] {
    let sourcererFunction: string = 'get_forum_decode_info_current';
    const [elementObjList, setElementObjList] = useState<IElementNewbbTopicInfo[]>([]);

    /* Różne QUERY w zależności od tego czy COURSE_USER_ID */
    const rqSourcererFunction: IRQOutputList = RestApiCommonService.getSourcererFunctionRQ(
        sourcererFunction,
        "sql_query3",
        {
            topic_id: topic_id,
            user_id: userInfoObj.user_id
        },
        isEnabled
    );

    useEffect(() => {

        if (rqSourcererFunction && rqSourcererFunction?.data?.data && !rqSourcererFunction.isLoading) {
            setElementObjList(processElement(rqSourcererFunction.data.data));
        }

    }, [
        rqSourcererFunction.isLoading ? "1" : "0",
        rqSourcererFunction.isRefetching ? "1" : "0",
        sourcererFunction,
        topic_id,
        userInfoObj.user_id,
    ]);


    return [
        elementObjList,
        setElementObjList,
        rqSourcererFunction.refetch
    ]
}
