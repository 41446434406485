    import ICtxReducerState from "../type/reducer_state/ICtxReducerState";
    import SoftBox from "../components/SoftBox";
    import {Link} from "react-router-dom";
    import CheckCircleIcon from "@mui/icons-material/CheckCircle";
    import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
    import React from "react";
    import IElementForumChapterIssue from "../type/newbb/IElementForumChapterIssue";
    import {IElementChapterIssueDecodeInfoList} from "../hook/useCHForumChapterIssueDecodeInfoList";
    import SoftTypography from "../components/SoftTypography";
    import CameraOutdoorOutlinedIcon from "@mui/icons-material/CameraOutdoorOutlined";
    import SoftAvatar from "../components/SoftAvatar";

    export function getChapterIssueSelectIconTSX(isCanItemSelect: boolean, chapterIssueLink: string, isRatingValue: 0 | null | boolean, globalConfigParamObj: ICtxReducerState) {
        return <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent={"flex-end"}
        >
            <SoftBox
                display={'inline-block'}
                component={isCanItemSelect
                    ? Link
                    : 'div'}
                to={chapterIssueLink}
            >
                {
                    isRatingValue ?
                        <CheckCircleIcon
                            fontSize={globalConfigParamObj.isMobile ? "medium" : "large"}
                            sx={{
                                color: 'green',
                            }}
                        /> :
                        <ArrowCircleRightOutlinedIcon
                            fontSize={globalConfigParamObj.isMobile ? "large" : "large"}
                            sx={{
                                color: 'lightgrey',
                            }}
                        />
                }
            </SoftBox>
        </SoftBox>;
    }

    /* BUGFIX: zanim NAVIGATE - zamknij POPUP poniewaz w tym kontekście pozostaje */
    export function getForumChapterIssueListTSX(
        uni_semester: number | null,
        elementChapterIssueObjList: IElementForumChapterIssue[],
        isCanItemSelect: boolean,
        chNavigateFunction: any,
        globalConfigParamObj: ICtxReducerState,
        currentChapterIssueObj?: IElementChapterIssueDecodeInfoList,
        onBeforeNavigateFunction?: any,
    ) {

        return (
            <>
                {elementChapterIssueObjList.map((elementChapterIssueObj: IElementForumChapterIssue,
                                                 index: number
                ) => {
                    let isIssueLast = (index == elementChapterIssueObjList.length - 1);
                    let isRatingValue = elementChapterIssueObj.rating_value && elementChapterIssueObj.rating_value >= -1;
                    let chapterIssueLink = elementChapterIssueObj.chapter_issue_link;
                    return (
                        <SoftBox
                            key={index}
                            display="flex"
                            flexDirection="row"
                            justifyContent={"space-between"}
                            py={{xs: 1, md: 1, xl: 1}}
                            px={{xs: 0.5, md: 1, xl: 1}}
                            sx={{
                                borderBottom: "1px solid #ccc",
                                /*       "&:hover": {
                                           backgroundColor: "lightgrey",
                                       }*/

                            }}
                        >
                            <SoftBox
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                                justifyContent="center"
                                sx={globalConfigParamObj.isMobile ?
                                    {
                                        width: "calc(100% - 3em)",
                                        maxWidth: "85vw",
                                    } :
                                    {
                                        width: "100%",
                                        maxWidth: "70vw",
                                    }}
                                onClick={() => {
                                    if (isCanItemSelect) {
                                        if (onBeforeNavigateFunction) {
                                            onBeforeNavigateFunction();
                                        }
                                        chNavigateFunction(chapterIssueLink);
                                    }
                                }}
                            >
                                <SoftBox
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent={"flex-start"}
                                    alignItems={"center"}
                                >
                                    <SoftTypography
                                        variant={"button"}
                                        display={"block"}
                                    >
                                        {elementChapterIssueObj.chapter_issue_title}
                                        {/*HOMEWORK napis*/}
                                        {
                                            (isIssueLast &&
                                                !uni_semester)
                                            && <>{" "}/{" "}Praca domowa
                                                <CameraOutdoorOutlinedIcon
                                                    fontSize={"medium"}
                                                    sx={{
                                                        marginLeft: "0.2em",
                                                    }}
                                                />
                                            </>
                                        }
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent={"flex-start"}
                                    alignItems={"baseline"}
                                ><SoftTypography
                                    variant={"button"}
                                    fontWeight={"light"}
                                    display={"block"}
                                    sx={{
                                        paddingTop: "0.2em",
                                    }}
                                >
                                    ~ {elementChapterIssueObj.issue_duration_minute} minut
                                </SoftTypography>
                                    <SoftTypography
                                        variant={"caption"}
                                        color={"info"}
                                        fontWeight={"light"}
                                        display={"inline-block"}
                                        sx={{
                                            paddingLeft: "1em",
                                        }}
                                    >
                                        {elementChapterIssueObj.issue_point_count} punktów
                                    </SoftTypography></SoftBox>
                            </SoftBox>

                            {getChapterIssueSelectIconTSX(isCanItemSelect,
                                chapterIssueLink,
                                isRatingValue,
                                globalConfigParamObj)}
                        </SoftBox>)
                })
                }
            </>
        );
    }

    export function getChapterGridChapterHeaderTSX(
        forumImagePath: string,
        forumSequenceNumber: number,
        chapterHeader: string,
        chapterTitle: string,
        description: string | null,
        getForumChapterGridUserListTSX: null | (() => (JSX.Element | null))
    ) {


        return <SoftBox display="flex" alignItems="center">
            <SoftAvatar
                src={forumImagePath}
                alt={"Przejdź do treści rozdziału..."}
                size="xl"
                variant="rounded"
                bgColor={"secondary"}
                sx={{p: 1}}
            />
            <SoftBox ml={2} lineHeight={0}>
                <SoftBox mb={1} lineHeight={0}>
                    <SoftTypography variant="h4"
                                    textTransform="capitalize"
                    >
                        {forumSequenceNumber}. {chapterHeader}
                    </SoftTypography>
                </SoftBox>
                <SoftBox><SoftTypography
                    variant="button"
                    fontWeight={"medium"}
                >{chapterTitle}</SoftTypography></SoftBox>
                {
                    getForumChapterGridUserListTSX && <SoftBox
                    >{getForumChapterGridUserListTSX()}</SoftBox>
                }
            </SoftBox>
        </SoftBox>;
    }
