import {RDX_SWITCH_DICTIONARY} from "../redux_action/rdxAction";
import IRdxState from "../type/reducer_state/IRdxState";
import {formikInitialValueObj} from "../form/formikInitialValueObj";


export let initialState: IRdxState = {
    session_id: undefined,
    property_value_id: null,
    TUTORIAL_HELP_TOP_SELECT: null,
    cookie_name: undefined, /* nie może być 0 ponieważ 0 to wybrana wartość */
    promocodeArray: {
        discount_value: 0,
        discount_value_percent: 0,
    },
    account_train_step: 0,
    form_submission_result: undefined,
        currentReplyCommentLineObj: null
};

export function rdxAppReduxReducer(state: IRdxState = initialState, action: any): any {
    switch (action.type) {
        case RDX_SWITCH_DICTIONARY.COURSE_PROPERTY_VALUE_SELECT:
            state.property_value_id = action.payload.property_value_id; // payload !
            return {
                ...state
            }
        case RDX_SWITCH_DICTIONARY.USER_COOKIE_NAME:
            state.cookie_name = action.payload.cookie_name; // payload !
            return {
                ...state
            }
        case RDX_SWITCH_DICTIONARY.TUTORIAL_HELP_TOP_SELECT:
            state.TUTORIAL_HELP_TOP_SELECT = action.payload.message_id; // payload !
            return {
                ...state
            }
        case RDX_SWITCH_DICTIONARY.PROMOCODE_ARRAY_UPDATE:
            state.promocodeArray = action.payload.promocodeArray; // payload !
            return {
                ...state
            }
       case RDX_SWITCH_DICTIONARY.ACCOUNT_TRAIN_STEP_UPDATE:
            state.account_train_step = (state.account_train_step + action.payload.account_train_step); // payload !
            return {
                ...state
            }
        case RDX_SWITCH_DICTIONARY.GALLERY_COMMENT_REPLY_SELECT:
            /* Dodaj do kontenera Gallery */
            if (action.payload.currentReplyCommentLineObj) {
                state.currentReplyCommentLineObj = action.payload.currentReplyCommentLineObj;
            }
            return                 state;
        default:
            return state;

    }
}

export function rdxGlobalSettingUpdate(globalSettingObj: any = {
    globalSettingObj: null
}): any {
    return {
        type: RDX_SWITCH_DICTIONARY.GLOBAL_SETTING_UPDATE, // this is to ROUTE
        payload: {
            globalSettingObj: globalSettingObj
        }
    }
}




