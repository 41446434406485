import React, {useEffect, useState} from "react";
import {API_URL} from "../service/restapi/RestApiService";

// Soft UI Dashboard PRO React components
import SoftBox from "../components/SoftBox";
// Pricing page components
import Grid from "@mui/material/Grid";

import IPropGantry from "../type/IPropGantry";
import Container from "@mui/material/Container";
import {useLocation} from "react-router-dom";
import GetMenuPageBanner from "../util/GetMenuPageBanner";
import AfdMainpageBanner from "./AfdMainpageBanner";
import ISelectorImageInfo from "../type/selector/ISelectorImageInfo";
import useCHSelectorImage from "../hook/useCHSelectorImage";
import SoftTypography from "../components/SoftTypography";
import AfdSelectorImageAuthor from "./AfdSelectorImageAuthor";
import ICtxGlobalConfig from "../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../hook/useCHGetContextSoftUI";


/*separate component with selector image logic for proper logic separation*/
function AfdSelectorImage({
                              menuItemObj,
                              containerObjList,
                              userInfoObj,
                              routeCategory,
                              redirectionObj,
                              selectorImageParamObj,
                              children,
                              mainpageBannerTSX
                          }: IPropGantry) {


    let promiseArray: Promise<any>[] = new Array<Promise<any>>();
    const queryHookObj = new URLSearchParams(useLocation().search);
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();


    let bannerHeight: string = GetMenuPageBanner(menuItemObj).bannerHeight;
    const selectorImageObj: ISelectorImageInfo | undefined = useCHSelectorImage(
        routeCategory,
        queryHookObj,
        selectorImageParamObj,
        menuItemObj,
        redirectionObj
    );

    /* Different layout depending on whether there is image or not
    `url(${selectorImageObj.image_huge_src})`
    backgroundImage: `url(${selectorImageObj.image_huge_src})`,
    * */
    let colorTheme = (menuItemObj.id == 289 ? "light" : "light");
    let imageSrc = (globalConfigParamObj.isMobile ? selectorImageObj?.image_big_thumb_src : selectorImageObj?.image_huge_src);
    return (
        (typeof selectorImageObj !== 'undefined' ?
                <SoftBox
                    position="relative"
                    height="10vh"
                    borderRadius="xl"
                    /*overflow="hidden"*/
                    bgColor="info"
                    variant="gradient"
                    m={{xs: 0, md: 1, lg: 2, xl: 2}}
                    pt={3} // BUGFIX: obrazem w tle nie wystarcza, aby pokyrć cały tekst MAINPAGE_WELCOME: 0-> 3 oraz daje zaokrąglenie na górze
                    pb={bannerHeight} //20
                    sx={{
                        background: (colorTheme == "dark" ?
                                `linear-gradient(rgba(10, 10, 10, 0.7),rgba(10, 10, 10, 0.9)), url(${imageSrc})` :
                                `url(${imageSrc})`
                        ),
                        backgroundSize:
                            globalConfigParamObj.isMobile
                            && menuItemObj.xx_page_banner !== 'short_standard'
                                ? "contain" : "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: globalConfigParamObj.isMobile ? "0% 0%" : "0% 11%",

                    }}
                >

                    {/* Display menu */}
                    <Grid
                        container
                        spacing={{xs: 1, md: 1, lg: 3}}
                        justifyContent="center"
                        sx={{textAlign: "center"}}
                    >
                        {/*lg={7}*/}
                        <Grid item xs={10} lg={7}>
                            {children}
                        </Grid>
                    </Grid>
                    {mainpageBannerTSX}

                    <SoftTypography variant={"caption"} color="white"
                                    position="absolute"
                                    bottom={0} right={0} p={3}
                                    sx={{
                                        transform: "skewX(0deg)" // reverse
                                    }}
                    >
                        <AfdSelectorImageAuthor
                            selectorImageObj={selectorImageObj}
                            menuItemObj={menuItemObj}
                        />
                    </SoftTypography>

                </SoftBox>
                :
                <>
                    <SoftBox
                        position="relative"
                        height={GetMenuPageBanner(menuItemObj).bannerHeight}
                        borderRadius="xl"
                        overflow="hidden"
                        bgColor={"white"}
                        variant="gradient"
                        m={2}
                        pt={12}
                        pb={120} //20
                    >
                        <Grid container spacing={3} justifyContent="center" sx={{textAlign: "center"}}>
                            {/*lg={7}*/}
                            <Grid item xs={10} lg={7}>
                                {children}
                            </Grid>
                        </Grid>
                    </SoftBox>
                </>
        )
    );

}

export default AfdSelectorImage;


{/*  <SoftBox mb={1}>
                                  <SoftTypography variant="h3" color="white" fontWeight="bold">
                                    See our pricing
                                </SoftTypography>
                                </SoftBox>
                                <SoftBox mb={2}>
                                  <SoftTypography variant="body2" color="white" fontWeight="regular">
                                    You have Free Unlimited Updates and Premium Support on each package.
                                  </SoftTypography>
                                </SoftBox>*/
}
{/* <Grid container item xs={12} sm={10} md={8} lg={7} sx={{ mx: "auto" }}>
                                  <SoftBox width="100%" mt={6}>
                                    <AppBar position="static">
                                      <Tabs value={tabValue} onChange={tabHandler}>
                                        <Tab
                                          id="monthly"
                                          label={
                                            <SoftBox py={0.5} px={2}>
                                              Monthly
                                            </SoftBox>
                                          }
                                        />
                                        <Tab
                                          id="annual"
                                          label={
                                            <SoftBox py={0.5} px={2}>
                                              Annual
                                            </SoftBox>
                                          }
                                        />
                                      </Tabs>
                                    </AppBar>
                                  </SoftBox>
                                </Grid>*/
}


{/*// @ts-ignore*/
}
{/*       <SoftBox
                                    component="img"
                                    src={waves}
                                    alt="waves"
                                    width="105rem"
                                    position="absolute"
                                    top={0}
                                />*/
}
