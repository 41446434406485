import React, {useEffect, useState} from "react";
import IContainerQuery from "../../type/IContainerQuery";
import {CircularProgress} from "@mui/material";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftAlert from "../../components/SoftAlert";

interface IEmptyboxProps {
    controllerInfoObj: IContainerQuery;
    userInfoObj?: any;
    paramObj?: any;
    elementObjList?: any[];
    elementIsLoading?: boolean;
}

export default function Emptybox({
                                     controllerInfoObj: controllerInfoObj,
                                     userInfoObj: userInfoObj,
                                     paramObj: paramObj,
                                     elementObjList: elementObjList,
                                     elementIsLoading: elementIsLoading,
                                 }: IEmptyboxProps) {
    /* Wyświetlaj lub nie TEKST EMPTY
    - jeżeli jest LOADING i jest FALSE bo zakończone
    - np.: GetClubPageLearnChapterCurrentList
    * */
    if (typeof elementIsLoading !== 'undefined' && elementIsLoading === false && controllerInfoObj.empty_text) {
        return <SoftBox
            className={"emptytext" + controllerInfoObj.php_function_code}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            px={{xs: 1, sm: 2}}
            py={{xs: 1, sm: 2}}
        ><SoftAlert
            color={"light"}
        ><SoftTypography
            variant={"body2"}
            fontStyle={"italic"}
            color={"white"}
        >{
            controllerInfoObj.empty_text
        }</SoftTypography></SoftAlert></SoftBox>;
    } else {
        /*Normalnie każda podana tablica nie spełnia "undefined" więc jeżeli chcesz kółko - to nie podawaj tablicy*/
        if (elementObjList === undefined) {
            /* migające kółka bardzo złe */
            if (controllerInfoObj.page_item_id == 62 &&
                controllerInfoObj.php_function_code !== 'get_club_gallery_image_big_thumb'
            ) {
                return null;
            } else {
                return <SoftBox
                    className={"emptybox_" + controllerInfoObj.php_function_code}
                ><CircularProgress/></SoftBox>;
                /*return (
                    <CircularProgress color="inherit" />
                )*/
            }
        } else {
            return null;
        }
        ;
    }


};
