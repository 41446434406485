import {Backdrop, Card, CardActions, CardHeader, Modal} from "@mui/material";
/*import  {makeStyles } from '@material-ui/core/styles';*/
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import React, {useEffect, useState} from "react";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import SoftButton from "../../components/SoftButton";
import Grid from "@mui/material/Grid";
import SpaceShip from "../../examples/Icons/SpaceShip";
import Fade from '@mui/material/Fade';
import {Transition} from "react-transition-group";
import useCHAddData from "../../hook/useCHAddData";
import useCHSourcererData from "../../hook/useCHSourcererData";
import AfdSupermumDefaultProjectCard from "../../examples/Cards/ProjectCards/AfdSupermumDefaultProjectCard";
import IGlobalMenuChild from "../../type/global_menu/IGlobalMenuChild";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../hook/useCHGetContextSoftUI";
import SoftAvatar from "../../components/SoftAvatar";


interface IProp {
    title: string;
    isLoader: boolean;
    setIsLoader: (isLoader: boolean) => void;
    handleClose: () => void;
    modalValueObj: {
        collapseObj: IGlobalMenuChild;
    };
}


function ModalClubViewerTooltip(
    {
        title,
        isLoader,
        setIsLoader,
        handleClose,
        modalValueObj
    }: IProp
) {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    /*const classes = useStyles();*/
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: '60vw', md: '60vw', lg: '50vw'
        },
        maxWidth: '600px',
        bgcolor: 'background.paper',
        /*border: '1px solid #666',*/
        boxShadow: "18",
        p: 4,
        borderRadius: "md",
        transition: `all 2500ms ease-in-out`,
    };

    var transitionStyles: any = {
        entering: {opacity: 1},
        entered: {opacity: 1},
        exiting: {opacity: 0},
        exited: {opacity: 0},
    };

    const defaultStyle = {
        transition: `opacity 2500ms ease-in-out`,
        opacity: 0,
    }


    const [inProp, setInProp] = useState<boolean>(false);
    useEffect(() => {
            setTimeout(function () {
                setInProp(true);
            }, 3500);
        }
        , []);

    handleClose = () => {
        setIsLoader(false);
    }

    const handleOpen = () => setIsLoader(true);
    let desc: string = "xxx";


    function getContent() {
        return (
            <div>
                <Modal
                    open={isLoader}
                    onClose={handleClose}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: "5%",
                        marginRight: "5%",
                        marginTop: "5%",
                        opacity: 0.9,
                    }}
                >
                    <Fade
                        in={isLoader}
                        timeout={900}
                        easing={"ease-in-out"}
                        style={
                            {
                                transitionDelay: 100 + 'ms',
                            }
                        }
                    >
                        {/*<SoftButton onClick={handleOpen} size="small">{title}</SoftButton>*/}
                        <Card
                            sx={{
                                maxWidth: "100%",
                                width: "100%",
                                paddingTop: "1em",
                            }}
                        >
                            <CardContent
                                sx={{maxHeight: 400, overflow: "overlay"}}
                            >
                                <SoftBox
                                    py={{xs: 2.5, sm: 2.5, md: 2.5, lg: 2.5, xl: 2.5}}
                                    pr={{xs: 0, sm: 0, md: 0, lg: 4, xl: 4}}
                                    display={globalConfigParamObj.isMobile ? "block" : "flex"}
                                    sx={globalConfigParamObj.isMobile ?
                                        {
                                            float: "left",
                                        } : {}}
                                ><SoftAvatar src={modalValueObj.collapseObj.icon}
                                             size={globalConfigParamObj.isMobile ? "md" : "xxl"}
                                             alt="Pogląd strony Klubu Mam Akademii..."
                                             variant="rounded"/></SoftBox>
                                <SoftTypography
                                    variant={globalConfigParamObj.isMobile ? "body2" : "body2"}

                                >
                                    {modalValueObj.collapseObj.locked_tooltip_desc}
                                </SoftTypography>
                            </CardContent>
                            {/*<Divider/>*/}
                            <SoftBox
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={"flex-end"}
                                width={"100%"}
                                pb={1}
                            >
                                <CardActions style={{float: "right"}}>
                                    <SoftButton
                                        autoFocus
                                        onClick={handleClose}
                                        startIcon={<CloseIcon/>}
                                        size="large"
                                        color="primary"> </SoftButton>
                                </CardActions>
                            </SoftBox>

                        </Card>
                    </Fade>
                </Modal>
            </div>

        );

    }

    return getContent();

}

export default ModalClubViewerTooltip;
