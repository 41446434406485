// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

/*import Link from "@mui/material/Link";*/
import {Link} from "react-router-dom";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";

// Soft UI Dashboard PRO React example components
import AfdDefaultNavbarMenu from "../../DefaultNavbar/AfdDefaultNavbarMenu";

import IPropMenu     from "../../../../type/menu/IPropMenu";
import IPropMenuRoute from "../../../../type/menu/IPropMenuRoute";
import IGlobalMenuParent from "../../../../type/global_menu/IGlobalMenuParent";
import IGlobalMenuChild from "../../../../type/global_menu/IGlobalMenuChild";

import PhotoCameraFrontOutlinedIcon from '@mui/icons-material/PhotoCameraFrontOutlined';
import {cardActionStyles} from "../cardActionStyles";

/*
// Setting default values for the props of DocsMenu
AfdDocsMenu.defaultProps = {
    mobileMenu: false,
    open: false,
    close: false,
};

// Typechecking props for the DocsMenu
AfdDocsMenu.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
    open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    close: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    mobileMenu: PropTypes.bool,
};*/

/* AFD: dodatkowy parametr wybierający gałąż według nazwy
 */
function AfdDocsMenu({
                       menuParentItemObjList,
                       open = false,
                       close = false,
                       mobileMenu = false,
                         target = "_self",
                       parentKey }: IPropMenu) {
    const parentKeyObjList: IGlobalMenuParent[] = menuParentItemObjList.filter((menuParentItemObj: IGlobalMenuParent) => menuParentItemObj.key === parentKey);

/*    const iconComponents = {
      PhotoCameraFrontOutlinedIcon
    };*/


    const renderDocsMenuRoute = (
        parentKeyObj: IGlobalMenuParent,
        isMobile: boolean
    ) => {
        return (
            <>{parentKeyObj.collapse.map((
                    {
                        key: collapseKey,
                        href,
                        name,
                        icon,
                        description,
                        menu_id
                    }: IGlobalMenuChild,
                ) => {
                    let iconIndex =  icon + "OutlinedIcon";
                    // TODO - BLOG do przerobienia
                    let isExternalLink = (collapseKey == 'key_629' ||
                        collapseKey == 'key_298' ||
                        collapseKey == 'key_133' ||
                        collapseKey == 'key_31') ? true : false;
                    // @ts-ignore
                /*let IconComponent: any = iconComponents[iconIndex];*/
                if (!isMobile) {
                    /* DESKTOP version */
                    return (
                        <MenuItem
                            key={collapseKey}
                            component={isExternalLink ? 'a' : Link}
                            href={isExternalLink ? href : undefined}
                            target={isExternalLink ? target : undefined}
                            to={href}
                            onClick={mobileMenu ? undefined : close}
                        >
                            {/* Start item     https://developers.google.com/fonts/docs/material_icons                             */}
                            <SoftBox display="flex" py={0.25}

                            >

                                <SoftBox
                                    sx={cardActionStyles}
                                >{typeof icon === "string" ?
                                    <Icon
                                        baseClassName="material-icons-outlined"
                                    >{icon}</Icon>
                                    : <SoftBox mt={0.5}>{icon}</SoftBox>}</SoftBox>
                                <SoftBox pl={2} lineHeight={0}>
                                    <SoftTypography variant="h6" fontWeight="bold">
                                        {name}
                                    </SoftTypography>
                                    <SoftTypography variant="button" fontWeight="regular" color="text">
                                        {description}
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        </MenuItem>
                    );
                } else {
                    /* MOBILE version */

                    if (menu_id == 91 ||
                        menu_id == 79 ||
                        menu_id == 99) {
                        icon = 'timeline';
                    }

                    return (
                        <MenuItem
                            key={collapseKey}
                            component={Link}
                            to={href}
                            onClick={mobileMenu ? undefined : close}
                        >
                            {/* Start item     https://developers.google.com/fonts/docs/material_icons                             */}
                            <SoftBox display="flex" py={0.25}

                            >

                                <SoftBox
                                    sx={cardActionStyles}
                                >
                                    {typeof icon === "string" ?
                                    <Icon
                                        sx={
                                        { fontWeight: "light"}
                                    }
                                        baseClassName="material-icons-outlined"                                    >{icon}</Icon> //https://mui.com/material-ui/api/icon/
                                    : <SoftBox mt={0.5}>{icon}</SoftBox>}</SoftBox>
                                <SoftBox pl={2} lineHeight={0}>
                                    <SoftTypography variant="button" fontWeight="regular">
                                        {name}
                                    </SoftTypography>
                                    {/*<SoftTypography variant="button" fontWeight="regular" color="text">
                                        {description}
                                    </SoftTypography>*/}
                                </SoftBox>
                            </SoftBox>
                        </MenuItem>
                    );
                }
                })
            }</>
        );
    }


    return mobileMenu ? (
        <>{renderDocsMenuRoute(parentKeyObjList[0],
            mobileMenu
        )}</>
    ) : (
        <AfdDefaultNavbarMenu open={open} close={close}>
            {renderDocsMenuRoute(parentKeyObjList[0],
                mobileMenu
            )}
        </AfdDefaultNavbarMenu>
  );
}


export default AfdDocsMenu;
