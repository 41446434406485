import React, {useEffect, useState} from "react";
import {API_URL} from "../service/restapi/RestApiService";

// Soft UI Dashboard PRO React components
import SoftBox from "../components/SoftBox";
// Pricing page components
import Grid from "@mui/material/Grid";

import AfdSelectorSimple from "../selector/AfdSelectorSimple";
import IContainerQuery from "../type/IContainerQuery";
import Container from "@mui/material/Container";
import GetMenuPageBanner from "../util/GetMenuPageBanner";
import {getPosition2ContainerMap} from "../util/UtilReact";
import IJos4Menu from "../type/IJos4Menu";
import IUserInfo from "../type/IUserInfo";
import IRedirectionInfo from "../type/IRedirectionInfo";
import SoftTypography from "../components/SoftTypography";
import ISelectorImageInfo from "../type/selector/ISelectorImageInfo";
import ISelectorPosition from "../type/ISelectorPosition";
import IFormFormikGantry from "../type/form/IFormFormikGantry";

interface IProp {
    menuItemObj: IJos4Menu;
    containerObjList: IContainerQuery[];
    userInfoObj: IUserInfo;
    routeCategory: String,
    redirectionObj: IRedirectionInfo;
    getSelectorPositionJSX: (positionCode: ISelectorPosition,
                             formikGantry?: IFormFormikGantry
                             ) => JSX.Element | null;
    /*selectorImageObj: ISelectorImageInfo;*/
    children?: JSX.Element;
}

/*separate component with selector image logic for proper logic separation*/
function AfdMainpageBanner({
                               menuItemObj,
                               containerObjList,
                               userInfoObj,
                               routeCategory,
                               redirectionObj,
                                getSelectorPositionJSX,
                               /*selectorImageObj,*/
                               children
                           }: IProp) {


    const position2ContainerMap = getPosition2ContainerMap(containerObjList);
    var containerMarginTop: number = GetMenuPageBanner(menuItemObj).containerMarginTop;


    if (routeCategory === 'mainpage' ||
        routeCategory === 'course_desc' ||
        /*routeCategory === 'instructor' ||*/
        menuItemObj.id == 823 ||
        menuItemObj.id == 560 ||
        menuItemObj.id == 244 ||
        menuItemObj.id == 280 ||
        menuItemObj.id == 612 ||
        menuItemObj.id == 279
    ) {
        return (
            <>
                {/*BUGFIX: nie używamy ABSOLUTE, ale wewnętrzny komponent = relative*/}
                <SoftBox
                    /*position="absolute"
                    zIndex={2}*/
                    /*top={{xs: 110, sm: 130, md: 150, lg: 205, xl: 205}}
                    left={{xs: 1, md: 1, lg: 1, xl: 1}}
                    right={{xs: 1, md: 1, lg: 1, xl: 1}}*/
                    display="flex"
                    flexDirection="row"
                    /*height="10vh"*/ /* nie wymuszaj wysokości */
                    variant="transparent"
                    minHeight={"10vw"}
                >
                    <Grid container
                          spacing={{
                                xs: 0,
                                sm: 1,
                                md: 2,
                                lg: 3,
                                xl: 3,
                          }}
                          direction="row"
                    >
                        <Grid item
                              xs={12}
                              md={6}
                              lg={6}
                              xl={6}
                        >
                            <SoftBox
                            >
                                {getSelectorPositionJSX('position-mainpage-welcome')}
                            </SoftBox>
                        </Grid>
                        <Grid item
                              xs={12}
                              md={3}
                              lg={3}
                              xl={3}
                        >
                            <SoftBox>
                            </SoftBox>
                        </Grid>
                        <Grid item
                              xs={12}
                              md={3}
                              lg={3}
                              xl={3}
                        >{/*VIDEO i opisy do fotografii */}
                            <SoftBox
                                display="flex"
                                flexDirection="column"
                                justifyContent="flex-start"
                                textAlign={"right"}
                                pr={{xs: 0,
                                    sm: 2,
                                    md: 5, lg: 5}}
                            >
                                <SoftBox>{getSelectorPositionJSX('position-mainpage-video')}</SoftBox>
                                <SoftBox>{getSelectorPositionJSX('position-mainpage-social')}</SoftBox>
                            </SoftBox></Grid>
                    </Grid>
                    {/*

                <SoftBox>[[CONTENT_USER_DIAG]] [[ADMIN_IMAGE_REPLACE_LINK]]
                    [[ADMIN_IMAGE_LEFT_LINK]]
                </SoftBox>
                <SoftBox>
                    {getSelectorPositionJSX('position-mainpage-toolbar')}
                </SoftBox>
                <SoftBox>
                    {getSelectorPositionJSX('position-mainpage-strip')}
                </SoftBox>



                <SoftBox>
                    <SoftTypography className="user_full_name">
                        {selectorImageObj.content_user_full_name}
                    </SoftTypography>
                    <SoftTypography className="user_sesje_title">{
                        selectorImageObj.content_user_sesje_title
                    }</SoftTypography>
                    <SoftTypography className="user_website">Strona fotografa: <a
                        href="[[WEBSITE_URL]]" target="_blank"
                        title="Zobacz stronę fotografa...">{
                        selectorImageObj.content_user_website
                    }</a></SoftTypography>
                </SoftBox>

                <SoftBox>
                    Dołącz do <strong>{
                    selectorImageObj.global_user_count
                }</strong>+ Mam
                </SoftBox>*/}

                </SoftBox>
            </>
        );



    }
    if (routeCategory === 'amocourse')  {
        return (
            <>
                <SoftBox
                    display="flex"
                    flexDirection="row"
                    /*height="10vh"*/ /* nie wymuszaj wysokości */
                    variant="transparent"
                    minHeight={"30vw"}
                >
                    <Grid container
                          spacing={{
                              xs: 0,
                              sm: 1,
                              md: 2,
                              lg: 3,
                              xl: 3,
                          }}
                          direction="row"
                    >
                        <Grid item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                        >
                            <SoftBox>
                                {getSelectorPositionJSX('position-mainpage-welcome')}
                            </SoftBox>
                        </Grid>
                    </Grid>

                </SoftBox>
            </>
        );
    }

    return (
        <></>
    );

}

export default AfdMainpageBanner;
