import ISelectorImageInfo from "../type/selector/ISelectorImageInfo";
import React from "react";
import IJos4Menu from "../type/IJos4Menu";
import SoftBox from "../components/SoftBox";
import SoftTypography from "../components/SoftTypography";
import ICtxGlobalConfig from "../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../hook/useCHGetContextSoftUI";

interface IPropAfdSelectorImageAuthor {
    selectorImageObj?: ISelectorImageInfo;
    menuItemObj: IJos4Menu;
}

function AfdSelectorImageAuthor({
                                    selectorImageObj: selectorImageObj,
                                    menuItemObj: menuItemObj
                                }: IPropAfdSelectorImageAuthor) {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    if (menuItemObj.xx_page_banner === 'short_standard') {
        return null;
    }

    function getAuthorName(title?: string,
                           link?: string
    ): React.ReactNode {
        if (title) {
            if (link) {
                return (<SoftBox
                    sx={{
                        opacity: 0.9,
                    }
                    }
                    component="a"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    color={"white"}
                >
                    {title}
                </SoftBox>);
            } else {
                return (
                    <SoftBox
                        color={"white"}
                        sx={{
                            opacity: 0.9,
                        }
                        }
                    >
                        {title}
                    </SoftBox>
                );
            }

        } // enf of IF TITLE
    }

    /* WWW lub FB oraz Insta */
    if (selectorImageObj && selectorImageObj.user_id) {
        if (globalConfigParamObj.isMobile) {
            return <>

            </>;
        } else {
            return <>
                <SoftBox
                    display={"block"}
                >{getAuthorName(selectorImageObj.content_user_full_name, selectorImageObj.content_user_website)}</SoftBox>
                <SoftBox
                    display={"block"}
                >{getAuthorName(selectorImageObj.content_user_sesje_title, selectorImageObj.content_user_website)}</SoftBox>
                <SoftBox
                    display={"block"}
                >
                    {getAuthorName(selectorImageObj.content_user_instagram, '' + selectorImageObj.content_user_instagram)}
                </SoftBox>
            </>;
        }
    }
    return <></>;
}

export default AfdSelectorImageAuthor;
