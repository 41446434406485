import React, {Suspense, useEffect, useState} from "react";
import {debugLog} from "../../util/UtilReact";

// @mui material components
import Container from "@mui/material/Container";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";

// Soft UI Dashboard PRO React example components
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import {getPosition2ContainerMap} from "../../util/UtilReact";

import AfdSelectorSimple from "../../selector/AfdSelectorSimple";
import AfdSelectorImage from "../AfdSelectorImage";
import IContainerQuery from "../../type/IContainerQuery";
import useCHGantryData from "../../hook/useCHGantryData";
import IPropGantry from "../../type/IPropGantry";

import GetMenuPageBanner from "../../util/GetMenuPageBanner";

import ISelectorPosition from "../../type/ISelectorPosition";
import Grid from "@mui/material/Grid";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import GetClubDashboardNavbar from "../../component/controller/get_club_dashboard_navbar/GetClubDashboardNavbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';

import CtxClubChapter from "../../context/CtxClubChapter";
import IElementGalleryCommentLine from "../../type/gallery/IElementGalleryCommentLine";
import AppBar from "@mui/material/AppBar";
import {
    navbar,
    navbarContainer,
    navbarDesktopMenu, navbarIconButton,
    navbarRow
} from "../../component/controller/get_club_dashboard_navbar/styles";
import Toolbar from "@mui/material/Toolbar";
import Breadcrumbs from "../../examples/Breadcrumbs";
import Icon from "@mui/material/Icon";
import GetGlobalProfileIcon from "../../component/sourcerer/GetGlobalProfileIcon";
import useCHGetContextSoftUI from "../../hook/useCHGetContextSoftUI";
import {useLocation, useNavigate} from "react-router-dom";
import {setMiniSidenav, setOpenConfigurator, setTransparentNavbar} from "../../redux_reducer/util/dispatchShortcut";
import Menu from "@mui/material/Menu";
import NotificationItem from "../../examples/Items/NotificationItem";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import ConfigButtonFactory from "../../examples/Configurator/ConfigButtonFactory";
import {isDev} from "../../util/isDev";
import AfdConfigurator from "../../examples/Configurator/AfdConfigurator";
import IElementGalleryLatestCommentNg from "../../type/gallery/IElementGalleryLatestCommentNg";
import SoftAvatar from "../../components/SoftAvatar";
import IconButton from "@mui/material/IconButton";
import GetMyAcademyNotificationList from "../../component/sourcerer/GetMyAcademyNotificationList";
import SoftTypography from "../../components/SoftTypography";
import {ToastContainer} from "react-toastify";
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import linkDictionary from "../../linkDictionary";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import useCHForumDecodeInfoCurrent from "../../hook/useCHForumDecodeInfoCurrent";
import useCHTopicDecodeInfoCurrent from "../../hook/useCHTopicDecodeInfoCurrent";
import useCHForumChapterIssueDecodeInfoList from "../../hook/useCHForumChapterIssueDecodeInfoList";
import AfdLoadingFullpage from "../../component/AfdLoadingFullpage";
import useCHSourcererData from "../../hook/useCHSourcererData";
import IElementNewbbUniForumInfo from "../../type/newbb/IElementNewbbUniForumInfo";

const team2 = require("../../assets/images/team-2.jpg");
const logoSpotify = require("../../assets/images/small-logos/logo-spotify.svg");

function RtClubChapter({
                           menuItemObj,
                           containerObjList,
                           userInfoObj,
                           routeCategory,
                           redirectionObj
                       }: IPropGantry) {

    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    var [menuItemListObj, setMenuItemListObj] = useCHGantryData(
        menuItemObj,
        containerObjList,
        userInfoObj,
        routeCategory,
        redirectionObj
    );
    const [storeOrigCommentObj, setStoreOrigCommentObj] = useState<IElementGalleryCommentLine | null>(null);
    const [storeMyAcademyNotifCounter, setStoreMyAcademyNotifCounter] = useState<{
        comment: number;
        post: number;
        instructor: number;
    }>({
        comment: 0,
        post: 0,
        instructor: 0
    });

    const useQuery = () => new URLSearchParams(useLocation().search);
    const queryHookObj = useQuery();
    let isNewbbChapterEnabled = (menuItemObj.id == 810);
    let urlChapterForumId: number | null = queryHookObj.get('f') ? Number.parseInt(queryHookObj.get('f') as string) : null;
    let urlChapterTopicId: number | null = queryHookObj.get('t') ? Number.parseInt(queryHookObj.get('t') as string) : null;
    let urlChapterIssueCode: string | null = queryHookObj.get('i') ? queryHookObj.get('i') : null;

    const [elementCurrentForumInfoObjList, setElementCurrentForumInfoObjList, refetchElementCurrentForumInfoFunction] = useCHForumDecodeInfoCurrent({
            forum_id: urlChapterForumId ? urlChapterForumId : -1,
            topic_id: urlChapterTopicId ? urlChapterTopicId : -1,
            userInfoObj: userInfoObj,
            isCourseInfo: false,
            isEnabled: (isNewbbChapterEnabled && urlChapterForumId != null && urlChapterTopicId != null),
        },
    );
    /* Pobiera informacje także XX_COURSE_CALENDAR:
    - pobiera informacje o kursie dla NONUNI natomiast Uni pobiera z własnych HOOKs
    *  */
    const [elementTopicAnyUserInfoObjList, setElementTopicAnyUserInfoObjList, refetchElementTopicInfoObjList] = useCHTopicDecodeInfoCurrent(
        {
            topic_id: urlChapterTopicId ? urlChapterTopicId : -1,
            userInfoObj: userInfoObj,
            isEnabled: (isNewbbChapterEnabled && urlChapterForumId != null && urlChapterTopicId != null),
        }
    );

    /* Pobierz informacje o UNI zamiast rozszerzać DECODE FORUM */
    let isChapterDisForumEnabled: boolean = (elementCurrentForumInfoObjList && elementCurrentForumInfoObjList.length > 0 && !!elementCurrentForumInfoObjList[0].chapter_dis_forum_id);
    const [elementUniForumInfoObjList, setElementUniForumInfoObjList] = useCHSourcererData<IElementNewbbUniForumInfo>(
        'get_forum_decode_info_current',
        'sql_query4',
        {
            dis_forum_id: isChapterDisForumEnabled ? elementCurrentForumInfoObjList[0].chapter_dis_forum_id : -1,
        },
        (isNewbbChapterEnabled && isChapterDisForumEnabled),
    );

    /* Pobiera listę ISSUE dla wybranego CHAPTER i oznacza CURRENT ISSUE */
    const [elementIssueInfoObjList, setElementIssueInfoObjList, refetchElementIssueInfoFunction,
        elementIssueInfoIsLoading,
        elementIssueInfoIsFetching,
        elementIssueInfoIsIdle
    ] = useCHForumChapterIssueDecodeInfoList(
        {
            chapter_forum_id: urlChapterForumId ? urlChapterForumId : -1,
            current_chapter_issue_code: urlChapterIssueCode ? urlChapterIssueCode : "",
            userInfoObj: userInfoObj,
            isEnabled: (isNewbbChapterEnabled && urlChapterForumId != null && urlChapterTopicId != null),
        },
    );


    const navigate = useNavigate();
    const handleConfiguratorOpen = () => {
        navigate(linkDictionary.CLUB_GALLERY_NEW_IMAGE);

    };

    function getGalleryImageListButtonTSX() {
        return <CollectionsOutlinedIcon
            sx={{
                /*color: "primary.main",*/
            }}
        />;
    }


    let configButtonTSX = ConfigButtonFactory(
        handleConfiguratorOpen,
        getGalleryImageListButtonTSX()
    );

    /* Button */
    function getLatestCommentButtonTSX() {
        return <Icon color="inherit">
            rss_feed
        </Icon>
    }

    const [tabValue, setTabValue] = useState<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 8>(1);

    const handleSetTabValueFunction = (event: any, newValue: any) => {
        setTabValue(newValue);
        debugLog(tabValue);
    };

    const position2ContainerMap = getPosition2ContainerMap(containerObjList);

    /* App bar */
    let absolute: boolean = false;
    let light: boolean = false;
    let isMini: boolean = false;


    const [navbarType, setNavbarType] = useState('');
    const [controller, dispatch] = useCHGetContextSoftUI();
    const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator} = controller;
    const route = useLocation().pathname.split("/").slice(1);


    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        /* A function that sets the transparent state of the navbar.
        - either FIXED and position 0
        or
        - not FIXED
         */
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

    const getSelectorPositionJSX = function (positionCode: ISelectorPosition): JSX.Element | null {
        if (position2ContainerMap[positionCode]?.length > 0) {

            /* BUGFIX: disableGutters nie działa */
            if (menuItemObj.xx_display_menutype === 'dummy') {
                return (
                    <AfdSelectorSimple
                        key={positionCode}
                        menuItemObj={menuItemObj}
                        userInfoObj={userInfoObj}
                        position={positionCode}
                        redirectionObj={redirectionObj}
                        containerAssocList={position2ContainerMap[positionCode]}
                    />
                );
            } else {
                return (
                    <Container
                        /*disableGutters={true}*/ // nie działa
                    >
                        <AfdSelectorSimple
                            key={positionCode}
                            menuItemObj={menuItemObj}
                            userInfoObj={userInfoObj}
                            position={positionCode}
                            redirectionObj={redirectionObj}
                            containerAssocList={position2ContainerMap[positionCode]}
                        /></Container>);
            }
        } else {
            return null;
        }

    }


    /*****           *******************************************************                   ******/
    var containerMarginTop: number = GetMenuPageBanner(menuItemObj).containerMarginTop;

    function getTabBody1() {
        return <SoftBox
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
        ><Grid container spacing={1}>
            {/* MAIN container*/}
            <Grid item xs={12} md={9} lg={9}>
                {getSelectorPositionJSX('feature-a')}
                {getSelectorPositionJSX('content-top-a')}
                {
                    userInfoObj.is_tech_account == 1 ?
                        getSelectorPositionJSX('admin-instructor-toolbar') : null
                }
                {/*zaznaczanie Oli dla CHAT*/}
                {getSelectorPositionJSX('body')}
                {/* TODO {getSelectorPositionJSX('content-bottom-a')}*/}

            </Grid>
            <Grid item xs={12} md={3} lg={3}
            >
                {getSelectorPositionJSX('sidebar-a')}
                {getSelectorPositionJSX('sidebar-b')}
                {getSelectorPositionJSX('sidebar-c')}
            </Grid>
            {/* RIGHT sidebar */}
            {/*       <Grid item xs={12} lg={3}>

            </Grid>*/}
        </Grid>
        </SoftBox>;
    }

    function getTabBody2() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-info-body')}
                </SoftBox>
            </Grid>
        </Grid>;
    }

    function getTabBody3() {
        return <Grid container>
            <Grid item xs={6} lg={6}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-moderation-body')}
                </SoftBox>
            </Grid>
            <Grid item xs={6} lg={6}>
                <SoftBox my={3}>

                </SoftBox>
            </Grid>
        </Grid>;
    }

    function getTabBody4() {
        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {getSelectorPositionJSX('REACT-favourite-body')}
                </SoftBox>
            </Grid>
            {/*<Grid item xs={6} lg={6}>
                <SoftBox my={3}>

                </SoftBox>
            </Grid>*/}
        </Grid>;
    }

    let specificationObjList = [
        {
            label: 'nowe zdjęcia',
            icon: <NotesOutlinedIcon/>,
            value: 0
        },
        {
            label: 'statystyki',
            icon: <InsertChartOutlinedTwoToneIcon/>,
            value: 1
        },
        /*   {
               label: 'moderacja',
               icon: <FlagOutlinedIcon/>,
               value: 2
           },
           {
               label: 'ulubione',
               icon: <BookmarkAddOutlinedIcon/>,
               value: 3
           }*/
    ];

    /* Nie pokazuj jeżeli tylko jedna */
    function getTabListTSX() {
        if (specificationObjList.length < 2
            || menuItemObj.id != 617
        ) {
            return null;
        } else {
            return <SoftBox
                alignItems={"left"}
            > <Tabs orientation={"horizontal"}
                    value={tabValue}
                    onChange={handleSetTabValueFunction}>
                {
                    specificationObjList.map((specificationObj, index) => {
                            return <Tab
                                key={index}
                                label={globalConfigParamObj.isMobile &&
                                index !== tabValue
                                    ? '' : specificationObj.label} // nie pokazuj reszty
                                icon={specificationObj.icon}
                                value={specificationObj.value}
                                sx={{
                                    paddingRight: 2
                                }}
                            />
                        }
                    )
                }
            </Tabs>
            </SoftBox>;
        }
    }

    return (
        <>
            {
                /* BUGFIX: nie puszczaj dopóki ładuje ISSUE bo jeżeli posta lista - tylko problemy */
                (!elementIssueInfoIsLoading || elementIssueInfoObjList.length > 0)
                    ? <CtxClubChapter.Provider value={{
                        storeOrigCommentObj: storeOrigCommentObj,
                        setStoreOrigCommentObj: setStoreOrigCommentObj,
                        storeMyAcademyNotifCounter: storeMyAcademyNotifCounter,
                        setStoreMyAcademyNotifCounter: setStoreMyAcademyNotifCounter,
                        tabValue: tabValue,
                        setTabValue: setTabValue,
                        storeNewbbForumInfoObj: elementCurrentForumInfoObjList[0],
                        storeUniForumInfoObj: elementUniForumInfoObjList[0],
                        storeNewbbTopicInfoObj: elementTopicAnyUserInfoObjList[0],
                        storeNewbbIssueDecodeInfoObjList: elementIssueInfoObjList,
                        urlDisForumId: urlChapterForumId,
                        urlDisTopicId: urlChapterTopicId,
                        urlChapterIssueCode: urlChapterIssueCode,
                        refetchElementCurrentForumInfoFunction: refetchElementCurrentForumInfoFunction,
                        refetchElementIssueInfoFunction: refetchElementIssueInfoFunction,
                    }}>


                        {getSelectorPositionJSX('showcase-a')}
                        <DashboardLayout>

                            <AppBar
                                // @ts-ignore
                                position={absolute ? "absolute" : navbarType}
                                color="inherit"
                                sx={
                                    (theme) => navbar(
                                        theme,
                                        {
                                            transparentNavbar, absolute, light
                                        }
                                    )
                                }
                            >
                                <Toolbar sx={(theme) => navbarContainer(theme)}>
                                    <SoftBox color="inherit" mb={{xs: 1, md: 0}}
                                             sx={(theme: any) => navbarRow(theme, {isMini})}
                                    >
                                        {/*<Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light}/>*/}
                                        <Icon fontSize="medium"
                                            /*sx={navbarDesktopMenu}*/
                                              onClick={handleMiniSidenav}>
                                            {miniSidenav ? "menu_open" : "menu"}
                                        </Icon>
                                        {/*{getSelectorPositionJSX('top-a')}*/}


                                        {/*  <SoftBox pr={1}>
                        <SoftInput
                            placeholder="Type here..."
                            icon={{component: "search", direction: "left"}}
                            label={"id1"}
                            id={"id1"}
                            name={"id1"}
                            type={"text"}
                        />
                    </SoftBox>*/}
                                        {getSelectorPositionJSX('navigation')}
                                    </SoftBox>


                                </Toolbar>
                            </AppBar>

                            <SoftBox
                                display="flex"
                                flexDirection={"row"}
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                {!globalConfigParamObj.isMobile ? getTabListTSX() : getTabListTSX()
                                }
                                {getSelectorPositionJSX('maintop-a')}
                            </SoftBox>

                            <SoftBox mt={2}>
                                {tabValue === 1 && getTabBody1()}
                                {tabValue === 2 && getTabBody2()}
                                {tabValue === 3 && getTabBody3()}
                                {tabValue === 4 && getTabBody4()}

                            </SoftBox>
                            <SoftBox
                                display="flex"
                                flexDirection={"column"}
                                justifyContent="space-evenly"
                                alignItems="flex-start"
                            >
                                <Grid
                                    container
                                    spacing={{xs: 1, md: 2}}
                                >
                                    <Grid
                                        item
                                        xs={12} sm={12} lg={12}
                                    >{getSelectorPositionJSX('mainbottom-a')}</Grid>
                                    <Grid
                                        item
                                        xs={12} sm={12} lg={12}
                                    >{getSelectorPositionJSX('mainbottom-b')}</Grid>
                                    <Grid
                                        item
                                        xs={12} sm={12} lg={12}
                                    >{getSelectorPositionJSX('mainbottom-c')}</Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={{xs: 1, md: 2}}
                                >
                                    <Grid
                                        item
                                        xs={12} sm={12} lg={6}
                                    >{getSelectorPositionJSX('bottom-a')}</Grid>
                                    <Grid
                                        item
                                        xs={12} sm={12} lg={6}
                                    >{getSelectorPositionJSX('bottom-b')}</Grid>
                                </Grid>
                            </SoftBox>
                            {getSelectorPositionJSX('module-footer-a')}
                            {
                                (menuItemObj)
                                    ?
                                    <AfdConfigurator
                                        menuItemObj={menuItemObj}
                                        redirectionObj={redirectionObj}
                                        userInfoObj={userInfoObj}
                                        routeCategory={routeCategory}
                                        selectorTSX={getSelectorPositionJSX('drawer')}
                                    /> : null
                            }
                            {/*TODO*/}
                            {
                                menuItemObj.id != 617 ?
                                    configButtonTSX : null}

                        </DashboardLayout>
                    </CtxClubChapter.Provider>
                    :
                    <AfdLoadingFullpage
                        componentCategory={"RtClubChapter"}
                        componentInfo={''}
                    />
            }
        </>
    )
}

export default RtClubChapter;
