import SoftBox from '../../components/SoftBox';
import React from 'react';
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import IFormFormikGantry from "../../type/form/IFormFormikGantry";
import {useSelector} from "react-redux";
import IRdxState from "../../type/reducer_state/IRdxState";
import IUniversalFormSubmitReponse from "../../type/comprofiler/IUniversalFormSubmitReponse";
import {AfdAlertErrorForm} from "../../component/error_boundary/AfdAlertErrorForm";
import SoftTypography from "../../components/SoftTypography";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Icon from "@mui/material/Icon";


interface IProp {
    formFieldObj: IFormFieldInfo,
    formikGantry: IFormFormikGantry,
    templateModule: string,
}

/* Osobna funkcja dla walidacji pól
    - w przypadku prostych walidacji YUP
    - w przypadku złożonych walidakcji na WIELE PÓL: także YUP, jako standard
        ??? w sumie to <ErrorMessage powinno działać, ale zamiast tego na razie działa to // TODO
 */
function FormNgValidationNoFieldTemplate({
                                         formFieldObj,
                                         formikGantry,
                                         templateModule
                                     }: IProp) {

    const state: IRdxState = useSelector((state: IRdxState) => state);


    /* Tutaj także indywidualna walidacja dla SUCCESS */
    function getTemplateContent() {
       if (
           formFieldObj.ng_validation_no_template ||
           templateModule === 'ELEMENT_FORM_NO_VALIDATION_NEWBB_POST_TITLE'

       )    {
            return (
                <>
                    {formikGantry.errors[formFieldObj.field_name]}
                </>
            );
        }
    }

    let templateTSX = getTemplateContent();

    if (templateTSX
    ) {
        return (
            <SoftBox
                px={{xs: 1, sm: 2, md: 3}}
                py={{xs: 0.5, sm: 0.5, md: 0.5}}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}

            >
                <InfoOutlinedIcon
                    fontSize={"small"}
                    color={"error"}
                    sx={{
                        marginRight: '0.5rem',
                        marginBottom: '0.2rem',
                    }}
                />
                <SoftTypography
                    display={"block"}
                    color={"error"}
                    variant={"caption"}
                >{templateTSX}</SoftTypography>
            </SoftBox>

            /*
            <SoftBox
                px={{xs: 1, sm: 2, md: 3}}
                py={{xs: 1, sm: 1, md: 2}}
            ><SoftAlert color="error"
                        sx={{
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                        }}
            >
                <SoftTypography
                    variant={"button"}
                    fontWeight={"regular"}
                    color={"white"}
                >{templateTSX}
                </SoftTypography>
            </SoftAlert></SoftBox>*/
        );
    } else {
        return null;
    }

}

export default FormNgValidationNoFieldTemplate;
