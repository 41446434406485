import React from 'react';
import axios from "axios";
import RestApiService from "./RestApiService";
import RestApiAmocourseService from "./RestApiAmocourseService";

import {API_URL} from "./RestApiService";
import {useQuery} from "react-query";
import IRQOutputSingle from "../../type/rq/IRQOutputSingle";
import IRQOutputList from "../../type/rq/IRQOutputList";
import {rqOptionStaticObj} from "../rqOptionStaticObj"
import IRQQueryKey from "../../type/rq/IRQQueryKey";
import {IRQOutputGlobalFont, IRQOutputParamRegProvider} from "../../type/rq/IRQOutputLibrary";
import IAxiosPostData from "../../type/axios/IAxiosPostData";
import {bin2hex} from "../../util/Util";
import linkDictionary from "../../linkDictionary";
import {isDev} from "../../util/isDev";
//"Access-Control-Request-Method": 'GET',
//"Access-Control-Request-Headers": 'Origin,X-Requested-With,Content-Type,Accept',
const headers = {
    "Content-Type": 'text/html'
};

//?function_name=get_site_opinion_table&page_item_id=206&sequence=80&object_id=&object2_id=-1&limit_from=0&day_ago_from=0

class RestApiCommonService extends RestApiService {

    constructor() {
        super();
    }


    /*takes JSON with parameters*/
    static getSourcererFunctionUrl(
        sourcerer_function: string,
        sql_column_name: "sql_query1" | "sql_query2" | "sql_query3" | "sql_query4" | "sql_query5" | "sql_query6" | "sql_query7" | "sql_query8" | "sql_query9" | "sql_query10",
        sql_input_obj: {},
    ): string {
        const myUrl = new URL(API_URL);

        myUrl.searchParams.append("sourcerer_function", sourcerer_function);
        myUrl.searchParams.append("sql_column_name", sql_column_name);
        myUrl.searchParams.append("sql_input_obj", encodeURIComponent(JSON.stringify(sql_input_obj)));

        return (myUrl.href);
    }

    /*static rqDebug(x1: any,x2: any,x3: any)   {
        debugLog("RQ" + JSON.stringify(x1) + JSON.stringify(x2) + JSON.stringify(x3));
        return RestApiService.getPromise("xx");
    }
*/
    static getSourcererFunctionRQ(
        sourcerer_function: string,
        sql_column_name: "sql_query1" | "sql_query2" | "sql_query3" | "sql_query4" | "sql_query5" | "sql_query6" | "sql_query7" | "sql_query8" | "sql_query9" | "sql_query10",
        sql_input_obj: {},
        isEnabled: boolean | undefined = true
    ): IRQOutputList {
        let rqQueryKey: any[] = ['getSourcererFunctionRQ', sourcerer_function, sql_column_name, sql_input_obj];
        const {
            isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching
        } = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getSourcererFunctionUrl(queryKey[1], queryKey[2], queryKey[3])),
            {
                ...rqOptionStaticObj,
                enabled: isEnabled
            },
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }


    /*takes JSON with parameters*/
    static getNewControllerQueryUrl(sql_column_name: string,
                                    function_name: string,
                                    page_item_id: number,
                                    sequence: number,
                                    sql_input_obj: any,
                                    limit_from: number | null = null,
                                    day_ago_from: number | null = null,
                                    master_column_name: string | null = null,
                                    child_element_name: string | null = null
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("sql_column_name", sql_column_name);
        myUrl.searchParams.append("controller_name_child", function_name);
        myUrl.searchParams.append("page_item_id", page_item_id.toString());
        myUrl.searchParams.append("sequence", sequence.toString());
        myUrl.searchParams.append("sql_input_obj", encodeURIComponent(JSON.stringify(sql_input_obj)));
        if (limit_from !== null) {
            myUrl.searchParams.append("limit_from", limit_from.toString());
        }
        if (day_ago_from !== null) {
            myUrl.searchParams.append("day_ago_from", day_ago_from.toString());
        }
        if (master_column_name) myUrl.searchParams.append('master_column_name', master_column_name);
        if (child_element_name) myUrl.searchParams.append('child_element_name', child_element_name);

        return myUrl.href;
    }

    static getNewControllerQueryRQ(
        sql_column_name: string,
        function_name: string,
        page_item_id: number,
        sequence: number,
        sql_input_obj: any,
        limit_from: number | null = null,
        day_ago_from: number | null = null,
        master_column_name: string | null = null,
        child_element_name: string | null = null,
        isEnabled: boolean | undefined = true
    ): IRQOutputList {
        let rqQueryKey: any[] = ['getNewControllerQueryRQ',
            sql_column_name,
            function_name,
            page_item_id,
            sequence,
            sql_input_obj,
            limit_from, day_ago_from, master_column_name, child_element_name];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getNewControllerQueryUrl(
                    queryKey[1],
                    queryKey[2],
                    queryKey[3],
                    queryKey[4],
                    queryKey[5],
                    queryKey[6],
                    queryKey[7],
                    queryKey[8],
                    queryKey[9]
                )),
            {
                ...rqOptionStaticObj,
                enabled: isEnabled
            }
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }


    /*https://www.akademia-fotografii-dzieciecej.pl//components/php_restapi/index.php?function_name=get_site_opinion_table&page_item_id=206&sequence=80&object_id=null&object2_id=-1&limit_from=0&day_ago_from=0&sql_column_name=sql_query1*/
    static getElementQueryUrl(
        controllerInfoObj: any,
        sqlInputObj: any,
        limit_from: number,
        day_ago_from: number,
        master_column_name: string | null = null,
        child_element_name: string | null = null,
        sql_column_name: string | null = null
    ): string {

        return RestApiCommonService.getNewControllerQueryUrl(
            (sql_column_name ? sql_column_name : "sql_query1"),
            controllerInfoObj.php_function_code,
            controllerInfoObj.page_item_id,
            controllerInfoObj.sequence,
            sqlInputObj,
            limit_from,
            day_ago_from,
            master_column_name,
            child_element_name
        );
    }

    static getElementQueryRQ(
        controllerInfoObj: any,
        sqlInputObj: any,
        limit_from: number,
        day_ago_from: number,
        master_column_name: string | null = null,
        child_element_name: string | null = null,
        sql_column_name: string | null = null
    ): IRQOutputList {
        let rqQueryKey: any[] = ['getElementQueryRQ', controllerInfoObj, sqlInputObj, limit_from, day_ago_from, master_column_name, child_element_name, sql_column_name];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getElementQueryUrl(
                    queryKey[1], queryKey[2], queryKey[3], queryKey[4], queryKey[5], queryKey[6], queryKey[7])),
            rqOptionStaticObj
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }

    /*https://www.akademia-fotografii-dzieciecej.pl//components/php_restapi/index.php?function_name=get_site_opinion_table&page_item_id=206&sequence=80&object_id=null&object2_id=-1&limit_from=0&day_ago_from=0&sql_column_name=container_sql_query*/
    static getContainerQueryUrl = function (
        controllerInfoObj: any,
        sqlInputObj: any,
        limit_from: number,
        day_ago_from: number): string {


        return RestApiCommonService.getNewControllerQueryUrl(
            "container_sql_query",
            controllerInfoObj.php_function_code,
            controllerInfoObj.page_item_id,
            controllerInfoObj.sequence,
            sqlInputObj,
            limit_from,
            day_ago_from
        );
    }

    static getContainerQueryRQ = function (
        controllerInfoObj: any,
        sqlInputObj: any,
        limit_from: number,
        day_ago_from: number): IRQOutputList {
        let rqQueryKey: any[] = ['getContainerQueryRQ', controllerInfoObj, sqlInputObj, limit_from, day_ago_from];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getContainerQueryUrl(queryKey[1], queryKey[2], queryKey[3], queryKey[4])),
            rqOptionStaticObj
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }


    /*get list of menu items for a specific menu*/
    static getMenuItemListUrl(menutype: string): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("menutype_item_list", menutype);
        return myUrl.href;
    }

    static getMenuItemListRQ(menutype: string): IRQOutputList {
        let rqQueryKey: IRQQueryKey = ['getMenuItemListRQ', menutype];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getMenuItemListUrl(queryKey[1])),
            rqOptionStaticObj
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }

    static getJos4MenuUrl(id: number): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("query_call", 'jos4_menu');
        myUrl.searchParams.append("id", id.toString());
        return myUrl.href;
    }

    static getJos4MenuRQ(id: number | null | undefined): IRQOutputList {
        let rqQueryKey: IRQQueryKey = ['getJos4MenuRQ', id];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getJos4MenuUrl(queryKey[1])),
            {
                ...rqOptionStaticObj,
                enabled: (!!id)
            },
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }


    static getGalleryImageInfoUrl(id: number,
                                  viewer_user_id: number
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("query_call", 'gallery_image_info');
        myUrl.searchParams.append("id", id.toString());
        myUrl.searchParams.append("viewer_user_id", viewer_user_id.toString());
        return myUrl.href;
    }

    static getGalleryImageInfoRQ(id: number,
                                 viewer_user_id: number,
                                 isEnabled: boolean = true
    ): IRQOutputList {
        let rqQueryKey: IRQQueryKey = ['getGalleryImageInfoRQ', id, viewer_user_id];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getGalleryImageInfoUrl(queryKey[1], queryKey[2])),
            {
                ...rqOptionStaticObj,
                enabled: isEnabled
            },
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }

    static getUserInfoUrl(user_id: number): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("query_call", 'user_info');
        myUrl.searchParams.append("user_id", user_id.toString());
        return myUrl.href;
    }

    static getUserInfoRQ(user_id: number): IRQOutputList {
        let rqQueryKey: IRQQueryKey = ['getUserInfoRQ', user_id];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getUserInfoUrl(queryKey[1])),
            rqOptionStaticObj
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }

    static getRedirectionInfoUrl(routeCategory: string, url: string, itemId: number | null = null): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("query_call", 'redirection_info');
        myUrl.searchParams.append("route_category", routeCategory);
        myUrl.searchParams.append("url", encodeURIComponent(url));
        if (itemId !== null) {
            myUrl.searchParams.append("Itemid", itemId.toString());
        }
        return myUrl.href;
    }

    static getRedirectionInfoRQ(routeCategory: string, url: string, itemId: number | null = null): IRQOutputList {
        let rqQueryKey: IRQQueryKey = ['getRedirectionInfoRQ', routeCategory, url, itemId];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getRedirectionInfoUrl(queryKey[1], queryKey[2], queryKey[3])),
            rqOptionStaticObj
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }

    static getSelectorCourseDescUrl(
        itemId: number,
        categoryId: number | undefined,
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("query_call", 'selector_course_desc');
        myUrl.searchParams.append("item_id", itemId.toString());
        myUrl.searchParams.append("category_id", String(categoryId));
        return myUrl.href;
    }

    static getSelectorCourseDescRQ(itemId: number,
                                   categoryId: number | undefined,
    ): IRQOutputList {
        let rqQueryKey: IRQQueryKey = ['getSelectorCourseDescRQ', itemId, categoryId];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getSelectorCourseDescUrl(queryKey[1], queryKey[2])),
            rqOptionStaticObj
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }


    static getGlobalFontUrl(master_font_family?: string,
                            slave_font_family?: string,
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("query_call", 'global_font');
        if (master_font_family) myUrl.searchParams.append("master_font_family", master_font_family);
        if (slave_font_family) myUrl.searchParams.append("slave_font_family", slave_font_family);
        return myUrl.href;
    }

    static getGlobalFontRQ(master_font_family?: string,
                           slave_font_family?: string,): IRQOutputGlobalFont {
        let rqQueryKey: IRQQueryKey = ['getGlobalFontRQ', master_font_family, slave_font_family];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getGlobalFontUrl(queryKey[1], queryKey[2])),
            rqOptionStaticObj
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }

    static getInstructorLetterInfoUrl(instructorLetter?: string) {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("query_call", 'instructor_letter_info');
        if (instructorLetter) {
            myUrl.searchParams.append("instructor_letter", instructorLetter);
        }
        return (myUrl.href);
    }

    static getInstructorLetterInfoRQ(instructorLetter?: string): IRQOutputList {
        let rqQueryKey: IRQQueryKey = ['getInstructorLetterInfoRQ', instructorLetter];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getInstructorLetterInfoUrl(queryKey[1])),
            rqOptionStaticObj
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }

    static getFormFieldInfoUrl(field_group?: string,
                               callback_function?: string,
                               fieldGroupInstanceKey?: string // DUMMY
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("query_call", 'form_field_info');
        if (field_group) {
            myUrl.searchParams.append("field_group", field_group);
        }
        if (callback_function) {
            myUrl.searchParams.append("callback_function", callback_function);
        }
        if (fieldGroupInstanceKey) {
            myUrl.searchParams.append("fieldGroupInstanceKey", fieldGroupInstanceKey);
        }
        return (myUrl.href);
    }

    static getFormFieldInfoRQ(field_group?: string,
                              callback_function?: string,
                              fieldGroupInstanceKey?: string
    ): IRQOutputList {
        let rqQueryKey: IRQQueryKey = ['getFormFieldInfoRQ', field_group, callback_function, fieldGroupInstanceKey];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getFormFieldInfoUrl(queryKey[1], queryKey[2], queryKey[3])),
            rqOptionStaticObj
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }

    //https://www.akademia-fotografii-dzieciecej.pl/components/php_restapi/index.php?query_call=get_amocourse_form_reg_provider_assoc&user_id=714&course_id=10778
    static getAmocourseFormRegProviderAssocUrl(user_id: number,
                                               course_id: number,
                                               is_site_provider: boolean
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("query_call", 'get_amocourse_form_reg_provider_assoc');
        myUrl.searchParams.append("user_id", user_id.toString());
        myUrl.searchParams.append("course_id", course_id.toString());
        myUrl.searchParams.append("is_site_provider", is_site_provider.toString());
        return (myUrl.href);
    }

    static getAmocourseFormRegProviderAssocRQ(user_id: number,
                                              course_id: number,
                                              is_site_provider: boolean
    ): IRQOutputParamRegProvider {
        let rqQueryKey: IRQQueryKey = ['getAmocourseFormRegProviderAssocRQ', user_id, course_id, is_site_provider];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getAmocourseFormRegProviderAssocUrl(queryKey[1], queryKey[2], queryKey[3])),
            rqOptionStaticObj
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }

    static getXxClubPageModule2PageSetsUrl(pageItemId: number): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("query_call", 'xx_club_page_module_2_page_sets');
        myUrl.searchParams.append("page_item_id", pageItemId.toString());
        return (myUrl.href);
    }

    static getXxClubPageModule2PageSetsRQ(pageItemId: number | undefined | null): IRQOutputList {
        let rqQueryKey: IRQQueryKey = ['getXxClubPageModule2PageSetsRQ', pageItemId];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getXxClubPageModule2PageSetsUrl(queryKey[1])),
            {
                ...rqOptionStaticObj,
                enabled: !!pageItemId
            }
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }


    //"https://www.akademia-fotografii-dzieciecej.pl//components/php_restapi/index.php?function_name="+afdCtrlParamCtrlName+"&page_item_id="+afdCtrlParamPageItemid+"&object_id="+afdImageId+"&sequence="+afdCtrlParamCtrlSequence,
    static getUserImageUrl(
        afdCtrlParamCtrlName: string,
        afdCtrlParamPageItemid: string,
        afdCtrlParamCtrlSequence: number,
        afdImageId: number): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("function_name", afdCtrlParamCtrlName);
        myUrl.searchParams.append("page_item_id", afdCtrlParamPageItemid);
        myUrl.searchParams.append("sequence", afdCtrlParamCtrlSequence.toString());
        myUrl.searchParams.append("object_id", afdImageId.toString());
        return (myUrl.href);
    }

    static getUserImageRQ(
        afdCtrlParamCtrlName: string,
        afdCtrlParamPageItemid: string,
        afdCtrlParamCtrlSequence: number,
        afdImageId: number): IRQOutputList {
        let rqQueryKey: IRQQueryKey = ['getUserImageRQ', afdCtrlParamCtrlName, afdCtrlParamPageItemid, afdCtrlParamCtrlSequence, afdImageId];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getUserImageUrl(queryKey[1], queryKey[2], queryKey[3], queryKey[4])),
            rqOptionStaticObj
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }

    static getFunctionSelectResultUrl(functionName: string,
                                      object1Id: string | number | null,
                                      object2Id: string | number | null
    ): string {
        if (object1Id == null) object1Id = '';
        if (object2Id == null) object2Id = '';
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("function_select", functionName);
        myUrl.searchParams.append("object1_id", object1Id.toString());
        myUrl.searchParams.append("object2_id", object2Id.toString());
        return (myUrl.href);
    }

    static getFunctionSelectResultRQ(functionName: string,
                                     object1Id: string | number | null,
                                     object2Id: string | number | null,
                                     isEnabled: boolean = true
    ): IRQOutputList {
        let rqQueryKey: IRQQueryKey = ['getFunctionSelectResultRQ', functionName, object1Id, object2Id];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getFunctionSelectResultUrl(queryKey[1], queryKey[2], queryKey[3])),
            {
                ...rqOptionStaticObj,
                enabled: isEnabled
            },
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }

    static getFunctionSelectPromise(functionName: string,
                                    object1Id: string | number | null = null,
                                    object2Id: string | number | null = null
    ): Promise<any> {
        return RestApiService.getPromise(RestApiCommonService.getFunctionSelectResultUrl(functionName, object1Id, object2Id));
    }


    static getSqlworksheetUrl(
        sqlworksheet_name: string,
        param1: string,
        param2: string
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("query_call", "sqlworksheet");
        myUrl.searchParams.append("sqlworksheet_name", sqlworksheet_name);
        myUrl.searchParams.append("param1", param1);
        myUrl.searchParams.append("param2", param2);

        return myUrl.href;
    }

    static getSqlworksheetRQ(sqlworksheet_name: string,
                             param1: string | number,
                             param2: string | number): IRQOutputList {
        let rqQueryKey: IRQQueryKey = ['getSqlworksheetRQ', sqlworksheet_name, param1, param2];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getSqlworksheetUrl(queryKey[1], queryKey[2], queryKey[3])),
            rqOptionStaticObj
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }

    static setMailSend(afdMailType: string,
                       afdEmail: string,
    ): string {
        var afdEmailHex = bin2hex(encodeURIComponent(afdEmail));
        var insertAndEmailingTimeout = 7000;

        const myUrl = new URL(API_URL);

        myUrl.searchParams.append("custom_call", 'setMailSend');
        myUrl.searchParams.append("mail_type", afdMailType);
        myUrl.searchParams.append("email", afdEmailHex);
        return myUrl.href;

    }

    /* ============================ DML ============================ */

    /* Aktualizuje informacje o zdjęciu
     https://developer.mozilla.org/pl/docs/Web/JavaScript/Referencje/Obiekty/encodeURIComponent
     odkodowuje w PHP: https://www.php.net/manual/en/function.rawurldecode.php
     */

    static setUserImageInfoUpdateUrl(afdImageId: number,
                                     afdImageTitle: string,
                                     afdImageDesc: string,
                                     afdIsOwner: string,
                                     afdIsAuthorized: string,
                                     afdIsCommercialUse: string,
                                     afdIsRodoApperance: string
    ): string {
        afdImageTitle = encodeURIComponent(afdImageTitle);
        afdImageDesc = encodeURIComponent(afdImageDesc);
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("custom_call", 'set_user_image_info_update');
        myUrl.searchParams.append("image_id", afdImageId.toString());
        myUrl.searchParams.append("image_title", afdImageTitle);
        myUrl.searchParams.append("image_desc", afdImageDesc);
        myUrl.searchParams.append("is_owner", afdIsOwner.toString());
        myUrl.searchParams.append("is_authorized", afdIsAuthorized.toString());
        myUrl.searchParams.append("is_commercial_use", afdIsCommercialUse.toString());
        myUrl.searchParams.append("is_rodo_apperance", afdIsRodoApperance.toString());
        return (myUrl.href);
    }

    static setUserImageSocialInfoUpdateUrl(afdImageId: number,
                                           afdSocialInstagramUrl: string
    ): string {
        afdSocialInstagramUrl = encodeURIComponent(afdSocialInstagramUrl);
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("custom_call", 'set_user_image_social_info_update');
        myUrl.searchParams.append("image_id", afdImageId.toString());
        myUrl.searchParams.append("instagram_url", afdSocialInstagramUrl);
        return (myUrl.href);
    }


    /* Musi być USER_ID - tak wymaga API */
    static setUserImageDeleteUrl(afdImageId: number,
                                 afdUserId: number,
                                 unpublishStatusCode: number
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("action_name", 'userImageDelete');
        myUrl.searchParams.append("id", afdImageId.toString());
        myUrl.searchParams.append("user_id", afdUserId.toString());
        myUrl.searchParams.append("unpublish_status_code", unpublishStatusCode.toString());
        return (myUrl.href);
    }


    static setXmMaterializedUserRefresh(
        postObj: any,
    ): IAxiosPostData {

        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: postObj,
        };
        return postDataObj;
    }


    /*Lista zdjęć USERa
    - analogicznie poprzez tabelę CLUB
    - bierze USER_COOKIE_NAME i bierze IMAGE, które może być -1*/
    static getUserImageListUrl(afdCtrlParamCtrlName: string,
                               afdCtrlParamPageItemId: string,
                               afdCtrlParamCtrlSequence: number,
                               afdUserId: number,
                               afdImageId: number
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("function_name", afdCtrlParamCtrlName);
        myUrl.searchParams.append("page_item_id", afdCtrlParamPageItemId);
        myUrl.searchParams.append("object_id", afdUserId.toString());
        myUrl.searchParams.append("sequence", afdCtrlParamCtrlSequence.toString());
        myUrl.searchParams.append("object2_id", afdImageId.toString());
        return (myUrl.href);
    }


    static setActionUrl(afdActionName: string,
                        afdObjectId: number | string,
                        afdUserId?: number,
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("action_name", afdActionName);
        myUrl.searchParams.append("id", afdObjectId.toString());
        if (afdUserId) {
            myUrl.searchParams.append("user_id", afdUserId.toString());
        }
        return (myUrl.href);

    }

    static getFunctionUrl(afdFunctionName: string,
                          afdPageItemId: number,
                          afdSequence: number,
                          afdObjectId: number | string,
                          afdObjectId2: number | string,
                          queryLimitFrom: number,
                          dayAgoFrom: number
    ): string {

        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("function_name", afdFunctionName);
        myUrl.searchParams.append("page_item_id", afdPageItemId.toString());
        myUrl.searchParams.append("sequence", afdSequence.toString());
        myUrl.searchParams.append("object_id", afdObjectId.toString());
        myUrl.searchParams.append("object2_id", afdObjectId2.toString());
        myUrl.searchParams.append("limit_from", queryLimitFrom.toString());
        myUrl.searchParams.append("day_ago_from", dayAgoFrom.toString());
        return (myUrl.href);

    }


    static getClientNgContainerActionListUrl(
        php_procedure_name: string,
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("query_call", 'getClientNgContainerActionList');
        myUrl.searchParams.append("php_function_code", php_procedure_name);
        return (myUrl.href);
    }

    static getFieldSelectUrl(
        cName: string,
        tName: string,
        wName: string,
        oName: string
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("field_select", tName);
        myUrl.searchParams.append("cname", cName);
        myUrl.searchParams.append("wname", wName);
        myUrl.searchParams.append("oname", oName);
        return (myUrl.href);
    }

    static setFieldUpdateUrl(
        cName: string,
        tName: string,
        wName: string,
        oName: string,
        vName: string
    ): string {
        vName = vName.replace(/\n\r?/g, '|');
        vName = encodeURIComponent(vName);
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("field_update", tName);
        myUrl.searchParams.append("cname", cName);
        myUrl.searchParams.append("wname", wName);
        myUrl.searchParams.append("oname", oName);
        myUrl.searchParams.append("vname", vName);
        return (myUrl.href);
    }


    static setUserCommentInsertUrl(
        commentUserId: number,
        userImageId: number,
        commentText: string,
        origCommentId: number | null,
        heartId?: number | null,
    ): string {
        commentText = encodeURIComponent(commentText);
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("user_comment_insert", '1');
        myUrl.searchParams.append("comment_user_id", commentUserId.toString());
        myUrl.searchParams.append("image_id", userImageId.toString());
        myUrl.searchParams.append("comment_text", commentText);
        myUrl.searchParams.append("orig_comment_id", origCommentId ? origCommentId.toString() : '');
        myUrl.searchParams.append("heart_id", heartId ? heartId.toString() : '');
        return (myUrl.href);
    }

    static getVoucherSosNameInfoUrl(
        afdSosName: string
    ): string {
        afdSosName = encodeURIComponent(afdSosName);
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("getVoucherSosNameInfo", '1');
        myUrl.searchParams.append("sosname", btoa(afdSosName));
        return (myUrl.href);
    }

    static getCourseEventInfoUrl(
        categoryId: string
    ): string {
        categoryId = encodeURIComponent(categoryId);
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("getCourseEventInfo", '1');
        myUrl.searchParams.append("category_id", categoryId.toString());
        return (myUrl.href);
    }

    /*static getUserImageRQ(
        afdCtrlParamCtrlName: string,
        afdCtrlParamPageItemid: string,
        afdCtrlParamCtrlSequence: number,
        afdImageId: number): IRQOutputList {
        let rqQueryKey: IRQQueryKey = ['getUserImageRQ', afdCtrlParamCtrlName, afdCtrlParamPageItemid, afdCtrlParamCtrlSequence, afdImageId];
        const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
            rqQueryKey,
            ({queryKey}) =>
                RestApiService.getPromise(RestApiCommonService.getUserImageUrl(queryKey[1], queryKey[2], queryKey[3], queryKey[4])),
            rqOptionStaticObj
        );
        return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
    }*/
    static getChatbotAssistantResponse(
        endpointName: "assistant_tutorial_glossary" | "assistant_photo_advisor" | "assistant_site_registration" | "assistant_club_account" | "assistant_photo_inspire",
        model: string,
        prompt_text: string,
        thread_id: string,
        isDev: boolean
    ): string {
        prompt_text = encodeURIComponent(prompt_text);
        let myUrl = new URL("https://pythonproject2.azurewebsites.net/" + endpointName);
        if (isDev)    {
            myUrl = new URL("http://127.0.0.1:5000/" + endpointName);
        }

        myUrl.searchParams.append("model", model);
        myUrl.searchParams.append("prompt_text", prompt_text);
        myUrl.searchParams.append("thread_id", thread_id);
        return (myUrl.href);
    }

            static getChatbotAssistantResponseRQ(
                endpointName: "assistant_tutorial_glossary" | "assistant_photo_advisor" | "assistant_site_registration" | "assistant_club_account" | "assistant_photo_inspire",
                model: string,
                prompt_text: string,
                thread_id: string,
                isDev: boolean
            ): IRQOutputList {
                let rqQueryKey: IRQQueryKey = ['getChatbotAssistantResponseRQ', endpointName, model, prompt_text, thread_id];
                const {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching} = useQuery(
                    rqQueryKey,
                    ({queryKey}) =>
                        RestApiService.getPromise(RestApiCommonService.getChatbotAssistantResponse(queryKey[1], queryKey[2], queryKey[3], queryKey[4], queryKey[5])),
                    rqOptionStaticObj
                );
                return {isLoading, isError, data, error, isIdle, refetch, isFetching, isRefetching};
            }


    static setDictRatingUrl(
        rating: number,
        afdGroupId: number,
        afdDictCode: string,
        afdInstructorUserId: number,
        afdCurrentUserId: number
    ): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("custom_call", 'set_forum_dict_rating_insert');
        myUrl.searchParams.append("dict_code", afdDictCode);
        myUrl.searchParams.append("user_id", afdCurrentUserId.toString());
        myUrl.searchParams.append("group_id", afdGroupId.toString());
        myUrl.searchParams.append("rating_value", rating.toString());
        myUrl.searchParams.append("instructor_user_id", afdInstructorUserId.toString());
        return (myUrl.href);
    }

    static getModuleContentUrl(module_title: string): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("query_call", "module_content");
        myUrl.searchParams.append("module_title", module_title);

        return myUrl.href;
    }

    static setDbLongTrackAdapterUrl(
        adapterClassName: string,
        procedureName: string,
        userId: number,
        id: number
    ): IAxiosPostData {

        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: {
                "set_db_long_track_adapter": 1,
                "adapter_class_name": adapterClassName,
                "procedure_name": procedureName,
                "user_id": userId,
                "id": id

            }
        }
        return postDataObj;
    }



    static setSiteContactUsInsertUrl(
        user_email: string,
        user_id: number,
        message_title: string,
        message_text: string,
        image_id: number,
        group_id: number,
        link_url: string
    ): IAxiosPostData {
        const myUrl = new URL(API_URL);
        var afdEmailHex = bin2hex(encodeURIComponent(user_email));

        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: {
                "set_site_contact_us_insert": 1,
                "user_email": afdEmailHex,
                "user_id": user_id,
                "message_title": message_title,
                "message_text": message_text
            }
        }
        if (image_id > 0) postDataObj.postData["image_id"] = image_id;
        if (group_id > 0) postDataObj.postData["group_id"] = group_id;
        if (link_url) postDataObj.postData["link_url"] = link_url;

        return postDataObj;
    }



}

export default RestApiCommonService;
