import React from 'react';
import IUserInfo from "../../type/IUserInfo";
import IJos4Menu from "../../type/IJos4Menu";
import IRedirectionInfo from "../../type/IRedirectionInfo";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import parse from "html-react-parser";
import Divider from "@mui/material/Divider";
import AfdConfiguratorUser from "./component/AfdConfiguratorUser";
import AfdConfiguratorApp from "./component/AfdConfiguratorApp";
import AfdConfiguratorMenu from "./component/AfdConfiguratorMenu";
import AfdVertButtonGroup from "./component/AfdVertButtonGroup";


interface IProp {
    html: string,
    handleCloseFunction: () => void,
    userInfoObj: IUserInfo;
    menuItemObj: IJos4Menu;
    redirectionObj?: IRedirectionInfo;
    routeCategory: string;
}


function AfdConfiguratorAdminPanel(
    {
        html,
        handleCloseFunction,
        userInfoObj,
        menuItemObj,
        redirectionObj,
        routeCategory
    }: IProp
) {
    return <>

        <SoftBox>
            <SoftTypography variant="h5">AFD Konfigurator</SoftTypography>
            <SoftTypography variant="body2" color="text"
                            style={{display: "inline-block", whiteSpace: "pre-line"}}
            >
                Strona: "{parse(html)}"
            </SoftTypography>
        </SoftBox>


        <Divider/>
        <SoftBox pt={1.25} pb={1} px={3}>
            <AfdConfiguratorUser userInfoObj={userInfoObj}/>
        </SoftBox>
        <SoftBox pt={1.25} pb={1} px={3}>
            <AfdConfiguratorApp menuItemObj={menuItemObj} redirectionObj={redirectionObj}/>
        </SoftBox>
        <SoftBox pt={1.25} pb={1} px={3}>
            <AfdConfiguratorMenu menuItemObj={menuItemObj} routeCategory={routeCategory}
                                 redirectionObj={redirectionObj}/>
        </SoftBox>

        {/*        <AfdColorSelect
          dispatchFunction={rdxConfigDispatchFunction}
            sidenavColor={sidenavColor}
        />*/}
        {/*        <AfdSidenavSelect
            transparentSidenav={transparentSidenav}
            handleTransparentSidenav={handleTransparentSidenav}
            handleWhiteSidenav={handleWhiteSidenav}
            disabled={disabled}
        />*/}
        <Divider/>

        {/*  <AfdCheckboxSelect
            title="Mini Sidenav"
            checked={miniSidenav}
            handleChecked={handleMiniSidenav}
            />*/}

        <AfdVertButtonGroup buttonList={
            [{
                href: "https://www.creative-tim.com/product/soft-ui-dashboard-pro-react",
                target: "_blank",
                rel: "noreferrer",
                color: "info",
                variant: "gradient",
                title: "buy now"
            }
            ]}
        />


        {/*    <AfdThankYou title={"Zarekomenduj nas"} buttonList={
            [{
              title: "Twitter",
                href: "https://www.creative-tim.com/product/soft-ui-dashboard-pro-react",
                target: "_blank",
                rel: "noreferrer",
                  color: "info",
              socialType: "twitter",
            },
                {
                    title: "Facebook",
                    href: "https://www.creative-tim.com/product/soft-ui-dashboard-react",
                    target: "_blank",
                    rel: "noreferrer",
                    color: "dark",
                    socialType: "facebook",
                }]
        }
        />*/}


        {/*        <SoftBox display="flex" justifyContent="center">
          <a
            className="github-button"
            href="https://github.com/creativetimofficial/ct-soft-ui-dashboard-pro-react"
            data-icon="octicon-star"
            data-size="large"
            data-show-count="true"
            aria-label="Star creativetimofficial/ct-soft-ui-dashboard-pro-react on GitHub"
          >
            Star
          </a>
        </SoftBox>*/}
    </>;
}

export default AfdConfiguratorAdminPanel;
