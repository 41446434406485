// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import React, {useState} from "react";

// Soft UI Dashboard PRO React components
import Switch from "@mui/material/Switch";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import HTMLReactParser from "html-react-parser";
import parse from "html-react-parser";
import { HTMLReactParserOptions, Element } from 'html-react-parser';
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import { setTextSquareTagToDiv } from "../../util/UtilReact";
import {ErrorMessage, Field} from "formik";
import SoftAvatar from "../../components/SoftAvatar";
import Divider from "@mui/material/Divider";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../hook/useCHGetContextSoftUI";

/*https://mui.com/material-ui/react-switch/*/

function ElementGlobalNewsletterPrefMini(
    {
        formFieldObj,
        formikGantry,
        parserOptions
    }: IFormFieldProp
) {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const [answersPost, setAnswersPost] = useState(false);

    var formFieldTip= (formFieldObj.field_tip ?
        setTextSquareTagToDiv(formFieldObj.field_tip) : "");
    let linkDescription = (formFieldObj.link_description ? parse(formFieldObj.link_description) : "");
    let contentInstruction = (formFieldObj.content_instruction ? parse(formFieldObj.content_instruction) : "");

    /*var htmlToReact = function() {
        if (formFieldObj.field_tip) {
            return HTMLReactParser(formFieldObj.field_tip);
        }
    }
    var fieldTip = htmlToReact();*/

    /*https://www.npmjs.com/package/html-react-parser*/

    return (
        <>
            <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
            >
                <SoftBox display={globalConfigParamObj.isMobile ? "block" : "flex"}
                         alignItems="center">
                    <SoftBox
                        mr={{ xs: 2, md: 2, lg: 0 }}
                        sx={
                        globalConfigParamObj.isMobile ? {
                            float: "left",
                            } : {}
                    }
                    ><SoftAvatar src={formFieldObj.image_src}
                                         alt="Slack logo"
                                         variant="rounded"
                                         size="xl"
                        /*sx={{
                            display: "none",
                        }}*/
                    /></SoftBox>
                    <SoftBox ml={{ xs: 1, md: 2, lg: 2 }}
                             lineHeight={0}>
                        {/*<SoftAvatar src={formFieldObj.image_src}
                                    alt="Slack logo"
                                    variant="rounded"
                                    size="xl"
                        />*/}
                        <SoftTypography variant="h5" fontWeight="medium">
                            {formFieldObj.item_name}
                        </SoftTypography>
                        <SoftTypography variant="button" color="text" fontWeight="regular">
                            <SoftBox>

                                {linkDescription}</SoftBox>
                            <SoftBox>{contentInstruction}</SoftBox>
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>

                {/*form CHECKBOX / SWTICH*/}
                <SoftBox
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: "100%", sm: "auto" }}
                    mt={{ xs: 1, sm: 0 }}
                >
                    <SoftBox lineHeight={0} mx={2}>
                        <SoftTypography variant="button" color="text" fontWeight="regular">
                            {parse(
                                formFieldTip,
                                parserOptions
                            )
                            }
                        </SoftTypography>
                    </SoftBox>

                    <SoftBox mr={1}
                             display={"flex"}
                                alignItems={"center"}
                    >
                        {/* TOOD -  nie działa w success - otrzymuje: Received `true` for a non-boolean attribute `success`.

                        !formikGantry.errors[formFieldObj.field_name]*/}
                        <Field
                            name={formFieldObj.field_name} as={Switch}
                            value={"on"} // must be STRING to write to DOM
                            error={formikGantry.errors[formFieldObj.field_name] &&
                                formikGantry.touched[formFieldObj.field_name]
                        }
                            success={
                                (formikGantry.values[formFieldObj.field_name] &&
                                formikGantry.values[formFieldObj.field_name].length > 0 ? "true" : "false")
                            }

                        />
                        <SoftTypography variant={"caption"} color={"error"}>
                            <ErrorMessage name={formFieldObj.field_name}/>
                        </SoftTypography>

                        {(formFieldObj.field_is_required === 'true') ?
                            <SoftBox ml={1}><SoftTypography variant={"h5"} color={"error"}>
                                *
                            </SoftTypography></SoftBox>
                            : null
                        }

                    </SoftBox>
                </SoftBox>
            </SoftBox>
            <Divider />



{/*
            <SoftBox pt={1.5} pb={2} px={2} lineHeight={1.25}>
                <SoftBox display="flex" py={1} mb={0.25}>
                    <SoftBox mt={0.25}>

                        <Field
                            name={formFieldObj.field_name} as={Switch}
                            error={formikGantry.errors[formFieldObj.field_name] && formikGantry.touched[formFieldObj.field_name]}
                            success={
                                formikGantry.values[formFieldObj.field_name] &&
                                formikGantry.values[formFieldObj.field_name].length > 0 &&
                                !formikGantry.errors[formFieldObj.field_name]}
                        />

                    </SoftBox>
                    <SoftBox width="80%" ml={2}>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                            {parse(
                                formFieldTip,
                                parserOptions
                            )
                            }
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            </SoftBox>*/}

            {/*
           <div className="divTable">


               <div className="divTableBody">

                   <div className="divTableRow">
                       <div className="divTableCell content_front_image"><img alt="image" className="lozad" src=""/>
                           {row.item_name}
                       </div>
                   </div>

                   <div className="divTableRow">
                       <div className="divTableCell content_full">
                           &lt;label for="{row.field_name}"&gt;{row.field_label}&lt;/label&gt;
                           &lt;input id="{row.field_name}" className="{row.field_css_class_list}" name="{row.field_name}"
                           ng-required="{row.ng_required}" ng-model="formArray.{row.field_name}"
                           ng-init="{row.ng_init_function_name}('{row.field_group}', '{row.field_name}')"
                           type="{row.field_type}" value="{row.field_default_value}" /&gt;<span
                           className="content_instruction">
</span>
                           <blockquote>{row.link_description}</blockquote>
                           {row.content_instruction}&lt;/span&gt;
                       </div>
                   </div>

               </div>


           </div>*/}

        </>
    );
}

/*// typechecking props for FormField
ElementGlobalNewsletterPrefMini.propTypes = {
  field_label: PropTypes.string.isRequired,
};*/

export default ElementGlobalNewsletterPrefMini;


