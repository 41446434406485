import {createContext} from "react";
import ICtxYupSchema from "../type/context/ICtxYupSchema";
import IElementNewbbTopicPost from "../type/newbb/IElementNewbbTopicPost";
import IElementForumDecodeInfoCurrent from "../type/newbb/IElementForumDecodeInfoCurrent";
import IElementNewbbTopicInfo from "../type/newbb/IElementNewbbTopicInfo";
import IElementNewbbUniForumInfo from "../type/newbb/IElementNewbbUniForumInfo";
import IElementNewbbAnchorPost from "../type/newbb/IElementNewbbAnchorPost";
import {IOrderByCode} from "../gantry/rt-club-newbb";
import {formikInitialValueObj} from "../form/formikInitialValueObj";
/*
    The problem here is that you need to help TypeScript understand the types of the objects you're dealing with.
    https://blog.logrocket.com/how-to-use-react-context-typescript/
 */

const defaultYupContext: ICtxYupSchema = {
    storeValidationSchemaShapeObj: {},
    setStoreValidationSchemaShapeObj: () => {},
    storeFormikInitialValueObj: formikInitialValueObj,
    setStoreFormikInitialValueObj: () => {},
};


const CtxYupSchemaObj = createContext<ICtxYupSchema>(defaultYupContext)
//const AppObjContext = createContext(null);

export default CtxYupSchemaObj;
