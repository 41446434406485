import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

type colorsEnum = "inherit | primary" | "secondary" | "success" | "info" | "warning" | "dark" | "text" | "light" | "white";
type fontWeightEnum = "false | light" | "regular" | "medium" | "bold";
type textTransformEnum = "none" | "capitalize" | "uppercase" | "lowercase";
type verticalAlignEnum = "unset | baseline" | "sub" | "super" | "text-top" | "text-bottom" | "middle | top" | "bottom";



  /*"inherit",
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "error",
  "light",
  "dark",
  "text",
  "white",
}
*/
type colorsEnumType = {
  [key in keyof colorsEnum]: string;
}

            /*

            // Setting default values for the props of SoftTypography


            // Typechecking props for the SoftTypography
            SoftTypography.propTypes = {
              color: PropTypes.oneOf([
                "inherit",
                "primary",
                "secondary",
                "info",
                "success",
                "warning",
                "error",
                "light",
                "dark",
                "text",
                "white",
              ]),
              fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold"]),
              textTransform: PropTypes.oneOf(["none", "capitalize", "uppercase", "lowercase"]),
              verticalAlign: PropTypes.oneOf([
                "unset",
                "baseline",
                "sub",
                "super",
                "text-top",
                "text-bottom",
                "middle",
                "top",
                "bottom",
              ]),
              textGradient: PropTypes.bool,
              children: PropTypes.node.isRequired,
              opacity: PropTypes.number,
            };
            */


/*https://www.creative-tim.com/learning-lab/react/typography/soft-ui-dashboard/*/
interface ISoftTypographyAttr {
    align?: string;
    color?: "inherit" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "text" | "white";
    display?: string;
    gutterBottom?: boolean;
    fontWeight?: 'false' | "light" | "regular" | "medium" | "bold";
    textTransform?: "none" | "capitalize" | "uppercase" | "lowercase";
    verticalAlign?: "unset" | "baseline" | "sub" | "super" | "text-top" | "text-bottom" | "middle" | "top" | "bottom";
    lineHeight?: number;
    fontStyle?: string;
    textGradient?: boolean;
    opacity?: number;
    component?: any;
    sx?: any;
    to?: string;
    p?: number;
    pb?: number | {};
    pt?: number | {};
    pl?: number | {};
    pr?: number | {};
    py?: number | {};
    px?: number | {};
    mx?: number | {};
    my?: number | {};
    mt?: number | {};
    mr?: number | {};
    ml?: number | {};
    mb?: number | {};
    bottom?: number | {};
    top?: number | {};
    left?: number | {};
    right?: number | {};
    borderBottomLeftRadius?: any;
    variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "button" | "caption" | "overline" | "inherit" | IResp;
    onClick?: any;
    href?: string;
    target?: string;
    rel?: string;
    children: any;
    rest?: any;
    className?: string;
    style?: any;
    position?: string;
};

interface ISoftTypography {
  ownerState: ISoftTypographyAttr;
  ref: any;
}

// Custom styles for SoftTypography
import SoftTypographyRoot from "./SoftTypographyRoot";
import IResp from "../../type/resp/IResp";

/*SoftTypography.defaultProps = {
  color: "dark",
  fontWeight: false,
  textTransform: "none",
  verticalAlign: "unset",
  textGradient: false,
  opacity: 1,
};*/

// TOOD: default font-weight
const SoftTypography = forwardRef(
  (
    { color = "dark",
        fontWeight = 'false',
        textTransform = "none",
        verticalAlign = "unset",
        textGradient = false,
        opacity = 1,
        children,
        ...rest }: ISoftTypographyAttr,
    ref: any
  ) => (
    <SoftTypographyRoot
      {...rest}
      ref={ref}
        // @ts-ignore
      ownerState={{ color, textTransform, verticalAlign, fontWeight, opacity, textGradient }}
    >
      {children}
    </SoftTypographyRoot>
  )
);

export default SoftTypography;
