import useCHAddData from "../../../../hook/useCHAddData";

const ElementMainpageMessage = React.lazy(() => import("./ElementMainpageMessage")); // page_item_id: 1

import React, {useEffect, useState} from "react";
import {lazy, Suspense} from "react";
import Emptybox from "../../../emptybox/Emptybox";
import AfdLoadingFullpage from "../../../AfdLoadingFullpage";
import AfdContainerCard from "../../../AfdContainerCard";

import useCHContainerData from "../../../../hook/useCHContainerData";

import IPropContainer from "../../../../type/IPropContainer";

import Grid from "@mui/material/Grid";

import GoogleFontLoader from 'react-google-font-loader';

import parse, {Element} from "html-react-parser";
import RestApiCommonService from "../../../../service/restapi/RestApiCommonService";
import {processElement} from "../../../../util/UtilReact";
import IGlobalFont from "../../../../type/IGlobalFont";
import IRQOutputSingle from "../../../../type/rq/IRQOutputSingle";
import IRQOutputList from "../../../../type/rq/IRQOutputList";
import {IRQOutputGlobalFont} from "../../../../type/rq/IRQOutputLibrary";


/*ng-click="psedudoScrollNextPage()"*/
//{containerRow.post_count}
const GetSiteMainpageMessage = ({
                                    controllerInfoObj: controllerInfoObj,
                                    userInfoObj: userInfoObj,
                                    paramObj: paramObj
                                }: IPropContainer) => {
    const components: any = {
        ElementMainpageMessage,

    }
    let ElementComponent: React.JSXElementConstructor<any>;

    const rqNewControllerQuery: IRQOutputList = RestApiCommonService.getNewControllerQueryRQ(
        'sql_query2',
        controllerInfoObj.php_function_code,
        controllerInfoObj.page_item_id,
        controllerInfoObj.sequence,
        {},
        null,
        null,
        null,
        null
    );
    const rqGlobalFont: IRQOutputGlobalFont = RestApiCommonService.getGlobalFontRQ(
        undefined, /* 'Playfair Display', */
        'Roboto',
    );


    /*
         TODO: session data analytics
         ("/administrator/components/php_scripts/sourcerer/analytics/get_google_analytics_utils.php");
        var session =  JSession.getInstance();*/
    var is_landing_price_only = paramObj.position_param0;
    var session_mainpage_message_dimension = paramObj.position_param3;
    if (!session_mainpage_message_dimension) session_mainpage_message_dimension = 'MESSAGE_' + controllerInfoObj.page_item_id;


    if (!is_landing_price_only) is_landing_price_only = 'N'; //&is_price_only


    if (controllerInfoObj.element1CamelName !== undefined) {
        ElementComponent = components[controllerInfoObj.element1CamelName];
        var [elementPageGridObjList, getElementPageGridObjList, containerInfoObj, setContainerInfoObj] = useCHContainerData(controllerInfoObj,
            {
                session_mainpage_message_dimension: session_mainpage_message_dimension,
                page_itemid: controllerInfoObj.page_item_id
            });

    }

    /* DZIANIE:
    Repozytorium wszystkich messages jest w PAGE_GRID
        - ewentualnie dane SEASON pdmieniane przez PARSER
        - nie ma nic na CONST_PARAM

    1: zmieniono na mniej wydajne: zawsze pobieraj CONSTRAINT ponieważ 90% przypadków jest - ale bez zależności CONSTRAINT od pieerwszego zapytania page_grid
    2: W przypadku reklam skierowanych do osób, które znają dobrze ofertę
    - chcemy na MOBILE mieć cenę na pierwszym, max drugim ekranie
    - problem  BOUNCE > 90% */
    /* Pobierz aktualne CONSTRAINT
        */
    const [elementSeasonNameObjList, setElementSeasonNameObjList] = useState<any>([]);
    /*https://www.npmjs.com/package/react-google-font-loader*/
    const [elementGlobalFontObj, setElementGlobalFontObj] = useState<IGlobalFont>();

    useEffect(() => {
        if (!rqGlobalFont.isLoading &&
            !rqNewControllerQuery.isLoading) {
            setElementSeasonNameObjList(processElement(rqNewControllerQuery.data.data, controllerInfoObj));
            setElementGlobalFontObj(rqGlobalFont.data.data);
        }
        ;
    }, [
        rqGlobalFont.isLoading ? 1 : 0,
        rqNewControllerQuery.isLoading ? 1 : 0,
    ]);

    /* OLD: OVERRIDE - kwarantanna
    - w sumie niewykorzystywana ponieważ in tak lepiej TIME CONSTRAINT + inne zdjęcia
    */

    /* if ($override_season_welcome_title)
        $season_name_row['season_welcome_title'] = $override_season_welcome_title;
    else
        $season_name_row['season_welcome_title'] = $season_name_row['season_welcome_title_auto'];
    */

    function getContainerContent() {

        let row = containerInfoObj;

        const seasonParserOptions = {
            replace: (domNode: any) => {
                if (domNode instanceof Element) {
                    if (domNode.attribs.content === 'SEASON_WELCOME_TITLE2') {
                        return <span>{parse(elementSeasonNameObjList[0].season_welcome_title_auto)}</span>;
                    }
                    if (domNode.attribs.content === 'MARKETING_SEASON_INTRO') {
                        return <span>{parse(elementSeasonNameObjList[0].marketing_season_intro)}</span>;
                    }
                    if (domNode.attribs.content === 'CONSTRAINT_INTRO_DESC') {
                        return <span>{parse(elementSeasonNameObjList[0].constraint_intro_desc)}</span>;
                    }

                }
            }
        }


        if (controllerInfoObj.containerName === 'CONTAINER_SITE_MAINPAGE_MESSAGE'
            && elementGlobalFontObj
        ) {
            return (
                <>
                    <GoogleFontLoader
                        fonts={[
                            {
                                font: elementGlobalFontObj.master_font_family,
                                weights: [400],
                            },
                            {
                                font: elementGlobalFontObj.slave_font_family,
                                weights: [400],
                            },
                        ]}
                    />
                    <ElementComponent
                        elementPageGridObj={elementPageGridObjList ? elementPageGridObjList[0] : undefined}
                        paramObj={paramObj}
                        controllerInfoObj={controllerInfoObj}
                        seasonParserOptions={seasonParserOptions}
                        elementGlobalFontObj={elementGlobalFontObj}
                    />
                </>
            );
        }
    }

    return (
        <>
            <Suspense fallback={<AfdLoadingFullpage
                componentCategory="AfdController_Suspense"
                controllerInfoObj={controllerInfoObj}
            />}>
            </Suspense>
            {
                (elementSeasonNameObjList?.length > 0
                    /*&& elementPageGridObjList.length > 0*/
                    && elementGlobalFontObj
                    && containerInfoObj) ?
                    (
                        <AfdContainerCard
                            controllerInfoObj={controllerInfoObj}
                            userInfoObj={userInfoObj}
                            paramObj={paramObj}
                            elementObjList={elementSeasonNameObjList}
                        >
                            {getContainerContent()}
                        </AfdContainerCard>
                    ) : (<Emptybox
                        controllerInfoObj={controllerInfoObj}
                        userInfoObj={userInfoObj}
                        paramObj={paramObj}
                        elementObjList={elementSeasonNameObjList}
                    />)
            }
        </>
    );
}

export default GetSiteMainpageMessage;
