import React, {useEffect, useState} from "react";
import { lazy , Suspense } from "react";
import RestApiService from "../../service/restapi/RestApiService";
import RestApiCommonService from "../../service/restapi/RestApiCommonService";
import {camelize, capitalize} from "../../util/Util";
import {processContainer, processElement} from "../../util/UtilReact";
import Emptybox from "../emptybox/Emptybox";
import AfdLoadingFullpage from "../AfdLoadingFullpage";
import AfdContainerCard from "../AfdContainerCard";

import Grid from "@mui/material/Grid";
import SoftBox from "../../components/SoftBox";
import RokContainerTitle from "../../title/RokContainerTitle";
import Card from "@mui/material/Card";
import SoftTypography from "../../components/SoftTypography";
import DefaultItem from "../../examples/Items/DefaultItem";
import DataTable from "../../examples/Tables/DataTable";
import ProductCell from "../../layouts/ecommerce/overview/components/ProductCell";
import SoftButton from "../../components/SoftButton";
import {Link} from "react-router-dom";
import parse from "html-react-parser";
import SoftAvatar from "../../components/SoftAvatar";
import useCHSourcererData from "../../hook/useCHSourcererData";


const GetBusymumCalendar = ({
                                 controllerInfoObj: controllerInfoObj,
                                 userInfoObj: userInfoObj,
                                 paramObj: paramObj
}: any) => {
    var containerDefaultRow: any;

    /*Pobierz dane element*/
    var [elementObjList] = useCHSourcererData(
        'get_busymum_calendar',
        'sql_query1',
        {
        });

    function getContainerContent()  {

        let dataTableData = {
            columns: [
                { Header: "", accessor: "image_thumb_src", width: "7%" },
                { Header: "Sezon", accessor: "season_name", width: "17%" },
                { Header: "Daty od", accessor: "date_from", width: "17%" },
                { Header: "", accessor: "season_desc", width: "20%"  },
            ],
            rows:
                elementObjList.map((
                    {
                        image_thumb_src,
                        season_name,
                        date_from,
                        date_to,
                        season_desc,
                    }
                        : any) => {
                    return {
                        image_thumb_src: <SoftAvatar src={image_thumb_src} alt={season_name} />,
                        season_name: <>
                            {season_name}
                            </>,
                        date_from: <SoftBox
                            display="flex"
                            flexDirection={"column"}
                        >
                            <SoftBox>{date_from}</SoftBox>
                            <SoftBox>{date_to}</SoftBox>
                        </SoftBox>,
                        season_desc: <SoftBox
                            sx={{
                                maxWidth: "90%", /* BUGFIX */
                                wordBreak: "break-word",
                            }}
                        >
                            <SoftTypography variant="caption" fontWeight="light">
                            {parse(season_desc)}
                            </SoftTypography>
                            </SoftBox>,
                    }
                    /*product: <ProductCell image={nikeV22} name="Nike v22 Running" orders={8.232} />,
                        value: <DefaultCell>$130.992</DefaultCell>,*/
                })
        }


            return (
                <>
                    {/*<RokContainerTitle>Kalendarz trybu "Zajęta Mama":</RokContainerTitle>*/}

                        <SoftBox pt={2} px={2}>
                            <SoftTypography variant="h6" fontWeight="medium">
                                Kalendarz trybu "Zajęta Mama":
                            </SoftTypography>
                            <SoftTypography
                                variant={"body2"}
                            >
                                Tryb zajęta Mama dostępny jest bezpłatnie w następujących okresach roku.
                                </SoftTypography>

                        </SoftBox>
                                <SoftBox py={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <SoftBox pt={3} px={3}>
{/*                                                <SoftTypography variant="h6" fontWeight="medium">
                                                    {controllerInfoObj.module_title}
                                                </SoftTypography>*/}
                                            </SoftBox>
                                            <SoftBox py={1}
                                            >
                                                <DataTable
                                                    table={dataTableData}
                                                    /*entriesPerPage={false}*/
                                                    canSearch={false}
                                                    showTotalEntries={false}
                                                    isSorted={false}
                                                    noEndBorder

                                                />
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </SoftBox>
                </>
            );


    }

    return (
        <>
        <Suspense fallback={ <AfdLoadingFullpage
            componentCategory="AfdController_Suspense"
            controllerInfoObj={controllerInfoObj}
        />}>
        </Suspense>
            {
                (elementObjList?.length > 0) ?
                            getContainerContent()
                     : (<Emptybox
                        controllerInfoObj={controllerInfoObj}
                        userInfoObj={userInfoObj}
                        paramObj={paramObj}
                        elementObjList={elementObjList}
                    />)
            }
        </>
    );
}

export default GetBusymumCalendar;
