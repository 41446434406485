// Soft UI Dashboard PRO React context provider
import {useMemo, useReducer} from "react";
import rdxConfigReducerFunction from "../redux_reducer/rdxConfigReducerFunction";
import {CtxSoftUIGlobalSetupByRdx} from "../context/CtxSoftUIGlobalSetupByRdx";
import ICtxReducerState from "../type/reducer_state/ICtxReducerState";



function ProviderGlobalConfigCtxWithReducer({ children }: any) {
  const reducerInitialState: ICtxReducerState = {
    miniSidenav: false,
    transparentSidenav: true,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    isMobile: undefined
  };

    /* Mistrzowski ruch!: weź REDUCER i zapakuj do Context
    - zpakuj do Contextu: 1) stan początkowy 2) funkcję redukującą
    - wysyłaj zmiany i odbieraj od razu przez Context
    * */
    const [controllerParamObj, rdxConfigDispatchFunction] = useReducer<any>(rdxConfigReducerFunction, reducerInitialState);
    const contextValue
        = useMemo(
        () => [controllerParamObj, rdxConfigDispatchFunction],
        [controllerParamObj, rdxConfigDispatchFunction]
    );

  // @ts-ignore
  return <CtxSoftUIGlobalSetupByRdx.Provider value={contextValue}>{children}</CtxSoftUIGlobalSetupByRdx.Provider>;
}

export {ProviderGlobalConfigCtxWithReducer};
