import React, {useState} from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";
// import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../../components/SoftBox";

// Soft UI Dashboard PRO React example components
import AfdDefaultNavbarMobileLink from "./AfdDefaultNavbarMobileLink";

// DefaultNavbar dropdown menus
import PagesMenu from "../../DefaultNavbar/Menus/PagesMenu";
import AuthenticationMenu from "../../DefaultNavbar/Menus/AuthenticationMenu";
import ApplicationsMenu from "../../DefaultNavbar/Menus/ApplicationsMenu";
import EcommerceMenu from "../../DefaultNavbar/Menus/EcommerceMenu";
import IGlobalMenuParent from "../../../../type/global_menu/IGlobalMenuParent";
import AfdDocsMenu from "../Menus/AfdDocsMenu";
import AfdEvolutionMenu from "../Menus/AfdEvolutionMenu";
import {GlobalMenuActionButton} from "../GlobalMenuActionButton";
import IUserInfo from "../../../../type/IUserInfo";

/*
// Typechecking props for the AfdDefaultNavbarMenu
AfdDefaultNavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};
*/

interface IProp {
    routes: any[],
    open: any,
    close: any,
    action: {
        type: "external" | "internal";
        route: string;
        label:
            string;
        color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark"
    },
    userInfoObj: IUserInfo
}

function AfdDefaultNavbarMobile({
                                    routes,
                                    open,
                                    close,
                                    action: action,
                                    userInfoObj
                                }: IProp) {
    const {width} = open && open.getBoundingClientRect();
    const [openCollapse, setOpenCollapse] = useState(''); // BUGFIX:false

    const handleSepOpenCollapse = (name: any) =>
        openCollapse === name ? setOpenCollapse('') : setOpenCollapse(name);

    return (
        <Menu
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            anchorEl={open}
            open={Boolean(open)}
            onClose={close}
            MenuListProps={{style: {width: `calc(${width}px - 4rem)`}}}
        >
            <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                px={0.5}>

                {/*
     <AfdDefaultNavbarLink
          name="pages"
          collapseStatus={openCollapse === "pages"}
          onClick={() => handleSepOpenCollapse("pages")}
        >
          <SoftBox maxHeight="16rem" overflow="auto">
            <PagesMenu routes={routes} mobileMenu />
          </SoftBox>
        </AfdDefaultNavbarLink>
       */}
                {
                    routes.map((parentObj: IGlobalMenuParent, index) => {
                            let Element = AfdDocsMenu;
                            return (
                                <AfdDefaultNavbarMobileLink
                                    key={parentObj.key}
                                    name={parentObj.name}
                                    collapseStatus={openCollapse === parentObj.key}
                                    onClick={() => handleSepOpenCollapse(parentObj.key)}
                                    light={false}
                                >
                                    <SoftBox maxHeight="16rem" overflow="auto">
                                        <Element
                                            parentKey={parentObj.key}
                                            menuParentItemObjList={routes}
                                            mobileMenu/>
                                    </SoftBox>
                                </AfdDefaultNavbarMobileLink>
                            )
                        }
                    )
                }
                <SoftBox
                    display={{xs: "flex", lg: "none"}}
                    justifyContent="center"
                    pt={1}
                >
                    <GlobalMenuActionButton
                        action={action}
                        isMenuMobile={true}
                        userInfoObj={userInfoObj}
                    />
                </SoftBox>

            </SoftBox>
        </Menu>
    );
}


export default AfdDefaultNavbarMobile;
