import {Backdrop, Card, CardActions, CardHeader, Modal} from "@mui/material";
/*import  {makeStyles } from '@material-ui/core/styles';*/
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import React, {useEffect, useState} from "react";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import SoftButton from "../../components/SoftButton";
import Grid from "@mui/material/Grid";
import SpaceShip from "../../examples/Icons/SpaceShip";
import Fade from '@mui/material/Fade';
import {Transition} from "react-transition-group";
import useCHAddData from "../../hook/useCHAddData";
import useCHSourcererData from "../../hook/useCHSourcererData";
import AfdSupermumDefaultProjectCard from "../../examples/Cards/ProjectCards/AfdSupermumDefaultProjectCard";
import IGlobalMenuChild from "../../type/global_menu/IGlobalMenuChild";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../hook/useCHGetContextSoftUI";
import SoftAvatar from "../../components/SoftAvatar";
import IUserInfo from "../../type/IUserInfo";
import RestApiCommonService from "../../service/restapi/RestApiCommonService";
import loader from "ts-loader";


interface IProp {
    title: string;
    loaderModalUserId: number | null;
    setLoaderModalUserId: (isLoader: number | null) => void;
    handleClose: () => void;
    modalValueObj: {};
    isMobile: boolean;
}


export interface IElementModalIdea {
    idea_created_datetime: string;
    idea_id: number;
    idea_scope: string;
    idea_title: string;
    prompt: string;
    user_front_image_id: number | null;
    username: string;
    mianownik: string | null;
    image_light_thumb_src: string; // jezeli NULL to TNPhoto
    max_curdate_idea_2_user_id: number | null;
}


function ModalIdea(
    {
        title,
        loaderModalUserId,
        setLoaderModalUserId,
        handleClose,
        modalValueObj,
        isMobile
    }: IProp
) {

    let user_id: number = loaderModalUserId ? loaderModalUserId : -1;
    let fadeObjList = {
        title: {
            duration: 2000,
            delay: 500
        },
        img: {
            duration: 2000,
            delay: 2500
        },
        content: {
            duration: 5000,
            delay: 4000
        }
    };

    var [elementIdeaObjList] = useCHSourcererData<IElementModalIdea>(
        'modal_idea',
        'sql_query1',
        {
            idea_scope: "USER",
            user_id: user_id
        });
    const [boxFadeIn, setBoxFadeIn] = useState(false);
    const [titleFadeIn, setTitleFadeIn] = useState(false);
    const [contentFadeIn, setContentFadeIn] = useState(false);
    const [seconds, setSeconds] = useState((fadeObjList.content.duration + fadeObjList.content.delay + 3000) / 1000);
    let isModalDisplay: boolean = elementIdeaObjList?.length > 0
        && user_id > 0
        && elementIdeaObjList[0].user_front_image_id != null // USER ma zdjęcie do pokazania
        && !(elementIdeaObjList[0].max_curdate_idea_2_user_id); // USER nie ma jeszcze zapisanej informacji o wyświetleniu MODAL




    useEffect(() => {
        if(seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
            handleClose();
        }
    }, [seconds]);

    /* Zapisz informacje o wyświetleniu MODAL */
    const handleModalOpen = (
        elementIdeaObj: IElementModalIdea
    ) => {
        /* Funkcja tutaj ponieważ gwarancja ID */
        RestApiCommonService.getPromise(RestApiCommonService.setActionUrl(
            'setIdea2UserInsert',
            [elementIdeaObj.idea_id,
                elementIdeaObj.user_front_image_id,
                user_id,
            ].join('|'),
            user_id
        )).then((response: any) => {
            if (response?.data) {
                let idea2UserId = Number(response.data);
            }
        });
    }


    /* Zapisz raz */
    useEffect(() => {
            if (!!loaderModalUserId &&
                isModalDisplay
            ) {
                                handleModalOpen(elementIdeaObjList[0]);
            }
        }
        , [(elementIdeaObjList && elementIdeaObjList?.length > 0) ? 1 : 0]); // Tylko raz kiedy zładowane dane

    useEffect(() => {
            if (!!loaderModalUserId) {
                setTimeout(() => {
                    setBoxFadeIn(true);
                }, fadeObjList.img.delay);
                setTimeout(() => {
                    setTitleFadeIn(true);
                }, fadeObjList.title.delay);
                setTimeout(() => {
                    setContentFadeIn(true);
                }, fadeObjList.content.delay);
                }
        }
        , [!!loaderModalUserId ? 1 : 0]);

    /*const classes = useStyles();*/
    const softBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // centering
        width: {
            xs: '100%', md: '100%', lg: '100%' // musi przykryć całość
        },
        /*maxWidth: '600px',*/
        bgcolor: 'background.paper',
        backgroundColor: "rgba(250,250,250,1)",
        /*border: '1px solid #666',*/
        boxShadow: "18",
        px: 4,
        py: 4,
        borderRadius: "md",
        transition: `all 2500ms ease-in-out`,
    };

    var transitionStyles: any = {
        entering: {opacity: 1},
        entered: {opacity: 1},
        exiting: {opacity: 0},
        exited: {opacity: 0},
    };

    const defaultStyle = {
        transition: `opacity 2500ms ease-in-out`,
        opacity: 0,
    }

    handleClose = () => {
        setLoaderModalUserId(null);
    }

    // const handleOpen = () => setLoaderModalUserId(true);
    let desc: string = "xxx";


    function getContentModalWindow(elementIdeaObj: IElementModalIdea) {


        return (
            <div>
                <Modal
                    open={!!loaderModalUserId}
                    onClose={handleClose}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: "0%",
                        marginRight: "0%",
                        marginTop: "0%",
                        marginBottom: "0%",
                        opacity: 1,
                    }}
                >
                    <SoftBox
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        width={"100%"}
                        height={"100%"}
                        sx={softBoxStyle}
                    >

                        <Card>
                            <SoftBox
                                display={"flex"}
                                flexDirection={isMobile ? "column" : "row"}
                                justifyContent={"space-around"}
                                alignItems={"flex-start"}
                                px={{xs: 1, md: 1, lg: 2}}
                                py={{xs: 1, md: 1, lg: 2}}
                            >
                                <Fade
                                    in={boxFadeIn}
                                    appear={true}
                                    timeout={fadeObjList.img.duration}
                                >
                                    <SoftBox
                                        px={{xs: 1, md: 1, lg: 2}}
                                        py={{xs: 1, md: 1, lg: 2}}
                                    >

                                        <SoftBox
                                            component={"img"}
                                            width={"100%"}
                                            borderRadius={"md"}
                                            /*shadow={"xs"}*/
                                            alt={"Zdjęcie Mamy"}
                                            src={elementIdeaObj.image_light_thumb_src}
                                        />
                                    </SoftBox>
                                </Fade>
                                <SoftBox
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={"flex-start"}
                                    alignItems={"flex-start"}
                                >
                                    {/*TItle*/}
                                    <Fade
                                        in={titleFadeIn}
                                        appear={true}
                                        timeout={fadeObjList.title.duration}
                                    ><SoftBox
                                        py={{xs: 2, md: 2, lg: 5}}
                                        px={{xs: 2, md: 2, lg: 2}}
                                    ><SoftTypography
                                        variant={"h4"}
                                    >{elementIdeaObj.mianownik ?
                                        elementIdeaObj.mianownik : elementIdeaObj.username
                                    }
                                        {isMobile}
                                    </SoftTypography></SoftBox></Fade>
                                    {/*Content*/}
                                    <Fade
                                        in={contentFadeIn}
                                        appear={true}
                                        timeout={fadeObjList.content.duration}
                                    ><SoftBox
                                        px={{xs: 5, md: 5, lg: 5}}
                                        py={{xs: 5, md: 5, lg: 5}}
                                    ><SoftTypography
                                        variant={"body2"}
                                        sx={{
                                            maxWidth: "35ch",

                                        }}
                                    >{elementIdeaObj.idea_title}</SoftTypography></SoftBox></Fade>
                                </SoftBox>
                            </SoftBox>
                            {/*Button footer*/}
                            <SoftBox
                                py={{xs: 2, md: 2, lg: 2}}
                                px={{xs: 2, md: 2, lg: 2}}
                            >
                                <Fade
                                    in={contentFadeIn}
                                    appear={true}
                                    timeout={fadeObjList.content.duration + 2000}
                                >
                                    <SoftBox
                                        display={"flex"}
                                        flexDirection={"row"}
                                        justifyContent={"space-between"}
                                        alignItems={"center"}
                                    >
                                        <SoftBox>
                                            <SoftTypography
                                                display={"block"}
                                                color={"info"}
                                                variant={"caption"}
                                            >przejście do strony za {seconds} sekund ...</SoftTypography>
                                        </SoftBox>
                                        <IconButton aria-label="settings" onClick={handleClose}>
                                            <CloseIcon className="colorW"/>
                                        </IconButton>
                                    </SoftBox></Fade></SoftBox>
                        </Card>
                    </SoftBox>
                </Modal>
            </div>

        );

    }


    return (
        <>
            {
                isModalDisplay ?
                    (
                        getContentModalWindow(elementIdeaObjList[0])
                    ) : (<div>

                    </div>)
            }
        </>
    );

}

export default ModalIdea;
