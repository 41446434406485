
import React, {Suspense, useState} from "react";

// @mui material components
import GetUserProfileInfo from "../../sourcerer/GetUserProfileInfo";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import Icon from "@mui/material/Icon";
import CtxAppObj from "../../../context/CtxApp";
import useCHSourcererData from "../../../hook/useCHSourcererData";

interface IProps {
    authorUserId: number
    imageInfoObj: {
        title: string,
        description?: string,
        image_upload_date?: string,
    }
}

function AfdImageTooltip(
    {
        authorUserId,
        imageInfoObj
    }: IProps
) {
    const appObj = React.useContext(CtxAppObj);
    var [elementAddObj1List] = useCHSourcererData(
        'get_user_profile_info',
        'sql_query1',
        {
            url_user_id: authorUserId
        });

    function getContainerContent() {

        let specification = [
            {
                title: "w Akademii od",
                value: elementAddObj1List[0].reg_date,
                icon: "app_registration"
            },
            {
                title: "miasto",
                value: elementAddObj1List[0].miasto,
                icon: "location_on"
            }
        ];
        return (
            <>
                <SoftBox width="100%" height="100%" display="flex" alignItems="center">
                    <SoftBox width="40%" height="100%">
                        <SoftBox
                            component="img"
                            src={elementAddObj1List[0].avatar}
                            alt={elementAddObj1List[0].username}
                            shadow="lg"
                            borderRadius="lg"
                            maxWidth="100%"
                            /*maxHeight="100%"*/
                            sx={{ objectFit: "cover" }}
                        />
                    </SoftBox>
                    <SoftBox width="60%" py={2.5} px={4}>
                        <SoftBox mb={1} lineHeight={1}>
                            <SoftTypography variant="h5" fontWeight="bold">
                                {elementAddObj1List[0].firstname} ({elementAddObj1List[0].username})
                            </SoftTypography>
                            <SoftTypography
                                variant="button"
                                color="text"
                                textTransform="uppercase"
                                fontWeight="medium"
                            >
                                {elementAddObj1List[0].rank_title_desc}
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox mb={3}>
                            {
                                specification.map((item, index) => {
                                        let iconPath =  item.icon + "OutlinedIcon";
                                        return (
                                            <SoftBox key={index} mb={0}
                                            >
                                                <Icon
                                                    baseClassName="material-icons-outlined"
                                                >{iconPath}</Icon>&nbsp; &nbsp;
                                                <SoftTypography variant="caption" color="text" display="inline">
                                                    {item.title}:&nbsp;
                                                </SoftTypography>
                                                <SoftTypography variant="caption" color="text" display="inline"
                                                                fontWeight="bold"
                                                >
                                                    {item.value}
                                                </SoftTypography>
                                            </SoftBox>
                                        )
                                    }
                                )
                            }

                        </SoftBox>
                        {/*<SoftBox display="flex">{renderSocial}</SoftBox>*/}
                    </SoftBox>
                </SoftBox>
            </>
        );
    }

    return (
        <>
            {
                (elementAddObj1List?.length > 0) ?
                    (
                        getContainerContent()
                    ) : null
            }
        </>
    );
}

export default AfdImageTooltip;

