import {useEffect, useState} from "react";
import {debugLog} from "../util/UtilReact";
import ISelectorImageInfo from "../type/selector/ISelectorImageInfo";
import RestApiService from "../service/restapi/RestApiService";
import RestApiImageService from "../service/restapi/RestApiImageService";
import {processContainer, processElement} from "../util/UtilReact";
import IJos4Menu from "../type/IJos4Menu";
import IRedirectionInfo from "../type/IRedirectionInfo";
import ISelectorImageParam from "../type/selector/ISelectorImageParam";

import IRQOutputSingle   from "../type/rq/IRQOutputSingle";


function useCHSelectorImage(routeCategory: String,
                            queryHookObj: URLSearchParams,
                            selectorImageParamObj?: ISelectorImageParam,
                            menuItemId?: IJos4Menu,
                            redirectionObj?: IRedirectionInfo
): ISelectorImageInfo | undefined
{

    const [selectorImageId, setSelectorImageId] = useState<number | undefined>(undefined);
    const [selectorImageObj, setSelectorImageObj] = useState<ISelectorImageInfo | undefined>();
    var imagePromise: Promise<any>;

    let rqSelectorImageInfo: IRQOutputSingle | undefined = undefined;
    let rqSelectorImageAmocourse: IRQOutputSingle | undefined = undefined;
    let rqSelectorImageArticleDescAssoc: IRQOutputSingle | undefined = undefined;
    let rqSelectorImageEvolutionAssoc: IRQOutputSingle | undefined = undefined;
    let rqSelectorImageInstructorAssoc: IRQOutputSingle | undefined = undefined;
    let rqSelectorImageMainpageAssoc: IRQOutputSingle | undefined = undefined;

    /* BUGFIX: nigdy CustomHook w warunku */
    //setSelectorImageObj(processContainer(responseArrayInfo[0].data));
    rqSelectorImageAmocourse = RestApiImageService.getSelectorImageAmocourseRQ(
        (selectorImageParamObj?.courseEventId ? selectorImageParamObj.courseEventId : -1),
        (routeCategory === 'amocourse')
    );
    rqSelectorImageArticleDescAssoc = RestApiImageService.getSelectorImageArticleDescAssocRQ(
        null,
        11,
        (routeCategory === 'course_list')
    );
    rqSelectorImageArticleDescAssoc = RestApiImageService.getSelectorImageArticleDescAssocRQ(
        null,
        Number(redirectionObj?.object_desc_category_id),
        (routeCategory === 'course_desc')
    );
    rqSelectorImageEvolutionAssoc = RestApiImageService.getSelectorEvolutionImageAssocRQ(
        /*Number(queryHookObj.get('user_id')),*/
        Number(redirectionObj?.object_user_id),
        (routeCategory === 'evolution')
    );
    // TODO
    rqSelectorImageInstructorAssoc = RestApiImageService.getSelectorInstructorImageAssocRQ(
        /*Number(queryHookObj.get('user_id')),*/
        /* Dla ANY sprawdzaj banner na jos4_menu, jeżeli nie - pobierz z mainpage */
        Number(redirectionObj?.object_user_id),
        (routeCategory === 'instructor')
    );
    rqSelectorImageMainpageAssoc = RestApiImageService.getSelectorMainpageImageAssocRQ(
        null,
        (
            routeCategory === 'menu' ||
            routeCategory === 'tutorial' ||
            routeCategory === 'any')
    );
    rqSelectorImageMainpageAssoc = RestApiImageService.getSelectorMainpageImageAssocRQ(
        null,
        (routeCategory === 'mainpage' ||
            routeCategory === 'login_page'
        )
    );

    /* Raz image INFO ale pod warunkiem */
    rqSelectorImageInfo = RestApiImageService.getSelectorImageInfoRQ(
        (selectorImageId ? selectorImageId : -1),
        0,
        ((selectorImageId ? selectorImageId : -1) > 0)
    );

    useEffect(() => {
            if (rqSelectorImageAmocourse && !rqSelectorImageAmocourse.isIdle && !rqSelectorImageAmocourse?.isLoading) {                setSelectorImageId(rqSelectorImageAmocourse.data.data.image_id);            }
            if (rqSelectorImageArticleDescAssoc && !rqSelectorImageArticleDescAssoc.isIdle && !rqSelectorImageArticleDescAssoc.isLoading) {                setSelectorImageId(rqSelectorImageArticleDescAssoc.data.data.image_id);            }
            if (rqSelectorImageEvolutionAssoc && !rqSelectorImageEvolutionAssoc.isIdle && !rqSelectorImageEvolutionAssoc.isLoading) {                setSelectorImageId(rqSelectorImageEvolutionAssoc.data.data.image_id);            }
            if (rqSelectorImageInstructorAssoc && !rqSelectorImageInstructorAssoc.isIdle && !rqSelectorImageInstructorAssoc.isLoading) {                setSelectorImageId(rqSelectorImageInstructorAssoc.data.data.image_id);            }
            if (
                routeCategory === 'menu' ||
                routeCategory === 'tutorial' ||
                routeCategory === 'any') {
                if (menuItemId?.banner_image_id) {
                    setSelectorImageId(menuItemId.banner_image_id);
                } else {
                    if (rqSelectorImageMainpageAssoc && !rqSelectorImageMainpageAssoc.isIdle && !rqSelectorImageMainpageAssoc.isLoading) {
                        setSelectorImageId(rqSelectorImageMainpageAssoc.data.data.image_id);
                    }
                }
            } else if (routeCategory === 'mainpage' ||
                routeCategory === 'login_page'
            ) {
                if (rqSelectorImageMainpageAssoc && !rqSelectorImageMainpageAssoc.isIdle && !rqSelectorImageMainpageAssoc.isLoading) {
                    setSelectorImageId(rqSelectorImageMainpageAssoc.data.data.image_id);
                }
            }

            /* Odpal przeliczeznie IMAGE INFO na poprzez zmianę stanu setSelectorImageObj */
            if (rqSelectorImageInfo?.data && (!rqSelectorImageInfo.isLoading)) {
                debugLog('XX EFFECT: ' + JSON.stringify(rqSelectorImageInfo.data.data));
                setSelectorImageObj(processContainer(rqSelectorImageInfo.data.data));
            }
        }
        , [
            routeCategory,
            menuItemId?.banner_image_id, /* Dla niektórych to samo QUERY ale override w JOS4_MENU */
            selectorImageId,
            rqSelectorImageAmocourse?.isLoading ? 1: 0,
            rqSelectorImageArticleDescAssoc?.isLoading ? 1: 0,
            rqSelectorImageEvolutionAssoc?.isLoading ? 1: 0,
            rqSelectorImageInstructorAssoc?.isLoading ? 1: 0,
            rqSelectorImageMainpageAssoc?.isLoading ? 1: 0,
            rqSelectorImageInfo?.isLoading]);

    return selectorImageObj;
}

export default useCHSelectorImage;
