import SoftBox from "../../components/SoftBox";
import {Link} from "react-router-dom";
import SoftTypography from "../../components/SoftTypography";
import React from "react";
import IResp from "../../type/resp/IResp";
import IconButton from "@mui/material/IconButton";
import {navbarIconButton} from "../../component/controller/get_club_dashboard_navbar/styles";
import Icon from "@mui/material/Icon";
import SoftAvatar from "../../components/SoftAvatar";
import IUserInfo from "../../type/IUserInfo";
import IContainerQuery from "../../type/IContainerQuery";

const akademia_watermark_100 = require('../../assets/images/logos/akademia_watermark_100.png');

function getGlobalMenuLogoTSX(transparent: boolean,
                              light: boolean,
                              mobile: boolean = false,
                              controllerInfoObj?: IContainerQuery
) {
    return <SoftBox component={Link} to="/" py={transparent ? 1.5 : 0.75} lineHeight={1}>
        <SoftTypography variant="button" fontWeight="bold" color={light ? "white" : "dark"}>
            {/*Mniejsze LOGO dla non-trasparent = COVER*/}
            <SoftBox component="img" src={akademia_watermark_100} alt="Logo Akademii Fotografii Dziecięcej"
                     width={transparent ?
                         (mobile ?
                             (controllerInfoObj?.page_item_id == 1 ? "25vw"  : "20vw")
                             : "100px") :
                         (mobile ?
                             (controllerInfoObj?.page_item_id == 1 ? "25vw"  : "20vw")
                             : "50px")
                     }
                     sx={
                         ({palette: {dark, white}}: any) => ({
                                 filter: light ? "none" : `invert(0.9)`,
                             }
                         )
                     }
            />
            {/* <Image
                  src={akademia_watermark_100}
                  alt="logo"
                  width="100px"
                  height="100px"
            />*/}
            {/*<Image src={} alt="logo" width="100px" height="100px" />*/}
        </SoftTypography>
    </SoftBox>;
}

export {
    getGlobalMenuLogoTSX
};
