import IContainerQuery from "../type/IContainerQuery";
import IAmocoursePriceInfo from "../type/amocourse/IAmocoursePriceInfo";
import IAmocourseTotalArray from "../type/amocourse/IAmocourseTotalArray";
import IAmocoursePromocodeInfo from "../type/amocourse/IAmocoursePromocodeInfo";
import {isDev} from "./isDev";
import IFormFormikGantry from "../type/form/IFormFormikGantry";
import linkDictionary from "../linkDictionary";
import {IUtmCampaign, IUtmMedium, IUtmPlacement, IUtmSource} from "../type/IUtm";
import ICtxClubChapter from "../type/context/ICtxClubChapter";
import {
    IElementForumChapterContent
} from "../component/controller/get_club_forum_chapter_content/GetClubForumChapterContent";


// uses moment.js
function getIsDebug()	{
    /*   var futureDate = moment('2022-06-20 13:15:26').add(3, 'hours');
       var currDate = moment();
       var isDebug = currDate.isBefore(futureDate);
       // debugLog("DEBUG: " + isDebug);
       return isDebug;*/
    const targetDate = new Date('2023-06-01 20:24:00');
    const now = new Date();
    const timeDiff = now.getTime() - targetDate.getTime();
    const threeHoursInMilliseconds = 30 * 60 * 60 * 1000;

    return (timeDiff > 0 && timeDiff <= threeHoursInMilliseconds);
}

/* console.debug - każdy może podejrzeć zmieniając LEVEL w przeglądarce */
function debugLog(msg: string | number | any | { [key: string]: any; }, styleObj: string | null = null) {
    if (isDev() || getIsDebug()) {
        console.log("Debug: " + msg, styleObj);
    }
}

function debugDir(msg: any) {
    if (isDev() || getIsDebug()) {
        console.dir(msg);
    }
}

//https://codeburst.io/javascript-map-vs-foreach-f38111822c0f
function processRowList(responseObjList: [] | any[]): [] {

        /* BUGFIX: map instead of forEach - do not operate on refrence from REACT QUERY - copy object NOT TO modify original */
        // @ts-ignore
        let responseObjList2: [] = responseObjList.map(function (responseObj: any) {
                return processRow(responseObj);
            });
        return responseObjList2;
}

function getAddHost(value: string,
                    isForceProd: boolean = false,
                    ): string {
    let domainUrl = 'https://www.akademia-fotografii-dzieciecej.pl';
    if (value !== null && !value.includes(domainUrl)) {
        return (isDev() && !isForceProd ? 'http://localhost:3000' : domainUrl) + value;
    }
    return value;
}

function getRemoveHost(value: string): string {
    let domainUrl = 'https://www.akademia-fotografii-dzieciecej.pl';
    if (value !== null && value.includes(domainUrl)) {
        return value.replace(isDev() ? 'http://localhost:3000' : domainUrl, '');
    }
    return value;
}

function addLeadingSlash(str: string): string {
    if (!str.startsWith('/')) {
        return `/${str}`;
    }
    return str;
}

function getAddImageHost(value: string): string {
    let domainUrl = 'https://www.akademia-fotografii-dzieciecej.pl';
    if (value !== null && !value.includes(domainUrl)) {
        /* BUGFIX: tylko jeżeli się zaczyna ponieważ post_text nie */
        if (value.toString().startsWith('/jwallpapers_files/')) {
            value = getAddSrcKey(value.toString().replace('/jwallpapers_files/', domainUrl + '/jwallpapers_files/'));
        } else if (value.toString().startsWith('/images/')) {
            value = getAddSrcKey(value.toString().replace('/images/', domainUrl + '/images/'));
        }
    }
    return value;
}


function getUrlParseDomainName(url: string): string | null {
    try {
        const parsedUrl = new URL(url);
        return parsedUrl.hostname;
    } catch (err) {
        console.error(`Failed to parse URL: ${url}`);
        return null;
    }
}

function getAddSrcKey(url: string): string {
    /*You can use the URL constructor to check whether the url argument has a valid URL format.*/
    try {
        new URL(url);
    } catch {
        return url;
    }
    const srcKey = 'acc=437b930db84b8079c2dd804a71936b5f';
    if (!url.includes(srcKey) && url.length > 0) {
        const separator = url.includes('?') ? '&' : '?';
        return url + separator + srcKey;
    }
    return url;
}
function getReplaceToThumb(url: string): string {
    const pattern = /(\/)?(big|light)_thumb(_)?/g;
    const replacement = '/thumb_';
    return url.replace(pattern, replacement);
}


function processRow(responseObj: any) {

        /* BUGFIX: do not operate on refrence from REACT QUERY - copy object NOT TO modify original */
        let responseObjCopy: any   = Object.assign({}, responseObj);

        Object.entries(responseObjCopy).forEach(entry => {
            let key: any = entry[0];
            let value: any = entry[1];
            //let [key, value]: [string, string]  = entry;
            responseObjCopy[key] = getAddImageHost(value);

        });
        return responseObjCopy;
    }


    function processContainer(responseObj: any, controllerObj: any = {}) {
        debugLog('Retrieved/C/' + controllerObj.php_function_code + ': '+responseObj + ' element');
        if (responseObj)   {
            return processRow(responseObj);
        } else {
            return {}; // empty object
        }
    }
    /* BUGFIX: do not operate on refrence from REACT QUERY - copy object NOT TO modify original */
    function processElement(responseObjList: [] | any[], controllerObj: any = {}) {
        if (controllerObj !== {})   {
            debugLog('Retrieved/E:/' + controllerObj.php_function_code + ': '+controllerObj.element_module1+': '+responseObjList?.length + ' elements');
        }
        debugDir(responseObjList);
        if (responseObjList?.length > 0)   {
            //let responseObjListCopy: []   = [...responseObjList];
            return processRowList(responseObjList);
        } else {return [];}
    }

    function getPosition2ContainerMap(containerObjList: IContainerQuery[])  {
        /* REDUCE to groun by position-code and in this way do not launch SELECTOR at all for empty positions
        https://stackoverflow.com/questions/21776389/javascript-object-grouping
        */
        const position2ContainerMap: {
            [key: string]: IContainerQuery[]
        } = containerObjList.reduce((newPositionGroupList: any, positionItem: IContainerQuery) => {
                const group = (newPositionGroupList[positionItem.position_code] || []);
                group.push(positionItem);
                newPositionGroupList[positionItem.position_code] = group;
                return newPositionGroupList;
        }, {});
        return position2ContainerMap;
    }


    /* Replace SINGLE tag [ ] to div */
    function setTextSquareTagToDiv(inputText: string): string {
        return inputText.replace(/\[+([^\][]+)]+/g,
                '<div content="$1"></div>');
    }


    /* Special HTML tag, which is unlikely used in your context*/
    function setTextSquareDoubleTagToDiv(inputText: string,
                                         prefix: string,
                                         tagName: string = 'bbtag'): string {
        /*
        WITH no PREFIX
        return inputText.replace(/\[\[(.*?)\]\]/g, `<${tagName} content="$1"></${tagName}>`);*/
        // Make a regex that matches [[ followed by prefix and any other characters, ending with ]]
        const regex = new RegExp(`\\[\\[${prefix}(.*?)\\]\\]`, 'g');
        return inputText.replace(regex, `<${tagName} content="${prefix}$1"></${tagName}>`);
    }

    function setTextClearToBr(inputText: string): string {
        return inputText.replace('[clear][/clear]',
                '<br/>');
    }

        function setTextParagraphToBr(inputText: string): string {
            /*return inputText.replace('\r\n',
                '<br/>');*/
            return inputText.replace(/\n/g, "<br>");
        }


    export function setRemoveModelResponseReferences(input: string): string {
        // Use a regular expression to match and remove content within square brackets
        return input.replace(/\[\d+:\d+†[^\]]+\]/g, '');
    }


/*formInstallmentNo: Number = formikGantry.values.is_installment,
    formIsInstr: String = formikGantry.values.data_instr,
    priceArray: IAmocoursePriceArray = priceArray*/
function setCalculateTotal(formikGantry: IFormFormikGantry,
                           promocodeArray: IAmocoursePromocodeInfo,
                           priceArray: IAmocoursePriceInfo): IAmocourseTotalArray	{
    /*,
        ,*/

    let formInstallmentNo: Number = (formikGantry?.values.is_installment ? formikGantry.values.is_installment : 1);
    /* Dodanie logiki zamiast nadpisanie */
    let formIsInstr: "half" | "full" = (formikGantry?.values.data_instr == 'full' || formikGantry?.values.is_instr_add[0] == 'on' ? 'full' : 'half');

    let totalLocalArray: IAmocourseTotalArray = {};
    if (priceArray) {
        if  (formIsInstr == 'half') {
            totalLocalArray['price_orig'] = priceArray?.instr_out_orig;
            totalLocalArray['price_disc'] = priceArray?.instr_out_disc;
        } else {
            totalLocalArray['price_orig'] = priceArray?.instr_in_orig;
            totalLocalArray['price_disc'] = priceArray?.instr_in_disc;
        }
        totalLocalArray['amocourse_discount_desc'] = priceArray?.discount_text;

        // Rabat promocode
        if (totalLocalArray['price_disc'] &&
            totalLocalArray['price_orig'] &&
            promocodeArray['discount_value'] > 0) {
            totalLocalArray['promocode_discount'] = promocodeArray['discount_value_percent'];
            totalLocalArray['promocode_total'] = totalLocalArray['price_disc'] * (1 - promocodeArray['discount_value']);
        } else {
            totalLocalArray['promocode_total'] = totalLocalArray['price_disc'];
        }

        if (totalLocalArray['promocode_total'] && totalLocalArray['promocode_total'] > 0) {

            // Stawki RAT
            totalLocalArray.installment1_amount = (totalLocalArray['promocode_total'] / priceArray.instr_installment_count);
            totalLocalArray.installment2_amount = (totalLocalArray['promocode_total'] / priceArray.instr_installment_count);
            totalLocalArray.installment3_amount = (totalLocalArray['promocode_total'] / priceArray.instr_installment_count);

            // W sumie pierwsza rata czy INSTALLMENT_COUNT 1 powinna wynosić tyle samo
            if (formInstallmentNo == 1) {
                totalLocalArray['promocode_total_curdate'] = totalLocalArray.installment1_amount;
            } else {
                totalLocalArray['promocode_total_curdate'] = totalLocalArray['promocode_total'];
            }
        } // end of IF promocode_total > 0
    }
    return totalLocalArray;
};


/* BY ID:
newbb_reply_post_text_
 */
function addTextToTextarea(id: string, text: string): void {
    //const textarea = document.querySelector(`textarea[id="${id}"]`);
    const textarea = document.querySelector(`textarea[id="${id}"]`);
    if (textarea) {
        // @ts-ignore
        textarea.value += text;
    } else {
        console.error(`Textarea with name "${id}" not found.`);
    }
}

function containsHTML(content: string): boolean {
    const htmlTagRegex = /<[^>]*>/g;
    return htmlTagRegex.test(content);
}

/* Wybiera zdania ale bez tagów */
function selectFirstNSentences(text: string, n: number): string {
    // Split the text into sentences
    const sentences = text.split(/[.!?]+/);

    // Initialize a new array to store the selected sentences
    let selectedSentences = [];

    // Loop through the sentences
    for (let i = 0; i < n; i++) {
        // Get the current sentence
        let sentence = sentences[i];

        // Remove any tags in square brackets
        sentence = sentence.replace(/\[.*?\]/g, "");

        // Add the sentence to the selectedSentences array
        selectedSentences.push(sentence);
    }

    // Join the selected sentences and return the result
    return selectedSentences.join(" ");
}

function selectNSentencesUpToTag(text: string, n: number): string {
    // Split the text into sentences
    const sentences = text.split(/[.!?]+/);
    // Initialize a new array to store the selected sentences
    let selectedSentences = [];
    // loop through the sentences
    for (let i = 0; i < sentences.length; i++) {
        // Get the current sentence
        let sentence = sentences[i];
        // check if sentence has "["
        if(sentence.includes("[")){
            // get the index of "["
            const index = sentence.indexOf("[");
            // slice the sentence from start to "[" index
            sentence = sentence.slice(0,index);
            // Add the sentence to the selectedSentences array
            selectedSentences.push(sentence);
            // break the loop
            break;
        }else{
            // Add the sentence to the selectedSentences array
            selectedSentences.push(sentence);
        }
        // check if we have reached the desired n sentences
        if(selectedSentences.length === n){
            // break the loop
            break;
        }
    }
    // Join the selected sentences and return the result
    //return selectedSentences.join(" ");
    //return selectedSentences.map(sentence => sentence.trim() ? sentence + "." : sentence ).join(" ");
    return selectedSentences.filter(sentence => sentence.trim().length > 0).map(sentence => sentence.trim() ? sentence + "." : sentence ).join(" ");
}

function countSentencesUpToTag(text: string): number {
    // Split the text into sentences
    const sentences = text.split(/[.!?]+/);
    // Initialize a variable to store the count
    let count = 0;
    // loop through the sentences
    for (let i = 0; i < sentences.length; i++) {
        // Get the current sentence
        let sentence = sentences[i];
        // check if sentence has "["
        if(sentence.includes("[")){
            // break the loop
            break;
        }
        // increment the count
        count++;
    }
    return count;
}

function selectNSentencesAfterTag(text: string, n: number): string {
    // Use the selectNSentencesUpToTag function to get the selected text
    const selectedText = selectNSentencesUpToTag(text, n);
    // Get the index of the last character of the selected text
    const selectedTextIndex = text.indexOf(selectedText) + selectedText.length;
    // Get the remaining text
    const remainingText = text.slice(selectedTextIndex);
    return remainingText;
}

function getPostTextExtractImageUrls(text: string, startTag: string, endTag: string): string[] {
    const imageUrls: string[] = [];
    const regex = new RegExp(`\\${startTag}(.*?)\\${endTag}`, "g");
    let match: RegExpExecArray | null;
    while ((match = regex.exec(text))) {
        let imageUrl = match[1].replace("]", "").replace("[/", "");
        /* Remove DOMAIN if exist to standarize */
        imageUrl = getRemoveHost(imageUrl);
        imageUrls.push(imageUrl);
    }
    return imageUrls;
}

export const wrapNumbersInTags = (text: string) => {
    const words = text.split(' ');
    const processedWords = words.map(word => {
        const numberMatch = word.match(/\d+/g);
        if (numberMatch) {
            return word.replace(numberMatch[0], `[zdjecie]${numberMatch[0]}[/zdjecie]`);
        }
        return word;
    });
    return processedWords.join(' ');
}

/*function replaceTags(content: string, tagName: string = "zdjecie"): string {

}*/

/* Example:
[blockquote]"dowolne narzędzie kierowania uwagi widza na treści przez nas zamierzone. W tym sensie
i w praktyce cały proces tworzenia fotografii będzie dla nas odpowiednim doborem elementów fotograficznych i w odpowiednich proporcjach. "[/blockquote]

function replaceZdjecieTags(content: string): string {
    content = content.replace(/\[zdjecie\]([\s\S]*?)\[\/zdjecie\]/g, `<div content="$1"></div>`);
    return content;
}
* */
function replaceZdjecieTags(content: string, tagName: string = "zdjecie", htmlTag: string = "div"): string  {
    // Construct the replacement regex dynamically using the provided "tagName"
    const regex = new RegExp(`\\[${tagName}\\]([\\s\\S]*?)\\[\\/${tagName}\\]`, "g");
    // Replace all occurrences of the tag in the content
    content = content.replace(regex, `<${htmlTag} content="$1"></${htmlTag}>`);
    return content;
}

function replaceZdjecieTagsInContent(content: string, tagName: string = "zdjecie", htmlTag: string = "div"): string  {
    // Construct the replacement regex dynamically using the provided "tagName"
    const regex = new RegExp(`\\[${tagName}\\]([\\s\\S]*?)\\[\\/${tagName}\\]`, "g");
    // Replace all occurrences of the tag in the content
    content = content.replace(regex, `<${htmlTag}>$1</${htmlTag}>`);
    return content;
}


function getAddUtmTags(url: string,
                       utm_source: string | null,
                       utm_medium: string | null,
                       utm_campaign: string | null,
                       utm_content: string | null,
                          utm_placement: string | null,
): string {

    const myUrl = new URL(getAddHost(url));
    utm_source && myUrl.searchParams.append("utm_source", utm_source);
    utm_medium && myUrl.searchParams.append("utm_medium", utm_medium);
    utm_campaign && myUrl.searchParams.append("utm_campaign", utm_campaign);
    utm_content && myUrl.searchParams.append("utm_content", utm_content);
    utm_placement && myUrl.searchParams.append("utm_placement", utm_placement);
    return myUrl.href;
}

export function getAccountCreateUtmUrl(
    message: "CLUB" | string,
    utm_source: IUtmSource,
    utm_medium: IUtmMedium,
    utm_campaign: IUtmCampaign,
    utm_content: string | null,
    utm_placement?: IUtmPlacement,
)    {
    const url = new URL(getAddHost(linkDictionary.ACCOUNT_CREATE_URL));
        const params = new URLSearchParams(url.search);
        params.set('message', 'CLUB');
    url.search = params.toString();
    let accountCreateUrl = url.pathname.toString() + url.search.toString();

    return getAddUtmTags(
        accountCreateUrl,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_placement ? utm_placement : null,
    );
}

/* Ostatnie tylko jeżeli EXPLICITE podane w parametrze url
- oraz mamy rekordy ISSUE
* */
export function getCtxIsIssueLast(ctxChapterFunctionObj: ICtxClubChapter) {
    let isIssueLast: boolean = ((
            ctxChapterFunctionObj &&
            ctxChapterFunctionObj.storeNewbbIssueDecodeInfoObjList &&
            ctxChapterFunctionObj.storeNewbbIssueDecodeInfoObjList?.length > 0 &&
            ctxChapterFunctionObj.urlChapterIssueCode == ctxChapterFunctionObj?.storeNewbbIssueDecodeInfoObjList[ctxChapterFunctionObj?.storeNewbbIssueDecodeInfoObjList.length - 1].chapter_issue_code)
    ) || false;
    return isIssueLast;
}

/* pierwsze jeżeli EXPLICITE podane w parametrze url
lub ISSUE nie podane w URL
 */
export function getCtxIssueIsFirst(ctxChapterFunctionObj: ICtxClubChapter) {
    return ((
            ctxChapterFunctionObj &&
            ctxChapterFunctionObj.storeNewbbIssueDecodeInfoObjList &&
            ctxChapterFunctionObj?.storeNewbbIssueDecodeInfoObjList?.length > 0 &&
            ctxChapterFunctionObj.urlChapterIssueCode == ctxChapterFunctionObj?.storeNewbbIssueDecodeInfoObjList[0].chapter_issue_code) ||
        !ctxChapterFunctionObj?.urlChapterIssueCode);
}

export {processContainer, processElement, processRowList, getPosition2ContainerMap, setTextSquareTagToDiv,
    setTextSquareDoubleTagToDiv,
    getAddHost,
    getAddImageHost,
    getRemoveHost,
    getAddSrcKey,
    addLeadingSlash,
    getUrlParseDomainName,
    getReplaceToThumb,
    setCalculateTotal,
    setTextClearToBr,
    setTextParagraphToBr,
    debugLog,
    debugDir,
    addTextToTextarea,
    containsHTML,
    selectFirstNSentences,
    selectNSentencesUpToTag, selectNSentencesAfterTag, countSentencesUpToTag,
    replaceZdjecieTags,
    replaceZdjecieTagsInContent,
    getPostTextExtractImageUrls,
    getAddUtmTags

};


