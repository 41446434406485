import IJos4Menu from "../type/IJos4Menu";
import ICtxGlobalConfig from "../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../hook/useCHGetContextSoftUI";

export const xx_page_banner: {
    [key: string]: {
        [key: string]: {
            banner_height: string,
            container_margin_top: number,
        }
    }
} = {
    /* 1: reveal z opóźnieniem dla długiej strony z MAINPAGE_BANNER
    * */
    tall_mainpage: {
        desktop: {
            banner_height: "95vh",
            container_margin_top: -40,
        },
        mobile: {
            banner_height: "35vh",// nie za krótki bo nie mieści się zdjęcie
            container_margin_top: 0, //  zdjęcie poziome, więc na pionowym nie ma potrzeby  czyli = 0
        }
    },
    /* 2: strony z MAINPAGE BANNER analogicznie ale osobno
    * */
    tall_reveal: {
        desktop: {
            banner_height: "95vh",
            container_margin_top: -80, // nie za wysoko bo wchodzi na MENU
        },
        mobile: {
            banner_height: "32vh", // ACCOUNT: nie za krótki bo nie mieści się zdjęcie
            container_margin_top: 0, // ACCOUNT: nie za wysoko bo wchodzi na MENU
        }
    },
    /* 3: wszelkie tresci na długim tle z minimalnym reveal
     */
    tall_cinema: {
        desktop: {
            banner_height: "90vh",
            container_margin_top: -90,
        },
        mobile: {
            banner_height: "90vh",
            container_margin_top: -90,
        }
    },
    mid_header: { // AMOCOURSE TRAIN to display header on image still
        desktop: {
            banner_height: "35vh",
            container_margin_top: 0, // nie za wysoko bo wchodzi na MENU
        },
        mobile: {
            banner_height: "29vh",
            container_margin_top: 0, // nie za wysoko bo wchodzi na MENU
        }
    },

    /* 4: krótki banner dla tresci, które zmieszają się graficznie, np: VIDEO
    - brak opóźnienia reveal / brak prezentacji zdjęcia
     */
    short_standard: {
        desktop: {
            banner_height: "15vh",
            container_margin_top: 0, // NIGDY  nie najeżdzaj z treściami
        },
        mobile: {
            banner_height: "15vh",
            container_margin_top: 0, // NIGDY  nie najeżdzaj z treściami
        }
    }
}

export default function GetMenuPageBanner(menuItemObj: IJos4Menu) {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();

    let pageBanner = (menuItemObj.xx_page_banner ? menuItemObj.xx_page_banner : 'short_standard');
    let bannerHeight: string = xx_page_banner[pageBanner][globalConfigParamObj.isMobile ? 'mobile' : 'desktop'].banner_height;
    let containerMarginTop: number = xx_page_banner[pageBanner][globalConfigParamObj.isMobile ? 'mobile' : 'desktop'].container_margin_top;
    return {
        bannerHeight: bannerHeight,
        containerMarginTop: containerMarginTop
    };
}


