
import React, {Suspense, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import useCHSourcererData from "../../../hook/useCHSourcererData";
import DefaultItem   from "../../../examples/Items/DefaultItem";
import CtxAppObj from "../../../context/CtxApp";
import SoftTypography from "../../../components/SoftTypography";
import colors from "../../../assets/theme/base/colors";
import SoftBox from "../../../components/SoftBox";

interface IProps {
    team_letter: string,
}

function AfdInstructorTooltip(
    {
        team_letter
    }: IProps
) {


    var [elementWelcomeObj1List] = useCHSourcererData(
        'get_welcome',
        'sql_query1',
        {
            team_letter: team_letter,
        });

    const appObj = React.useContext(CtxAppObj);


    function getContainerContent() {
            let instructor = {
                rank_title: elementWelcomeObj1List[0].instr_official_name,
                image: elementWelcomeObj1List[0].image_path,
                full_name: elementWelcomeObj1List[0].username,
            };

            return (
                <>
                    <SoftBox width="100%" height="100%" display="flex" alignItems="center">
                        <SoftBox width="40%" height="100%">
                            <SoftBox
                                component="img"
                                src={instructor.image}
                                alt={instructor.full_name}
                                shadow="lg"
                                borderRadius="lg"
                                maxWidth="100%"
                                /*maxHeight="100%"*/
                                sx={{ objectFit: "cover" }}
                            />
                        </SoftBox>
                        <SoftBox width="60%" py={2.5} px={4}>
                            <SoftBox mb={1} lineHeight={1}>
                                <SoftTypography variant="h5" fontWeight="bold">
                                    {instructor.full_name}
                                </SoftTypography>
                                <SoftTypography
                                    variant="button"
                                    color="text"
                                    textTransform="uppercase"
                                    fontWeight="medium"
                                >
                                    {instructor.rank_title}
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox mb={3}>
                                <SoftTypography variant="body2" color="text">
                                    {instructor.full_name}
                                </SoftTypography>
                            </SoftBox>
                            {/*<SoftBox display="flex">{renderSocial}</SoftBox>*/}
                        </SoftBox>
                    </SoftBox>

                </>
            );
    }

    return (
        <>
            {
                (elementWelcomeObj1List?.length > 0) ?
                    (
                        getContainerContent()
                    ) : (<div></div>)
            }
        </>
    );
}

export default AfdInstructorTooltip;

