import React, {useState} from 'react';
import IUserInfo from "../../type/IUserInfo";
import SoftBox from "../../components/SoftBox";
import {Link, useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import {navbarIconButton} from "../controller/get_club_dashboard_navbar/styles";
import Icon from "@mui/material/Icon";
import SoftTypography from "../../components/SoftTypography";
import SoftAvatar from "../../components/SoftAvatar";
import linkDictionary from "../../linkDictionary";
import IRQOutputList from "../../type/rq/IRQOutputList";
import RestApiCommonService from "../../service/restapi/RestApiCommonService";
import useCHSourcererData from "../../hook/useCHSourcererData";
import IContainerQuery from "../../type/IContainerQuery";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';
import FaceTwoToneIcon from '@mui/icons-material/FaceTwoTone';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';

interface IProp {
    controllerInfoObj: IContainerQuery;
    userInfoObj: IUserInfo;
}



export interface IGlobalMenuUser {
    client_track_id: number;
    page_set_code: string;
    css_is_proinfo: string;
    icon1_url: string;
    icon1_title: string;
    xx_display_menutype: string;
    user_profile_url: string;
    picture_link: string;
    css_is_homework: string;
    homework_topic_url: string;
    menutype: string;
    css_is_switch: string;
    global_login_url: string;
    bg_id: number;

}

function GetGlobalProfileIcon({
                                  controllerInfoObj,
                                  userInfoObj
                              }: IProp) {

    var [globalMenuUserObjList] = useCHSourcererData<IGlobalMenuUser>(
        'get_global_profile_icon',
        'sql_query2',
        {
            user_id: userInfoObj.user_id,
            page_set_code: userInfoObj.page_set_code,
            parent_menu_id: controllerInfoObj.page_item_id
        });

    let loginInfoObj =
        {
            /*linkUrl: "/index.php?option=com_content&Itemid=155&id=159&lang=pl&view=article",*/ //klub-mam-logowanie.html
            linkUrl: linkDictionary.LOGIN_URL,
            iconName: "face3",
            isLight: true,
        }
    let iconLabel: string | undefined = undefined;

    /* Menu actions */
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const handleMenuOpen = (event: any) => setIsMenuOpen(event.currentTarget);
    const closeMenuFunction = () => setIsMenuOpen(false);

    /*const navigate = useNavigate();
    const handleConfiguratorOpen = () => {
        navigate(linkDictionary.CLUB_GALLERY_NEW_IMAGE);

    };*/
    const navigate = useNavigate();
    let menuItemSpecObjList = [
        {
            label: "Profil",
            icon: <FaceTwoToneIcon fontSize={"medium"}/>,
            onClick: () => {
                navigate(linkDictionary.PROFILE_URL);
                /*window.location.href = linkDictionary.PROFILE_URL;*/
            },
            sx: {
                borderBottom: "1px solid #e0e0e0",
                paddingBottom: "0.5rem",
            }
        },
        {
            label: "Wyloguj",
            icon: <LogoutTwoToneIcon fontSize={"medium"}/>,
            onClick: () => {
                navigate(linkDictionary.LOGIN_URL);
            },
            sx: {
                paddingTop: "0.5rem",
            }
        },
    ]


    const renderSideMenu = (closeMenuFunction: any) => (
        <Menu
            // @ts-ignore
            anchorEl={isMenuOpen}
            // @ts-ignore
            anchorReference={null}
            /*anchorOrigin={{vertical: "top", horizontal: "left"}}*/
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            /*transformOrigin={{vertical: "top", horizontal: "right"}}*/
            open={Boolean(isMenuOpen)}
            onClose={closeMenuFunction}
            keepMounted
        >
            {
                menuItemSpecObjList.map((menuItemSpecObj, index) => {
                    return <MenuItem onClick={menuItemSpecObj.onClick}
                                     key={index}
                                     sx={menuItemSpecObj.sx}
                    >
                        <SoftBox
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                        >{menuItemSpecObj.icon}
                            <SoftBox
                                pt={0.5}
                                pl={0.5}
                            >{menuItemSpecObj.label}</SoftBox>
                        </SoftBox>
                    </MenuItem>
                })

            }
        </Menu>
    );


    function getProfileIconTSX() {
        if (userInfoObj.user_id == 0) {
            return (
                <SoftBox
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"row"}
                >
                    <SoftBox

                    >

                        <Link to={loginInfoObj.linkUrl}
                        >
                            {/*https://mui.com/material-ui/api/icon-button/*/}
                            <IconButton sx={navbarIconButton}
                                        size="medium"
                                        edge="end"

                            >
                                <Icon
                                    sx={
                                        (
                                            {palette: {dark, white}}: any
                                        ) => ({
                                            color: loginInfoObj.isLight ? white.main : dark.main,
                                        })
                                    }
                                >
                                    {loginInfoObj.iconName}
                                </Icon>

                                {(iconLabel ?
                                    <SoftTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color={loginInfoObj.isLight ? "white" : "dark"}
                                    >
                                        {iconLabel}
                                    </SoftTypography>
                                    : null)
                                }
                            </IconButton>
                        </Link>
                    </SoftBox>
                </SoftBox>
            );
        } else {
            /*Logged*/
            return (
                <SoftBox
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"row"}
                >
                    <SoftBox
                        onClick={handleMenuOpen}
                    >
                        {/*<Link
                            to={loginInfoObj.linkUrl}*/}
                            {/*globalMenuUserObj.user_profile_url*/}

                            {/*https://mui.com/material-ui/api/icon-button/*/}
                            <SoftAvatar
                                src={globalMenuUserObjList[0].picture_link}
                                alt={globalMenuUserObjList[0].icon1_title}
                                size={"md"}
                                variant={"circle"}
                                shadow={"md"}
                                sx={{}}
                            />

                    </SoftBox>
                    {renderSideMenu(
                        closeMenuFunction
                    )}
                </SoftBox>
            );
        }
    }


    return (globalMenuUserObjList.length > 0 ? getProfileIconTSX() : null);
}

export default GetGlobalProfileIcon;
