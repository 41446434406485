
import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for the SoftBadge
import SoftBadgeRoot from "./SoftBadgeRoot";

interface IProps {
  color?: "primary" | "secondary" | "success" | "error" | "warning" | "info" | "light" | "dark";
    variant?: "gradient" | "contained";
    size?: "xs" | "sm" | "md" | "lg";
    circular?: boolean;
    indicator?: boolean;
    border?: boolean;
    children?: any;
    container?: boolean;
    badgeContent?: any;
}
  /*

  // Setting default values for the props of SoftBadge
  SoftBadge.defaultProps = {
    color: "info",
    variant: "gradient",
    size: "sm",
    circular: false,
    indicator: false,
    border: false,
    children: false,
    container: false,
  };

  // Typechecking props of the SoftBadge
  SoftBadge.propTypes = {
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    variant: PropTypes.oneOf(["gradient", "contained"]),
    size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
    circular: PropTypes.bool,
    indicator: PropTypes.bool,
    border: PropTypes.bool,
    children: PropTypes.node,
    container: PropTypes.bool,
  };*/

const SoftBadge = forwardRef(
  (
      { color = "info",
        variant = "gradient",
        size = "sm",
        circular = false,
        indicator = false,
        border= false,
        container= false,
        children=false,
        ...rest }: IProps,
   ref) => (
    <SoftBadgeRoot
      {...rest}
      ownerState={{ color, variant, size, circular, indicator, border, container, children }}
        /*// @ts-ignore*/
      ref={ref}
      color="default"
    >
      {children}
    </SoftBadgeRoot>
  )
);


export default SoftBadge;
