import React from "react";
import SoftBox   from "../components/SoftBox";
import SoftTypography from "../components/SoftTypography";

import breakpoints   from "../assets/theme/base/breakpoints";

interface IProp {
    containerCard?: string;
    children: any;
}

const RokPageTitle = ({
                          children,
                          containerCard
                      }: IProp) => {
    const { values } = breakpoints;
    return (
        <SoftBox mb={3} p={1}>
            <SoftTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
                color={containerCard === 'AfdCardDark' ? "white" : "inherit"}
            >
                {children}
            </SoftTypography>
        </SoftBox>
    );
}

export default RokPageTitle;

