import React, {useState} from "react";

// react-router-dom components
import {useLocation} from "react-router-dom";

// @mui material components

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";

// Authentication layout components
import AfdSelectorSimple from "../../selector/AfdSelectorSimple";
import IPropGantry from "../../type/IPropGantry";
import Grid from "@mui/material/Grid";
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import useCHGantryData from "../../hook/useCHGantryData";
import {getPosition2ContainerMap} from "../../util/UtilReact";
import ISelectorImageInfo from "../../type/selector/ISelectorImageInfo";
import useCHSelectorImage from "../../hook/useCHSelectorImage";
import AfdSelectorImageAuthor from "../AfdSelectorImageAuthor";
import ISelectorPosition from "../../type/ISelectorPosition";

enum ICoverLayoutColor {
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light"
}

interface ICoverLayout  {
    color?: ICoverLayoutColor;
    header?: string;
    title?: string;
    description?: string;
    image: string;
    top?: number;
    children: any;
};

// Images
const curved9 = require("../../assets/images/curved-images/curved9.jpg");

function RtContactCover({
                            menuItemObj,
                            containerObjList,
                            userInfoObj,
                            routeCategory,
                            redirectionObj
                        }: IPropGantry) {

    var [menuItemListObj, setMenuItemListObj,] = useCHGantryData(
        menuItemObj,
        containerObjList,
        userInfoObj,
        routeCategory,
        redirectionObj
    );

    const position2ContainerMap = getPosition2ContainerMap(containerObjList);
    const getSelectorPositionJSX = function(positionCode: ISelectorPosition): JSX.Element | null {
        if (position2ContainerMap[positionCode]?.length > 0) {
            return (
                <AfdSelectorSimple
                    menuItemObj={menuItemObj}
                    userInfoObj={userInfoObj}
                    position={positionCode}
                    containerAssocList={position2ContainerMap[positionCode]}
                />
                );
        } else {
            return null;
        }
    }
    const queryHookObj = new URLSearchParams(useLocation().search);
    const selectorImageObj: ISelectorImageInfo | undefined = useCHSelectorImage(routeCategory, queryHookObj);


    const [rememberMe, setRememberMe] = useState(true);
    const handleSetRememberMe = () => setRememberMe(!rememberMe);

/*
  function getAfdCoverLayout ({ color = "info", header, title, description, image, top = 20 }: ICoverLayout) {
    return (

    );
  }*/

/*    var ComponentContent = getAfdCoverLayout(
        {
            title: "Cover",
            description: "Cover",
            image: "https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
            header: '',
            color: 'info',
            children: {}

        }
    )*/

    /*var image = (selectorImageObj ? selectorImageObj.image_huge_src : curved9);*/
    var children = {};
    /*var borderRadius: any =  {};*/



    return (
      <PageLayout background="white">
          { getSelectorPositionJSX("showcase-a") }
          <Grid
              container
              justifyContent="center"
              sx={{
                  minHeight: "75vh",
                  margin: 0,
              }}
          >
              <Grid item xs={11} sm={8} md={5} xl={3}>
                  <SoftBox mt={{ xs: 3, sm: 3, md: 10, lg: 20, xl: 20 }}
                  >
                      <SoftBox px={{ xs: 2, sm: 2, md: 3, lg: 3, xl: 3 }}
                                 py={{ xs: 2, sm: 2, md: 3, lg: 3, xl: 3 }}
                      >

                          {/*// @ts-ignore*/}
                          <SoftBox> {/*  component="form" role="form"*/}
                              {/*// @ts-ignore*/}
                              <SoftBox mb={2} lineHeight={1.25}>
                                  {/*// @ts-ignore*/}
                                  <SoftBox mb={1} ml={0.5}>
                                      { getSelectorPositionJSX("content-top-a") }
                                      { getSelectorPositionJSX("body") }

                                  </SoftBox>
                              </SoftBox>
                          </SoftBox>



                      </SoftBox>
                      { getSelectorPositionJSX("bottom-a") }

                  </SoftBox>
              </Grid>
              <Grid item xs={12} md={5}>

                  <SoftBox
                      height="100%"
                      display={{ xs: "none", md: "block" }}
                      position="relative"
                      right={{ md: "-12rem", xl: "-16rem" }}
                      mr={-16}
                      sx={{
                          transform: "skewX(-10deg)",
                          overflow: "hidden",
                          borderBottomLeftRadius:
                          // @ts-ignore
                                ({ borders: { borderRadius } }) => borderRadius.lg,
                      }}
                  >
                      {selectorImageObj && <SoftBox
                          ml={-8}
                          height="100%"
                          sx={{
                              backgroundImage: `url(${selectorImageObj.image_huge_src})`,
                              backgroundSize: "cover",
                              transform: "skewX(10deg)",
                          }}
                      />}


                              <>
                                  <SoftTypography variant={"caption"} color="white"
                                                  position="absolute"
                                                  bottom={0} left={0} p={3}
                                                  sx={{
                                                      transform: "skewX(10deg)" // reverse
                                                  }}
                                  >
                                      <AfdSelectorImageAuthor
                                          selectorImageObj={selectorImageObj}
                                          menuItemObj={menuItemObj}
                                      />
                                  </SoftTypography>
                              </>
                  </SoftBox>
              </Grid>
          </Grid>

          {/*footer*/}
          { getSelectorPositionJSX("footer-a") }

      </PageLayout>

  );
}

export default RtContactCover;
