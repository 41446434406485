import SoftBox from '../../components/SoftBox';
import React from 'react';
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import IFormFormikGantry from "../../type/form/IFormFormikGantry";

import SoftAlert from "../../components/SoftAlert";
import Icon from '@mui/material/Icon';
import {useSelector} from "react-redux";
import IRdxState from "../../type/reducer_state/IRdxState";
import SoftTypography from "../../components/SoftTypography";
import IUniversalFormSubmitReponse from "../../type/comprofiler/IUniversalFormSubmitReponse";

interface IProp {
    formFieldObj: IFormFieldInfo,
    formikGantry: IFormFormikGantry,
    templateModule: string,
    formSubmissionResult?: IUniversalFormSubmitReponse | undefined,
    formArray?: any
    promocodeArray?: any
    sosArray?: any
}

function FormNgValidationYesTemplate({
                                         formFieldObj,
                                         formikGantry,
                                         templateModule,
    formSubmissionResult,
                                         formArray,
                                         promocodeArray,
                                         sosArray
                                     }: IProp) {

    const state: IRdxState = useSelector((state: IRdxState) => state);


    /* Tutaj także indywidualna walidacja dla SUCCESS */
    function getTemplateContent() {
        if (templateModule === 'ELEMENT_EBOOK_FIELD_BUTTON_YES_VALIDATION') {
            return (
                <>
                    Link do Twojego eBooka wysłaliśmy właśnie emailem. Sprawdź skrzynkę email.<br></br>Miłej lektury :)
                </>
            );
        }
        if (templateModule === 'ELEMENT_FORM_FIELD_VALID_YES_PASSWORD'
            && formikGantry.values[formFieldObj.field_name]
        ) {
            return (
                <>
                    Bardzo dobrze. Hasła są zgodne.
                </>
            );
        }
        if (templateModule === 'ELEMENT_FORM_FIELD_VALID_YES_PROMOCODE'
            && state.promocodeArray?.post_reg_exception_code
        ) {
            return (
                <>
                    {/**/}
                    <Icon fontSize="small">thumb_up</Icon>&nbsp;
                    Gratulacje! Kod <b>{formikGantry.values.promocode}</b> to poprawny kod promocyjny.
                    Rabat: <b>{state.promocodeArray.discount_value_percent}</b>.
                </>
            );
        }
        if (templateModule === 'ELEMENT_FORM_FIELD_VALID_YES_SOS_NAME') {
            return (
                <>
                    <div className="voucher_validate voucher_validate_yes afd_showcase">Gratulacje! Znaleźliśmy Mamę,
                        która może być tą, którą szukasz.<br></br>
                        <dl>
                            <dt>Login: <b>{sosArray.sos_username}</b></dt>
                            <dt>Email: <b>{sosArray.sos_email}</b></dt>
                        </dl>
                        <p>
                            Ostatni, kurs ścieżki, który przeszła to kurs: <b>{sosArray.prev_description}</b>, a zatem
                            następny to: <b>{sosArray.description}</b>.
                            Kurs ten kosztuje obecnie (wraz z aktualnymi rabatami) <b>{sosArray.instr_in_disc}</b>.
                            Kliknij w przycisk, aby wygenerować Voucher na prezent dla tego kursu i tej Mamy Akademii.
                        </p>
                        <p>Pamiętaj: Voucher jest na kurs, a nie kwotę. Nawet jeżeli cena kursu wzrośnie - Voucher jest
                            na 100% ceny kursu.</p>
                        <button className="afd_button_blog" ng-click="setVoucherSosSelect()">wybierz...</button>
                    </div>
                </>
            );
        }
        if (templateModule === 'ELEMENT_FORM_FIELD_VALID_YES_URL') {
            return (
                <>
                    Bardzo dobrze. Podałaś adres strony we właściwym formacie...
                </>
            );
        }
        if (templateModule === 'ELEMENT_FORM_FIELD_VALID_YES_USERNAME'
            && formikGantry.values[formFieldObj.field_name]
        ) {
            return (
                <>
                    <p>Gratulacje! Nazwa <b>{formikGantry.values[formFieldObj.field_name]}</b> jest jeszcze dostępna.
                    </p>
                </>
            );
        }
        if (templateModule === 'ELEMENT_GLOBAL_FIELD_BUTTON_UPDATE_YES_VALIDATION') {
            return (
                <>
                    <div className="afd_toast">Pomyślnie zapisane...</div>
                </>
            );
        }
        if (templateModule === 'ELEMENT_VOUCHER_BUTTON_PRINT_REQ_YES_CONF') {
            return (
                <>
                    Przyjęliśmy zlecenie wykonania Vouchera w dużej wersji do druku. Zostanie przesłany emailem w ciągu
                    3 dni roboczych.
                </>
            );
        }
        if (templateModule === 'ELEMENT_ACCOUNT_CREATE_SUBMIT_YES_VALID') {
            return (
                <>
                    {formSubmissionResult?.message_desc}
                </>
            )
        }
    }

    let templateTSX = getTemplateContent();

    if (templateTSX
    ) {
        return (
            <SoftBox
                px={{xs: 1, sm: 2, md: 3}}
                py={{xs: 1, sm: 1, md: 2}}
            ><SoftAlert color="success"
                        sx={{
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                        }}
            >
                <SoftTypography
                    variant={"button"}
                    fontWeight={"regular"}
                    color={"white"}
                >{templateTSX}
                </SoftTypography>
            </SoftAlert></SoftBox>
        );
    } else {
        return null;
    }

}

export default FormNgValidationYesTemplate;
