/*Pobierz dane element*/
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import RestApiService from "../service/restapi/RestApiService";
import RestApiCommonService from "../service/restapi/RestApiCommonService";
import {processContainer, processElement} from "../util/UtilReact";
import IRQOutputList from "../type/rq/IRQOutputList";
import {QueryObserverBaseResult, QueryObserverResult} from "react-query";
import IElementForumDecodeInfoCurrent from "../type/newbb/IElementForumDecodeInfoCurrent";
import IUserInfo from "../type/IUserInfo";


export default function useCHForumDecodeInfoCurrent(
    {
        forum_id,
        topic_id,
        userInfoObj,
        isCourseInfo = false,
        isEnabled  = false,
    }: {
        forum_id: number,
        topic_id: number,
        userInfoObj: IUserInfo,
        isCourseInfo: boolean,
        isEnabled?: boolean,
    }
): [
    IElementForumDecodeInfoCurrent[],
    Dispatch<SetStateAction<IElementForumDecodeInfoCurrent[]>>,
    (x?: any) => Promise<QueryObserverResult<any, unknown>>,
] {
    let sourcererFunction: string = 'get_forum_decode_info_current';
    const [elementObjList, setElementObjList] = useState<IElementForumDecodeInfoCurrent[]>([]);

    /* Różne QUERY w zależności od tego czy COURSE_USER_ID */
    const rqSourcererFunction: IRQOutputList = RestApiCommonService.getSourcererFunctionRQ(
        sourcererFunction,
        (isCourseInfo ? 'sql_query2' : "sql_query1"),
        {
            forum_id: forum_id,
            topic_id: topic_id,
            user_id: userInfoObj.user_id
        },
        (isEnabled) /*!!forum_id*/
        // tylko jeżeli forum_idj istnieje
    );

    useEffect(() => {

        if (rqSourcererFunction && rqSourcererFunction?.data?.data && !rqSourcererFunction.isLoading) {
            setElementObjList(processElement(rqSourcererFunction.data.data));
        }

    }, [
        rqSourcererFunction.isLoading ? "1" : "0",
        rqSourcererFunction.isRefetching ? "1" : "0",
        sourcererFunction,
        forum_id,
        topic_id,
        userInfoObj.user_id,
    ]);


    return [
        elementObjList,
        setElementObjList,
        rqSourcererFunction.refetch
    ]
}
