const ElementWhoIsOnlineRow = React.lazy(() => import("./ElementWhoIsOnlineRow")); // page_item_id: 1

import React, {useEffect, useState} from "react";
import { lazy , Suspense } from "react";
import Emptybox from "../../../emptybox/Emptybox";
import AfdLoadingFullpage from "../../../AfdLoadingFullpage";
import AfdContainerCard from "../../../AfdContainerCard";

import useCHContainerData from "../../../../hook/useCHContainerData";

import IPropContainer from "../../../../type/IPropContainer";

import Grid from "@mui/material/Grid";
import SoftBox from "../../../../components/SoftBox";


/*ng-click="psedudoScrollNextPage()"*/
//{containerRow.post_count}
const GetSiteWhoIsOnline = ({
                                 controllerInfoObj: controllerInfoObj,
                                 userInfoObj: userInfoObj,
                                 paramObj: paramObj
}: IPropContainer) => {
    const components: any = {
        ElementWhoIsOnlineRow,

    }
    let ElementComponent: React.JSXElementConstructor<any>;
    if (controllerInfoObj.element1CamelName !== undefined) {
        ElementComponent = components[controllerInfoObj.element1CamelName];
        var [elementObjList, getElementObjList, containerInfoObj, setContainerInfoObj] = useCHContainerData(controllerInfoObj);

    }

    function getContainerContent()  {
        let row = containerInfoObj;
             if (controllerInfoObj.containerName === 'CONTAINER_SITE_WHO_IS_ONLINE_ROW')    {
            return (
                <>
                     {
                    elementObjList.map((element: any, index: number) => (
                        <ElementComponent
                            key={index}
                            row={element}
                        />
                    ))
                }
                </>
            );
        }
    }

    return (
        <>
        <Suspense fallback={ <AfdLoadingFullpage
            componentCategory="AfdController_Suspense"
            controllerInfoObj={controllerInfoObj}
        />}>
        </Suspense>
            {
                (elementObjList?.length > 0 && containerInfoObj) ?
                    (
                        <AfdContainerCard
                            controllerInfoObj={controllerInfoObj}
                            userInfoObj={userInfoObj}
                            paramObj={paramObj}
                            elementObjList={elementObjList}
                        >
                            {getContainerContent()}
                        </AfdContainerCard>
                    ) : (<Emptybox
                        controllerInfoObj={controllerInfoObj}
                        userInfoObj={userInfoObj}
                        paramObj={paramObj}
                        elementObjList={elementObjList}
                    />)
            }
        </>
    );
}

export default GetSiteWhoIsOnline;
