
import React, {Suspense, useState} from "react";

// @mui material components
import GetUserProfileInfo from "../../sourcerer/GetUserProfileInfo";

interface IProps {
    urlUserId: number,
    introMessage?: string | undefined;
    isShowExperience?: boolean;
}

function AfdUserTooltip(
    {
        urlUserId,
        introMessage,
        isShowExperience = false
    }: IProps
) {

    return (
        <>
            <GetUserProfileInfo
                urlUserId={urlUserId}
                isShowExperience={isShowExperience}
            />
        </>
    );
}

export default AfdUserTooltip;

