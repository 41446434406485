import {createContext} from "react";
import IAppObj from "../type/context/IAppObj";
import IElementGalleryCommentLine from "../type/gallery/IElementGalleryCommentLine";

import ICtxGalleryDashboard from "../type/context/ICtxGalleryDashboard";
import ICtxClubDouble from "../type/context/ICtxClubDouble";
import ICtxSiteProfile from "../type/context/ICtxSiteProfile";
/*
    The problem here is that you need to help TypeScript understand the types of the objects you're dealing with.
    https://blog.logrocket.com/how-to-use-react-context-typescript/
 */
const defaultContext: ICtxSiteProfile = {
    storeMyAcademyNotifCounter: {
        comment: 0,
        post: 0,
        instructor: 0,
    },
    setStoreMyAcademyNotifCounter: () => {},
    storeTabValue: null,
    setStoreTabValue: () => {},

};
const CtxSiteProfile = createContext<ICtxSiteProfile>(defaultContext);
//const AppObjContext = createContext(null);

export default CtxSiteProfile;
