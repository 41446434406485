import React, {useEffect} from 'react';
import {FormikHelpers, useFormik} from "formik";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import IUserInfo from "../../../type/IUserInfo";
import ReactSelect from "react-select";
/*https://www.digitalocean.com/community/tutorials/react-react-select*/
import AsyncSelect, { useAsync } from 'react-select/async';
import RestApiService from "../../../service/restapi/RestApiService";
import RestApiCommonService from "../../../service/restapi/RestApiCommonService";
import SoftAvatar from "../../../components/SoftAvatar";
import {processElement} from "../../../util/UtilReact";
import {Link} from "react-router-dom";
import {setGeneralDispatch} from "../../../redux_reducer/util/dispatchShortcut";

import { useDispatch } from "react-redux";
import { actUserCookieName, RDX_SWITCH_DICTIONARY }  from "../../../redux_action/rdxAction";
import {useCookies} from "react-cookie";


interface IProp {
    userInfoObj: IUserInfo;
}

const AfdConfiguratorUser = (
    {userInfoObj}: IProp
) => {
    const dispatchFunction = useDispatch();
    const [cookieJos4Name, setCookieJos4Name] = useCookies(['jos4_name']); // de facto nazwa cookie (jako typ ?? )
    const [cookieJos4Value, setCookieJos4Value] = useCookies(['cookieJos4Value']); // de facto nazwa cookie (jako typ ?? )
    const [cookiePhpbb3_u, setCookiePhpbb3_u] = useCookies(['phpbb3_6cqy7_u']);
    const [cookiePhpbb3_k, setCookiePhpbb3_k] = useCookies(['phpbb3_6cqy7_k']);
    const [cookiePhpbb3_sid, setCookiePhpbb3_sid] = useCookies(['phpbb3_6cqy7_sid']);

    const userFormikForm = useFormik(
        {
            initialValues: {
                user_select: null
            },
            children: undefined,
            component: undefined,
            initialErrors: undefined,
            initialTouched: undefined,
            innerRef: undefined,
            isInitialValid: undefined,
            onSubmit<Values>(values: Values, formikHelpers: FormikHelpers<Values>): void | Promise<any> {
                return undefined;
            },
        });

    /* Formik handleChange */
    useEffect(() => {
        if (userFormikForm.values.user_select)           {
            // @ts-ignore
            dispatchFunction(actUserCookieName(Number(userFormikForm.values.user_select?.value)));
        }

    }, [
        userFormikForm.values.user_select
    ]);

    /*https://react-select.com/styles
    control: () => ({
            // none of react-select's styles are passed to <Control />
            width: 200,
        }),
    * */
    const asyncSelectCustomStyle = {
        option: (provided: any, state: any) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'green' : 'black',
            padding: 5,
            fontSize: "0.8em"
        }),
        singleValue: (provided: any, state: any) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        }
    }

    var loadOptions =  (inputValue: any, callback: any) => {
        // perform a request
        //const requestResults =
        RestApiService.getPromise(RestApiCommonService.getNewControllerQueryUrl(
            'sql_query1',
            'get_club_friend_user_search',
            190,
            4,
            {
                username: inputValue,
                const_param1: 7
            },
            null,
            null
        )).then ( (response) => {
            // transform the data with your callback
            /* debugDir(response.data ); */
            let reponseProcessedListObj: any[] = processElement(response.data);
            callback(reponseProcessedListObj.map(
                (item: any) => {
                    return {
                        value: item.client_track_id,
                        label: <>
                            <SoftBox key={item.client_track_id} component="li" display="flex" alignItems="center" py={1} mb={1}>
                                <SoftBox mr={2}>
                                    <SoftAvatar src={item.picture_link} alt="something here" variant="rounded" shadow="md" />
                                </SoftBox>
                                <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="flex-start"
                                    justifyContent="center"
                                >
                                    <SoftTypography variant="button" fontWeight="medium">
                                        {item.username}
                                    </SoftTypography>
                                    <SoftTypography variant="caption" color="text">
                                        {item.firstname} / {item.cb_miasto}
                                    </SoftTypography>
                                    {
                                        item.block == 1 ?
                                            <SoftTypography variant="caption" color="error"
                                                            fontStyle="italic"
                                            >
                                                zablokowana
                                            </SoftTypography>
                                            :
                                            null
                                    }
                                </SoftBox>
                                {/*<SoftBox ml="auto">
                                    {action.type === "internal" ? (
                                        <SoftButton component={Link} to={action.route} variant="text" color="info">
                                            {action.label}
                                        </SoftButton>
                                    ) : (
                                        <SoftButton
                                            component="a"
                                            href={action.route}
                                            target="_blank"
                                            rel="noreferrer"
                                            variant="text"
                                            color={action.color}
                                        >
                                            {action.label}
                                        </SoftButton>
                                    )}
                                </SoftBox>*/}
                            </SoftBox>
                        </>

                    }
                }
            ));
        }
        );
    }

    return (
        <>
            <form onSubmit={userFormikForm.handleSubmit}>
            <SoftBox pt={1} pb={2}>
            <SoftTypography variant="h5">UserInfoObj:</SoftTypography>
            <pre>
                  <SoftTypography
                      variant={"body2"}>user: <strong>{userInfoObj.user_id} / {userInfoObj.username}</strong></SoftTypography>

                <SoftTypography display={"block"} variant={"caption"}>cookieJos4Name: <strong>{cookieJos4Name.jos4_name}</strong></SoftTypography>
                <SoftTypography display={"block"} variant={"caption"}>cookieJos4Value: <strong>{cookieJos4Value.cookieJos4Value}</strong></SoftTypography>
                <SoftTypography display={"block"} variant={"caption"}>cookiePhpbb3_u: <strong>{cookiePhpbb3_u.phpbb3_6cqy7_u}</strong></SoftTypography>
                <SoftTypography display={"block"} variant={"caption"}>cookiePhpbb3_k: <strong>{cookiePhpbb3_k.phpbb3_6cqy7_k}</strong></SoftTypography>
                <SoftTypography display={"block"} variant={"caption"}>cookiePhpbb3_sid: <strong>{cookiePhpbb3_sid.phpbb3_6cqy7_sid}</strong></SoftTypography>

                  <SoftTypography
                      variant={"body2"}>page_set_code: <strong>{userInfoObj.page_set_code}</strong></SoftTypography>
                  <SoftTypography variant={"body2"}>usertype: <strong>{userInfoObj.usertype}</strong></SoftTypography>
              </pre>
                <AsyncSelect
                    defaultValue={userFormikForm.values.user_select}
                    onChange={(selectedOption: any) => {
                        userFormikForm.setFieldValue('user_select', selectedOption);
                    }}
                    loadOptions={loadOptions}
                    styles={asyncSelectCustomStyle}
                />

        </SoftBox>
        </form>
        </>
    );
};

export default AfdConfiguratorUser;
