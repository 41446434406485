import React, {Suspense} from "react";

import Grid from "@mui/material/Grid";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import AfdInstructorDescComplexProfileCard
    from "../../examples/Cards/ProfileCards/AfdInstructorDescComplexProfileCard";
import parse from "html-react-parser";
import Card from "@mui/material/Card";

import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import GetInstructorPortfolio from "./get_instructor_portfolio"
import SoftBadge from "../../components/SoftBadge";
import Icon from "@mui/material/Icon";
import IAppObj from "../../type/context/IAppObj";
import useCHAddData from "../../hook/useCHAddData";
import IContainerQuery from "../../type/IContainerQuery";
import CtxAppObj from "../../context/CtxApp";
import useCHSourcererData from "../../hook/useCHSourcererData";
import AfdLoadingFullpage from "../AfdLoadingFullpage";
import AfdContainerCard from "../AfdContainerCard";
import Emptybox from "../emptybox/Emptybox";
import IInstructorDesc from "../../type/IInstructorDesc";
import instructorLetterInfoService from "../../service/InstructorLetterInfoService";
import SoftAvatar from "../../components/SoftAvatar";

interface IProps {
    userId: number;
    controllerInfoObj?: IContainerQuery;
    templateModule: string;
}

const GetInstructorDesc = ({
                               userId: userId,
                               controllerInfoObj: controllerInfoObj,
                               templateModule: templateModule
                           }: IProps) => {
    let limit = 8;

    var elementInstructorInfoObjList: IInstructorDesc[];
    [elementInstructorInfoObjList] = useCHSourcererData(
        'get_instructor_desc',
        'sql_query1',
        {
            user_id: userId,
            limit: limit
        },
        true
    )


    function getSpecificationList(specificationObjList: any[]) {
        return specificationObjList.map(({
                                             title, value, ending, threshold
                                         }, key) => {

                if (Number.parseInt(value) >= Number.parseInt(threshold)) {
                    return (
                        <SoftBox key={key} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                            <SoftTypography variant="body2" color="text" verticalAlign="middle">
                                {title} {value} {ending}
                            </SoftTypography>
                        </SoftBox>)
                } else {
                    return null;
                }
            }
        )
    };

    function getContainerContent() {

        var instructorRow: IInstructorDesc = elementInstructorInfoObjList[0];
        let educationInfo = instructorRow.education_info ? parse(instructorRow.education_info) : '';
        let additionalInfo = instructorRow.additional_info ? parse(instructorRow.additional_info) : '';
        var instructorFUllName = instructorRow.full_name + " (" + instructorRow.username + ")";
        var specificationObjList = [
            {
                title: "Uczestniczyło w kursach",
                value: instructorRow.stat_user_count,
                ending: 'Mam',
                threshold: instructorRow.thres_user_count
            },
            {
                title: "Zostało akredytowanych",
                value: instructorRow.stat_super_count,
                ending: 'absolwentek',
                threshold: instructorRow.thres_user_super
            },
            {
                title: "Założyło działalność",
                value: instructorRow.stat_sesje_count,
                ending: 'absolwentek',
                threshold: 0

            }
        ];

        if (templateModule === 'ElementInstructorDescriptionNoSld') {
            return (
                <>
                    {
                        (elementInstructorInfoObjList?.length > 0 ?
                            <SoftBox py={{xs: 2, sm: 2, md: 3, lg: 3}}
                            >
                                <Card sx={{overflow: "visible"}}>
                                    <SoftBox p={{xs: 3, sm: 3, md: 3, lg: 3}}
                                    >
                                        {/*   <SoftBox mb={3}>
                                            <SoftTypography variant="h5" fontWeight="medium">
                                                Product Details
                                            </SoftTypography>
                                        </SoftBox>*/}

                                        <Grid container spacing={{xs: 2, sm: 2, md: 3, lg: 3}}
                                        >
                                            <Grid item xs={12} lg={6} xl={5}>
                                                <GetInstructorPortfolio
                                                    userId={userId}
                                                    templateModule={''}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={5} sx={{mx: "auto"}}>


                                                <SoftBox>
                                                    <SoftBox mb={1}>
                                                        <SoftTypography variant="h3" fontWeight="bold">
                                                            {instructorFUllName}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftBox mt={1}>
                                                        <SoftTypography variant="h6" fontWeight="medium"
                                                                        display={"inline"}
                                                        >
                                                            Styl:{" "}
                                                        </SoftTypography>
                                                        <SoftTypography variant="h5" fontWeight="medium"
                                                                        display={"inline"}
                                                        >
                                                            {instructorRow.style}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftBox mb={1}>
                                                        <SoftTypography variant="body2">
                                                            {educationInfo}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    {/*<SoftBadge variant="contained" color="success"
                                                               badgeContent="in stock"
                                                               container/>*/}
                                                    <SoftBox mt={3} mb={1} ml={0.5}>
                                                        <SoftTypography variant="caption" fontWeight="bold">
                                                            Doświadczenie i osiągnięcia:
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftBox component="ul" m={0} pl={4} mb={2}>
                                                        {getSpecificationList(
                                                            specificationObjList
                                                        )}
                                                    </SoftBox>
                                                </SoftBox>


                                            </Grid>
                                        </Grid>
                                    </SoftBox>
                                </Card>
                            </SoftBox> : '')
                    }
                </>
            );
        } else if (templateModule === 'ElementInstructorDescImage') {
            return (
                <>
                    <AfdInstructorDescComplexProfileCard
                        image="https://bit.ly/3DlX6gn"
                        name="Alec Thompson"
                        position="CEO / Co-Founder"
                        description="And I love you like Kanye loves Kanye. We need to restart the human foundation."
                        social={[]}
                    />
                </>)

        } else if (templateModule === 'ElementInstructorDescriptionDetail') {
            var description = (elementInstructorInfoObjList[0].description ? parse(elementInstructorInfoObjList[0].description) : '');
            var fullUser = elementInstructorInfoObjList[0].full_name + " (" + elementInstructorInfoObjList[0].username + ")";
            return (
                <>
                    <SoftBox width="100%" height="100%" display="flex" alignItems="center">
                        <SoftBox width="40%" height="100%">
                            <SoftBox
                                component="img"
                                src={elementInstructorInfoObjList[0].image_path}
                                alt={elementInstructorInfoObjList[0].username}
                                shadow="lg"
                                borderRadius="lg"
                                height="100%"
                                sx={{
                                    objectFit: "cover",
                                    maxWidth: "100%",
                            }}
                            />
                        </SoftBox>
                        <SoftBox width="60%" py={2.5} px={4}>



                            <SoftBox mb={1}>
                                <SoftTypography variant="h3" fontWeight="bold">
                                    {instructorFUllName}
                                </SoftTypography>
                            </SoftBox>
                            {
                                instructorRow.style && <SoftBox mt={1}>
                                    <SoftTypography variant="h6" fontWeight="medium"
                                                    display={"inline"}
                                    >
                                        Styl:{" "}
                                    </SoftTypography>
                                    <SoftTypography variant="h5" fontWeight="medium"
                                                    display={"inline"}
                                    >
                                        {instructorRow.style}
                                    </SoftTypography>
                                </SoftBox>
                                }
                            {educationInfo && <SoftBox mb={1}>
                                    <SoftTypography variant="body2">
                                        {educationInfo}
                                    </SoftTypography>
                                </SoftBox>
                                }
                            {additionalInfo && <SoftBox mb={1}>
                                <SoftTypography variant="body2">
                                    {additionalInfo}
                                </SoftTypography>
                            </SoftBox>}
                            {/*<SoftBadge variant="contained" color="success"
                                                               badgeContent="in stock"
                                                               container/>*/}
                            <SoftBox mt={3} mb={1} ml={0.5}>
                                <SoftTypography variant="caption" fontWeight="bold">
                                    Doświadczenie i osiągnięcia:
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox component="ul" m={0} pl={4} mb={2}>
                                {getSpecificationList(
                                    specificationObjList
                                )}
                            </SoftBox>


                        </SoftBox>
                    </SoftBox>
                </>)
        } else if (templateModule === 'ElementInstructorMainMessage') {
            var description = (elementInstructorInfoObjList[0].description ? parse(elementInstructorInfoObjList[0].description) : '');
            var fullUser = elementInstructorInfoObjList[0].full_name + " (" + elementInstructorInfoObjList[0].username;
            return (
                <>
                    {/*<span className="content_season_welcome">{row.full_name}</span><br/><span
                       className="content_season_intro_desc">
            {row.mainpage_message_desc}</span>

            */}
                </>
            )
        } else if (templateModule === 'ElementInstructorDescMainpageStat') {
            return (
                <>
                    {/*   <div>Uczestniczyło w kursach: <strong>{row.stat_user_count}</strong> Mam</div>
                <div>Wyróżnionych <strong>{row.stat_ep_count}</strong>fotografii absolwentek.</div>
                <div>Zostało akredytowanych: <strong>{row.stat_super_count}</strong> absolwentek</div>
                <div>Założyło działalność: <strong>{row.stat_sesje_count}</strong> absolwentek</div>*/}
                </>
            )
        } else if (templateModule === 'rt-site-instructor') {
            return <><Grid item>
                <SoftAvatar
                    src={elementInstructorInfoObjList[0].image_path}
                    alt="profile-image"
                    variant="rounded"
                    size="xl"
                    shadow="sm"
                />
            </Grid>
                <Grid item>
                    <SoftBox height="100%" mt={0.5} lineHeight={1}>
                        <SoftTypography variant="h5" fontWeight="medium">
                            {elementInstructorInfoObjList[0].full_name}
                        </SoftTypography>
                        <SoftTypography variant="button" color="text" fontWeight="medium">
                            {/*{elementInstructorInfoObjList[0].*/}
                            Instruktor Akademii / Fotograf dziecięcy
                        </SoftTypography>
                    </SoftBox>
                </Grid></>

        } else {
            return <p></p>;
        }
    } // end of FUND

    return (
        <>
            <Suspense fallback={<AfdLoadingFullpage
                componentCategory="AfdController_Suspense"
                controllerInfoObj={controllerInfoObj}
            />}>
            </Suspense>
            {
                (elementInstructorInfoObjList?.length > 0
                ) ?
                    getContainerContent()
                    : (null)
            }
        </>
    );

}

export default GetInstructorDesc;


{/*  <AfdInstructorDescComplexProfileCard
                           image="https://bit.ly/3DlX6gn"
                           name={row.full_name + " (" + row.username + ")"}
                           position="CEO / Co-Founder"
                           description={description}
                           social={[

                           ]}
                           />
                           */
}


{/*<div className="divTable">
                           <div className="divTableBody">


                               <div className="divTableRow">
                                   <div className="divTableCell content_front_image content_image_grid {row.css_is_video}">
                                       {row.content_image_grid}
                                       {row.content_video}
                                   </div>
                                   <div className="divTableCell content_opinion">

                                       <div className="content_info">[afd-ornam]{row.full_name} ({row.username})[/afd-ornam]</div>

                                       <div className="content_avatar"><img className="lozad" alt="Zdjęcie instruktorki kursu"
                                                                            src=""/></div>


                                       <h2 className="afd_h2_kurs_small">Doświadczenie pedagogiczne:</h2>
                                       <span className="content_experience">
            Uczestniczyło w kursach: <strong>{row.stat_user_count}</strong> Mam<br/>Zostało akredytowanych: <strong>{row.stat_super_count}</strong> absolwentek<br/>Założyło działalność: <strong>{row.stat_sesje_count}</strong> absolwentek (zobacz listę poniżej)
            </span>


                                       <h2 className="afd_h2_kurs_small">Styl fotografii:</h2> {row.style}
                                       <h2 className="afd_h2_kurs_small">Strona WWW:</h2> <a
                                       title="Pokaz fotografii instruktorki kursu: {row.full_name}" href="">{row.url}</a>
                                   </div>
                               </div>

                           </div>


                           <div className="divTableFooting">

                               <div className="divTableFoot">
                                   <a title="Pokaz fotografii absolwentek instruktorki: {row.full_name}"
                                      href="{row.instructor_url}">zobacz efekty pracy instruktorki...</a>
                               </div>
                           </div>

                       </div>
            */
}
