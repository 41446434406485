import React, {ReactElement, Suspense, useContext, useEffect, useState} from "react";

import Grid from "@mui/material/Grid";
import parse from "html-react-parser";
import AfdAmocourseWavedPricingCard from "../../examples/Cards/PricingCards/AfdAmocourseWavedPricingCard";
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import RestApiCommonService from "../../service/restapi/RestApiCommonService";
import RokPageTitle from "../../title/RokPageTitle";
import InfiniteScroll from "react-infinite-scroll-component";

import AmocoursePriceArrayContext from "../../context/CtxAmocourse";
import IAmocoursePriceInfo from "../../type/amocourse/IAmocoursePriceInfo";
import Card from "@mui/material/Card";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import WavedPricingCardWavesRoot from "../../examples/Cards/PricingCards/WavedPricingCard/WavedPricingCardWavesRoot";
import SoftButton from "../../components/SoftButton";
import {setTextSquareTagToDiv} from "../../util/UtilReact";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import {Field} from "formik";
import {RadioGroup} from "@mui/material";
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import AfdTooltipAmocourseInstr from "../../component/tooltip/AfdTooltipAmocourseInstr";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../hook/useCHGetContextSoftUI";
import ICtxAmocourse from "../../type/context/ICtxAmocourse";
import CtxAmocourse from "../../context/CtxAmocourse";


interface IPricingCard {
    name: string,
    title: string,
    orig_price: number,
    discount_percent: number,
    disc_price: number,
    course_stat_ratio: number
}


/* Odpowiednim AMOCOURSE CALLBACK: dla   get_amocourse_form_reg_field_list */
const ElementRegistrationFormInstrSelect = (
    {
        paramObj,
        formFieldObj,
        formikGantry,
        parserOptions
    }: IFormFieldProp
) => {
    const ctxAmocourse: ICtxAmocourse = useContext(CtxAmocourse);
    const [courseStatRatio, setCourseStatRatio] = useState<number>(0);
    const [specObjList, setSpecObjList] = useState<any[]>([]);
    const [globalConfigParamObj, globalConfigParamObjDispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();

    //debugLog("FORM INSTR priceArray: ", JSON.stringify(priceArray));

    useEffect(() => {
        let promiseArray = [];
        promiseArray.push(RestApiCommonService.getFunctionSelectPromise(
                'xm_get_course_user_count',
                paramObj.paramRegProviderObj?.catid
            )
        );

        Promise.all(
            promiseArray
        ).then(
            (responseArray) => {
                let responseCourseStatRatio = responseArray[0].data.function_select;
                setCourseStatRatio(parseFloat(responseCourseStatRatio));
            }
        )
    }, []);


    /* TODO:
                if (field_group == 'amocourse_reg_select') {
                 // Kurs bez instruktora
                if (paramObj.paramRegProviderObj.is_instructor_course == 0) {
                    field_group = 'amocourse_reg_select_no_instr';
                }
                 // Tylko dla WpparamObj.paramRegProviderObjadzewnia nie pokazuj wyboru, bo nowi nie wiedzą o co chodzi
                if ((paramObj.paramRegProviderObj.catid == 11)) {
                    return null;
                }
            }
     */


    function getPricingCardTSX(
        pricingCardObj: IPricingCard,
        color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" = "dark",
        cardTitle: string,
        fieldTip: string | React.ReactNode,
        fieldDescFull: string | React.ReactNode,
        specificationObjList: Array<{
            label: string;
            count: number;
        }>,
        action: any,
    ) {

        if (typeof fieldDescFull === "string") {
            fieldDescFull = parse(fieldDescFull);
        }
        let tooltipId = 'tooltip_' + pricingCardObj.name;
        return (
            <Card>
                <SoftBox
                    position="relative"
                    pt={3}
                    pb={6}
                    px={{
                        xs: 2, sm: 2, md: 3, lg: 3, xl: 3 // przestrzeń na zaokrąglenia
                    }}
                    textAlign="center"
                    bgColor={color}
                    variant="gradient"
                >
                    <SoftBox position="relative" zIndex={1}>
                        <SoftBox
                            data-for={tooltipId}
                            data-tip={''}
                        >
                            <SoftTypography variant={globalConfigParamObj.isMobile ? "h5" : "h5"}
                                            color={color === "light" ? "dark" : "white"} gutterBottom>
                                {cardTitle}
                                &nbsp;&nbsp;<ContactSupportOutlinedIcon
                                fontSize="medium"
                            />

                            </SoftTypography>
                            <AfdTooltipAmocourseInstr
                                tooltipId={tooltipId}
                                tipContent={fieldDescFull}
                            />
                        </SoftBox>

                        <SoftBox lineHeight={1.5}
                                 textAlign={"left"}
                                 pt={2}
                                 pb={2}
                        >
                            <SoftTypography
                                color={"white"}
                                variant={"body2"}
                                lineHeight={globalConfigParamObj.isMobile ? 1.5 : 2}
                            >{fieldTip}
                            </SoftTypography></SoftBox>
                        <SoftTypography
                            variant={globalConfigParamObj.isMobile ? "h3" : "h1"}
                            fontWeight="bold"
                            color={color === "light" ? "dark" : "white"}
                        >

                            {pricingCardObj.course_stat_ratio}
                            <SoftBox component="small" color={color === "light" ? "dark" : "white"}>
                                &nbsp;Mam
                            </SoftBox>
                        </SoftTypography>
                        <SoftTypography
                            variant="body2"
                            fontWeight="medium"
                            color={color === "light" ? "dark" : "white"}
                            gutterBottom
                        >
                            wybrało ten kurs
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        pt={2}
                    >
                        <SoftTypography
                            color={"white"} variant={"caption"}
                            display={"block"}
                        >Cena z rabatem chwilowym: </SoftTypography>
                        <SoftTypography
                            color={"white"} variant={"h6"}
                            display={"block"}
                            px={1}
                        ><span>{pricingCardObj.disc_price} x {paramObj.paramRegProviderObj?.payment_count} rat</span></SoftTypography>
                    </SoftBox>
                </SoftBox>
                <WaveStripTSX/>
                <SoftBox p={4.5}>

                    {/*RADIO */}
                    <SoftBox
                        display={"flex"}
                        flexDirection={"column"} justifyContent={"center"} alignItems={"center"}
                        mb={2}
                        sx={{
                            transform: "scale(1.5)",
                        }}
                    ><Field
                        key={pricingCardObj.name}
                        name={formFieldObj.field_name} as={Radio}
                        error={formikGantry.errors[formFieldObj.field_name]}
                        value={pricingCardObj.name} // must be STRING to write to DOM
                        success={
                            (formikGantry.values[formFieldObj.field_name] &&
                                formikGantry.values[formFieldObj.field_name].length > 0 &&
                                !formikGantry.errors[formFieldObj.field_name]) ? "true" : "false"}
                    /></SoftBox>
                    {/*<SoftBox component="ul" mx="auto" sx={{listStyle: "none"}}>
                        <SoftTypography
                            variant="caption"
                        >{fieldDescFull}</SoftTypography>
                    </SoftBox>*/}
                </SoftBox>
            </Card>
        );
    }


    function getContainerContent(
        internalPriceArray: IAmocoursePriceInfo,
    ) {
        /* After data exists */
        let pricingCardObjList: IPricingCard[] = [
            {
                name: "half",
                title: "Z oceną kompetencji",
                orig_price: internalPriceArray.instr_out_orig,
                discount_percent: internalPriceArray.discount_percent,
                disc_price: internalPriceArray.instr_out_disc,
                course_stat_ratio: courseStatRatio
            },
      /*      {
                name: "full",
                title: "Z instruktorem",
                orig_price: internalPriceArray.instr_in_orig,
                discount_percent: internalPriceArray.discount_percent,
                disc_price: internalPriceArray.instr_in_disc,
                course_stat_ratio: courseStatRatio
            }*/
        ];


        if (1 == 1) {

            return (
                <>
                    {/*<SoftBox pt={1.5} pb={2} px={2} lineHeight={1.25}>
               */}
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={pricingCardObjList[0].name}
                        name="radio-buttons-group"
                    >
                        <Grid container
                              spacing={{xs: 0, sm: 1, md: 2, xl: 2}}
                    >
                        {
                            pricingCardObjList.map((pricingCardObj: IPricingCard, index: number) => {
                                let fieldLabel = formFieldObj.field_label.split("|")[index];
                                let fieldDescFull = formFieldObj.field_desc_full.split("|")[index];
                                var formFieldTip: string = formFieldObj.field_tip ? setTextSquareTagToDiv(formFieldObj.field_tip) : "";
                                let fieldTip = formFieldTip.split("|")[index];
                                return            (
                                    // @ts-ignore
                                    <Grid
                                        key={index}
                                        item
                                            xs={pricingCardObjList.length == 1 ? 12 : 6}
                                            md={pricingCardObjList.length == 1 ? 12 : 6}
                                            lg={pricingCardObjList.length == 1 ? 12 : 6}
                                            xl={pricingCardObjList.length == 1 ? 12 : 6}
                                        p={{xs: 1, sm: 1, md: 1, lg: 1, xl: 1,}}>
                                        {getPricingCardTSX(
                                            pricingCardObj,
                                            "dark",
                                            fieldLabel,
                                            parse(fieldTip, parserOptions),
                                            fieldDescFull,
                                            [{
                                                label: "Cena",
                                                count: 0
                                            }],
                                            null
                                        )}</Grid>)
                                }
                            )

                        }</Grid>
                    </RadioGroup>
                    {/*
                   <SoftBox mt={0.25}>

                       <Switch
                           name={formFieldObj.field_name}
                           id={formFieldObj.field_name}
                           // @ts-ignore
                           checked={value}
                           onChange={onChange}
                       />
                   </SoftBox>
                   <SoftBox width="80%" ml={2}>
                       <SoftTypography variant="button" fontWeight="regular" color="text">
                           {parse(
                               formFieldTip,
                               parserOptions
                           )
                           }
                       </SoftTypography>
                   </SoftBox>
                   */}


                    {/*
           <div className="divTable">

               <div className="divTableHeading">
                   <div className="divTableHead">
                       <h2 className="afd_h2_ornam"> &lt;label
                           for="{row.field_name}"&gt;{row.field_label} &lt;/label&gt; </h2>


                       {row.field_tip}
                   </div>
               </div>

               <div className="divTableBody">

                   <div className="divTableRow">
                       <div className="divTableCell content_full">{row.field_desc_full}</div>
                   </div>

                   <div className="divTableRow">
                       <div className="divTableCell content_stat">
                           <blockquote>{row.content_ratio_desc}%</blockquote>
                           Mam wybiera tę opcję
                       </div>
                   </div>

                   <div className="divTableRow">
                       <div className="divTableCell content_price">
                           <div className="content_price_orig">
                               <span>Cena z rabatem chwilowym: </span>

                               <span>{row.content_ng_instr_disc_price} x {row.payment_count} rat</span>
                           </div>


                       </div>
                   </div>


               </div>

               <div className="divTableFooting">
                   <div className="divTableFoot content_input">
                       &lt;input id="{row.field_name}" className="{row.field_css_class_list}" name="{row.field_name}"
                       size="0" type="{row.field_type}" value="{row.field_default_value}"
                       ng-model="formArray.{row.field_name}" ng-minlength="{row.ng_minlength}"
                       ng-blur="setAmocourseFormPreregLogInsert('{row.field_name}')"
                       ng-change="setFormFieldOnChange('{row.field_name}')" /&gt;</div>
               </div>


           </div>*/}
                </>
            );
        }
    }


    /* FIX: ponieważ w DB RADIO jest w dwóch rekordach */
    if (formFieldObj.field_default_value === 'half') {
        return null;
    }

    return (
        <>
            <Suspense fallback={<div>Loading... </div>}>
            </Suspense>
            {
                (courseStatRatio && ctxAmocourse?.storePriceArray
                ) ?
                    (
                        getContainerContent(ctxAmocourse?.storePriceArray)
                    ) : (<div>No rows</div>)
            }
        </>
    );

}

export default ElementRegistrationFormInstrSelect;


function WaveStripTSX() {
    return <SoftBox position="relative" height="3.125rem" overflow="hidden" zIndex={2} mt={-5}>
        <SoftBox position="absolute" width="100%" top={0} zIndex={1}>
            <SoftBox
                component="svg"
                position="relative"
                width="100%"
                height="3.125rem"
                minHeight="3.125rem"
                maxHeight="3.125rem"
                mb={-0.875}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 40"
                preserveAspectRatio="none"
                shapeRendering="auto"
            >
                <defs>
                    <path
                        id="card-wave"
                        d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                    />
                </defs>
                <WavedPricingCardWavesRoot>
                    <use xlinkHref="#card-wave" x="48" y="-1" fill="rgba(255,255,255,0.30"/>
                    <use xlinkHref="#card-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"/>
                    <use xlinkHref="#card-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"/>
                    <use xlinkHref="#card-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"/>
                    <use xlinkHref="#card-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"/>
                    <use xlinkHref="#card-wave" x="48" y="16" fill="rgba(255,255,255,0.99)"/>
                </WavedPricingCardWavesRoot>
            </SoftBox>
        </SoftBox>
    </SoftBox>;
}
