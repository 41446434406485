import {debugLog} from "../util/UtilReact";
import IContainerQuery from "../type/IContainerQuery";
import IJos4Menu     from "../type/IJos4Menu";
import IAmocoursePromocodeInfo from "../type/amocourse/IAmocoursePromocodeInfo";
import IElementGalleryCommentLine from "../type/gallery/IElementGalleryCommentLine";
import IUniversalFormSubmitReponse from "../type/comprofiler/IUniversalFormSubmitReponse";

export const RDX_SWITCH_DICTIONARY = {
    'SET_SESSION_ID': 'actSetSessionId',
    'COURSE_PROPERTY_VALUE_SELECT': 'actCoursePathSelect',
    'JOS4_MENU_UPDATE': 'actJos4MenuUpdate',
    'CONTROLLER_UPDATE': 'actControllerUpdate',
    'GLOBAL_SETTING_UPDATE': 'actGlobalSettingUpdate',
    'TUTORIAL_HELP_TOP_SELECT': 'actTutorialHelpTopSelect',
    'USER_COOKIE_NAME': 'actUserCookieName',
    'FORMIK_PARAM_OBJ_UPDATE': 'actFormikParamObjUpdate',
    'PROMOCODE_ARRAY_UPDATE': 'actPromocodeArrayUpdate',
    'ACCOUNT_TRAIN_STEP_UPDATE': 'actAccountTrainStepUpdate',
    'GALLERY_COMMENT_REPLY_SELECT': 'actGalleryCommentReplySelect',
}

/* REDUX action is a function
- nie przekazujemy obiektów bo obiekty dopiero REFETCH z bazy
    - przekazujemy tylko ID obiektów, które ulegają zmianie

*  */
export const actCoursePathSelect = (property_value_id: number) => {
    return {
        type: RDX_SWITCH_DICTIONARY.COURSE_PROPERTY_VALUE_SELECT, // this is to ROUTE
        payload: {
            property_value_id: property_value_id
        }
    }
}


export const actTutorialHelpTopSelect = (message_id: number) => {
    return {
        type: RDX_SWITCH_DICTIONARY.TUTORIAL_HELP_TOP_SELECT, // this is to ROUTE
        payload: {
            message_id: message_id
        }
    }
}

export const actJos4MenuUpdate = (menuItemObj: IJos4Menu) => {
    return {
        type: RDX_SWITCH_DICTIONARY.JOS4_MENU_UPDATE, // this is to ROUTE
        payload: {
            menuItemObj: menuItemObj
        }
    }
}

export const actControllerUpdate = (controllerObj: IContainerQuery) => {
    return {
        type: RDX_SWITCH_DICTIONARY.CONTROLLER_UPDATE, // this is to ROUTE
        payload: {
            controllerObj: controllerObj
        }
    }
}

export const actGlobalSettingUpdate = (globalSettingObj: any) => {
    return {
        type: RDX_SWITCH_DICTIONARY.GLOBAL_SETTING_UPDATE, // this is to ROUTE
        payload: {
            globalSettingObj: globalSettingObj
        }
    }
}

export const actUserCookieName = (cookie_name: string | undefined) => {
    debugLog('COOKIE name change', cookie_name);
    return {
        type: RDX_SWITCH_DICTIONARY.USER_COOKIE_NAME, // this is to ROUTE
        payload: {
            cookie_name: cookie_name
        }
    }
}

export const actAccountTrainStepUpdate = (account_train_step: number) => {
    return {
        type: RDX_SWITCH_DICTIONARY.ACCOUNT_TRAIN_STEP_UPDATE, // this is to ROUTE
        payload: {
            account_train_step: account_train_step
        }
    }
}

export const actPromocodeArrayUpdate = (promocodeArray: IAmocoursePromocodeInfo) => {
    return {
        type: RDX_SWITCH_DICTIONARY.PROMOCODE_ARRAY_UPDATE, // this is to ROUTE
        payload: {
            promocodeArray: promocodeArray
        }
    }
}

// TODO - usuń
export const actFormikParamObjUpdate = (
    initialValues: any,
    validationSchemaShapeObj: any,
                                        ) => {
    return {
        type: RDX_SWITCH_DICTIONARY.FORMIK_PARAM_OBJ_UPDATE, // this is to ROUTE
        payload: {
            initialValues: initialValues,
            validationSchemaShapeObj: validationSchemaShapeObj,
        }
    }
}


export const actGalleryCommentReplySelect = (currentReplyCommentLineObj: IElementGalleryCommentLine) => {
    return {
        type: RDX_SWITCH_DICTIONARY.GALLERY_COMMENT_REPLY_SELECT, // this is to ROUTE
        payload: {
            currentReplyCommentLineObj: currentReplyCommentLineObj,
        }
    }
}

