import Divider from "@mui/material/Divider";
import SoftBox from "../components/SoftBox";
import SoftButton from "../components/SoftButton";
import {Link} from "react-router-dom";
import React from "react";
import IElementTutorialBodyRow from "../type/tutorial/IElementTutorialBodyRow";
import SoftTypography from "../components/SoftTypography";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import AfdTooltipImage from "../component/tooltip/AfdTooltipImage";
import SoftAlert from "../components/SoftAlert";
import {FormikErrors, FormikValues} from "formik";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AfdTooltipUser from "../component/tooltip/AfdTooltipUser";
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";
import ReadMoreOutlinedIcon from "@mui/icons-material/ReadMoreOutlined";
import IElementGalleryPublicList from "../type/gallery/IElementGalleryPublicList";
import ICtxReducerState from "../type/reducer_state/ICtxReducerState";
import ICtxGlobalConfig from "../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../hook/useCHGetContextSoftUI";
import {IElementGlobalConfig} from "../type/gloibal_config/IElementGlobalConfig";
import parse, {Element} from "html-react-parser";
import IGlobalFont from "../type/IGlobalFont";
import {CircularProgress} from "@mui/material";
import SoftAvatar from "../components/SoftAvatar";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import Iframe from "react-iframe";
import IUserInfo from "../type/IUserInfo";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ICtxFormResponse from "../type/context/ICtxFormResponse";
import IUniversalFormSubmitReponse from "../type/comprofiler/IUniversalFormSubmitReponse";

function getRegistrationConfirmActionButton(
    type: string,
    route: string,
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "white" = "secondary",
    label: string
) {
    return <>
        <Divider/>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
            {type === "internal" ? (
                <SoftButton
                    component={Link}
                    to={route}
                    variant="gradient"
                    color={color}
                    size="small"
                >
                    {label}
                </SoftButton>
            ) : (
                <SoftButton
                    component="a"
                    href={route}
                    variant="gradient"
                    color={color}
                    size="small"
                >
                    {label}
                </SoftButton>
            )}
            {/*<SoftBox display="flex">{renderMembers}</SoftBox>*/}
        </SoftBox>
    </>;
}


function getFormAlertTSX(
    intro: string,
    message: string | React.ReactNode,
    errorObjList?: FormikErrors<FormikValues>,
    alertColor: "error" | "warning" | "info" | "success" = "error"
) {
    return <SoftBox
        my={{xs: 1, sm: 1, md: 1}}
        mx={{xs: 1, sm: 2, md: 3, lg: 4}}
    ><SoftAlert
        color={alertColor}
    >
        <SoftTypography
            color={"white"}
            variant={"caption"}
        ><SoftBox
            pr={1}
            display={"inline"}
            /*display={"flex"}
            alignItems={"baseline"}*/
        >
            <SoftTypography
                color={"white"}
                variant={"caption"}
                fontWeight={"bold"}
            ><InfoOutlinedIcon
                fontSize={"small"}
            />&nbsp;
                {intro}:</SoftTypography></SoftBox>
            {message}
        </SoftTypography>
    </SoftAlert></SoftBox>;
}


function getTutorialImageTag(
    tutorialMessageImageRowObj: IElementTutorialBodyRow,
    mainpageImageId: number,
    index: number,
    iconRightMargin: number = 15,
    iconBottomMargin: number = 15,
    brightnessPerc: string = "100%"
) {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    // TODO - BUGFIX - mainpage image idzie z kontekstu strony
    let imageSrc: string = tutorialMessageImageRowObj.image_id == mainpageImageId ? 'tutorialMessageImageRowObj.mainpage_auto_image_id' :
        (globalConfigParamObj.isMobile ? tutorialMessageImageRowObj.image_light_thumb_src : tutorialMessageImageRowObj.image_big_thumb_src);
    let tooltipIndex: string = "tooltip_" + tutorialMessageImageRowObj.client_track_id + "_" + tutorialMessageImageRowObj.tip_seq;

    return <>
        <SoftBox
            component="img" src={imageSrc} shadow="lg" borderRadius="xl" width="100%"
            sx={{
                    filter: 'brightness(' + brightnessPerc + ')',
                }}
        >
        </SoftBox>
        <SoftBox
            sx={{
                position: "absolute",
                bottom: iconBottomMargin,
                right: iconRightMargin,
            }}
            data-for={tooltipIndex}
            data-tip=''
        >
            {
                (tutorialMessageImageRowObj.css_is_author_desc === 'css_yes_author_desc') ?
                    <><SoftTypography
                        variant="button"
                        color="white"
                        textTransform="uppercase"
                        fontWeight="medium"
                        sx={{
                            opacity: 0.9,
                        }}
                    >
                        <FaceOutlinedIcon
                            fontSize={"medium"}
                        />
                    </SoftTypography>
                        <AfdTooltipImage
                            tooltipId={tooltipIndex}
                            userId={tutorialMessageImageRowObj.author_user_id}
                            imageInfoObj={{
                                title: ''
                            }}
                        /></> : <></>
            }
        </SoftBox>
    </>;
}

function getWelcomeInstructorInfoTSX(
    infoPosition: "footer" | "tooltip",
    instructor: {
        image: any;
        full_name: string;
        rank_title: string
        user_id: number
    }
) {
    if (infoPosition === "footer") {
        return <>
            {instructor.rank_title ? (
                <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
                    {instructor.full_name}
                </SoftTypography>
            ) : null}
            {instructor.full_name ? (
                <SoftTypography variant="caption" color="text">
                    {instructor.rank_title}
                </SoftTypography>
            ) : null}
        </>;
    } else if (infoPosition === "tooltip") {
        return <>
            <AfdTooltipUser
                tooltipId='test'
                userId={instructor.user_id}
            />
        </>
    }
}

function getMUICardLinkTSX(type: "internal" | "external",
                           route: string,
                           label: string | React.ReactNode,
                           color: "inherit" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "text",
) {
    const cardActionStyles = {
        display: "inline-block",
        textDecoration: "none",

        "& .MuiTypography-root": {
            display: "flex",
            alignItems: "center",
        },

        "& .material-icons, .material-icons-round,": {
            fontSize: "1.125rem",
            transform: `translateX(2px)`,
            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
        },

        "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
            {
                transform: `translateX(6px)`,
            },
    };

    return <>
        {type === "internal" ? (
            /*// @ts-ignore*/
            <Link to={route} sx={cardActionStyles}>
                <SoftTypography
                    variant="body2"
                    color={color}
                    /*textTransform="capitalize"*/
                    component="span"
                >
                    {label}&nbsp;
                    <Icon sx={{fontWeight: "bold"}}>arrow_forward</Icon>
                </SoftTypography>
            </Link>
        ) : (
            <MuiLink href={route} target="_blank" rel="noreferrer" sx={cardActionStyles}>
                <SoftTypography
                    variant="body2"
                    color={color}
                    textTransform="capitalize"
                    component="span"
                >
                    {label}
                    <Icon sx={{fontWeight: "bold"}}>arrow_forward</Icon>
                </SoftTypography>
            </MuiLink>
        )}
    </>;
}

/*ng-click="psedudoScrollNextPage()"*/
export function getMoreinfoButtonTSX(
    buttonTitle: JSX.Element,
    buttonHrefUrl: string,
    buttonVariant: "outlined" | "contained" | "text" | "gradient" = "outlined",
    buttonIsInternal: boolean = true
) {
    return <SoftBox
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        py={1}
    >
        {
            buttonIsInternal ? <SoftButton
                variant={buttonVariant}
                color={"info"}
                size={"small"}
                endIcon={<ReadMoreOutlinedIcon
                    fontSize={"large"}
                />}
                href={buttonHrefUrl}
            >
                {buttonTitle}
            </SoftButton> :
                /*temp for transition*/
                <SoftBox
                component={"a"}
                href={buttonHrefUrl}
                target={"_blank"}
                rel={"noreferrer"}
            >
                {buttonTitle}
            </SoftBox>


        }
    </SoftBox>;
}

export {
    getRegistrationConfirmActionButton, getTutorialImageTag, getFormAlertTSX, getWelcomeInstructorInfoTSX,
    getMUICardLinkTSX
};

/* BOX zdjęcia globalny używany w Nowe zdjęcia / User profile */
export function getGlobalImageBoxTSX(currentImageObj: IElementGalleryPublicList,
                                     globalConfigParamObj: ICtxReducerState) {
    return <SoftBox
        component={Link}
        to={currentImageObj.url}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        title={"Idź do strony fotografii w Galerii Publicznej"}
        alt={currentImageObj.seo_image_alt}
    >
        {
            globalConfigParamObj.isMobile ? <SoftBox
                    component={"img"}
                    src={currentImageObj.image_thumb_src}
                    alt={currentImageObj.seo_image_alt}
                    shadow={currentImageObj.css_li_class == 'afd_visit_no' ?
                        "lg" : "none"}
                    borderRadius={"sm"}
                    sx={currentImageObj.css_li_class == 'afd_visit_no' ? {
                        maxWidth: '100%',
                        maxHeight: '60vh',
                    } : {
                        maxWidth: '90%',
                        maxHeight: '60vh',
                        opacity: '0.4',
                    }}
                /> :
                <SoftBox
                    component={"img"}
                    src={currentImageObj.image_thumb_src}
                    alt={currentImageObj.seo_image_alt}
                    shadow={currentImageObj.css_li_class == 'afd_visit_no' ?
                        "lg" : "none"}
                    borderRadius={"sm"}
                    sx={currentImageObj.css_li_class == 'afd_visit_no' ? {
                        maxWidth: '100%',
                        maxHeight: '60vh',
                    } : {
                        maxWidth: '70%',
                        maxHeight: '60vh',
                        opacity: '0.4',
                    }}
                />
        }
    </SoftBox>;
}

export function getMoreRecordButtonTSX(handleShowMoreClick: () => void) {
    return <SoftBox
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
        alignItems={'top'}
        py={3}

    ><SoftButton
        variant={"outlined"}
        color={"primary"}
        size={"small"}
        onClick={handleShowMoreClick}
    >
        pokaż więcej...
    </SoftButton></SoftBox>;
}

export function getGlobalConfigParserOption(elementGlobalValueListObj: IElementGlobalConfig[],
                                            formatterFunction: (any: string | JSX.Element | JSX.Element[]) => any
) {
    const parserOptions: {
        replace: (domNode: any) => any
    }
        = {
        replace: (domNode: any) => {
            if (domNode instanceof Element) {
                if (domNode.attribs.content === 'REGISTRATION_BUSYMUM_ADDITIONAL_PAY_PERCENT') {
                    return <>{formatterFunction(parse(elementGlobalValueListObj[0].registration_busymum_additional_pay_percent.toString()))}</>;
                }
                if (domNode.attribs.content === 'COURSE_CAL_CHAPTER_OFF_AFTER_BUSY_ON') {
                    return <>{formatterFunction(parse(elementGlobalValueListObj[0].course_close_busymum_day.toString()))}</>;
                }
            }
        }
    }
    return parserOptions;
}


export function getAmocoursePriceShortTextDescTSX(
    pIsInstallment: number,
    pInstrInstallmentCount: number,
    pInstrInPrice: number,
    pInstrOutRatio: number,
    pDiscountValue: number,
    pCategoryId: number,
    pInstrInDisc: number,
    pInstrOutDisc: number,
    courseUniSemester: number | null,
    isInstrutorCourse: number,
    uniSemNextCourseEventId: number,
    uniSemNextCoursePaymentCount: number
): string | JSX.Element {
    let pShortText: string = '';
    let pPackageDiscount: number = 0;
    let instrInDiscCurDate: number = 0;
    let instrOutDiscCurDate: number = 0;

    pPackageDiscount = 0;

    function getFullOnlyDescTSX(isInstrutorCourse: number) {
        return isInstrutorCourse == 1
            ? <SoftTypography
                variant={"caption"}
                color={"success"}
            > (z dodatkowym wsparciem)</SoftTypography>
            : <SoftTypography
                variant={"caption"}
                color={"success"}
            > (z oceną kompetencji)</SoftTypography>;
    }

    if (pCategoryId == 35) {
        return <>{pInstrInDisc} PLN <span
            className="content_shorttext_small">zamiast w sumie {pInstrInPrice} PLN {getFullOnlyDescTSX(isInstrutorCourse)}</span></>;
    } else if (courseUniSemester !== null) {
        /* UNI Payment count */
        let pInstrInstallmentCount = Math.max(uniSemNextCoursePaymentCount, 6);
        return <>{pInstrInDisc} PLN x {pInstrInstallmentCount} rat {getFullOnlyDescTSX(isInstrutorCourse)}</>;
        /* Regular courses */
    } else {
        instrInDiscCurDate = Math.round(Math.floor((pInstrInDisc / pInstrInstallmentCount) * 100) / 100);
        instrOutDiscCurDate = Math.round(Math.floor((pInstrOutDisc / pInstrInstallmentCount) * 100) / 100);

        /* Tylko z instruktorek */
        if (pInstrOutRatio == 1) {
            return <>
                {pIsInstallment == 0 ? pInstrInDisc : instrInDiscCurDate} PLN
                {pIsInstallment == 0 ? '' : ` x ${pInstrInstallmentCount} rat`}
                {(getFullOnlyDescTSX(isInstrutorCourse))}</>;
        } else {
            /* Zmiana 2023  - w przypadku obu wersji - bierz OUT PRICE */
            return <>
                {pIsInstallment == 0 ? pInstrOutDisc : instrOutDiscCurDate} PLN
                {pIsInstallment == 0 ? '' : ` x ${pInstrInstallmentCount} rat`}
                <span>
                    {/*lub*/}
                    {/*{pIsInstallment == 0 ? pInstrInDisc : instrInDiscCurDate} PLN*/}
                    {/*{pIsInstallment == 0 ? '' : ` x ${pInstrInstallmentCount} rat`}*/}
                </span>
            </>

        }
    }
}

/* */
export function getAfdPageHeaderTSX(specObjList: {
                                        name: string;
                                        label: string;
                                        value: string | React.ReactNode;
                                        desc?: string;
                                        url?: string;
                                        isVisible?: boolean
                                    }[]
) {
    return <>
        {
            specObjList.map((specObj, index) => {
                specObj.isVisible = specObj.isVisible === undefined ? true : specObj.isVisible;
                if (specObj.isVisible) {
                    return (
                        <SoftBox key={index}
                                 display={"flex"}
                                 flexDirection={"row"}
                                 justifyContent={"flex-start"}
                                 alignItems={"baseline"}

                        >
                            <SoftTypography
                                display={"inline-block"}
                                variant={"caption"}
                                color="text"
                                fontWeight="medium"
                                sx={{mr: 1}}
                            >{specObj.label}</SoftTypography>
                            <SoftTypography
                                variant={"button"}
                                color="text"
                                fontWeight={"light"}
                            >{specObj.value}</SoftTypography>
                        </SoftBox>
                    )
                }
            })
        }
    </>;
}

export function getMainpageMessageTSX(globalConfigParamObj: ICtxReducerState,
                                      elementGlobalFontObj: IGlobalFont,
                                      finalMessageHeading: string | JSX.Element | JSX.Element[],
                                      finalMessageDesc: string | JSX.Element | JSX.Element[]
) {
    return <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        pl={{xs: 0, md: 2, lg: 10}}
    ><SoftTypography
        variant={globalConfigParamObj.isMobile ? "h4" : "h2"} // musi być maóe, bo messageDesc się nie mieści
        display={"block"}
        color={"white"}
        pb={{xs: 1, sm: 2, md: 3, lg: 3}} // nie może być 0, bo nie ma marginesu
        sx={{
            fontFamily: elementGlobalFontObj.master_font_family,
        }}
    >
        {finalMessageHeading}</SoftTypography>
        <SoftTypography
            pl={{xs: 2, md: 5}}
            variant={globalConfigParamObj.isMobile ? "button" : "h5"}
            fontWeight={"regular"}
            display={"block"}
            color={"white"}
            sx={{
                fontFamily: elementGlobalFontObj.slave_font_family,
                fontSize: {
                    xs: "0.8rem", sm: "0.9rem", md: "1rem", lg: "1.1rem", xl: "1.2rem"  // dodatkowe zmkniejszenie dla XS aby zmieścić tekst nad tłem
                }
            }}
        >
            {finalMessageDesc}
        </SoftTypography></SoftBox>;
}

/*ng-click="psedudoScrollNextPage()"*/
export function getTableRankTSX(index: number) {
    return <><SoftTypography
        variant={"button"}
        display={"inline-block"}
        sx={{
            marginRight: "0.5ch"
        }}
    >#</SoftTypography>
        <SoftTypography
            variant={"h4"}
            display={"inline-block"}
        >{index + 1}</SoftTypography></>;
}

export function getCollapseMoreBarSmallTSX(borderWidth:
                                               { "0": number, "1": string, "2": string, "3": string, "4": string, "5": string },
                                           borderColor: any,
                                           collapse: number | boolean,
                                           variant: "button" | "caption" = "button",
                                           justifyContent: "flex-start" | "center" | "flex-end" = "flex-start"
) {
    return <SoftBox
        display="flex"
        justifyContent={justifyContent}
        alignItems="center"
        py={1}
        borderBottom={`${borderWidth[1]} solid ${borderColor}`}
        sx={{cursor: "pointer"}}
    >
        <SoftTypography variant={variant}
                        color={collapse == 1 ? "text" : "dark"} sx={{userSelect: "none"}}
                        fontWeight={"regular"}
        >
            więcej informacji...
        </SoftTypography>
        <SoftBox
            /*color={collapse == 1 ? "dark" : "text"}*/
            color={"text"}
            pl={1}
        >
            <Icon sx={{fontWeight: "bold"}} fontSize="small">
                {collapse == 1 ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            </Icon>
        </SoftBox>
    </SoftBox>;
}

/*ng-click="psedudoScrollNextPage()"*/
export function getIsSubmittingProgressTSX(isSubmitting: boolean) {
    return <>
        {(isSubmitting ?
            <SoftBox pt={{xs: 2, sm: 2, md: 2, lg: 3, xl: 3}}
                     px={{xs: 2, sm: 2, md: 2, lg: 3, xl: 3}}
            >
                <CircularProgress
                    color="info"
                />
            </SoftBox>
            : null)
        }
    </>;
}

/* AMOCOURSE confirmation panel: image with intro and spec */


export function getConfirmPanelTSX(
    imageSrc: string,
    imageAlt: string,
    titleTSX: JSX.Element,
    subtitleTSX: JSX.Element,
    elementObj: any,
    specObjList?: { key: string; value1: any; isVisible: boolean }[],
    endingTSX?: JSX.Element,
    footerTSX?: JSX.Element,
) {
    return <SoftBox p={2}>
        <SoftBox display="flex" alignItems="center">
            <SoftAvatar
                src={imageSrc}
                alt={imageAlt}
                size="xl"
                variant="rounded"/>
            <SoftBox ml={1} lineHeight={0}>
                <SoftTypography variant="body2">
                    {titleTSX}
                </SoftTypography>
                <SoftTypography variant="h5" fontWeight="medium"
                                p={1}
                >
                    {subtitleTSX}
                </SoftTypography>
                <SoftTypography
                    p={1}
                    display={'block'}
                    variant="caption"
                    fontWeight="regular"
                    color="text"

                >
                    {specObjList &&
                        specObjList.map((specObj) => {
                                if (specObj.isVisible) {
                                    return (
                                        <SoftBox key={specObj.key}>
                                            {specObj.value1}
                                        </SoftBox>
                                    )
                                }
                            }
                        )
                    }
                </SoftTypography>
            </SoftBox>
        </SoftBox>
        {
            endingTSX && <SoftBox my={2}>
                <SoftTypography variant="body2" color="text">
                    {endingTSX}
                </SoftTypography>
            </SoftBox>
            }
        {footerTSX}
        {/*<SoftBox display="flex" justifyContent="space-between" alignItems="center">
                   {action.type === "internal" ? (
                       <SoftButton
                           component={Link}
                           to={action.route}
                           variant="gradient"
                           color={action.color}
                           size="small"
                       >
                           {action.label}
                       </SoftButton>
                   ) : (
                       <SoftButton
                           component="a"
                           href={action.route}
                           variant="gradient"
                           color={action.color}
                           size="small"
                       >
                           {action.label}
                       </SoftButton>
                   )}
                   <SoftBox display="flex">{renderMembers}</SoftBox>
               </SoftBox>*/}
    </SoftBox>;
}
/* CARD dla posta dla przejrzystego rekomentowania innych postów w ramach rozdziału */
export function getChapterRecomListPostCardTSX(postImageSrc: string,
                                               dictTitleList: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode>,
                                               reportText: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode>,
                                               postUrl: string, postFragment: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode>,
                                               linkTitle: string | JSX.Element,
                                               postImageAlt?: string,
                                               ) {
    return (
        <>
            <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent={"flex-start"}
                alignItems="center"
                sx={{
                    borderTop: "1px solid #ccc",
                }}

            >
                <SoftBox
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                    justifyContent={"flex-start"}
                    pr={{xs: 1, sm: 2, md: 2}}
                    py={{xs: 0.5, sm: 0.5, md: 0.5}}
                >
                    <SoftBox
                    ><SoftAvatar
                        alt="Zdjęcie posta"
                        src={postImageSrc}
                        variant={"rounded"}
                        size={"xl"}
                    /></SoftBox>
                </SoftBox>

                <SoftBox
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                    justifyContent={"flex-start"}
                    px={{xs: 1, sm: 2, md: 2}}
                    py={{xs: 0.5, sm: 0.5, md: 0.5}}
                >
                    <SoftBox><SoftTypography
                        variant={"caption"}
                    >{dictTitleList}</SoftTypography>
                    </SoftBox>
                    <SoftBox><SoftTypography
                        variant={"button"}
                    >{reportText}</SoftTypography></SoftBox>
                    <SoftBox>
                        <SoftBox
                            component={Link}
                            to={postUrl}
                        >
                            <SoftTypography
                                variant={"body2"}
                            >{postFragment}</SoftTypography>
                        </SoftBox>
                        <SoftBox
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                        >
                            <SoftButton
                                component={Link}
                                to={postUrl}
                                variant={"text"}
                                color={"secondary"}
                                endIcon={<KeyboardArrowRightOutlinedIcon/>}
                            >
                                {linkTitle}
                            </SoftButton>
                        </SoftBox>
                    </SoftBox>

                </SoftBox>

            </SoftBox>
        </>
    );
}

export function getMovieVimeoGlobal(video_id: string) {
    var url = '//player.vimeo.com/video/' + video_id;
    var allowfullscreen = true;
    var frameborder = 0;


    return (
        <>
            <SoftBox
                mt={2}
            ><Iframe url={url}
                     id={video_id}
                     height="550px"
                     width="100%"
                     className="myClassname"
                     display="block"
                     allowFullScreen={allowfullscreen}
                     frameBorder={frameborder}
                     position="relative"
                     styles={{
                         "min-height": "30vw"
                     }}
            /></SoftBox>
        </>
        /*       <>
                        <div className="divTable">
        <div className="divTableBody">

        <div className="divTableRow">
        <div className="divTableCell content_intro">{row.content_video_intro}
        &lt;figure&gt;&lt;iframe className="lozad" data-src="//player.vimeo.com/video/{row.vimeo_id}?title=0&amp;amp;byline=0&amp;amp;portrait=0" width="{row.video_width}" height="{row.video_height}" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""&gt;
        &lt;/iframe&gt;
        &lt;figcaption&gt;Kliknij powyżej, aby obejrzeć...&lt;/figcaption&gt;&lt;/figure&gt;</div>
        </div>

        </div>
        </div>
               </>*/
    );
}

export interface ISpecNotificationIconListObjList {
    [key: string]:
        {
            title: string,
            description: string,
            page_item_group_array: string[],
            iconName: string,
            redirectionType: string,
            sqlColumnName: string,
            sqlParamObj: {
                const_param2: string,
                perf_user_id: number,
            },
            iconElement: JSX.Element,
        };
}

/* Globalny zestaw list powiadomień
 */
export function getSpecNotificationIconObjList(
                                               userInfoObj: IUserInfo,
                                               isMobile?: boolean
) {
    /* Better than swich for DECLARATIVE */
    let specNotificationIconListObjList:
        ISpecNotificationIconListObjList
        = {
        comment: {
            title: "komentarze do zdjęć",
            description: "komentarze do Twoich zdjęć w Galerii Publicznej",
            page_item_group_array: [
                'comment',
            ],
            iconName: 'notifications',
            redirectionType: 'internal',
            sqlColumnName: 'sql_query1',
            sqlParamObj: {
                const_param2: "comment",
                perf_user_id: userInfoObj.user_id,
            },
            iconElement: <AddPhotoAlternateOutlinedIcon
                fontSize={isMobile ? "large" : "large"}
            />,
        },
        instructor: {
            title: "kursowe od instruktora",
            description: "posty napisane przez instruktora na kursie",
            page_item_group_array: [
                'instructor',
            ],
            iconName: 'notifications',
            redirectionType: 'external',
            sqlColumnName: 'sql_query2',
            sqlParamObj: {
                const_param2: "instructor",
                perf_user_id: userInfoObj.user_id,
            },
            iconElement: <SchoolOutlinedIcon
                fontSize={isMobile ? "large" : "large"}
            />,
        },
        post: {
            title: "posty od Mam",
            description: "posty napisane przez Mamy na forach kursowych",
            page_item_group_array: [
                'post',
            ],
            iconName: 'notifications',
            redirectionType: 'external',
            sqlColumnName: 'sql_query2',
            sqlParamObj: {
                const_param2: "notification",
                perf_user_id: userInfoObj.user_id,
            },
            iconElement: <ChatOutlinedIcon
                fontSize={isMobile ? "large" : "large"}
            />,
        },
        mysent: {
            title: "moje posty",
            description: "posty napisane przez Ciebie na forach kursowych",
            page_item_group_array: [
                'post',
            ],
            iconName: 'notifications',
            redirectionType: 'external',
            sqlColumnName: 'sql_query2',
            sqlParamObj: {
                const_param2: "mysent",
                perf_user_id: userInfoObj.user_id,
            },
            iconElement: <CreateOutlinedIcon
                fontSize={isMobile ? "large" : "large"}
            />,
        },

    }
    return specNotificationIconListObjList;
}

/*ng-click="psedudoScrollNextPage()"*/
export function getFormResponseAlertTSX(
    storeFormResponseObj: IUniversalFormSubmitReponse | null
) {
    let alertColor = storeFormResponseObj?.status;
    let alertIcon = storeFormResponseObj?.status === "success" ? "check_circle" : "warning";

    if (storeFormResponseObj) {
        return (<SoftAlert
            color={alertColor}
        >
            <Icon fontSize="medium"
            >{
                alertIcon
            }</Icon>&nbsp;&nbsp;
            <SoftBox
                display="flex"
                flexDirection="column"
            ><SoftTypography
                variant="caption"
                fontWeight="bold"
                color="white"
                display="inline"
                pb={1}
            >{storeFormResponseObj.message_desc}
            </SoftTypography>
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="white"
                    display="inline"
                >{storeFormResponseObj.data}
                </SoftTypography></SoftBox>
        </SoftAlert>);
    } else {
        return <></>;
    }

}
