import React, {lazy, useState, useEffect, useMemo, ReactNode, FC, JSXElementConstructor, Suspense} from "react";
import {useLocation} from "react-router-dom";
import AfdErrorBoundary from './component/error_boundary/AfdErrorBoundary';
import RestApiService, {API_URL} from "./service/restapi/RestApiService";
import RestApiCommonService from "./service/restapi/RestApiCommonService";
import {useParams} from "react-router-dom";

import IUserInfo from "./type/IUserInfo";
import IRedirectionInfo from "./type/IRedirectionInfo";
import IRouteMenutype from "./type/IRouteMenutype";
import AfdLoadingFullpage from "./component/AfdLoadingFullpage";
import IRQOutputList from "./type/rq/IRQOutputList";
import AppRouter2Decode2Gantryr from "./AppRouter2Decode2Gantry";
import AppRouter2Decode2Gantry from "./AppRouter2Decode2Gantry";
import {debugLog, debugDir, getAddHost} from "./util/UtilReact";
import linkDictionary from "./linkDictionary";
import {useSelector} from "react-redux";
import IRdxState from "./type/reducer_state/IRdxState";

interface IAppRouter2Decode {
    userInfoObj: IUserInfo;
    routeCategory: string;
    routerMenuId?: number | null;
    routeObj?: IRouteMenutype;
    routeRand?: number;
    rest?: any
}

/* Retrieve the data based on selected route
- takes appObj will all info as input
* */
export default function AppRouter2Decode(
    {
        userInfoObj: userInfoObj,
        routeCategory: routeCategory,
        routerMenuId: routerMenuId,
        routeObj: routeObj,
        routeRand: routeRand,
        rest: rest,
    }: IAppRouter2Decode) {


    let initialState = {
        redirection_id: -1,
        menu_item_id: -1,
        oldurl: '',
        newurl: '',
        is_canonical: 1
    };
    const [redirectionObj, setRedirectionObj] = useState<IRedirectionInfo>(initialState);
    const rdxState = useSelector((state: IRdxState) => {
        return state;
    });
    const getUserIdFromProfileUrl = (): string | null => {
        const pathname = useLocation().pathname;
        /* example: /component/option,com_comprofiler/Itemid,71/lang,pl/task,userProfile/user,11759/ */
        const userIdMatch = pathname.match(/\/user,(\d+)/);
        //pathname.match(/\/user\/(\d+)/);
        return userIdMatch ? userIdMatch[1] : null;
    };

    /* Najlepsze miejsce, aby sprawdzić ACCESS - pod warunkiem, że jest
    - dodaj do sql_query2 w get_site_menu_global - aby pobrać informacje o access
     */
    /*TODO: myUrl.searchParams.append('reason', "access_denied");*/
    useEffect(() => {
            if (routerMenuId && routeObj) {
                if (routeObj.access > 0 &&
                    (userInfoObj.user_id == 0) &&
                    !(rdxState.session_id)
                ) {
                    // ! User is not logged in  / Redirect to login page
                    /* TODO: nie działa ponieważ przelatuje i przez User_id = 0 zanim IDENTIFY i !SESSION_ID        do sprawdzenia, czy isLoading na getUserInfo pomoże */
                    /*const myUrl = new URL(getAddHost(linkDictionary.LOGIN_URL));
                    window.location.href = myUrl.href;*/
                }
            }
        },
        [routerMenuId]
    );


    /* ! MUST resolve routerMenuId
        - either it is passed or from redirectionObj
     */
    const location = useLocation();
    const urlParams = useParams();

    let urlItemid: number | null = null;
    let urlOption: string | null = null;
    let urlView: string | null = null;
    let urlId: number | null = null;
    let urlUserId: number | null = null;
    let urlMid: number | null = null;
    let urlPromocode: string | null = null;


    let userIdString: string | null = getUserIdFromProfileUrl() ? getUserIdFromProfileUrl() : null;

    /* Reset the redirectionObj */
    /* useEffect(() => {
         setRedirectionObj(initialState);
     }, [
         routeCategory,
         location.pathname, /!* ponieważ na tej podstawie przeliczane recirectionObj dla 62 *!/
         routerMenuId, /!* musi być ponieważ redirectionObj liczone wewnątrz POST FACTUM *!/
     ]);*/

    /* 71 - user profile - is USER not USER_ID */
    urlUserId = Number(new URLSearchParams(location.search).get(
        (urlItemid == 71 ? 'user' : 'user_id')
    ));
    urlMid = Number(new URLSearchParams(location.search).get('mid'));
    /* url parameters*/
    urlItemid = Number(new URLSearchParams(location.search).get('Itemid'));
    urlOption = new URLSearchParams(location.search).get('option');
    urlView = new URLSearchParams(location.search).get('view');
    urlId = Number(new URLSearchParams(location.search).get('id'));
    urlPromocode = new URLSearchParams(location.search).get('promocode');

    if (routeCategory === 'menu') {
        // pass value
        if (routerMenuId == 692 && redirectionObj.object_user_id
        ) {
            routeCategory = 'evolution';
        }
    } else if (
        routeCategory === 'mainpage'
    ) {
        routerMenuId = 1;
    } else if (
        routeCategory === 'newbb'
    ) {
        routerMenuId = 577;
    } else if (
        routeCategory === 'chapter'
    ) {
        routerMenuId = 810;
    } else if (
        routeCategory === 'own_profile'
    ) {
        routerMenuId = 71;
        urlUserId = null;
    }
    else if (
        routeCategory === 'other_profile'
    ) {
        routerMenuId = 71;
        if (userIdString) {
            urlUserId = Number.parseInt(userIdString);
        }
    }     else if
    (routeCategory === 'tutorial'
        || routeCategory === 'newurl'
        || routeCategory === 'course_desc'
        || routeCategory === 'login_page'
    ) {

    } else if (
        routeCategory === 'any'
    ) {
        if (location.pathname === '/index.php' ||
            location.pathname === '//index.php' ||
            location.pathname.startsWith("//index.html") ||
            location.pathname.startsWith("/index.html") ||
            /^\/index_REACT\d+\.html/.test(location.pathname)
        ) {
            routerMenuId = urlItemid; // always

            if (urlItemid == 71) {
                routerMenuId = 71;
                routeCategory = urlUserId ? 'other_profile' : 'own_profile';
            } else if (urlItemid == 507 &&
                urlUserId != null
            ) {
                routeCategory = 'instructor';
            } else if (urlItemid == 163) {
                routerMenuId = 163;
                routeCategory = 'backend_entries';

            } else if (urlOption == 'com_jfusion') {
                routerMenuId = 577;
                routeCategory = 'newbb';
            } else {
                /* Override / wyklucz 32 */
                if (urlOption == 'com_amocourse' && urlItemid == 154) {
                    routeCategory = 'amocourse';
                }

                /* ! menu_item_id must be defined in jos4_redirection */
            }
        }
    }

    /* RQ */
    const rqRedirectionInfo: IRQOutputList = RestApiCommonService.getRedirectionInfoRQ(
        (routeCategory == 'amocourse' ||
        routeCategory == 'evolution' ||
        routeCategory == 'backend_entries' ||
        routeCategory == 'instructor' ||
        routeCategory == 'chapter_list' ||
        routeCategory == 'any'
            ? 'newurl' : routeCategory),
        (location.pathname + location.search),
        urlItemid,
    );


    /* Jeżeli zmienia się URL ID lub routeCategory to musi być czyszczenie  */
    useEffect(() => {
            setRedirectionObj(initialState);
        }
        , [
            urlItemid,
        ]);

    useEffect(() => {
            /*reset state on Redirection
            - containerObjList enough for the component to wait for the NEW data
            * */
            if (routeCategory == 'menu' ||
                routeCategory == 'backend_entries' ||
                routeCategory == 'mainpage' ||
                routeCategory == 'chapter' ||
                routeCategory == 'chapter_list' ||
                routeCategory == 'newbb'
            ) {
                setRedirectionObj({
                    redirection_id: -1,
                    menu_item_id: (routerMenuId === null ? undefined : routerMenuId),
                    oldurl: '',
                    newurl: '',
                    is_canonical: 1,
                    object_user_id: routeObj?.object_user_id, // dla EVOLUTION
                });
            } else if (routeCategory == 'evolution'
            ) {
                /* EVLUTION:
                    albo przez newurl,
                    albo OLDURL wygenerowany dla USER
                    albo z param=USER_ID */
                setRedirectionObj({
                    redirection_id: -1,
                    menu_item_id: (routerMenuId === null ? undefined : routerMenuId),
                    oldurl: '',
                    newurl: '',
                    is_canonical: 1,
                    object_user_id: routeObj?.object_user_id ? routeObj?.object_user_id : Number(urlUserId), // dla EVOLUTION
                });
            } else if (routeCategory == 'instructor'
            ) {
                /* konwersja z ANY do INSTRUCTOR */
                setRedirectionObj({
                    redirection_id: -1,
                    menu_item_id: (urlItemid ? urlItemid : -1),
                    oldurl: '',
                    newurl: '',
                    is_canonical: 1,
                    object_user_id: Number(urlUserId), // dla EVOLUTION
                });
            } else if (
                routeCategory == 'own_profile' ||
                routeCategory == 'other_profile'
            ) {
                /* konwersja z ANY do INSTRUCTOR */
                setRedirectionObj({
                    redirection_id: -1,
                    menu_item_id: (routerMenuId === null ? undefined : routerMenuId),
                    oldurl: '',
                    newurl: '',
                    is_canonical: 1,
                    object_user_id: urlUserId? Number(urlUserId) : userInfoObj.user_id, // dla EVOLUTION
                });
            }
            else if (routeCategory == 'newbb' && (urlItemid == 577 || urlItemid == 40)) {
                /* konwersja z ANY do INSTRUCTOR */
                setRedirectionObj({
                    redirection_id: -1,
                    menu_item_id: (urlItemid ? urlItemid : -1),
                    oldurl: '',
                    newurl: '',
                    is_canonical: 1,
                    image_id: Number(urlId), // dla EVOLUTION
                });

            }
            /* Capture ANY but only for those two Itemids, else - go to "else" */
            else if (routeCategory == 'any'
                && (urlItemid == 62 || urlItemid == 120)
            ) {
                if (urlItemid == 62) {
                    /*  konwersja z ANY do INSTRUCTOR
                    BUGFIX: to nie to samo co IF -> IF - tylko jeżeli any+62 a jeżeli nie to ddalej
                     */
                    setRedirectionObj({
                        redirection_id: -1,
                        menu_item_id: (urlItemid ? urlItemid : -1),
                        oldurl: '',
                        newurl: '',
                        is_canonical: 1,
                        image_id: Number(urlId), // dla EVOLUTION
                    });
                }
                if (urlItemid == 120) {
                    /*  konwersja z ANY do INSTRUCTOR
                    BUGFIX: to nie to samo co IF -> IF - tylko jeżeli any+62 a jeżeli nie to ddalej
                     */
                    setRedirectionObj({
                        redirection_id: -1,
                        menu_item_id: (urlItemid ? urlItemid : -1),
                        oldurl: '',
                        newurl: '',
                        is_canonical: 1,
                        object_message_id: urlMid ? urlMid : undefined,
                        image_id: Number(urlId), // dla EVOLUTION
                    });
                }

            }
            else {
                if (!rqRedirectionInfo.isLoading) {

                    if (rqRedirectionInfo?.data.data) {
                        let localRedirectionObj: IRedirectionInfo = rqRedirectionInfo.data.data[0];

                        /* Przetłumacz z ANY
                        - to utopia zakładać, ze w REACT będziezmy wołać tylko po oldurl
                        - jeżeli np: EVOLUTION per oldurl - to nie ma problemu, ale jeżeli z ANY to dopiero tutaj po QUERY
                         */
                        if (routeCategory == 'any') {
                            if (localRedirectionObj.object_desc_category_id) {
                                routeCategory = 'course_desc';
                            }
                            /* BUGFIX: dla TUTORIAL wiele wpisów w jos4_redirection z różnymi mid. Wymuś jeden i przepisz mid */
                            if (localRedirectionObj.menu_item_id == 276 ||
                                (localRedirectionObj.menu_item_id == 233)
                            ) {
                                routeCategory = 'tutorial';
                                if (urlMid)
                                    localRedirectionObj.object_message_id = urlMid;
                            }
                            if (urlItemid == 692 &&
                                urlUserId != null) {
                                routeCategory = 'evolution';
                                localRedirectionObj.object_user_id = urlUserId;
                            }
                        }

                        if (routeCategory == 'course_desc') {
                            localRedirectionObj.menu_item_id = 35;
                        }
                        if (routeCategory == 'gallery') {
                            localRedirectionObj.menu_item_id = 62;
                        }
                        if (routeCategory == 'tutorial_mid_in_param') {
                            localRedirectionObj.object_message_id = urlMid ? urlMid : -1;
                        }


                        setRedirectionObj(localRedirectionObj);
                        debugLog("Redirection FINAL: " + routeCategory + "/" + routerMenuId + ": " + JSON.stringify(localRedirectionObj));

                    }
                }
            }

        }
        , [
            routeCategory,
            urlItemid, /* ważne tylko dla any */
            location.pathname, /* ponieważ na tej podstawie przeliczane recirectionObj dla 62 */
            redirectionObj?.menu_item_id,
            routerMenuId, /* musi być ponieważ redirectionObj liczone wewnątrz POST FACTUM */
            (rqRedirectionInfo.isLoading ? 1 : 0),
        ]);


    function getContainerContent() {
        return (
            <>
                <AppRouter2Decode2Gantry
                    userInfoObj={userInfoObj}
                    routeCategory={routeCategory}
                    redirectionObj={redirectionObj}
                />
            </>
        );
    }

    return (
        <>
            {
                (redirectionObj.menu_item_id && redirectionObj.menu_item_id > 0) ?
                    getContainerContent()
                    :
                    <AfdLoadingFullpage
                        componentCategory="AppRouter2Decode"
                    />


            }
        </>
    );
}
