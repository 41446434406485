import React from 'react';
import SoftAlert from "../../components/SoftAlert";
import Icon from "@mui/material/Icon";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import linkDictionary from "../../linkDictionary";
import parse from "html-react-parser";

function AfdAlertGoAndLogin(props:
                                { templateTSX: JSX.Element | React.ReactNode,
                                    diagInfo?: string,
                                }
) {
    return <SoftBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        color="info"
        width={"100%"}
        className={props.diagInfo}
    >
        <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            py={3}
            px={5}
        >
            <Icon fontSize="medium"
            >warning</Icon>&nbsp;&nbsp;
            <SoftTypography
                variant="caption"
                fontWeight="bold"
                display="inline"
                /*color="primary"*/
                pb={1}
            >
                {props.templateTSX}
            </SoftTypography>
        </SoftBox>
        <SoftBox>
            <SoftButton
                component={"a"}
                href={linkDictionary.LOGIN_URL}
                variant={"contained"}
                color={"primary"}
                size={"small"}
            >Zaloguj się</SoftButton>
        </SoftBox>

        {/*{formSubmissionResult?.data?.result_error && <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="white"
                        display="inline"
                    >{formSubmissionResult.data.result_error}
                    </SoftTypography>
                }*/}
    </SoftBox>;
}

export default AfdAlertGoAndLogin;
