import React, {useState} from "react";

import Switch from "@mui/material/Switch";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import HTMLReactParser from "html-react-parser";
import parse from "html-react-parser";
import { HTMLReactParserOptions, Element } from 'html-react-parser';
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import { setTextSquareTagToDiv } from "../../util/UtilReact";
import {ErrorMessage, Field} from "formik";

/*https://mui.com/material-ui/react-switch/*/

function ElementJwallpapersNgFieldCheckbox(
    {
        formFieldObj,
        formikGantry,
        parserOptions
    }: IFormFieldProp
) {
    const [answersPost, setAnswersPost] = useState(false);

    var formFieldTip= (formFieldObj.field_tip ?
        setTextSquareTagToDiv(formFieldObj.field_tip) : "");

    /*https://www.npmjs.com/package/html-react-parser*/

    return (
    <>
        <SoftBox pt={1.5} pb={2} px={2} lineHeight={1.25}>
            <SoftBox display="flex" py={1} mb={0.25}>
                <SoftBox mt={0.25}
                            display={"flex"}
                >
                    {/*checked={answersPost}
                    onChange={() => setAnswersPost(!answersPost)}*/}
                    {/*!!! BUG FIX - checkbox not NECCESAIRLY touched to ERROR !!!
                        && formikGantry.touched[formFieldObj.field_name]

                         success={
                            formikGantry.values[formFieldObj.field_name] &&
                            formikGantry.values[formFieldObj.field_name].length > 0 &&
                            !formikGantry.errors[formFieldObj.field_name]}

                    */}
                    <Field
                        name={formFieldObj.field_name} as={Switch}
                        error={formikGantry.errors[formFieldObj.field_name]}
                        value={formikGantry.errors[formFieldObj.field_name]} // must be STRING to write to DOM !!! // TODO: check if this is correct
                        success={
                            (formikGantry.values[formFieldObj.field_name] &&
                                formikGantry.values[formFieldObj.field_name].length > 0 &&
                                !formikGantry.errors[formFieldObj.field_name]) ? "true" : "false"}
                    />
                    {(formFieldObj.field_is_required === 'true') ?
                        <SoftTypography variant={"h5"} color={"error"}>
                            &nbsp;*
                        </SoftTypography>
                        : null
                    }
                    <SoftTypography variant={"caption"} color={"error"}>
                        <ErrorMessage name={formFieldObj.field_name}/>
                    </SoftTypography>

                    {/*<Switch
                        name={formFieldObj.field_name}
                        id={formFieldObj.field_name}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />*/}
                </SoftBox>
                <SoftBox width="80%" ml={2}>
                    <SoftTypography variant="button" fontWeight="regular" color="text">
                    {/*        {htmlToReact()}*/}
                        {parse(
                            formFieldTip,
                                parserOptions
                        )
                        }
                    </SoftTypography>
                </SoftBox>
            </SoftBox>
        </SoftBox>
    </>
  );
}


export default ElementJwallpapersNgFieldCheckbox;
