/*import process from 'process';*/

const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
function isDev(): boolean
{

    return development;


}

export { isDev }
