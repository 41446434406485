import {debugLog} from "../../util/UtilReact";
import RestApiCommonService from "../../service/restapi/RestApiCommonService";
import RestApiAmocourseService from "../../service/restapi/RestApiAmocourseService";
import IAmocoursePromocodeInfo from "../../type/amocourse/IAmocoursePromocodeInfo";

import {useDispatch} from "react-redux";
import {actPromocodeArrayUpdate} from "../../redux_action/rdxAction";

const ASYNC_VALIDATION_TIMEOUT_IN_MS = 50;

const validateUsernameFunc = async (value: string, resolve: any) => {
    try {
        RestApiCommonService.getPromise(RestApiCommonService.getFunctionSelectResultUrl(
            'get_user_id_from_username',
            value,
            null
        )).then((response) => {
            if (response.data.function_select  != null &&
                typeof response.data.function_select !== 'undefined'
            ) {
                debugLog("validateUsernameFunc response.data.function_select", response.data.function_select);
                resolve(false);

            } else {
                resolve(true);
            }
        })
    } catch (error) {
        resolve(false);
    }
}


export {
    ASYNC_VALIDATION_TIMEOUT_IN_MS,
    validateUsernameFunc,
    /*validatePromocodeFunc*/
};

