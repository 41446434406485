/* Context module functions
Setters that pass value to dispatch function
  - this is just method to name different dispatchFunction calls with different values for each parameter
* */
type TGreetFunction = (arg0:
                           { type: string; value: any; }
) => any

const setMiniSidenav = (dispatchFunction: any, value: boolean) => dispatchFunction({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatchFunction: TGreetFunction, value: any) => dispatchFunction({ type: "TRANSPARENT_SIDENAV", value });
/*color*/
const setSidenavColor = (dispatchFunction: TGreetFunction, value: any) => dispatchFunction({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatchFunction: TGreetFunction, value: any) => dispatchFunction({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatchFunction: TGreetFunction, value: any) => dispatchFunction({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatchFunction: any, value: boolean) => dispatchFunction({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatchFunction: TGreetFunction, value: any) => dispatchFunction({ type: "DIRECTION", value });
const setLayout = (dispatchFunction: TGreetFunction, value: any) => dispatchFunction({ type: "LAYOUT", value });
/* Bierz parametry i odpalaj DISPATCH */
const setGeneralDispatch = (dispatchFunction: TGreetFunction, value: any, type: string) => dispatchFunction({ type: type, value });



export {
    setMiniSidenav,
    setTransparentSidenav,
    setSidenavColor,
    setTransparentNavbar,
    setFixedNavbar,
    setOpenConfigurator,
    setDirection,
    setLayout,
    setGeneralDispatch
};
