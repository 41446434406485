// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import React, {useState} from "react";

// Soft UI Dashboard PRO React components
import Switch from "@mui/material/Switch";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import {ErrorMessage, Field} from "formik";
import parse from "html-react-parser";
import IFormFormikGantry from "../../type/form/IFormFormikGantry";

/*https://mui.com/material-ui/react-switch/*/

export function getFormFieldCheckboxTSX(formFieldObj: IFormFieldInfo,
                                 formikGantry: IFormFormikGantry) {
    return <SoftBox mt={0.25}>
        <Field
            name={formFieldObj.field_name} as={Switch}
            value={"on"} // must be STRING to write to DOM
            error={formikGantry.errors[formFieldObj.field_name] && formikGantry.touched[formFieldObj.field_name]}
            checked={formikGantry.values[formFieldObj.field_name][0] == "on"}
            success={
                (formikGantry.values[formFieldObj.field_name] &&
                    formikGantry.values[formFieldObj.field_name].length > 0 &&
                    !formikGantry.errors[formFieldObj.field_name]) ? "true" : "false"}
        />
        <SoftTypography variant={"caption"} color={"error"}>
            <ErrorMessage name={formFieldObj.field_name}/>
        </SoftTypography>
    </SoftBox>;
}

function ElementGlobalNgFieldCheckbox(
        {
            formFieldObj,
            formikGantry,
            parserOptions
        }: IFormFieldProp
        ) {
    const [answersPost, setAnswersPost] = useState(false);

    let fieldTip = formFieldObj.field_tip ? parse(formFieldObj.field_tip) : '';
    let fieldLabel: string = formFieldObj.field_label ? formFieldObj.field_label : '';

    return (
    <>
        <SoftBox pt={1.5} pb={2} px={2} lineHeight={1.25}>
            <SoftBox display="flex" py={1} mb={0.25}>
                {getFormFieldCheckboxTSX(formFieldObj, formikGantry)}
                <SoftBox width="80%" ml={2}>
                    {
                        (fieldLabel &&
                            formFieldObj.field_group == 'profile_user_newsletter')
                        && <SoftTypography variant="button" fontWeight="regular" color="text">
                            {fieldLabel}
                        </SoftTypography>
                    }
                    <SoftTypography variant="button" fontWeight="regular" color="text">
                        {fieldTip}
                    </SoftTypography>
                </SoftBox>
            </SoftBox>
        </SoftBox>
    </>
  );
}

/*// typechecking props for FormField
ElementGlobalNgFieldCheckbox.propTypes = {
  field_label: PropTypes.string.isRequired,
};*/

export default ElementGlobalNgFieldCheckbox;
