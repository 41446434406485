// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";

// Custom styles for the SidenavItem
import {item, itemContent, itemArrow} from "../../examples/Sidenav/styles/sidenavItem";

// Soft UI Dashboard PRO React contexts
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import {useCHGetContextSoftUI} from "../../context/CtxSoftUIGlobalSetupByRdx";
import IGlobalMenuChild from "../../type/global_menu/IGlobalMenuChild";
import IUserInfo from "../../type/IUserInfo";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AfdTooltipHeartEpLine from "../../component/tooltip/AfdTooltipHeartEpLine";
import React, {useState} from "react";
import AfdTooltipMenuViewerLocked from "../../component/tooltip/AfdTooltipMenuViewerLocked";
import ModalClubGlobal from "../../component/modal/ModalClubGlobal";
import ModalClubViewerTooltip from "../../component/modal/ModalClubViewerTooltip";

/*// Setting default values for the props of SidenavItem
SidenavItem.defaultProps = {
  active: false,
  nested: false,
  children: false,
  open: false,
};*/

/*// Typechecking props for the SidenavItem
SidenavItem.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  nested: PropTypes.bool,
  children: PropTypes.node,
  open: PropTypes.bool,
};*/

interface IProp {
    name: string;
    collapseObj: IGlobalMenuChild;
    userInfoObj: IUserInfo;
    active?: boolean;
    nested?: boolean;
    children?: any;
    open?: boolean;
    onClick?: any;
}

function SidenavItem({
                         name,
                         collapseObj,
                         userInfoObj,
                         active = false,
                         nested = false,
                         children = false,
                         open = false,
                         ...rest
                     }: IProp
) {

    const [controllerParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const {miniSidenav, sidenavColor} = controllerParamObj;
    const [isModalLoader, setIsModalLoader] = useState(false);

    let tooltipId = "sideNav_" + collapseObj.menu_id;

    let isViewerLocked = userInfoObj.page_set_code == 'viewer' &&
        collapseObj.viewer_container_module == 0;
    return (
        <>
            {
                (collapseObj.locked_tooltip_desc && isViewerLocked) &&  <ModalClubViewerTooltip
                    title={"Hello"}
                    isLoader={isModalLoader}
                    setIsLoader={setIsModalLoader}
                    handleClose={() => {
                    }}
                    modalValueObj={{
                        collapseObj: collapseObj,
                    }}
                />
            }
            <ListItem {...rest} component="li" sx={item}
                      /*data-for={tooltipId}
                      data-tip=""*/
            >
                <SoftBox
                    onClick={isViewerLocked ? () => {
                        console.log("Modal open");
                        if (collapseObj.locked_tooltip_desc) {
                            setIsModalLoader(true);
                        } else {
                        }
                    } : () => {

                    }
                }
                    sx={
                    (theme: any) => itemContent(theme, {active, miniSidenav, name, nested})}
                         key={tooltipId}
                >
                    {
                        (isViewerLocked ?
                                <>
                                    <SoftBox
                                        display="flex"
                                        flexDirection={"row"}
                                    >
                                        <LockOutlinedIcon
                                            sx={{
                                                marginRight: '0.5em',
                                            }}
                                        />
                                        <ListItemText primary={name}
                                        />

                                    </SoftBox>

                                </>
                                :
                                <><ListItemText primary={name}/>
                                    {children && (
                                        <Icon component="i" sx={(theme) => itemArrow(theme, {open, miniSidenav})}>
                                            expand_less
                                        </Icon>
                                    )}</>
                        )
                    }

                </SoftBox>
            </ListItem>
            {children && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {children}
                </Collapse>
            )}
        </>
    );
}


export default SidenavItem;
