import IFormFieldInfo from "../type/form/IFormFieldInfo";
import SoftTypography from "../components/SoftTypography";

const md5 = require('md5');

/* Zwraca string CRC w zależności od wersji PROVIDER
*/
function get_p24_sign_string(p24_api_version: string,
                             p24_id_sprzedawcy: string,
                             p24_crc: string,
                             p24_session_id: string,
                             p24_currency: string,
                             p24_kwota: number
) {

    p24_api_version = p24_api_version || '2';
    if (p24_api_version == '2') {
        var sign_string = p24_session_id + "|" + p24_id_sprzedawcy + "|" + "" +
            p24_kwota + "|" + p24_crc;
        return md5(sign_string);
    }
    if (p24_api_version == '3.2') {
        sign_string = p24_session_id + "|" + p24_id_sprzedawcy + "|" + p24_kwota + "|" + p24_currency + "|" + p24_crc;
        return md5(sign_string);
    }

}

function getDateFormatPlDesc(isoFormatDateString: string): string {
    let t: string[] = isoFormatDateString.split(/[- :]/);
    let dni = new Array("niedziela", "poniedziałek", "wtorek", "środa",
        "czwartek", "piątek", "sobota");
    let miesiace = new Array("stycznia", "lutego", "marca", "kwietnia", "maja",
        "czerwca", "lipca", "sierpnia", "września", "października", "listopada",
        "grudnia");


    let data = new Date(Date.UTC(Number.parseInt(t[0]), Number.parseInt(t[1]) - 1, Number.parseInt(t[2])));
    let nrd = data.getDay();
    let nrm = data.getMonth();
    let dzien = data.getDate();
    let rok = data.getFullYear();
    return (""
        + " " + dzien + " " + miesiace[nrm]);

}

function extractFileNameAndExtension(url: string): { filename: string, extension: string } {
    const lastSlashIndex = url.lastIndexOf('/');
    const lastUnderscoreIndex = url.lastIndexOf('_');
    const extensionStartIndex = url.lastIndexOf('.');

    let filenameStartIndex;
    if (lastUnderscoreIndex > lastSlashIndex) {
        filenameStartIndex = lastUnderscoreIndex + 1;
    } else {
        filenameStartIndex = lastSlashIndex + 1;
    }

    const filename = url.substring(filenameStartIndex, extensionStartIndex);
    const extension = url.substring(extensionStartIndex + 1);

    return {filename, extension};
}

function extractDigitsFromString(str: string): number {
    const digits = str.match(/\d+/g)?.join('');
    return digits ? parseInt(digits) : NaN;
}

function scrollToAnchor(anchorId: string): void {
    const element = document.getElementsByName(anchorId)[0];

    if (element) {
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
            // @ts-ignore
            scrollMarginTop: "500px",
        });
        /*window.scrollBy(0, -10);*/
    }
}

export function getAlphabetLetter(index: number): string {
    const alphabets: string[] = 'abcdefghijklmnopqrstuvwxyz'.split('');

    if (index < 1 || index > 26) {
        throw new Error('Index out of bounds. Please provide index between 1 and 26');
    }

    return alphabets[index - 1];
}


export {
    get_p24_sign_string,
    getDateFormatPlDesc,
    extractFileNameAndExtension,
    extractDigitsFromString,
    scrollToAnchor
};

/*Które grupy bazują z wartościami na USER_ID przekazywanymi w position*/
export function getFieldGroupIsPersistent(field_group: string) {
    return field_group == 'profile_user_info' ||
        field_group == 'profile_user_www' ||
        field_group == 'profile_user_foto' ||
        field_group == 'profile_user_dict' ||
        field_group == 'profile_user_notification' ||
        field_group == 'profile_user_newsletter' ||
        field_group == 'profile_user_sharing' ||
        field_group == 'account_create_legal';
}
