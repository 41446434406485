import {useEffect} from "react";
import {useLocation} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import SoftBox from "../../../components/SoftBox";

/*import { setLayout } from "context";*/
import {setLayout} from "../../../redux_reducer/util/dispatchShortcut";
import useCHGetContextSoftUI from "../../../hook/useCHGetContextSoftUI";

// Typechecking props for the DashboardLayout
/*DashboardLayout.propTypes = {
    children: PropTypes.node.isRequired,
};*/

interface IPropDashboardLayout {
    children: any;
}

function DashboardLayout({children}: IPropDashboardLayout) {
    const [controller, dispatch] = useCHGetContextSoftUI();  //useCHSoftUIController
    const {miniSidenav} = controller;
    const {pathname} = useLocation();

    useEffect(() => {
        setLayout(dispatch, "dashboard");
    }, [pathname]);

    return (
        <SoftBox
            sx={(
                {
                    breakpoints, transitions,
                    functions: {pxToRem}
                }: any
            ) => ({
                px: {
                    xs: 1, sm: 1, md: 2, lg: 3, xl: 3
                },
                py: {
                  xs: 2, sm: 2, md: 2, lg: 3, xl: 3
                },
                position: "relative",

                [breakpoints.up("xl")]: {
                    marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
                    transition: transitions.create(["margin-left", "margin-right"], {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.standard,
                    }),
                },
            })}
        >
            {children}
        </SoftBox>
    );
}

export default DashboardLayout;
