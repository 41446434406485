import React, {useState} from "react";

import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import HTMLReactParser from "html-react-parser";
import parse from "html-react-parser";
import { HTMLReactParserOptions, Element } from 'html-react-parser';
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import { setTextSquareTagToDiv } from "../../util/UtilReact";
import {ErrorMessage, Field} from "formik";
import {FormControl, RadioGroup} from "@mui/material";

/*https://mui.com/material-ui/react-switch/*/

function ElementAmocourseRegInstallmentRadio(
    {
        formFieldObj,
        formikGantry,
        parserOptions
    }: IFormFieldProp
) {
    const [answersPost, setAnswersPost] = useState(false);

    var formFieldTip= (formFieldObj.field_tip ?
        setTextSquareTagToDiv(formFieldObj.field_tip) : "");

    /*https://www.npmjs.com/package/html-react-parser*/

    return (
    <>
        <SoftBox pt={1.5} pb={2} px={2} lineHeight={1.25}>
            <SoftBox display="flex"
                     flexDirection={"row"}
                     py={1} mb={0.25}>
                {(formFieldObj.field_is_required === 'true') ?
                    <SoftTypography variant={"h5"} color={"error"}>
                        *&nbsp;
                    </SoftTypography>
                    : null
                }
                <SoftBox mt={0.25}
                         display={"flex"}
                         alignItems={"center"}
                         justifyContent={"center"}
                         flexDirection={"column"}
                >
                    <FormControl> {/* !!! musi być FORM CONTROL, aby RadioGroup mogło przekazywać defaultValue*/}
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={formikGantry.values[formFieldObj.field_name]}
                    >
                    {/*checked={answersPost}
                    onChange={() => setAnswersPost(!answersPost)}*/}
                    {/*!!! BUG FIX - checkbox not NECCESAIRLY touched to ERROR !!!
                        && formikGantry.touched[formFieldObj.field_name]

                         success={
                            formikGantry.values[formFieldObj.field_name] &&
                            formikGantry.values[formFieldObj.field_name].length > 0 &&
                            !formikGantry.errors[formFieldObj.field_name]}

                    */}
                    {
                        formFieldObj.field_default_value.split("|").map((item, index) => {
                            return (
                                <SoftBox
                                    key={index}
                                    flexDirection={"row"}
                                ><Field
                                    key={index}
                                    name={formFieldObj.field_name} as={Radio}
                                    error={formikGantry.errors[formFieldObj.field_name]}
                                    value={item} // must be STRING to write to DOM
                                    success={
                                        (formikGantry.values[formFieldObj.field_name] &&
                                            formikGantry.values[formFieldObj.field_name].length > 0 &&
                                            !formikGantry.errors[formFieldObj.field_name]) ? "true" : "false"}
                                />
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        /*textTransform="capitalize"*/
                                    >
                                        {parse(setTextSquareTagToDiv(formFieldObj.field_label.split("|")[index]), parserOptions)}
                                    </SoftTypography></SoftBox>
                            )
                        })
                    }

            {/*        <Field
                        name={formFieldObj.field_name} as={Radio}
                        error={formikGantry.errors[formFieldObj.field_name]}
                        value={"on"} // must be STRING to write to DOM
                        success={
                            (formikGantry.values[formFieldObj.field_name] &&
                                formikGantry.values[formFieldObj.field_name].length > 0 &&
                                !formikGantry.errors[formFieldObj.field_name]) ? "true" : "false"}
                    />*/}

                    </RadioGroup>
                    </FormControl>
                    <SoftTypography variant={"caption"} color={"error"}>
                        <ErrorMessage name={formFieldObj.field_name}/>
                    </SoftTypography>

                    {/*<Switch
                        name={formFieldObj.field_name}
                        id={formFieldObj.field_name}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />*/}
                </SoftBox>
                {/*<SoftBox width="80%" ml={2}>
                    <SoftTypography variant="button" fontWeight="regular" color="text">
                        {parse(
                            formFieldTip,
                                parserOptions
                        )
                        }
                    </SoftTypography>
                </SoftBox>*/}
            </SoftBox>
        </SoftBox>
    </>
  );
}


export default ElementAmocourseRegInstallmentRadio;
