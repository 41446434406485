
const ElementMenuPartnerBig = lazy(() => import('./ElementMenuPartnerBig'));
const ElementMenuPartnerSmall = lazy(() => import('./ElementMenuPartnerSmall'));

import React, {useEffect, useState} from "react";
import Emptybox from "../../emptybox/Emptybox";
import AfdLoadingFullpage from "../../AfdLoadingFullpage";
import AfdContainerCard from "../../AfdContainerCard";
import { lazy , Suspense } from "react";

import Grid from "@mui/material/Grid";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";

import useCHContainerData from "../../../hook/useCHContainerData";
/*
https://learus.github.io/react-material-ui-carousel/
import Carousel from 'react-material-ui-carousel'*/

/*https://www.npmjs.com/package/react-multi-carousel*/
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


/*ng-click="psedudoScrollNextPage()"*/
//{containerRow.post_count}
export const responsiveSlideshowParamObj = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 7,
        slidesToSlide: 7 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 5,
        slidesToSlide: 5 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    }
};

const GetSitePartnerList = ({
                                 controllerInfoObj: controllerInfoObj,
                                 userInfoObj: userInfoObj,
                                 paramObj: paramObj
}: any) => {
    const components: any = {
        ElementMenuPartnerBig,
ElementMenuPartnerSmall,

    }
    var ElementComponent = components[controllerInfoObj.element1CamelName];
    var [elementObjList, getElementObjList, containerInfoObj, setContainerInfoObj] = useCHContainerData(controllerInfoObj);

    function getContainerContent() {
        let row = containerInfoObj;
        if (controllerInfoObj.containerName === 'CONTAINER_SITE_MAIN_MENU_PARTNERS') {
            return (
                <>
                    {/*// @ts-ignore*/}
                    <SoftBox mt={8}>
                        {/*// @ts-ignore*/}
                        <SoftBox textAlign="center">
                            {/*// @ts-ignore*/}
                            <SoftTypography variant="h6" opacity={0.5}>
                                Współpracują z nami i zaufali nam:
                            </SoftTypography>
                        </SoftBox>
                        {/*// @ts-ignore*/}
                        <SoftBox mt={5}>
                            <Grid container spacing={3}>
                                {
                                    elementObjList.map((element: any) => (
                                        <ElementComponent
                                            key={element.client_track_id}
                                            row={element}
                                        />
                                    ))
                                }
                            </Grid>
                        </SoftBox>
                    </SoftBox>
                </>
            );
        }
        if (controllerInfoObj.containerName === 'CONTAINER_SITE_PARTNER_LIST_MAINPAGE') {

            return (
                <>
                    {/*// @ts-ignore*/}
                    <SoftBox
                                mb={{xs: 2, sm: 2, md: 5, lg: 7, xl: 7}}
                    >
                        {/*// @ts-ignore*/}
                        {/*<SoftBox textAlign="center">

                        </SoftBox>*/}
                        {/*BUGFIX: z GRID i FLEX działa tak,że pakuje wszystkie elementy*/}
                        <SoftBox mt={{xs: 2, sm: 2, md: 3, lg: 3, xl: 3}}>

                            {/*<Grid container spacing={3}>*/}
                                <SoftBox
                                   /* display="flex"
                                    justifyContent="center"
                                    alignItems="center"*/
                                    >
                                    {/*<Carousel
                                        autoPlay={true}
                                        indicators={true}
                                        animation="slide"
                                        interval={4000}
                                        navButtonsAlwaysVisible={false}

                                    >*/}
                                    <Carousel
                                        swipeable={false}
                                        draggable={false}
                                        showDots={true}
                                        responsive={responsiveSlideshowParamObj}
                                        ssr={true} // means to render carousel on server-side.
                                        infinite={true}
                                        autoPlay={true}
                                        autoPlaySpeed={4000}
                                        keyBoardControl={false}
                                        customTransition="all 3.5"
                                        transitionDuration={1500}
                                        containerClass="carousel-container"
                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                        deviceType={"desktop"}
                                        dotListClass="custom-dot-list-style"
                                        itemClass="carousel-item-padding-40-px"
                                    >
                                {
                                    elementObjList.map((element: any) => (
                                        <ElementComponent
                                            key={element.client_track_id}
                                            row={element}
                                        />
                                    ))
                                }
                                    </Carousel>
                                </SoftBox>
                            {/*</Grid>*/}
                        </SoftBox>
                    </SoftBox>
                </>
            );
        }
    }

    return (
        <>
            <Suspense fallback={ <AfdLoadingFullpage
            componentCategory="AfdController_Suspense"
            controllerInfoObj={controllerInfoObj}
        />}>
        </Suspense>
            {
                (elementObjList?.length > 0 && containerInfoObj) ?
                    (
                        <SoftBox
                            mt={7}
                        ><AfdContainerCard
                            controllerInfoObj={controllerInfoObj}
                            userInfoObj={userInfoObj}
                            paramObj={paramObj}
                            elementObjList={elementObjList}
                        >
                            {getContainerContent()}
                        </AfdContainerCard></SoftBox>

                    ) : (<Emptybox
                        controllerInfoObj={controllerInfoObj}
                        userInfoObj={userInfoObj}
                        paramObj={paramObj}
                        elementObjList={elementObjList}
                    />)
            }
        </>
    );
}

export default GetSitePartnerList;
