import ReactTooltip from "react-tooltip";
import React from "react";
import AfdInstructorTooltip  from "./content/AfdInstructorTooltip";
import AfdUserTooltip from "./content/AfdUserTooltip";
import {Place} from "react-tooltip";

interface IProp {
    tooltipId: string,
    tooltipType?: "instructor" | "student";
    userId: number;
    introMessage?: string;
    /* "top" | "right" | "bottom" | "left" | "left,right" | "top,right" | "bottom,right" | "top,left" | "bottom,left"; */
    place?: Place
}


/*https://www.npmjs.com/package/react-tooltip*/
function AfdTooltipUser(
    {
        tooltipId = 'id_none',
        tooltipType = "instructor",
        userId,
        introMessage,
        place
    }: IProp
) {


    function getElementTooltip() {
        if (tooltipType === "instructor") {
            return <AfdInstructorTooltip team_letter={'A'} />
        }
        if (tooltipType === "student") {
            return <AfdUserTooltip
                urlUserId={userId}
                introMessage={introMessage}
            />
        }
    }


    return <ReactTooltip
        id={tooltipId}
        textColor={"#000000"}
        place={place}
        /*backgroundColor={"white"}*/
        type={"light"} //dark, success, warning, error, info, light
        delayShow={100}
        delayHide={100}
        getContent={(dataTip: any) =>
            getElementTooltip()
        }
    >
        {/**/}
    </ReactTooltip>;
}

export default AfdTooltipUser;
