import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for SoftInput
import SoftInputRoot from "../../components/SoftInput/SoftInputRoot";
import SoftInputWithIconRoot from "../../components/SoftInput/SoftInputWithIconRoot";
import SoftInputIconBoxRoot from "../../components/SoftInput/SoftInputIconBoxRoot";
import SoftInputIconRoot from "../../components/SoftInput/SoftInputIconRoot";

// Soft UI Dashboard PRO React contexts
// @ts-ignore
import { useCHGetContextSoftUI } from "context/CtxSoftUIGlobalSetupByRdx";

interface IProp {
    name: string;
    id: string;
    label: string;
    type: string;
    placeholder: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: "small" | "medium" | "large";
  icon?: {
    component: any | boolean;
    direction: "left" | "right" | "none";
  };
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
  value?: string;
  multiline?: boolean;
  rows?: number | string;
  inputProps?: any;
  defaultValue?: number | {};
  helperText?: string;
}
/*
  // Setting default values for the props of SoftInput
  SoftInput.defaultProps = {
    size: "medium",
    icon: {
      component: false,
      direction: "none",
    },
    error: false,
    success: false,
    disabled: false,
  };

  // Typechecking props for the SoftInput
  SoftInput.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
    icon: PropTypes.shape({
      component: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
      direction: PropTypes.oneOf(["none", "left", "right"]),
    }),
    error: PropTypes.bool,
    success: PropTypes.bool,
    disabled: PropTypes.bool,
  };*/
/* BUGFIS: do not add multiline here */
const SoftInput = forwardRef((
    { size = "medium",
      icon = { component: false, direction: "none" },
      error = false,
      success = false,
      disabled = false,
        value,
      ...rest }: IProp,
    ref) => {
  let template;
  const [controller] = useCHGetContextSoftUI();
  const { direction } = controller;
  const iconDirection = icon.direction;

  if (icon.component && icon.direction === "left") {
    template = (
      <SoftInputWithIconRoot
          /*// @ts-ignore*/
          ref={ref} ownerState={{ error, success, disabled }}>
        <SoftInputIconBoxRoot
            /*// @ts-ignore*/
            ownerState={{ size }}>
          <SoftInputIconRoot fontSize="small"
              /*// @ts-ignore*/
                             ownerState={{ size }}>
            {icon.component}
          </SoftInputIconRoot>
        </SoftInputIconBoxRoot>

        <SoftInputRoot          {...rest}
            /*// @ts-ignore*/
          ownerState={{ size, error, success, iconDirection, direction, disabled }}
        />
      </SoftInputWithIconRoot>
    );
  } else if (icon.component && icon.direction === "right") {
    template = (
      <SoftInputWithIconRoot
          /*// @ts-ignore*/
          ref={ref} ownerState={{ error, success, disabled }}>
        <SoftInputRoot
          {...rest}
            /*// @ts-ignore*/
          ownerState={{ size, error, success, iconDirection, direction, disabled }}
        />
        <SoftInputIconBoxRoot
            /*// @ts-ignore*/
            ownerState={{ size }}>
          <SoftInputIconRoot fontSize="small"
              /*// @ts-ignore*/
                             ownerState={{ size }}>
            {icon.component}
          </SoftInputIconRoot>
        </SoftInputIconBoxRoot>
      </SoftInputWithIconRoot>
    );
  } else {
    template = (
      <SoftInputRoot {...rest} ref={ref}
          /*// @ts-ignore*/
                     ownerState={{ size, error, success, disabled }} />
    );
  }

  return template;
});



export default SoftInput;
