import React from "react";
import SoftTypography from "../components/SoftTypography";
import SoftBox from "../components/SoftBox";
import ICtxGlobalConfig from "../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../hook/useCHGetContextSoftUI";
interface IProp {
    containerCard?: string;
    children: any;
}

const RokContainerTitle = ({children,
                               containerCard
                           }: IProp) => {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();

    let variantStyle: any = (containerCard === 'AfdCardDark' ? "h6" :
            (globalConfigParamObj.isMobile ? "h6" : "h5")
    );
    return (
        <SoftBox mb={1}
                 pt={containerCard === 'AfdCardDark' ? 1 : 0}
        >
            <SoftTypography
                variant={variantStyle}
                color={containerCard === 'AfdCardDark' ? "white" : "inherit"}
            >{children}</SoftTypography>
        </SoftBox>
    );
}

export default RokContainerTitle;
