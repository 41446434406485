import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom"; // npm i react-router-dom --save
import AfdContainer from "../component/AfdContainer";
import IContainerQuery from "../type/IContainerQuery";
import IParamObj from "../type/IParamObj";
import ISelector from "../type/ISelector";
import RestApiService from "../service/restapi/RestApiService";
import RestApiCommonService from "../service/restapi/RestApiCommonService";
import RestApiAmocourseService from "../service/restapi/RestApiAmocourseService";
import {debugLog, debugDir} from "../util/UtilReact";

import AfdLoadingFullpage from "../component/AfdLoadingFullpage";
import {processContainer, processElement, setCalculateTotal} from "../util/UtilReact";
import RestApiAuthorizationService from "../service/restapi/RestApiAuthorizationService";
import IAmocoursePriceInfo from "../type/amocourse/IAmocoursePriceInfo";
import CtxAmocourse from "../context/CtxAmocourse";
import IRQOutputList from "../type/rq/IRQOutputList";
import IGalleryImageInfo from "../type/gallery/IGalleryImageInfo";
import ICtxAmocourse from "../type/context/ICtxAmocourse";
import IParamRegProviderObj from "../type/IParamRegProviderObj";
/* take the container list and slect those which are  relevant for this position *
 */
const AfdSelectorAmocourse = ({
                               menuItemObj: menuItemObj,
                               userInfoObj: userInfoObj,
                               position: position,
                               containerAssocList: containerAssocList,
                               redirectionObj: redirectionObj,
                               formikGantry: formikGantry,
                               setFormikParamObj: setFormikParamObj
                           }: ISelector) => {

    const useQuery = () => new URLSearchParams(useLocation().search);
    const queryHookObj = useQuery();

    const ctxAmocourse: ICtxAmocourse = useContext(CtxAmocourse);
    var promiseArray: Promise<any>[] = [];
    let courseEventId = queryHookObj.get('id');

    //https://stackoverflow.com/questions/68042569/how-can-i-get-the-params-from-the-url-with-react
    function setParamObjSwitch(queryHookObj: any) {

       if (menuItemObj.id == 154) { //sidebar-b only
        /*    if (position === 'body-train-step-1'
                || position === 'body-train-step-2'
                || position === 'body-train-step-3'
                || position === 'position-mainpage-welcome'
            ) {

            }*/
           promiseArray.push(
               RestApiService.getPromise(RestApiAmocourseService.getAmocoursePromocodePostRegExcInfo(
                       (courseEventId ? parseInt(courseEventId) : -1),
                       userInfoObj.user_id,
                       formikGantry?.values.promocode
                   )
               )
           );

        }
    }



    const [paramObj, setParamObj] = useState<IParamObj>({});
    const [isDataReady, setIsDataReady] = useState(false);

    /* Recalculate PARAMS if */
    useEffect(() => {
        setParamObjSwitch(queryHookObj);
        if (promiseArray.length > 0 &&
            ctxAmocourse.storePriceArray &&
            ctxAmocourse.storeRegProviderObj
        ) {
            Promise.all(
                promiseArray
            ).then(
                (responseArray) => {

                    if (menuItemObj.id == 154 &&
                        ctxAmocourse?.storePriceArray &&
                        ctxAmocourse?.storeRegProviderObj
                    ) {
                        let internalAmocoursePromocodePostRegExcInfo = (responseArray[0].data ? responseArray[0].data : []);
                        let internalStoreRegProviderObj: IParamRegProviderObj = ctxAmocourse?.storeRegProviderObj;

                        /* Add to parameters set for different POSITIONS */
                        setParamObj(
                            (prevParamObj: IParamObj) => {
                                return {
                                    ...prevParamObj,
                                    position_param0: courseEventId,
                                    position_param1: queryHookObj.get('promocode'),
                                    amocoursePromocodeArrayObj: internalAmocoursePromocodePostRegExcInfo,
                                    paramRegProviderObj:  internalStoreRegProviderObj
                                }
                            });
                        setIsDataReady(true);
                    }
                }
            );

        }
    }, [menuItemObj.id,
        userInfoObj.user_id,
        courseEventId,
        (formikGantry ? formikGantry.values.is_installment : undefined),
        (formikGantry ? formikGantry.values.data_instr : undefined),
        (formikGantry ? JSON.stringify(formikGantry.values.is_instr_add) : undefined),
        (!!ctxAmocourse.storePriceArray),
        (!!ctxAmocourse.storeRegProviderObj),
        formikGantry?.values.promocode
        // TODO (redirectionObj?.object_desc_category_id ? redirectionObj.object_desc_category_id : -1), // w przypadku 35 ta sama strona, inne opisy
    ]);

    /* check if any containers exist for the position */
    return (
        <>
            {
                (typeof containerAssocList !== 'undefined'
                    && typeof paramObj !== "undefined"
                    && isDataReady === true
                        ? containerAssocList.map((element: IContainerQuery) => (
                            <AfdContainer
                                key={element.sequence}
                                menuItemObj={menuItemObj}
                                userInfoObj={userInfoObj}
                                position={position}
                                controllerInfoObj={element}
                                paramObj={paramObj}
                                redirectionObj={redirectionObj}
                                formikGantry={formikGantry}

                            />
                        )) :
                        <AfdLoadingFullpage
                            componentCategory={"AfdSimpleSelector"}
                            componentInfo={"p:" + position + "_m:" + menuItemObj.id + "_u:" + userInfoObj.user_id}
                        />
                )
            }
        </>
    );
}

export default AfdSelectorAmocourse;
