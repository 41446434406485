// prop-type is a library for typechecking of props
import React from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import {ErrorMessage, Field} from "formik";
import FormNgValidationYesTemplate from "../validation/FormNgValidationYesTemplate";

/* https://mui.com/material-ui/react-text-field/ */
import FormNgValidationNoFieldTemplate from "../validation/FormNgValidationNoFieldTemplate";
import {getFieldGroupIsPersistent} from "../../util/UtilTS";


function ElementGlobalNgFieldRow(
    {
        paramObj,
        formFieldObj,
        formikGantry,
        ...rest
    }: IFormFieldProp
) {


    /*type={firstName.type}
    label={firstName.label}
    name={firstName.name}
    value={firstNameV}
    placeholder={firstName.placeholder}
    error={errors.firstName && touched.firstName}
    success={firstNameV.length > 0 && !errors.firstName}*/

    /*Reducing boilerplate
    The code above is very explicit about exactly what Formik is doing. onChange -> handleChange, onBlur -> handleBlur, and so on.
        However, to save you time, Formik comes with a few extra components to make life easier and less verbose: <Form />, <Field />,
    and <ErrorMessage />*/


    return (
        <>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    /*textTransform="capitalize"*/
                >
                    {formFieldObj.field_label}
                </SoftTypography>
                {(formFieldObj.field_is_required === 'true') ?
                    <SoftTypography
                        variant={"h5"} color={"error"}
                        display="inline"
                    >
                        &nbsp;*
                    </SoftTypography>
                    : null
                }
            </SoftBox>
            {/*// @ts-ignore*/}

            {/*- "error" is MATERIAL UI fiel, success is NOT:
                    https://mui.com/material-ui/api/text-field/
                    error	bool	false
                    If true, the label is displayed in an error state.
                    */}

            {/*
        https://formik.org/docs/api/field
            AS = as can either be a React component or the name of an HTML element to render.
            Formik will automagically inject onChange, onBlur, name,
            and value props of the field designated by the name prop to the (custom) component.

            COMPONENT = can either be a React component or the name of an HTML element to render. All additional props will be passed through.
            */}
            <Field
                name={formFieldObj.field_name} as={SoftInput}
                error={formikGantry.errors[formFieldObj.field_name] && formikGantry.touched[formFieldObj.field_name]}
                defaultValue={formikGantry.values[formFieldObj.field_name]} // działa! i dodaje wartości dalej
                success={
                    formikGantry.values[formFieldObj.field_name] &&
                    formikGantry.values[formFieldObj.field_name].length > 0 &&
                    !formikGantry.errors[formFieldObj.field_name]}
                placeholder={formFieldObj.field_placeholder}

                /*      startAdornment={
                          <InputAdornment position="start">
                              <AccountCircle />
                          </InputAdornment>
                      }*/
                variant={"standard"}
                fullWidth={formFieldObj.field_is_fullwidth == 1 ? true : false}
                onBlur={formikGantry.handleBlur}
                /*BUGFIX: bez mutliline nie wyświetla wartości dla Field ustawionej przez setFieldValue*/
                multiline={
                    getFieldGroupIsPersistent(formFieldObj.field_group) ? true : false}
                rows={1}
            />
            {formFieldObj.field_tip && <SoftBox
                lineHeight={1}
            >
                <SoftTypography
                    variant={"caption"}
                    fontWeight={"regular"}
                    color={"secondary"}
                >
                    {formFieldObj.field_tip}
                </SoftTypography>

            </SoftBox>
            }
            <>
                {
                    /* NO VALIDATION */
                    ((formFieldObj.ng_validation_no_template
                            && formikGantry.errors[formFieldObj.field_name]
                        ) &&
                        <FormNgValidationNoFieldTemplate
                            formFieldObj={formFieldObj}
                            formikGantry={formikGantry}
                            templateModule={formFieldObj.ng_validation_no_template}
                        />)
                }</>
            <>{
                /* Na razie wszystkie YES-komunikaty dla pól tekstowych po wypełnieniu, nie przed */
                ((formFieldObj.ng_validation_yes_template
                        && !formikGantry.errors[formFieldObj.field_name]
                        && formikGantry.values[formFieldObj.field_name].length > 0
                    ) &&
                    <FormNgValidationYesTemplate
                        formFieldObj={formFieldObj}
                        formikGantry={formikGantry}
                        templateModule={formFieldObj.ng_validation_yes_template}
                    />)
            }</>

            <SoftTypography variant={"caption"} color={"error"}>
                <ErrorMessage name={formFieldObj.field_name}/>
            </SoftTypography>

            {/*<SoftInput
            name={formFieldObj.field_name}
            id={formFieldObj.field_name}
                label={formFieldObj.field_label}
                   type={formFieldObj.field_type}
                   placeholder={formFieldObj.field_placeholder}
                   // @ts-ignore
                   value={value}
                   onChange={onChange}
            helperText={"xx"}
        />*/}
        </>
    );
}

/*// typechecking props for FormField
ElementGlobalNgFieldRow.propTypes = {
  field_label: PropTypes.string.isRequired,
};*/

export default ElementGlobalNgFieldRow;
