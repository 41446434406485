import {createContext} from "react";
import IPusherObj from "../type/context/IPusherObj";

/*
    The problem here is that you need to help TypeScript understand the types of the objects you're dealing with.
    https://blog.logrocket.com/how-to-use-react-context-typescript/
 */
const defaultContext: IPusherObj = {
        pusherObj: null
    };
const CtrPusher = createContext<IPusherObj>(defaultContext);
//const AppObjContext = createContext(null);

export default CtrPusher;
