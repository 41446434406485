import React, {useEffect, useState} from "react";
import IContainerQuery from "../type/IContainerQuery";
import SoftBox from "../components/SoftBox";
import Card from "@mui/material/Card";
import IUserInfo from "../type/IUserInfo";
import IParamObj from "../type/IParamObj";
import ElementGlobalPageTitleTooltip from "../title/ElementGlobalPageTitleTooltip";
import ElementGlobalModuleTitleTooltip from "../title/ElementGlobalModuleTitleTooltip";
import ElementGlobalModuleTitleH3 from "../title/ElementGlobalModuleTitleH3";
import {Transition} from "react-transition-group";
import Emptybox from "./emptybox/Emptybox";
import IJos4Menu from "../type/IJos4Menu";
import ICtxGlobalConfig from "../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../hook/useCHGetContextSoftUI";
import IResp from "../type/resp/IResp";

interface IAfdContainerCardProps {
    controllerInfoObj: IContainerQuery;
    userInfoObj: IUserInfo;
    paramObj: IParamObj;
    elementObjList?: any[];
    menuObj?: IJos4Menu;
    children?: any;
}

interface IContainerCssAnim {
    [key: string]: {
        yesMobile: {
            delay: number;
            duration: number;
        },
        noMobile: {
            delay: number;
            duration: number;
        }
    }
}

export default function AfdContainerCard({
                                     controllerInfoObj: controllerInfoObj,
                                     userInfoObj: userInfoObj,
                                     paramObj: paramObj,
                                     elementObjList: elementObjList,
                                        menuObj: menuObj,
                                    children
                                 }: IAfdContainerCardProps) {

    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    let containerCard = globalConfigParamObj.isMobile ? controllerInfoObj.container_card_mobile : controllerInfoObj.container_card;
    let containerCssAnim = controllerInfoObj.container_css_anim;

    const container_css_anim: IContainerCssAnim = {
        page_msg_1st: {
                noMobile: {
                    delay: 200,
                    duration: 2000
                },
                yesMobile: {
                    delay: 200,
                    duration: 1000
                }
        },
        page_msg_2nd: {
            noMobile: {
                delay: 1750,
                duration: 750
            },
            yesMobile: {
                delay: 1750,
                duration: 325
            },
        },
        page_msg_3rd: {
            noMobile: {
                delay: 2250,
                duration: 750
            },
            yesMobile: {
                delay: 2250,
                duration: 325
            },
        },
        standard: {
            noMobile: {
                delay: 100,
                duration: 1000
            },
            yesMobile: {
                delay: 100,
                duration: 500
            },
        },
        banner_hold: {
            noMobile: {
                delay: 2250,
                duration: 1250
            },
            yesMobile: {
                delay: 2250,
                duration: 625
            },
        },
        footer_delay: {
            noMobile: {
                delay: 4000,
                duration: 1000
            },
            yesMobile: {
                delay: 4000,
                duration: 500
            },
        }


    };
        /* Wyjatki GLOBAL */
    if (controllerInfoObj.php_function_code === 'get_site_global_menu') {
        if (controllerInfoObj.page_item_id == 1) {
            containerCssAnim = 'page_msg_3rd';
        }
    }
    let mobileKeyName: "yesMobile" | "noMobile" = (globalConfigParamObj.isMobile === true) ? 'yesMobile' : 'noMobile';

    const defaultStyle = {
        transition: `opacity ${container_css_anim[containerCssAnim][mobileKeyName].duration}ms ease-in-out`,
        opacity: 0,
    }

    var transitionStyles: any = {
        entering: { opacity: 1 },
        entered:  { opacity: 1 },
        exiting:  { opacity: 0 },
        exited:  { opacity: 0 },
    };

    const [inProp, setInProp] = useState(false);
    useEffect(() => {
        setTimeout(function(){
            setInProp(true);
        }, container_css_anim[containerCssAnim][mobileKeyName].delay);
    }
    , []);


    function renderTitleComponent() {
        if (controllerInfoObj.module_level && controllerInfoObj.module_title) {
            switch (controllerInfoObj.module_level) {
                case 'h1':
                    return <ElementGlobalPageTitleTooltip controllerInfoObj={controllerInfoObj}/>;
                case 'h2':
                    return <ElementGlobalModuleTitleTooltip controllerInfoObj={controllerInfoObj}/>;
                case 'h3':
                    return <ElementGlobalModuleTitleH3 controllerInfoObj={controllerInfoObj}/>;
            }
        } else {
            return null;
        }
    }

    /* BUGFIX: nie generuj AfdCard tam gdzie nie ma CONTAINER dla PAGE_SET_CODE */
    if (!controllerInfoObj.containerName) return null;

    /* BUGFIX: menu zwija się kiedy w Card */
    if (controllerInfoObj.php_function_code === 'get_site_global_menu' ||
        containerCard === 'NoSoftBox'
    )    {
            return (
                <div>
                    <Transition in={inProp} timeout={500}>
                        {state => (
                            <div style={{
                                ...defaultStyle,
                                ...transitionStyles[state]
                            }}>
                                {renderTitleComponent()}
                                {children}
                            </div>
                        )}
                    </Transition>
                </div>
            )
    }    else {

        var cardPadding:IResp = {xs: 1, sm: 2, md: 2, lg: 3, xl: 3};
        if (containerCard === 'AfdCardDark')
            cardPadding = {xs: 1, sm: 2, md: 2, lg: 3, xl: 3};
        if (containerCard === 'AfdGalleryCard')
            cardPadding = {xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5, xl: 0.5};

        /*Wszystkie inne CONTROLLER*/
            return (
                <div>
                    <Transition in={inProp} timeout={500}>
                        {state => (
                            <div style={{
                                ...defaultStyle,
                                ...transitionStyles[state]}}
                            >

                                {(containerCard === 'AfdCard' ||
                                    containerCard === 'AfdGalleryCard' ||
                                    containerCard === 'AfdCardDark'
                                ) ? (
                                    <SoftBox p={cardPadding}
                                    >
                                    <Card
                                        sx={{
                                            background: (containerCard === 'AfdCardDark'  ?  "black" : "white"),
                                        }
                                    }
                                    >
                                        <SoftBox py={{xs: 3, sm: 2, md: 1, lg: 1, xl: 1}}
                                                 px={3}>
                                            {renderTitleComponent()}
                                            {children}
                                        </SoftBox>
                                    </Card>
                                    </SoftBox>
                                        ) :
                                    (
                                        /* No AfdCard - empty */
                                        /* px:	padding-left, padding-right */
                                    <SoftBox py={{xs: containerCard === 'AfdNoCardYTight' ? 0.5 : 2, md: 2, xl: 2}}
                                             px={{xs: 0, sm: 1, md: 2, lg: 2, xl: 2}}
                                             >
                                        {renderTitleComponent()}
                                        {children}
                                    </SoftBox>
                                )}
                            </div>

                        )}
                    </Transition>

                </div>


            )
        }
};
