// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import React from "react";


// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import parse, {HTMLReactParserOptions} from "html-react-parser";
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import {Icon} from "@mui/material";
import {setTextSquareTagToDiv} from "../../util/UtilReact";
import FormNgValidationYesTemplate from "../validation/FormNgValidationYesTemplate";
import {useSelector} from "react-redux";
import IRdxState from "../../type/reducer_state/IRdxState";
import FormNgValidationNoSubmitTemplate from "../validation/FormNgValidationNoSubmitTemplate";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import {CircularProgress} from "@mui/material";
function ElementGalleryCommentSubmit(
        {
            formFieldObj,
            formikGantry,
            parserOptions,
            disabled,
            onClick
        }: IFormFieldProp
        ) {
    const rdxState = useSelector((state: IRdxState) => {
        return state;
    });

    const isLastStep = false;

/*    const handleNext = () => setActiveStep(activeStep + 1);*/

    return (
    <>
        <SoftBox
            display="flex"
            flexDirection={"column"}
            justifyContent="center"
            alignItems="center"

        >
            {formikGantry.isSubmitting && <CircularProgress
                color="info"
                size={30}
                sx={{
                    /*ml: 0.3,*/
                }}
            />
            }
            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                <SoftButton
                    variant="gradient"
                    color="secondary"
                    disabled={disabled}
                    isSubmit={formFieldObj.field_type === "submit"}
                    /*onClick={!isLastStep ? handleNext : undefined}*/
                    onClick={onClick}
                    fullWidth={formFieldObj.field_is_fullwidth == 1 ? true : false}
                    iconOnly={true}
                    sx={{}}
                >
                    <SendOutlinedIcon/>
                    {/*<Icon>favorite</Icon>*/}
                </SoftButton></SoftBox>
        </SoftBox>
            <>
                {
                    /*NO TEMPLATE*/
                ((formFieldObj.ng_validation_no_template
                        && rdxState?.form_submission_result?.status
                        && rdxState?.form_submission_result?.status === "error"
                    ) &&
                    <FormNgValidationNoSubmitTemplate
                        formFieldObj={formFieldObj}
                        formikGantry={formikGantry}
                        templateModule={formFieldObj.ng_validation_no_template}
                        formSubmissionResult={rdxState?.form_submission_result}
                    />)
            }</>
            <>{
                /*YES TEMPLATE*/
                ((formFieldObj.ng_validation_yes_template
                        && rdxState?.form_submission_result?.status
                        && rdxState?.form_submission_result?.status === "success"
                    ) &&
                    <FormNgValidationYesTemplate
                        formFieldObj={formFieldObj}
                        formikGantry={formikGantry}
                        templateModule={formFieldObj.ng_validation_yes_template}
                        formSubmissionResult={rdxState?.form_submission_result}
                    />)
            }</>


    </>
  );
}

/*// typechecking props for FormField
ElementGalleryCommentSubmit.propTypes = {
  field_label: PropTypes.string.isRequired,
};*/

export default ElementGalleryCommentSubmit;
