import React from 'react';
import RestApiService from "./RestApiService";

import {API_URL} from "./RestApiService";
import {useQuery} from "react-query";
import IRQOutputSingle from "../../type/rq/IRQOutputSingle";

import {rqOptionStaticObj} from "../rqOptionStaticObj"
import IRQQueryKey from "../../type/rq/IRQQueryKey";
import getUserImageNavAssoc from "../../component/sourcerer/GetUserImageNavAssoc";


class RestApiImageService extends RestApiService {

    /* Mtehod from:
        administrator/components/php_scripts/internal/AfdSelectorImageFactory.php */

    /* TODO  react query keys passed */
    static getSelectorMainpageImageAssoc(imageId: number | null): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("selector_image_select", 'getSelectorMainpageImageAssoc');
        if (imageId) {
            myUrl.searchParams.append("image_id", imageId.toString());
        }
        return myUrl.href;
    }

    static getSelectorMainpageImageAssocRQ(
        imageId: number | null,
        isEnabled: boolean = true
    ): IRQOutputSingle {
        let rqQueryKey: IRQQueryKey = ['getSelectorMainpageImageAssoc', imageId];
        const {isLoading, isError, data, error, isIdle} = useQuery(
            rqQueryKey,
            ({queryKey}) => RestApiService.getPromise(RestApiImageService.getSelectorMainpageImageAssoc(queryKey[1])),
            {
                ...rqOptionStaticObj,
                enabled: isEnabled
            }
        );
        return {isLoading, isError, data, error, isIdle};
    }


    static getUserImageNavAssoc(imageId: number, viewName: string, range: number): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("get_user_image_nav_assoc", '1');
        myUrl.searchParams.append("image_id", imageId.toString());
        myUrl.searchParams.append("view_name", viewName);
        myUrl.searchParams.append("range", range.toString());
        return myUrl.href;
    }

    static getUserImageNavAssocRQ(
        imageId: number,
        viewName: string,
        range: number,
        isEnabled: boolean = true
    ): IRQOutputSingle {
        let rqQueryKey: IRQQueryKey = ['getUserImageNavAssoc', imageId, viewName, range];
        const {isLoading, isError, data, error, isIdle} = useQuery(
            rqQueryKey,
            ({queryKey}) => RestApiService.getPromise(RestApiImageService.getUserImageNavAssoc(
                queryKey[1],
                queryKey[2],
                queryKey[3]
            )),
            {
                ...rqOptionStaticObj,
                enabled: isEnabled
            }
        );
        return {isLoading, isError, data, error, isIdle};
    }


    static getSelectorImageArticleDescAssoc(imageId: number | null, categoryId: number): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("selector_image_select", 'getSelectorImageArticleDescAssoc');
        if (imageId) {
            myUrl.searchParams.append("image_id", imageId.toString());
        }
        myUrl.searchParams.append("category_id", categoryId.toString());
        return myUrl.href;
    }

    static getSelectorImageArticleDescAssocRQ(
        imageId: number | null,
        categoryId: number,
        isEnabled: boolean = true
    ): IRQOutputSingle {
        let rqQueryKey: IRQQueryKey = ['getSelectorImageArticleDescAssoc', imageId, categoryId];
        const {isLoading, isError, data, error, isIdle} = useQuery(
            rqQueryKey,
            ({queryKey}) => RestApiService.getPromise(RestApiImageService.getSelectorImageArticleDescAssoc(queryKey[1], queryKey[2])),
            {
                ...rqOptionStaticObj,
                enabled: isEnabled
            }
        );
        return {isLoading, isError, data, error, isIdle};

    }

    static getSelectorImageAmocourseAssoc(courseEventId: number): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("selector_image_select", 'getSelectorImageAmocourseAssoc');
        if (courseEventId) {
            myUrl.searchParams.append("course_event_id", courseEventId.toString());
        }
        return myUrl.href;
    }

    static getSelectorImageAmocourseRQ(
        courseEventId: number,
        isEnabled: boolean = true
    ): IRQOutputSingle {
        /*
        https://react-query-v3.tanstack.com/guides/query-keys
        you can use an array with a string and any number of serializable objects to describe it.
        It's common to pass an object of additional options
           BUT: The following query keys, however, are not equal. Array item order matters!
        */

        let rqQueryKey: IRQQueryKey = ['getSelectorImageAmocourseAssoc', courseEventId];
        const {isLoading, isError, data, error, isIdle} = useQuery(rqQueryKey,
            ({queryKey}) => RestApiService.getPromise(RestApiImageService.getSelectorImageAmocourseAssoc(queryKey[1])),
            {
                ...rqOptionStaticObj,
                enabled: isEnabled
            }
        );

        return {isLoading, isError, data, error, isIdle};
    }

    static getSelectorInstructorImageAssoc(userId: number | null): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("selector_image_select", 'getSelectorInstructorImageAssoc');
        if (userId) {
            myUrl.searchParams.append("user_id", userId.toString());
        }
        return myUrl.href;
    }

    static getSelectorInstructorImageAssocRQ(
        userId: number | null,
        isEnabled: boolean = true
    ): IRQOutputSingle {
        let rqQueryKey: IRQQueryKey = ['getSelectorInstructorImageAssoc', userId];
        const {isLoading, isError, data, error, isIdle} = useQuery(rqQueryKey, ({queryKey}) => {
                    return RestApiService.getPromise(RestApiImageService.getSelectorInstructorImageAssoc(queryKey[1]));
                },
                {
                    ...rqOptionStaticObj,
                    enabled: isEnabled
                }
            )
        ;

        return {isLoading, isError, data, error, isIdle};
    }

    static getSelectorEvolutionImageAssoc(userId: number | null): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("selector_image_select", 'getSelectorEvolutionImageAssoc');
        if (userId) {
            myUrl.searchParams.append("user_id", userId.toString());
        }
        return myUrl.href;
    }

    static getSelectorEvolutionImageAssocRQ(
        userId: number | null,
        isEnabled: boolean = true
    ): IRQOutputSingle {
        let rqQueryKey: IRQQueryKey = ['getSelectorEvolutionImageAssoc', userId];
        const {isLoading, isError, data, error, isIdle} = useQuery(rqQueryKey, ({queryKey}) => {
                return RestApiService.getPromise(RestApiImageService.getSelectorEvolutionImageAssoc(queryKey[1]));
            },
            {
                ...rqOptionStaticObj,
                enabled: isEnabled
            }
        );
        return {isLoading, isError, data, error, isIdle};
    }


    /*public static function getSelectorNewsletterImageAssoc($message, $where_string)*/
    static getSelectorNewsletterImageAssoc(imageId: number | null): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("selector_image_select", 'getSelectorNewsletterImageAssoc');
        if (imageId) {
            myUrl.searchParams.append("image_id", imageId.toString());
        }
        return myUrl.href;
    }

    static getSelectorNewsletterImageAssocRQ(
        imageId: number | null,
        isEnabled: boolean = true
    ): IRQOutputSingle {
        let rqQueryKey: IRQQueryKey = ['getSelectorNewsletterImageAssoc', imageId];
        const {isLoading, isError, data, error, isIdle} = useQuery(rqQueryKey, ({queryKey}) => {
                return RestApiService.getPromise(RestApiImageService.getSelectorNewsletterImageAssoc(queryKey[1]));
            },
            {
                ...rqOptionStaticObj,
                enabled: isEnabled
            }
        );
        return {isLoading, isError, data, error, isIdle};
    }

    /* Get selector image info */
    static getSelectorImageInfoUrl(imageId: number, userId: number = 0): string {
        const myUrl = new URL(API_URL);
        myUrl.searchParams.append("query_call", 'selector_image_info');
        myUrl.searchParams.append("image_id", imageId.toString());
        myUrl.searchParams.append("user_id", userId.toString());
        return myUrl.href;
    }

    static getSelectorImageInfoRQ(
        imageId: number,
        userId: number = 0,
        isEnabled: boolean = true
    ): IRQOutputSingle {
        let rqQueryKey: IRQQueryKey = ['getSelectorImageInfo', imageId, userId];
        const {isLoading, isError, data, error, isIdle} = useQuery(rqQueryKey, ({queryKey}) => {
                return RestApiService.getPromise(RestApiImageService.getSelectorImageInfoUrl(queryKey[1], queryKey[2]));
            },
            {
                ...rqOptionStaticObj,
                enabled: isEnabled
            }
        );
        return {isLoading, isError, data, error, isIdle};
    }


}

export default RestApiImageService;
