import {Form, Formik} from "formik";


import React, {Suspense, useContext, useEffect, useState} from "react";
import AfdLoadingFullpage from "../../AfdLoadingFullpage";
import Emptybox from "../../emptybox/Emptybox";
import AfdContainerCard from "../../AfdContainerCard";

import useCHContainerData from "../../../hook/useCHContainerData";
import useCHGetContextSoftUI from "../../../hook/useCHGetContextSoftUI";
import IPropContainer from "../../../type/IPropContainer";
import ICtxGlobalConfig from "../../../type/context/ICtxGlobalConfig";
import SoftBox from "../../../components/SoftBox";
import {formikSitePasswordReset} from "../../../form/formikHandleSubmit";
import * as Yup from "yup";
import {CircularProgress, FormControl, FormControlLabel, Radio} from "@mui/material";
import GetFormFieldGroup from "../../../form/GetFormFieldGroup";
import IAttributeCallback from "../../../type/form/IAttributeCallback";
import IValueSitePasswordReset from "../../../type/value_initial/IValueSitePasswordReset";
import {useDispatch} from "react-redux";
import IFormFormikGantry from "../../../type/form/IFormFormikGantry";
import ICtxFormResponse from "../../../type/context/ICtxFormResponse";
import CtxFormResponse from "../../../context/CtxFormResponse";
import {formikProfileInitialValues} from "../../../form/formikProfileInitialValues";
import ICtxYupSchema from "../../../type/context/ICtxYupSchema";
import CtxYupSchemaObj from "../../../context/CtxYupSchemaObj";
import {getFormResponseAlertTSX} from "../../../util/UtilReactElement";
import RadioGroup from "@mui/material/RadioGroup";
import SoftTypography from "../../../components/SoftTypography";
import RokContainerTitle from "../../../title/RokContainerTitle";
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import RestApiService from "../../../service/restapi/RestApiService";
import RestApiAuthorizationService from "../../../service/restapi/RestApiAuthorizationService";
/*import generator from "generate-password";*/


export const specResetObj: {
    [key: string]: {
        label: string,
        radio_title: string,
        desc: string,
        button_title: string,
        button_field_group: string,
        icon_tsx: JSX.Element,
        display: boolean,
        mail_type: string
    }
}= {
    password_reset: {
        label: 'Zresetuj hasło do konta w Akademii',
        radio_title: "reset hasła",
        desc: 'Wpisz swój adres e-mail, aby zresetować hasło do konta w Akademii. Na Twój adres e-mail zostanie wysłane nowe hasło.',
        button_title: 'Zresetuj hasło',
        button_field_group: 'site_password_reset_button',
        icon_tsx: <></>,
        display: true,
        mail_type: 'EMAILING_GLOBAL_USER_PASSWORD_RESET'
    },
    username_reminder: {
        label: 'Przypomnij nazwę użytkownika',
        radio_title: "przypomnienie nazwy użytkownika",
        desc: 'Wpisz swój adres e-mail, aby otrzymać przypomnienie nazwy użytkownika. Na Twój adres e-mail zostanie wysłana nazwa użytkownika.',
        button_title: 'Wyślij przypomnienie',
        button_field_group: 'site_password_username_button',
        icon_tsx: <></>,
        display: true,
        mail_type: 'EMAILING_GLOBAL_USER_USERNAME_RESET'
    },
    account_restore: {
        label: 'Przywróć usunięte konto',
        radio_title: "przywrócenie usuniętego konta",
        desc: 'Wpisz swój adres e-mail, aby przywrócić usunięte konto w Akademii. Na Twój adres e-mail zostanie wysłany link umożliwiający przywrócenie konta. ' +
            'Przywrócone konto nie będzie posiadało żadnych danych osobowych (w tym zdjęć), ' +
            'natomiast odzyskasz dostęp do kursów oraz ciężko wypracowane przez Ciebie osiągnięcia (specjalizacje, zadania, rangi).',
        button_title: 'Przywróć usunięte konto',
        button_field_group: 'site_password_restore_button',
        icon_tsx: <></>,
        display: true,
        mail_type: 'EMAILING_GLOBAL_USER_ACCOUNT_RESTORE_RESET'
    },
}


//{containerRow.post_count}
/* I w przypadku reset hasła i przypomnienia USERNAME podawany jest EMAIL
 */
const GetSitePasswordReset = ({
                                  controllerInfoObj: controllerInfoObj,
                                  userInfoObj: userInfoObj,
                                  paramObj: paramObj
                              }: IPropContainer) => {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const ctxYupSchemaObj: ICtxYupSchema = useContext<ICtxYupSchema>(CtxYupSchemaObj);
    const ctxFormResponse: ICtxFormResponse = useContext<ICtxFormResponse>(CtxFormResponse);

    var field_group = controllerInfoObj.const_param1;

    /* Przełączanie pomiędzy opcjami */
    const [userOrPasswordToggle, setUserOrPasswordToggle] = useState("password_reset");

    // let userOrPasswordLabel = userOrPasswordToggle === 0 ? specResetObj.password_reset.label : specResetObj.username_reminder.label;
    // let userOrPasswordDesc = userOrPasswordToggle === 0 ? specResetObj.password_reset.desc : specResetObj.username_reminder.desc;
    let userOrPasswordLabel: string = specResetObj[userOrPasswordToggle].label;
    let userOrPasswordDesc: string = specResetObj[userOrPasswordToggle].desc;

    const rdxDispatchFunction = useDispatch();
    let enc: string | null | undefined = paramObj.position_param0;
    let urlUserId: number = paramObj.position_param1 ? parseInt(paramObj.position_param1) : -1;
    let key: string | null | undefined = paramObj.position_param2;

    function handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        // setUserOrPasswordToggle(parseInt(event.target.value));
        setUserOrPasswordToggle(event.target.value);
    }


    const components: any = {

    }
    let ElementComponent: React.JSXElementConstructor<any>;
    if (controllerInfoObj.element1CamelName !== undefined) {
        ElementComponent = components[controllerInfoObj.element1CamelName];
        var [elementObjList, getElementObjList, containerInfoObj, setContainerInfoObj] = useCHContainerData(controllerInfoObj);

    }

    /* Obsługa ACCOUNT RESTORE */
    useEffect(() => {
        if (key) {
            RestApiService.getPromise('',
                "POST",
                RestApiAuthorizationService.setUserAccountRestore(
                    urlUserId,
                    key
                )
            ).then((response) => {
                if (response.data.status === 'success') {
                    ctxFormResponse.setStorestoreFormResponseObj({
                        status: 'success',
                        message: 'account_restore_success',
                        message_desc: 'Przywrcenie konta zakończone sukcesem. Możesz się zalogować.',
                        data: response.data
                    });
                } else {
                    ctxFormResponse.setStorestoreFormResponseObj({
                        status: 'error',
                        message: 'account_restore_error',
                        message_desc: 'Wystąpił błąd podczas przywracania konta. Skontaktuj się z administratorem.',
                        data: response.data
                    });
                }
            }
            ).catch((error) => {
                ctxFormResponse.setStorestoreFormResponseObj({
                    status: 'error',
                    message: 'account_restore_error',
                    message_desc: 'Wystąpił błąd podczas przywracania konta. Skontaktuj się z administratorem.',
                    data: error
                });
            });
        }

    }, []);


/*    var password = generator.generate({
        length: 10,
        numbers: true
    });*/

    //password_reset_email
    // password_reset_button
    // password_reset_password
    // password_reset_password__verify
    function getAttributeCallbackFactory(): IAttributeCallback {
        const attributeCallback: IAttributeCallback = {
            password_reset_email: {
                disabled: (inputformikGantry: void | IFormFormikGantry) => {
                    return false;
                },
                display: (inputformikGantry: void | IFormFormikGantry) => {
                    return (!enc)
                },
            },
            password_reset_password: {
                disabled: (inputformikGantry: void | IFormFormikGantry) => {
                    return false;
                },
                display: (inputformikGantry: void | IFormFormikGantry) => {
                    return (!!enc) // jeżeli NIE puste
                },
            },
            password_reset_password__verify: {
                disabled: (inputformikGantry: void | IFormFormikGantry) => {
                    return false;
                },
                display: (inputformikGantry: void | IFormFormikGantry) => {
                    return (!!enc) // jeżeli NIE puste
                },
            },

        }
        return attributeCallback;
    }



    function getContainerContent() {
        let row = containerInfoObj;

        function getUsernameOrPasswordRadioGroupTSX() {
            return <SoftBox
                display="flex"
                flexDirection={"row"}
                py={1} mb={0.25}
                color={"inherit"}
                pt={{xs: 1, sm: 1, md: 1.5, lg: 1.5, xl: 1.5}}
                            pb={0.5}
                px={2}
                lineHeight={1.25}
            >
                    <FormControl sx={{}}
                        /*error={error}*/
                                 variant="standard">
                        <SoftTypography
                            variant={globalConfigParamObj.isMobile ? "caption" : "body2"}
                        ><RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            /*value={installmentToogle > 1 ? 1 : 0}*/
                            onChange={handleRadioChange}
                            value={userOrPasswordToggle}
                            sx={globalConfigParamObj.isMobile ?
                                {
                                    fontSize: "0.75rem",
                                } : {}
                            }
                        >
                            {/*<FormControlLabel
                                // @ts-ignore
                                disableTypography={true} // if true, the label is rendered as it is passed without an additional typography node.
                                labelPlacement={"end"}
                                value="0" control={<Radio/>}
                                label={specResetObj.password_reset.radio_title}
                            />
                            <FormControlLabel
                                // @ts-ignore
                                disableTypography={true} // if true, the label is rendered as it is passed without an additional typography node.
                                labelPlacement={"end"}
                                value="1" control={<Radio/>}
                                label={specResetObj.username_reminder.radio_title}
                            />*/}
                            {
                                Object.keys(specResetObj).map((key: string,
                                                               index: number) => {
                                        return (
                                            // @ts-ignore
                                        specResetObj[key].display && <FormControlLabel
                                            disableTypography={true} // if true, the label is rendered as it is passed without an additional typography node.
                                            labelPlacement={"end"}
                                            value={key}
                                            control={<Radio/>}
                                            // @ts-ignore
                                            label={specResetObj[key].radio_title}
                                        />
                                    )
                                }
                                )
                            }
                        </RadioGroup></SoftTypography>
                    </FormControl>
            </SoftBox>                ;
        }

        if (controllerInfoObj.containerName === 'CONTAINER_SITE_PASSWORD_RESET') {
            return (
                <>
                    {getUsernameOrPasswordRadioGroupTSX()}
                    <SoftBox
                        pt={{xs: 2, sm: 2, md: 2, lg: 3, xl: 3}}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <RokContainerTitle>

                        {userOrPasswordLabel}
                    </RokContainerTitle>
                        <SoftTypography
                            variant={"caption"}
                            color={"info"}
                        >
                            {userOrPasswordDesc}
                        </SoftTypography>
                        <SoftBox
                            pt={{xs: 2, sm: 2, md: 2, lg: 3, xl: 3}}
                        ><Formik
                            initialValues={formikProfileInitialValues['site_password_reset']}
                            onSubmit={
                                (
                                    values: IValueSitePasswordReset,
                                    {setSubmitting}: any,
                                ) => {
                                    return formikSitePasswordReset(
                                        values,
                                        {setSubmitting},
                                        userInfoObj,
                                        ctxFormResponse,
                                        {
                                            userOrPasswordToggleDesc: userOrPasswordToggle,
                                        });
                                }
                            }
                            validationSchema={Yup.object().shape(ctxYupSchemaObj.storeValidationSchemaShapeObj)}
                        >
                            {
                                ({
                                     values,
                                     errors,
                                     touched,
                                     /* Submitting state of the form. Returns true if submission is in progress and false otherwise.
                                      IMPORTANT: Formik will set this to true as soon as submission is attempted.
                                      To learn more about the submission process, see Form Submission.*/
                                     isSubmitting: isSubmitting,
                                     handleChange,
                                     handleBlur,
                                     handleSubmit,
                                 }) => (
                                    <Form id={"afd_form"} autoComplete="off" onSubmit={handleSubmit}
                                    >
                                        {(isSubmitting ?
                                            <SoftBox pt={{xs: 2, sm: 2, md: 2, lg: 3, xl: 3}}
                                                     px={{xs: 2, sm: 2, md: 2, lg: 3, xl: 3}}
                                            >
                                                <CircularProgress
                                                    color="info"
                                                />
                                            </SoftBox>
                                            : null)
                                        }
                                        {(field_group ?
                                            <GetFormFieldGroup
                                                controllerInfoObj={controllerInfoObj}
                                                userInfoObj={userInfoObj}
                                                paramObj={paramObj}
                                                field_group={field_group}
                                                formikGantry={
                                                    {
                                                        values,
                                                        touched,
                                                        errors,
                                                        isSubmitting
                                                    }
                                                }
                                                attributeCallback={getAttributeCallbackFactory()}
                                            /> : 'No field group or formik gantry')}
                                        <GetFormFieldGroup
                                            controllerInfoObj={controllerInfoObj}
                                            userInfoObj={userInfoObj}
                                            paramObj={paramObj}
                                            // @ts-ignore
                                            field_group={specResetObj[userOrPasswordToggle].button_field_group}
                                            formikGantry={
                                                {
                                                    values,
                                                    touched,
                                                    errors,
                                                    isSubmitting
                                                }
                                            }
                                            attributeCallback={getAttributeCallbackFactory()}
                                        />
                                    </Form>
                                )}
                        </Formik></SoftBox></SoftBox>
                    {
                        getFormResponseAlertTSX(ctxFormResponse.storeFormResponseObj)
                    }
                </>
            );
        }
    }

    return (
        <>
            <Suspense fallback={<AfdLoadingFullpage
                componentCategory="AfdController_Suspense"
                controllerInfoObj={controllerInfoObj}
            />}></Suspense>
            {
                (containerInfoObj) ?
                    (
                        <AfdContainerCard
                            controllerInfoObj={controllerInfoObj}
                            userInfoObj={userInfoObj}
                            paramObj={paramObj}
                        >
                            {getContainerContent()}
                        </AfdContainerCard>
                    ) : (<Emptybox
                        controllerInfoObj={controllerInfoObj}
                        userInfoObj={userInfoObj}
                        paramObj={paramObj}
                    />)
            }
        </>
    );
}

export default GetSitePasswordReset;
