import React from "react";
/*https://www.npmjs.com/package/react-iframe*/
import Iframe from 'react-iframe'
import SoftTypography from "../../../../components/SoftTypography";
import SoftBox from "../../../../components/SoftBox";
import ICtxGlobalConfig from "../../../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../../../hook/useCHGetContextSoftUI";

const ElementMovieMainpage = ({
                                  row,
                                    height = "550px",
                                    width = "100%",
                                  controllerInfoObj
                              }: any) => {

    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI()
    var video_id = controllerInfoObj.const_param1;
    var video_type = controllerInfoObj.const_param2;
    var welcome_text = controllerInfoObj.const_param3;
    var content_intro_text = controllerInfoObj.const_param4;
    var element_module1 = controllerInfoObj.element_module1;
    /*var dict_code = JRequest.getVar('dict_code');*/
    var instructor_letter = controllerInfoObj.const_param5;
    if (!instructor_letter) instructor_letter = 'M';

    var quality = 'hd720';

    var url = 'https://www.youtube.com/embed/' + video_id;
    var allowfullscreen = true;
    var frameborder = 0;

    /*height="550px"
    height="250px"*/

    return (
        <>
            <SoftBox
                display="flex"
                flexDirection="column"
            ><SoftBox
                py={{xs: 1, sm: 1, md: 2}}
            ><SoftTypography
                variant={globalConfigParamObj.isMobile ? "body2" : "body1"}
                fontWeight={"light"}
            >{content_intro_text}</SoftTypography></SoftBox>
                <Iframe url={url}
                        id={video_id}
                        height={height}
                        width={width}
                        className="myClassname"
                        display="block"
                        allowFullScreen={allowfullscreen}
                        frameBorder={frameborder}
                        position="relative"
                        styles={{
                            "min-height": "30vw"
                        }}
                /></SoftBox>
        </>
    );
}

export default ElementMovieMainpage;
