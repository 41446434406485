// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import React, {useState} from "react";

// Soft UI Dashboard PRO React components
import Switch from "@mui/material/Switch";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import HTMLReactParser from "html-react-parser";
import parse from "html-react-parser";
import { HTMLReactParserOptions, Element } from 'html-react-parser';
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import { setTextSquareTagToDiv } from "../../util/UtilReact";
import {ErrorMessage, Field} from "formik";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../hook/useCHGetContextSoftUI";

/*https://mui.com/material-ui/react-switch/*/


/* Zgody na przetwarzanie:
    612 - NEWSLETTER
    71 - PROFIL
 */
function ElementComprofilerRegistrationFieldCheckbox(
    {
        formFieldObj,
        formikGantry,
        parserOptions
    }: IFormFieldProp
) {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const [answersPost, setAnswersPost] = useState(false);

    var formFieldTip= (formFieldObj.field_tip ?
        setTextSquareTagToDiv(formFieldObj.field_tip) : "");
        /*https://www.npmjs.com/package/html-react-parser*/
    return (
    <>
        <SoftBox
            display="flex" py={1}
            mb={0.25}
            pt={{ xs: 1, sm: 1.5, md: 1.5 }}
            pb={{ xs: 1, sm: 1.5, md: 2 }}
            px={{ xs: 1, sm: 2 }}
            lineHeight={1.25}>
                <SoftBox mt={0.25}
                         display={"block"}
                         sx={{float:"left"}}

                >
                    {/*checked={answersPost}
                    onChange={() => setAnswersPost(!answersPost)}*/}
                    <Field
                        name={formFieldObj.field_name} as={Switch}
                        error={formikGantry.errors[formFieldObj.field_name] && formikGantry.touched[formFieldObj.field_name]}
                        value={"on"} // must be STRING to write to DOM
                        success={
                            (formikGantry.values[formFieldObj.field_name] &&
                            formikGantry.values[formFieldObj.field_name].length > 0 &&
                            !formikGantry.errors[formFieldObj.field_name]) ? "true" : "false"}
                    />
                    {(formFieldObj.field_is_required === 'true') ?
                        <SoftTypography variant={"h5"} color={"error"}>
                            &nbsp;*
                        </SoftTypography>
                        : null
                    }

                    <SoftTypography variant={"caption"} color={"error"}>
                        <ErrorMessage name={formFieldObj.field_name}/>
                    </SoftTypography>

                    {/*<Switch
                        name={formFieldObj.field_name}
                        id={formFieldObj.field_name}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />*/}
                </SoftBox>
                <SoftBox
                    display={"inline"}
                    width="80%"
                         ml={2}
                >
                    <SoftTypography variant={globalConfigParamObj.isMobile ? "caption" : "button"}
                                    fontWeight="regular"
                                    color="text"
                                    sx={globalConfigParamObj.isMobile ? {
                                         lineHeight: 1.2,
                    }: {}}
                    >
                    {/*        {htmlToReact()}*/}
                        {parse(
                            formFieldTip,
                                parserOptions
                        )
                        }
                    </SoftTypography>
                </SoftBox>
            </SoftBox>
    </>
  );
}

/*// typechecking props for FormField
ElementComprofilerRegistrationFieldCheckbox.propTypes = {
  field_label: PropTypes.string.isRequired,
};*/

export default ElementComprofilerRegistrationFieldCheckbox;
