
import { useContext } from "react";
import { CtxSoftUIGlobalSetupByRdx } from "../context/CtxSoftUIGlobalSetupByRdx";
import ICtxReducerState from "../type/reducer_state/ICtxReducerState";
import ICtxGlobalConfig from "../type/context/ICtxGlobalConfig";
// Soft UI Dashboard PRO React custom hook for using context

export default function useCHGetContextSoftUI() {
    const contextReducerArray: ICtxGlobalConfig
        = useContext<any>(CtxSoftUIGlobalSetupByRdx);


    //const [controllerParamObj, dispatchFunction] = useCHGetContextSoftUI();
    if (!contextReducerArray) {
        throw new Error("useCHGetContextSoftUI should be used inside the SoftUIControllerProvider.");
    }

    return contextReducerArray;
}
