
/*Pobierz dane element*/
import {useEffect, useState} from "react";
import RestApiService from "../service/restapi/RestApiService";
import RestApiCommonService from "../service/restapi/RestApiCommonService";
import {processContainer, processElement} from "../util/UtilReact";

import IContainerQuery   from "../type/IContainerQuery";
import IRQOutputList from "../type/rq/IRQOutputList";
import IRQOutputSingle from "../type/rq/IRQOutputSingle";


export default function useCHContainerData
    <T = any,
    U = any>(controllerInfoObj: IContainerQuery,
                                         elementParamObj:any = {
                                             type: 0,
                                             object2_id: 0
                                         },
                                         containerParamObj: any = {
                                             object_id: 0,
                                             object2_id: 0
                                         },
                                           masterColumnName?: string,
                                           childElementName?: string,
                                           sqlColumnName?: string,
                                            ) {

    var containerDefaultRow: any;

    const [elementObjList, setElementObjList] = useState<T[]>([]);
    const [containerInfoObj, setContainerInfoObj] = useState<U>(containerDefaultRow);

    const rqElementQuery: IRQOutputList = RestApiCommonService.getElementQueryRQ(
        controllerInfoObj,
        elementParamObj,
        0,
        0,
        masterColumnName,
        childElementName,
        sqlColumnName
    );
    const rqContainerQuery: IRQOutputSingle = RestApiCommonService.getContainerQueryRQ(
        controllerInfoObj,
        containerParamObj,
        0,
        0
    );

    useEffect(() => {

         if (!rqElementQuery.isLoading &&
                !rqContainerQuery.isLoading
         ) {
            setElementObjList(processElement(rqElementQuery.data.data, controllerInfoObj));
            setContainerInfoObj(processContainer(rqContainerQuery.data.data[0], controllerInfoObj));
        };
    }, [
        rqElementQuery.isLoading ? "1" : "0",
        rqElementQuery.isRefetching ? "1" : "0",
        rqContainerQuery.isLoading ? "1" : "0",
        JSON.stringify(elementParamObj),
        JSON.stringify(containerParamObj),
        sqlColumnName
    ]);

    return [
        elementObjList,
        setElementObjList,
        containerInfoObj,
        setContainerInfoObj,
        rqElementQuery.refetch,
        rqElementQuery.isLoading,
        rqElementQuery.isError,
        rqElementQuery.isRefetching
    ]
}
