import ElementMovieMainpage from "./ElementMovieMainpage"; // page_item_id: 1
import ElementMovieVimeoGlobal from "./ElementMovieVimeoGlobal"; // page_item_id: 243
/* OLD */ import ElementMovieNoborderNotable from "./ElementMovieNoborderNotable"; // page_item_id: 761

import React, {useEffect, useState} from "react";
import {lazy, Suspense} from "react";
import RestApiService from "../../../../service/restapi/RestApiService";
import RestApiCommonService from "../../../../service/restapi/RestApiCommonService";
import {camelize, capitalize} from "../../../../util/Util";
import {processContainer, processElement} from "../../../../util/UtilReact";
import Emptybox from "../../../emptybox/Emptybox";
import AfdLoadingFullpage from "../../../AfdLoadingFullpage";
import AfdContainerCard from "../../../AfdContainerCard";
import GetWelcome from "../../../sourcerer/GetWelcome";

/*ng-click="psedudoScrollNextPage()"*/
//{containerRow.post_count}
const GetSiteWelcomeVideoGlobal = ({
                                       controllerInfoObj: controllerInfoObj,
                                       userInfoObj: userInfoObj,
                                       paramObj: paramObj
                                   }: any) => {
    const components: any = {
        ElementMovieMainpage,
        ElementMovieNoborderNotable,
        ElementMovieVimeoGlobal,

    }

    let element1CamelName = controllerInfoObj.element1CamelName;
    var ElementComponent = components[element1CamelName];

    var containerName = controllerInfoObj.containerName;
    var containerDefaultRow: any;


    var video_id = controllerInfoObj.const_param1;
    var video_type = controllerInfoObj.const_param2;
    var welcome_text = controllerInfoObj.const_param3;
    var content_intro_text = controllerInfoObj.const_param4;
    /*var dict_code = JRequest.getVar('dict_code');*/
    var instructor_letter = (controllerInfoObj.const_param5 ? controllerInfoObj.const_param5 : 'M');
    var quality = 'hd720';
    /* Te funkcje nie robią nic poza podstawianiem wartości


    /*Pobierz dane element*/
    const [elementObjList, setElementObjList] = useState([]);
    const [containerInfoObj, setContainerInfoObj] = useState(containerDefaultRow);
    useEffect(() => {
        Promise.all(
            [RestApiService.getPromise(RestApiCommonService.getElementQueryUrl(
                controllerInfoObj,
                {
                },
                0,
                0
            )),
                RestApiService.getPromise(RestApiCommonService.getContainerQueryUrl(
                    controllerInfoObj,
                    {
                        "instructor_letter": instructor_letter,
                        object2_id: 0
                    },
                    0,
                    0
                ))
            ]).then((responseArray) => {
                setElementObjList(processElement(responseArray[0].data, controllerInfoObj));
                setContainerInfoObj(processContainer(responseArray[1].data[0], controllerInfoObj));

        });


    }, []);


    /*var query = "select user_rank from xm_all_users6 xx\
				where xx.user_id=" + "" + user_id;*/
    //print $query;

    /* Te funkcje nie robią nic poza podstawianiem wartości
    */
/*    if (video_type	 == 'VIMEO') {
        output  = get_vimeo_single_movie(video_id, width, content_intro_text, iframe_width, iframe_height, element_module1);
    }

    if (video_type	 == 'YOUTUBE') {
        output  = get_movie(video_id, width, var height, quality, element_module1, content_intro_text);
    }*/

    /* Jeżęli nie jesteśmy na stronie DICT */
    /*if (!dict_code) {*/

        /* Jeżeli jest podany tytuł to dodaj tytuł
        */
       /* output = str_replace("[[CONTENT_TITLE]]", '', output);
        if (welcome_text) {
            output = str_replace("[[CONTENT_TEXT]]", welcome_text, output);
        }
        if (content_intro_text) {
            output = str_replace("[[CONTENT_VIDEO]]", content_intro_text, output);
        }*/
    /*}*/


    function getContainerContent() {
        let row = containerInfoObj;
        if (containerName === 'CONTAINER_CLUB_RODO_MOVIE') {
            return (
                <>
                                Dowiedz się na temat zgód osobowych:
                                    <ElementComponent
                                        row={elementObjList[0]}
                                        width={"100%"}
                                        height={"550px"}
                                        controllerInfoObj={controllerInfoObj}
                                    />
                </>
            );
        }
        if (containerName === 'CONTAINER_SITE_MAINPAGE_BANNER_MOVIE') {
            return (
                <>
                            <ElementComponent
                                row={elementObjList[0]}
                                width={"100%"}
                                height={"250px"}
                                controllerInfoObj={controllerInfoObj}
                            />
                </>
            );
        }
        if (containerName === 'CONTAINER_SITE_MAINPAGE_SECOND_MOVIE' ||
            containerName === 'CONTAINER_SITE_MAINPAGE_MAIN_MOVIE'
        ) {
            return (
                <>
                            <ElementComponent
                                row={elementObjList[0]}
                                width={"100%"}
                                height={"550px"}
                                controllerInfoObj={controllerInfoObj}
                            />
                </>
            );
        }
        if (containerName === 'CONTAINER_SITE_WELCOME_VIDEO_GLOBAL') {
            return (
                <>
                    <GetWelcome team_letter={'A'}
                                content={content_intro_text}
                                ending={welcome_text}
                                instructorInfoPosition={"tooltip"}
                    />
                                <ElementComponent
                                    row={elementObjList[0]}
                                    width={"100%"}
                                    height={"550px"}
                                    controllerInfoObj={controllerInfoObj}
                                />
                </>
            );
        }
    }

    return (
        <>
            <Suspense fallback={ <AfdLoadingFullpage
            componentCategory="AfdController_Suspense"
            controllerInfoObj={controllerInfoObj}
        />}>
        </Suspense>
            {
                (elementObjList?.length > 0 && containerInfoObj) ?
                    (
                        <AfdContainerCard
                            controllerInfoObj={controllerInfoObj}
                            userInfoObj={userInfoObj}
                            paramObj={paramObj}
                            elementObjList={elementObjList}
                        >
                            {getContainerContent()}
                        </AfdContainerCard>

                    ) : (<Emptybox
                        controllerInfoObj={controllerInfoObj}
                        userInfoObj={userInfoObj}
                        paramObj={paramObj}
                        elementObjList={elementObjList}
                    />)
            }
        </>
    );
}

export default GetSiteWelcomeVideoGlobal;
