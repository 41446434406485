

import React, {Suspense, useState} from "react";

// react-images-viewer components
const image1  = require("assets/images/ecommerce/wooden-table.jpeg");
// @ts-ignore
import ImgsViewer from "react-images-viewer";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../components/SoftBox";
import useCHSourcererData from "../../../hook/useCHSourcererData";
import IElementInstructorProtfolio from "../../../type/IElementInstructorProtfolio";

interface IProps {
    userId: number,
    templateModule: string;
}

function GetInstructorPortfolio(
    {
        userId,
        templateModule
    }: IProps
) {

    var elementInstructorPortObjList: IElementInstructorProtfolio[];
    [elementInstructorPortObjList] = useCHSourcererData(
        'get_instructor_portfolio',
        'sql_query1',
        {
            "user_id": userId,
            "limit": 8
        });

    // current image
  const [currentImage, setCurrentImage] = useState(null);
  const [imgsViewer, setImgsViewer] = useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

  // @ts-ignore
  const handleSetCurrentImage = ({ currentTarget }) => {
      // make the image current
    setCurrentImage(currentTarget.src);
    // change the prev and next
    setImgsViewerCurrent(Number(currentTarget.id));
  };

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

    function getImageGalleryBuildTSX() {
        return <>
            <SoftBox
                component="img"
                src={currentImage ? currentImage : elementInstructorPortObjList[0].image_big_thumb_src}
                alt="Product Image"
                shadow="lg"
                borderRadius="lg"
                width="100%"
                onClick={openImgsViewer}
            />
            <SoftBox mt={2} pt={1}>
                <Grid container spacing={3}>

                    {
                        elementInstructorPortObjList.map(
                            (portfolioObj: any, index: number) => (
                                <Grid item xs={2}
                                      key={index}
                                >
                                    <SoftBox
                                        component="img"
                                        id={index}
                                        src={portfolioObj.image_big_thumb_src} // TODO - zaimplentować SrcSet dla wydajności
                                        // @ts-ignore
                                        big={portfolioObj.image_big_thumb_src}
                                        alt="small image 1"
                                        borderRadius="lg"
                                        shadow="md"
                                        width="100%"
                                        sx={{cursor: "pointer", height: "100%", objectFit: "cover"}}
                                        onClick={handleSetCurrentImage}
                                    />
                                </Grid>
                            )) // end of map
                    }
                </Grid>
            </SoftBox>
        </>;
    }

    /*[{ src: image1 }, { src: image2 }, { src: image3 }, { src: image4 }, { src: image5 }]*/
    function getContainerContent() {
        if (templateModule === 'ElementInstructorPortfolioImage') {
            return (
                <SoftBox>
                    {/*
                    https://www.npmjs.com/package/react-images-viewer
                    ImgsViewer is LIGHTBOX - odpalany tylko po wybraniu największego ze zdjęć
                        to PLUGin  - sam w sobie nic nie wyświetla, czeka

                        IMAGE_SRC - light_thumb_src
                    */}
                    <ImgsViewer
                        imgs={elementInstructorPortObjList.map(
                            (portfolioObj: any) => ({"src": portfolioObj.image_big_thumb_src})
                        )}
                        isOpen={imgsViewer}
                        onClose={closeImgsViewer}
                        currImg={imgsViewerCurrent}
                        onClickPrev={imgsViewerPrev}
                        onClickNext={imgsViewerNext}
                        backdropCloseable
                    />

                    {getImageGalleryBuildTSX()}
                </SoftBox>
            );
        } else {
            return (
                <SoftBox>
                    <ImgsViewer
                        imgs={elementInstructorPortObjList.map(
                            (portfolioObj: any) => ({"src": portfolioObj.image_src})
                        )}
                        isOpen={imgsViewer}
                        onClose={closeImgsViewer}
                        currImg={imgsViewerCurrent}
                        onClickPrev={imgsViewerPrev}
                        onClickNext={imgsViewerNext}
                        backdropCloseable
                    />

                    {getImageGalleryBuildTSX()}
                </SoftBox>
            );
        }
    } // end of func

    return (
        <>
            {
                (elementInstructorPortObjList?.length > 0) ?
                    (
                        getContainerContent()
                    ) : (<div>No rows</div>)
            }
        </>
    );
}

export default GetInstructorPortfolio;
