import React, {Suspense, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import useCHSourcererData from "../../hook/useCHSourcererData";
import DefaultItem from "../../examples/Items/DefaultItem";
import CtxAppObj from "../../context/CtxApp";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import Icon from "@mui/material/Icon";
import {IElementUserProfileInfo} from "../../type/profile/IElementUserProfileInfo";

interface IProps {
    urlUserId: number,
    isShowExperience?: boolean;
}

function GetUserProfileInfo(
    {
        urlUserId,
        isShowExperience = false
    }: IProps
) {
    const appObj = React.useContext(CtxAppObj);
    var [elementAddObj1List] = useCHSourcererData<IElementUserProfileInfo | undefined>(
        'get_user_profile_info',
        'sql_query1',
        {
            url_user_id: urlUserId
        });

    function getContainerContent(elementAddObj1: IElementUserProfileInfo) {

        let specification = [
            {
                title: "w Akademii od",
                value: elementAddObj1.reg_date,
                icon: "app_registration",
                isVisible: true
            },
            {
                title: "model aparatu",
                value: elementAddObj1.model,
                icon: "photo_camera",
                isVisible: true
            },
            {
                title: "miasto",
                value: elementAddObj1.miasto,
                icon: "location_on",
                isVisible: true
            },
            {
                title: "doświadczenie",
                value: elementAddObj1.user_experience_desc,
                icon: "work",
                isVisible: (isShowExperience && elementAddObj1.user_experience_desc)
            }
        ];
        return (
            <>
                <SoftBox width="100%" height="100%" display="flex" alignItems="center">
                <SoftBox width="40%" height="100%">
                    <SoftBox
                        component="img"
                        src={elementAddObj1.avatar}
                        alt={elementAddObj1.username}
                        shadow="lg"
                        borderRadius="lg"
                        maxWidth="100%"
                        /*maxHeight="100%"*/
                        sx={{ objectFit: "cover" }}
                    />
                </SoftBox>
                <SoftBox width="60%" py={2.5} px={4}>
                    <SoftBox mb={1} lineHeight={1}>
                        <SoftTypography variant="h5" fontWeight="bold">
                            {elementAddObj1.firstname} ({elementAddObj1.username})
                        </SoftTypography>
                        <SoftTypography
                            variant="button"
                            color="text"
                            textTransform="uppercase"
                            fontWeight="medium"
                        >
                            {elementAddObj1.rank_title_desc}
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox mb={3}>
                        {
                            specification.map((item, index) => {
                                    let iconPath =  item.icon + "OutlinedIcon";
                                    if (item.isVisible) {
                                        return (
                                            <SoftBox key={index} mb={0}
                                            >
                                                <Icon
                                                    baseClassName="material-icons-outlined"
                                                >{iconPath}</Icon>&nbsp; &nbsp;
                                                <SoftTypography variant="caption" color="text" display="inline">
                                                    {item.title}:&nbsp;
                                                </SoftTypography>
                                                <SoftTypography variant="caption" color="text" display="inline"
                                                                fontWeight="bold"
                                                >
                                                    {item.value}
                                                </SoftTypography>
                                            </SoftBox>
                                        );
                                    } else {
                                        return null;
                                    }
                                }
                            )
                        }

                    </SoftBox>
                    {/*<SoftBox display="flex">{renderSocial}</SoftBox>*/}
                </SoftBox>
            </SoftBox>
            </>
        );
    }

    return (
        <>
            {
                (elementAddObj1List
                    && elementAddObj1List[0]
                ) ?
                    (
                        getContainerContent(elementAddObj1List[0])
                    ) : null
            }
        </>
    );
}

export default GetUserProfileInfo;

