// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import React, {useState} from "react";

// Soft UI Dashboard PRO React components
import Switch from "@mui/material/Switch";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import {ErrorMessage, Field} from "formik";
import parse from "html-react-parser";
import {getFormFieldCheckboxTSX} from "./ElementGlobalNgFieldCheckbox";

/*https://mui.com/material-ui/react-switch/*/

function ElementFormProfileUserNewsletter(
        {
            formFieldObj,
            formikGantry,
            parserOptions
        }: IFormFieldProp
        ) {
    const [answersPost, setAnswersPost] = useState(false);

    let fieldTip = formFieldObj.field_tip ? parse(formFieldObj.field_tip) : '';
    let fieldLabel: string = formFieldObj.field_label ? formFieldObj.field_label : '';

    return (
    <>
        <SoftBox
            display="flex"
            mb={0.25}
            pt={{xs: 1, md: 1.5}}
            pb={{xs: 0.5, md: 1}}
            px={2} lineHeight={1.25}
        >
                {getFormFieldCheckboxTSX(formFieldObj, formikGantry)}
                <SoftBox width="80%" ml={2}>
                    {
                        (fieldLabel &&
                            formFieldObj.field_group == 'profile_user_newsletter')
                            && <SoftTypography variant="button"
                                               fontWeight="regular"
                                               color="text">
                            {fieldLabel}
                        </SoftTypography>
                        }
                    <SoftTypography variant="button" fontWeight="regular" color="text">
                        {fieldTip}
                    </SoftTypography>
                </SoftBox>
        </SoftBox>
    </>
  );
}

/*// typechecking props for FormField
ElementFormProfileUserNewsletter.propTypes = {
  field_label: PropTypes.string.isRequired,
};*/

export default ElementFormProfileUserNewsletter;
