import {createContext} from "react";
import ICtxClubChapter from "../type/context/ICtxClubChapter";
import IElementNewbbTopicPost from "../type/newbb/IElementNewbbTopicPost";
import IElementForumDecodeInfoCurrent from "../type/newbb/IElementForumDecodeInfoCurrent";
import IElementNewbbTopicInfo from "../type/newbb/IElementNewbbTopicInfo";
import IElementNewbbUniForumInfo from "../type/newbb/IElementNewbbUniForumInfo";
import IElementNewbbAnchorPost from "../type/newbb/IElementNewbbAnchorPost";
import {IOrderByCode} from "../gantry/rt-club-newbb";
/*
    The problem here is that you need to help TypeScript understand the types of the objects you're dealing with.
    https://blog.logrocket.com/how-to-use-react-context-typescript/
 */

const defaultContext: ICtxClubChapter = {
    storeOrigCommentObj: null,
    setStoreOrigCommentObj: () => {},
    storeMyAcademyNotifCounter: {
        comment: 0,
        post: 0,
        instructor: 0,
    },
    setStoreMyAcademyNotifCounter: () => {},
    tabValue: 1,
    setTabValue: () => {},
    storeNewbbForumInfoObj: undefined,
    storeUniForumInfoObj: undefined,
    storeNewbbTopicInfoObj: undefined,
    storeNewbbIssueDecodeInfoObjList: undefined,
    urlDisForumId: null,
    urlDisTopicId: null,
    urlChapterIssueCode: null,
    refetchElementCurrentForumInfoFunction: () => {},
    refetchElementIssueInfoFunction: () => {},
};


const CtxClubChapter = createContext<ICtxClubChapter>(defaultContext);
//const AppObjContext = createContext(null);

export default CtxClubChapter;
