import React, {useContext, useEffect, useState} from "react";
import {debugLog, processContainer, processElement} from "../../util/UtilReact";

// @mui material components
import Container from "@mui/material/Container";
import {Form, Formik} from 'formik';

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";

// Soft UI Dashboard PRO React example components
import PageLayout from "../../examples/LayoutContainers/PageLayout";


// Pricing page components
import Grid from "@mui/material/Grid";

import AfdSelectorAmocourse from "../../selector/AfdSelectorAmocourse";
import AfdSelectorImage from "../AfdSelectorImage";
import IContainerQuery from "../../type/IContainerQuery";
import useCHGantryData from "../../hook/useCHGantryData";
import IPropGantry from "../../type/IPropGantry";
import SoftTypography from "../../components/SoftTypography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
import SoftButton from "../../components/SoftButton";

import CtxAmocourse from "../../context/CtxAmocourse";

import {getPosition2ContainerMap} from "../../util/UtilReact";
import RestApiCommonService from "../../service/restapi/RestApiCommonService";
import RestApiAmocourseService from "../../service/restapi/RestApiAmocourseService";
import RestApiService from "../../service/restapi/RestApiService";
import {useLocation} from "react-router-dom";
import IAmocoursePriceInfo from "../../type/amocourse/IAmocoursePriceInfo";
import IAmocoursePromocodeInfo from "../../type/amocourse/IAmocoursePromocodeInfo";
import IAmocourseTotalArray from "../../type/amocourse/IAmocourseTotalArray";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import IRdxState from "../../type/reducer_state/IRdxState";
import IFormFormikGantry from "../../type/form/IFormFormikGantry";
import RestApiAuthorizationService from "../../service/restapi/RestApiAuthorizationService";
import {actAccountTrainStepUpdate, actUserCookieName} from "../../redux_action/rdxAction";
import IAmocourseRegInsert from "../../type/amocourse/IAmocourseRegInsert";
import ISelectorPosition from "../../type/ISelectorPosition";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../hook/useCHGetContextSoftUI";
import AfdMainpageBanner from "../AfdMainpageBanner";
import IUserInfo from "../../type/IUserInfo";

import {formikAmocourseHandleSubmit} from "../../form/formikHandleSubmit";
import SoftAlert from "../../components/SoftAlert";
import AfdAlertErrorForm from "../../component/error_boundary/AfdAlertErrorForm";
import parse from "html-react-parser";
import IValueRtAmocourseTrain from "../../type/value_initial/IValueRtAmocourseTrain";
import useCHSourcererData from "../../hook/useCHSourcererData";
import IElementNewbbAnchorPost from "../../type/newbb/IElementNewbbAnchorPost";
import {extractDigitsFromString} from "../../util/UtilTS";
import IRQOutputSingle from "../../type/rq/IRQOutputSingle";
import {IRQOutputParamRegProvider} from "../../type/rq/IRQOutputLibrary";
import IParamRegProviderObj from "../../type/IParamRegProviderObj";
import GetMenuPageBanner from "../../util/GetMenuPageBanner";
import ICtxFormResponse from "../../type/context/ICtxFormResponse";
import CtxFormResponse from "../../context/CtxFormResponse";
import useCHFBConversionAPI from "../../hook/useCHFBConversionAPI";
import ICtxYupSchema from "../../type/context/ICtxYupSchema";
import CtxYupSchemaObj from "../../context/CtxYupSchemaObj";
/*import React from "@types/react";*/

/* **********************************************************
     PriceArray to jest statyczny zestaw dannych - pobierany zatem na poziomie GANTRY i przekazywany w CONTEXT
       GET w PRICE INFO pobiera go i przekazuje to tablicy PRICE ARRAY
    TotalArray - to jest dynamiczna tablica przeliczana na bieżąco
    PromocodeArray - to zestaw informacji na temat promocode - pobierany w walidator.

        Nie przekazujemy PromocodeArray w Context ponieważ to zbiór danych, który nie dotyczy nawet całego drzewa AMOCOURSE
            ustawiany skądkolwiek (YUP validator) i pobierany gdziekolwiek
            nie będziemy nim zaśmiecać drzewa komponentów

      NO VALIDATE TEMPLATE  - ma statyczna komunikaty ponieważ YUP VALIDATOR to jest statyczny obiekt konfiguracyjny
      YES VALIDATE TEMPLATE - jest dynamiczne bo to dodatkowy komponent poza YUP/FORMIK i faktycznie w YES potrzeba dynamiki
********************************************************** */


function RtSiteTrain({
                         menuItemObj,
                         containerObjList,
                         userInfoObj,
                         routeCategory,
                         redirectionObj
                     }: IPropGantry) {
    const ctxYupSchemaObj: ICtxYupSchema = useContext<ICtxYupSchema>(CtxYupSchemaObj);
    var [menuItemListObj, setMenuItemListObj] = useCHGantryData(
        menuItemObj,
        containerObjList,
        userInfoObj,
        routeCategory,
        redirectionObj
    );


    const rdxState = useSelector((state: IRdxState) => {
        return state;
    });
    const ctxFormResponse: ICtxFormResponse = useContext<ICtxFormResponse>(CtxFormResponse);
    const rdxDispatchFunction = useDispatch();
    const [globalConfigParamObj, globalConfigDispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();

    // Wyjąkowo potrzebujemy parametrów wcześniej niż w SELECTOR
    let queryHookObj = new URLSearchParams(useLocation().search);
    let courseEventId = Number(queryHookObj.get('id'));
    let selectorImageParamObj = {
        courseEventId: courseEventId,
    }
    let regId: undefined | number = undefined;
    const location = useLocation();
    const { facebookApiFunction, facebookApiIsLoading, facebookApiData, facebookApiError } = useCHFBConversionAPI(
        {
            user_email: userInfoObj.user_email,
            user_score: 100,
            client_ip: ''
        });

    const [storePriceArray, setStorePriceArray] = useState<IAmocoursePriceInfo | null>(null);
    const [storeRegProviderObj, setStoreRegProviderObj] = useState<IParamRegProviderObj |  null>(null);

    /* Jeden wiersz
    - ponieważ inforamcje właściwe dla całej strony - pobieranie w GANTRY
    * */
    const rqAmocoursePriceArraySelect: IRQOutputSingle = RestApiAmocourseService.getAmocoursePriceArraySelectRQ(
        courseEventId,
        userInfoObj.user_id
    );

    useEffect(() => {
        if (rqAmocoursePriceArraySelect && rqAmocoursePriceArraySelect?.data?.data && !rqAmocoursePriceArraySelect.isLoading) {
            setStorePriceArray(processContainer(rqAmocoursePriceArraySelect.data.data));
        }

    }, [
        rqAmocoursePriceArraySelect.isLoading ? "1" : "0",
        rqAmocoursePriceArraySelect.isRefetching ? "1" : "0",
        courseEventId,
        userInfoObj.user_id,
    ]);

    const rqAmocourseFormRegProviderAssoc: IRQOutputParamRegProvider = RestApiCommonService.getAmocourseFormRegProviderAssocRQ(
        userInfoObj.user_id,
        courseEventId,
        false // TODO
    );
    useEffect(() => {
        if (rqAmocourseFormRegProviderAssoc && rqAmocourseFormRegProviderAssoc?.data?.data && !rqAmocourseFormRegProviderAssoc.isLoading) {
            setStoreRegProviderObj(processContainer(rqAmocourseFormRegProviderAssoc.data.data));
        }
    },
        [
            rqAmocourseFormRegProviderAssoc.isLoading ? "1" : "0",
            rqAmocourseFormRegProviderAssoc.isRefetching ? "1" : "0",
            courseEventId,
            userInfoObj.user_id,
        ]);



    /*Wizard controls*/
    let activeStep = useSelector((state: IRdxState) => {
        return state.account_train_step;
    });
    const steps = getSteps();
    const isLastStep = activeStep === steps.length - 1;

    const handleNext = () => {
        rdxDispatchFunction(actAccountTrainStepUpdate(
            1
        ));
    };
    const handleBack = () => {
        rdxDispatchFunction(actAccountTrainStepUpdate(
            -1
        ));
    }

    const position2ContainerMap = getPosition2ContainerMap(containerObjList);

    const getSelectorPositionJSX = function (
        positionCode: ISelectorPosition,
        formikGantry?: IFormFormikGantry
    ): JSX.Element | null {

        if (position2ContainerMap[positionCode]?.length > 0) {
            return (
                <Container>
                    <AfdSelectorAmocourse
                        menuItemObj={menuItemObj}
                        userInfoObj={userInfoObj}
                        position={positionCode}
                        redirectionObj={redirectionObj}
                        containerAssocList={position2ContainerMap[positionCode]}
                        formikGantry={formikGantry}
                    /></Container>);
        } else {
            return null;
        }
    }


    function getSteps() {
        return ["Krok #1: Email", "Krok #2: Dane", "Krok #3: Opcje i cena"];
    }

    function getStepContent(stepIndex: number,
                            formikGantry: IFormFormikGantry
    ) {
        switch (stepIndex) {
            case 0:
                return getSelectorPositionJSX("body-train-step-1",
                    formikGantry);
            case 1:
                return getSelectorPositionJSX("body-train-step-2",
                    formikGantry);
            case 2:
                return getSelectorPositionJSX("body-train-step-3",
                    formikGantry);
            default:
                return null;
        }
    }


    /* Domyślne wartości */
    var containerMarginTop: number = GetMenuPageBanner(menuItemObj).containerMarginTop;
    /*initialValuesObj.promocode = queryHookObj.get('promocode') || '';*/

    return (
        <PageLayout>
            {/*Zasadnicza różnica pomiędzy storePriceArray, które jest stałe, zależne od COURSE_EVENT i promocodeArray - zależne od gantry.value */}
            <CtxAmocourse.Provider value={
                {
                    storePriceArray: storePriceArray,
                    setStorePriceArray: setStorePriceArray,
                    storeRegProviderObj: storeRegProviderObj,
                    setStoreRegProviderObj: setStoreRegProviderObj,
                }
            }>
                {/*// @ts-ignore*/}
                <AfdSelectorImage
                    menuItemObj={menuItemObj}
                    containerObjList={containerObjList}
                    userInfoObj={userInfoObj}
                    routeCategory={routeCategory}
                    redirectionObj={redirectionObj}
                    selectorImageParamObj={selectorImageParamObj}
                    mainpageBannerTSX={   <AfdMainpageBanner
                        menuItemObj={menuItemObj}
                        containerObjList={containerObjList}
                        userInfoObj={userInfoObj}
                        routeCategory={routeCategory}
                        redirectionObj={redirectionObj}
                        getSelectorPositionJSX={getSelectorPositionJSX}
                    />}
                >
                    <>{getSelectorPositionJSX('top-a')}
                        {getSelectorPositionJSX('showcase-a')}</>

                </AfdSelectorImage>
                <SoftBox
                    position={'relative'}
                    px={{xs: 1, sm: 0}}
                    mt={containerMarginTop}
                >

                    <SoftBox

                        px={{xs: 1, sm: 0}}
                        left={{xs: 1, md: 1, lg: 1, xl: 1}}
                    >
                    </SoftBox>
                </SoftBox>


                {/*<Header tabValue={tabValue} tabHandler={handleSetTabValue} />*/}
                <Formik
                    initialValues={ctxYupSchemaObj.storeFormikInitialValueObj['rt-amocourse-train']}
                    component={undefined}
                    initialErrors={undefined}
                    initialTouched={undefined}
                    innerRef={undefined}
                    isInitialValid={undefined}
                    onSubmit={
                        (
                            values: IValueRtAmocourseTrain,
                            {setSubmitting}: any,
                        ) => {
                            return formikAmocourseHandleSubmit(
                                values,
                                {setSubmitting},
                                userInfoObj,
                                ctxFormResponse,
                                {
                                    courseEventId: courseEventId,
                                    categoryId: redirectionObj.object_desc_category_id,
                                    sessionId: '', // TODO
                                    location: location,
                                    facebookApiFunction: facebookApiFunction,
                                });
                        }
                    }
                    validationSchema={Yup.object().shape(ctxYupSchemaObj.storeValidationSchemaShapeObj)}
                >
                    {
                        ({
                             values,
                             errors,
                             touched,
                             isSubmitting,
                             handleChange,
                             handleBlur,
                             handleSubmit,
                         }) => {

                            /* TAK: bierze z kontekstu */
                            const handleBlurAfdFunction = (event: React.FocusEvent<any>
                            ) => {

                                RestApiCommonService.getPromise(RestApiAmocourseService.setAmocoursePreregLogInsert(
                                    rdxState.session_id ? rdxState.session_id : 'no_session_id',
                                    courseEventId,
                                    userInfoObj.user_id,
                                    event.target.name,
                                    // @ts-ignore // Świadome SUPPRESS ponieważ i tak nie możęmy zagwarantować co przychodzi z EVENT ???
                                    values[event.target.name] ? values[event.target.name].toString() : '',
                                )).then((insertResponseObj) => {
                                        debugLog("PRE REG insert: " + JSON.stringify(insertResponseObj));
                                    }
                                );
                            }


                            return (
                                <Form id={"afd_form"} name={"afd_form"} autoComplete="off">
                                    <SoftBox pt={3} pb={8}
                                    >

                                        <Grid container justifyContent="center">
                                            <Grid item xs={12} lg={8}>
                                                <SoftBox mt={{xs: 1, sm: 1, md: 3, lg: 6, xl: 6}}
                                                         mb={{xs: 0, sm: 0, md: 1, lg: 1, xl: 1}}
                                                         textAlign="center">
                                                    <SoftBox mb={1}>
                                                        <SoftTypography variant={
                                                            globalConfigParamObj.isMobile ? "h5" : "h3"
                                                        }
                                                                        fontWeight="bold">
                                                            Rejestracja na kurs fotograficzny:
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    {/*  <SoftTypography variant="h5" fontWeight="regular" color="secondary">
                            {row.description}
                            {row.startdate_weekday_name}, dnia {row.startdate_pl_desc}, godz. {row.starttime}
                        </SoftTypography>*/}
                                                </SoftBox>

                                                <Stepper activeStep={activeStep} alternativeLabel
                                                         sx={
                                                             globalConfigParamObj.isMobile ? {
                                                                 marginBottom: 2,
                                                                 marginTop: 4,
                                                             } : {}}
                                                >
                                                    {steps.map((label) => (
                                                        <Step key={label}>
                                                            <StepLabel
                                                                sx={globalConfigParamObj.isMobile ?
                                                                    {
                                                                        '& .MuiStepLabel-label': {
                                                                            fontSize: '0.9rem',
                                                                        }
                                                                    } : {}}
                                                            >{label}</StepLabel>
                                                        </Step>
                                                    ))}
                                                </Stepper>
                                                {
                                                    (userInfoObj.user_id != 714 /* is_tech_account == 0 && userInfoObj*/) ? <Card>
                                                            <SoftBox px={{xs: 1, sm: 1, md: 2, lg: 2}}
                                                                     py={{xs: 2, sm: 2, md: 2, lg: 2}}
                                                            >
                                                                <SoftBox>
                                                                    {getStepContent(activeStep,
                                                                        {
                                                                            values,
                                                                            touched,
                                                                            errors,
                                                                            isSubmitting,
                                                                            handleChange,
                                                                            handleBlur: handleBlurAfdFunction,
                                                                        }
                                                                    )}
                                                                    <SoftBox mt={{xs: 2, sm: 2, md: 3, lg: 3}}
                                                                             width="100%" display="flex"
                                                                             justifyContent="space-between">
                                                                        {activeStep === 0 ? (
                                                                            <SoftBox/>
                                                                        ) : (
                                                                            <SoftButton variant="gradient" color="light"
                                                                                        onClick={handleBack}>
                                                                                wstecz
                                                                            </SoftButton>
                                                                        )}
                                                                        {/*                                                    <SoftButton
                                                                variant="gradient"
                                                                color="dark"
                                                                onClick={!isLastStep ? handleNext : undefined}
                                                            >
                                                                {isLastStep ? "send" : "next"}
                                                            </SoftButton>*/}
                                                                    </SoftBox>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </Card> :
                                                        <AfdAlertErrorForm
                                                            templateTSX={parse("Twoje konto jest konto techniczne.  Nie możesz się na nie zarejestrować.")}
                                                        />
                                                }
                                            </Grid>
                                        </Grid>
                                    </SoftBox>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </CtxAmocourse.Provider>
        </PageLayout>

    )
}

export default RtSiteTrain;
