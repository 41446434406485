
// @ts-ignore
import SoftBox from "../../components/SoftBox";
import Icon from "@mui/material/Icon";
import React from "react";

function ConfigButtonFactory(handleConfiguratorOpen: () => void,
                             buttonConentTSX: JSX.Element): any {

    let isAdminMode = true;

    const configsButton = (
        // @ts-ignore
        <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.5rem"
            height="3.5rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{cursor: "pointer"}}
            onClick={handleConfiguratorOpen}
        >
            {/*fontSize="default"*/}

            {buttonConentTSX}
        </SoftBox>
    );

    return (isAdminMode ?  configsButton : null);
}
export default ConfigButtonFactory;
