// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import React from "react";


// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import IFormFieldInfo from "../../type/form/IFormFieldInfo";
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import {ErrorMessage, Field} from "formik";
import {setTextSquareTagToDiv} from "../../util/UtilReact";
import parse from "html-react-parser";


function ElementSimpleFormFieldTextarea(
        {
            formFieldObj,
            formikGantry,
            parserOptions
        }: IFormFieldProp
        ) {
    let rows = 4;
    var formFieldTip= (formFieldObj.field_tip ?
        setTextSquareTagToDiv(formFieldObj.field_tip) : "");

    return (
    <>
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block"
      >
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
        >
          {parse(formFieldObj.field_label)}
        </SoftTypography>
      </SoftBox>

        <Field
            name={formFieldObj.field_name} as={SoftInput}
            error={formikGantry.errors[formFieldObj.field_name] && formikGantry.touched[formFieldObj.field_name]}
            success={
                formikGantry.values[formFieldObj.field_name] &&
                formikGantry.values[formFieldObj.field_name].length > 0 &&
                !formikGantry.errors[formFieldObj.field_name]}
            placeholder={formFieldObj.field_placeholder}
            defaultValue={formikGantry.values[formFieldObj.field_name]}
            multiline
            rows={5}
            onBlur={formikGantry.handleBlur}
        />
        {formFieldTip && <SoftBox
            lineHeight={1}
        ><SoftTypography
            variant={"caption"}
            fontWeight={"regular"}
            color={"secondary"}
        >
            {parse(
                formFieldTip,
                parserOptions
            )}
        </SoftTypography></SoftBox>

        }
        <SoftTypography variant={"caption"} color={"error"}>
            <ErrorMessage name={formFieldObj.field_name}/>
        </SoftTypography>
    </>
  );
}

/*// typechecking props for FormField
ElementSimpleFormFieldTextarea.propTypes = {
  field_label: PropTypes.string.isRequired,
};*/

export default ElementSimpleFormFieldTextarea;
