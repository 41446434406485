import {useState, useCallback, useEffect, FunctionComponent} from 'react';
import axios, {AxiosResponse} from 'axios';
import APP_CONSTANTS from '../app_constants';
import CryptoJS from 'crypto-js';

// event enum 
export enum EventTypes {
    PageView = 'PageView',
    ViewContent = 'ViewContent',
    Search = 'Search',
    AddToCart = 'AddToCart',
    AddToWishlist = 'AddToWishlist',
    InitiateCheckout = 'InitiateCheckout',
    AddPaymentInfo = 'AddPaymentInfo',
    Purchase = 'Purchase',
    CompleteRegistration = 'CompleteRegistration',
    Other = 'Other',
};

interface IFBConversionPayloadUser {
    em: string[];
    ph?: Array<string | null>;
};

interface IPropHook {
    user_email: string;
    user_score: number;
    client_ip: string;
};

interface IFBConversionPayload {
    data: Array<{
        event_name: EventTypes;
        event_time: number;
        action_source: string;
        /*event_id: string;*/
        user_data: IFBConversionPayloadUser;
        custom_data?: {
            content_ids?: Array<string>;
            user_score?: number;
            client_ip?: string;
        }
        content_ids?: Array<string>;
    }>;
};


interface IFBConversionAPIResponse {
    // type as per your expected response
    [key: string]: any;
}

/*
IMPLEMENTATION: https://business.facebook.com/events_manager2/implementation_instructions/1487366004847224?business_id=125167442089143&nav_source=marketer_assessment
USING API: https://developers.facebook.com/docs/marketing-api/conversions-api/using-the-api#hashing
PAYLOAD HELPER: https://developers.facebook.com/docs/marketing-api/conversions-api/payload-helper
CUSTOMER DATA FORMAT: https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters
* */
export default function useCHFBConversionAPI({
                                               user_email,
                                               user_score,
                                                client_ip
                                           }: IPropHook):
    {
        facebookApiIsLoading: boolean;
        facebookApiData: IFBConversionAPIResponse | null;
        facebookApiError: any;
        facebookApiFunction: (
            type: EventTypes,
            event_source_url: string,
            action_source: string,
            content_ids?: Array<string>,
        ) => Promise<void>
    } {

    const [loading, setLoading] = useState<boolean>(false);
    const [responseData, setResponseData] = useState<IFBConversionAPIResponse | null>(null);
    const [error, setError] = useState<any>(null);

    const facebookApiFunction = useCallback(async (
        eventName: EventTypes,
        event_source_url: string,
        action_source: string,
        content_ids?: Array<string>,
    ) => {
        setLoading(true);

        // Preparing user responseData
        const userDataObj: IFBConversionPayloadUser = {
            em: [
                CryptoJS.SHA256(user_email).toString()
            ],
            ph: [
                null
            ],
        };

        const payloadObj: IFBConversionPayload = {
            data: [
                {
                    event_name: eventName,
                    event_time: Math.floor(Date.now() / 1000),
                    action_source: action_source,
                    // event_id: Math.random().toString(36).substring(2, 15), // Generate random event ID, since this depends on how you track your events
                    user_data: userDataObj,
                    custom_data: {
                        content_ids: content_ids,
                        // user_score: user_score,
                        // client_ip: client_ip,
                    }
                },
            ],
        };

        try {
            const response: AxiosResponse = await axios.post(
                `https://graph.facebook.com/v12.0/${APP_CONSTANTS.PIXEL_ID}/events?access_token=${APP_CONSTANTS.ACCESS_TOKEN}`,
                payloadObj,
                {
                    headers: {
                        'Content-Type': 'application/json', // BUGFIX: przeglądarka dodaje swój Content-Type ale to nie jest ten wysyłany EXPLICITE = data parameter is not sent
                    }
                }
            );

            setResponseData(response.data);
            setError(null);
        } catch (err) {
            setResponseData(null);
            setError(err);
        } finally {
            setLoading(false);
        }
    }, [user_email, user_score]);

    return {facebookApiIsLoading: loading, facebookApiData: responseData, facebookApiError: error, facebookApiFunction: facebookApiFunction};
};


/*
const { callFacebookAPI, loading, data, error } = useCHFBConversionAPI({ user_email: 'test@example.com', user_score: 500 });
const location = useLocation(); // If you are in a react-router context

useEffect(() => {
    // Assuming that you have a server-side endpoint /api/getuserip that returns the client's IP
    fetch("/api/getuserip")
        .then(response => response.text())
        .then(ip => callFacebookAPI(EventTypes.Purchase, location.pathname, "website", ip ));
}, [callFacebookAPI, location]);*/
