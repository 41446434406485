// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import React from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftInput from "../../components/SoftInput";
import {IFormFieldProp} from "../../type/form/IFormFieldProp";
import {Field, ErrorMessage} from "formik";
import FormNgValidationYesTemplate from "../validation/FormNgValidationYesTemplate";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

/* https://mui.com/material-ui/react-text-field/ */
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';


function ElementFormFieldPassword(
    {
        paramObj,
        formFieldObj,
        formikGantry,
        ...rest
    }: IFormFieldProp
) {

    const [showPassword, setShowPassword] = React.useState(false);
    return (
        <>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    /*textTransform="capitalize"*/
                >
                    {formFieldObj.field_label}
                </SoftTypography>
                {(formFieldObj.field_is_required === 'true') ?
                    <SoftTypography
                        variant={"h5"} color={"error"}
                        display="inline"
                    >
                        &nbsp;*
                    </SoftTypography>
                    : null
                }
            </SoftBox>
            {/*// @ts-ignore*/}

            {/*- "error" is MATERIAL UI fiel, success is NOT:
                    https://mui.com/material-ui/api/text-field/
                    error	bool	false
                    If true, the label is displayed in an error state.
                    */}

            <SoftBox
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                flexDirection={"row"}
            ><SoftBox><Field
                name={formFieldObj.field_name} as={SoftInput}
                type={showPassword ? 'text' : 'password'}
                error={formikGantry.errors[formFieldObj.field_name] && formikGantry.touched[formFieldObj.field_name]}
                success={
                    formikGantry.values[formFieldObj.field_name] &&
                    formikGantry.values[formFieldObj.field_name].length > 0 &&
                    !formikGantry.errors[formFieldObj.field_name]}
                placeholder={formFieldObj.field_placeholder}
                /*      startAdornment={
                          <InputAdornment position="start">
                              <AccountCircle />
                          </InputAdornment>
                      }*/
                variant={"standard"}
                fullWidth={formFieldObj.field_is_fullwidth == 1 ? true : false}
            /></SoftBox>
                <SoftBox
                    px={1}
                >
                    {showPassword ?
                        <VisibilityOutlinedIcon
                            onClick={() => setShowPassword(!showPassword)}
                            sx={{cursor: 'pointer'}}
                            fontSize={"medium"}
                            color={showPassword ? "info" : "info"}
                        /> :
                        <VisibilityOffOutlinedIcon
                            onClick={() => setShowPassword(!showPassword)}
                            sx={{cursor: 'pointer'}}
                            fontSize={"medium"}
                            color={showPassword ? "info" : "info"}
                        />
                    }

                </SoftBox>

            </SoftBox>

            {formFieldObj.field_tip && <SoftBox
                lineHeight={1}
            >


                <SoftTypography
                    variant={"caption"}
                    fontWeight={"regular"}
                    color={"secondary"}
                >
                    {formFieldObj.field_tip}
                </SoftTypography>

            </SoftBox>
            }
            <>{
                ((formFieldObj.ng_validation_yes_template
                        && !formikGantry.errors[formFieldObj.field_name]
                    ) &&
                    <FormNgValidationYesTemplate
                        formFieldObj={formFieldObj}
                        formikGantry={formikGantry}
                        templateModule={formFieldObj.ng_validation_yes_template}
                    />)
            }</>

            <SoftTypography variant={"caption"} color={"error"}>
                <ErrorMessage name={formFieldObj.field_name}/>
            </SoftTypography>
        </>
    );
}

/*// typechecking props for FormField
ElementFormFieldPassword.propTypes = {
  field_label: PropTypes.string.isRequired,
};*/

export default ElementFormFieldPassword;
