import {ToastContainer} from "react-toastify";

const ElementClubItemSummaryMain = React.lazy(() => import("./ElementClubItemSummaryMain")); // page_item_id: 669
const ElementClubItemSummaryOther = React.lazy(() => import("./ElementClubItemSummaryOther")); // page_item_id: 669

import React, {useEffect, useState} from "react";
import {lazy, Suspense} from "react";
import AfdLoadingFullpage from "../../../AfdLoadingFullpage";
import Emptybox from "../../../emptybox/Emptybox";
import AfdContainerCard from "../../../AfdContainerCard";

import useCHContainerData from "../../../../hook/useCHContainerData";
import useCHGetContextSoftUI from "../../../../hook/useCHGetContextSoftUI";
import IPropContainer from "../../../../type/IPropContainer";
import ICtxGlobalConfig from "../../../../type/context/ICtxGlobalConfig";

import Grid from "@mui/material/Grid";
import SoftBox from "../../../../components/SoftBox";
import GetMyAcademyNotificationList from "../../../sourcerer/GetMyAcademyNotificationList";
import GetGlobalProfileIcon from "../../../sourcerer/GetGlobalProfileIcon";
import {navbarRow} from "../../get_club_dashboard_navbar/styles";
import {getSpecNotificationIconObjList, ISpecNotificationIconListObjList} from "../../../../util/UtilReactElement";
/*ng-click="psedudoScrollNextPage()"*/
//{containerRow.post_count}
const GetClubPageItemSummary = ({
                                    controllerInfoObj: controllerInfoObj,
                                    userInfoObj: userInfoObj,
                                    paramObj: paramObj
                                }: IPropContainer) => {

    // @ts-ignore
    let pageItemGroup: "post" | "comment" | "instructor" = controllerInfoObj.const_param1;
    let light: boolean = false;
    let isMini: boolean = false;

    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const components: any = {
        ElementClubItemSummaryMain,
        ElementClubItemSummaryOther,

    }
    let ElementComponent: React.JSXElementConstructor<any>;

    /*    if (controllerInfoObj.element1CamelName !== undefined) {
            ElementComponent = components[controllerInfoObj.element1CamelName];
            var [elementObjList, getElementObjList, containerInfoObj, setContainerInfoObj] = useCHContainerData(controllerInfoObj,
                {
                    perf_user_id: userInfoObj.user_id,
                },
                {}
            );

        }*/

    function getContainerContent() {
        if (controllerInfoObj.containerName === 'CONTAINER_P_CLUB_PAGE_ITEM_SIDEBAR') {
            return (
                <>
                    {/* <p>     {
                        elementObjList.map((element: any, index: number) => (
                            <ElementComponent
                                key={index}
                                row={element}
                            />
                        ))
                    }</p>*/}
                    [clear][/clear]
                </>
            );
        }
        if (controllerInfoObj.containerName === 'CONTAINER_P_CLUB_PAGE_ITEM_SUMMARY') {
            let specNotificationIconObjList: ISpecNotificationIconListObjList = getSpecNotificationIconObjList(
                userInfoObj,
                globalConfigParamObj.isMobile
            );


            return (
                <>
                    {isMini ? null : (
                        <SoftBox sx={(theme: any) => navbarRow(theme, {isMini})}>
                            {
                                Object.keys(specNotificationIconObjList).map((element: any, index: number) => {
                                    return (
                                        <SoftBox
                                            key={index}
                                        ><GetMyAcademyNotificationList
                                            userInfoObj={userInfoObj}
                                            pageItemGroup={element}
                                            templateModule={'appbar'}
                                        /></SoftBox>
                                    );
                                }
                                )
                            }



                            <SoftBox color={light ? "white" : "inherit"}
                                     ml={2}
                            >
                                <GetGlobalProfileIcon
                                    controllerInfoObj={controllerInfoObj}
                                    userInfoObj={userInfoObj}
                                />

                                {/*   <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small">
                  <Icon
                    sx={
                    (
                        { palette: { dark, white } }: any
                    ) => ({
                      color: light ? white.main : dark.main,
                    })
                  }
                  >
                    account_circle
                  </Icon>
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color={light ? "white" : "dark"}
                  >
                    Sign in
                  </SoftTypography>
                </IconButton>
              </Link>*/}
                                {/*TODO*/}
                                {/*  <IconButton
                                size="small"
                                color="inherit"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                <Icon className={light ? "text-white" : "text-dark"}>
                                    {miniSidenav ? "menu_open" : "menu"}
                                </Icon>
                            </IconButton>*/}
                                {/*    <IconButton
                                size="small"
                                color="inherit"
                                sx={navbarIconButton}
                                onClick={handleConfiguratorOpen}
                            >
                                <Icon>settings</Icon>
                            </IconButton>*/}
                                {/*TODO - bug: sprawdzić specyfikacje: variant="contained"*/}


                            </SoftBox>
                        </SoftBox>
                    )}

                </>
            );
        }
    }

    return (
        <>
            <Suspense fallback={<AfdLoadingFullpage
                componentCategory="AfdController_Suspense"
                controllerInfoObj={controllerInfoObj}
            />}></Suspense>
            <AfdContainerCard
                controllerInfoObj={controllerInfoObj}
                userInfoObj={userInfoObj}
                paramObj={paramObj}
            >
                {getContainerContent()}
            </AfdContainerCard>
        </>
    );
}

export default GetClubPageItemSummary;
