
/*Pobierz dane element*/
import {useEffect, useState} from "react";
import RestApiService from "../service/restapi/RestApiService";
import RestApiCommonService from "../service/restapi/RestApiCommonService";

import IContainerQuery   from "../type/IContainerQuery";
import IJos4Menu from "../type/IJos4Menu";
import IUserInfo from "../type/IUserInfo";
import IRedirectionInfo from "../type/IRedirectionInfo";


export default function useCHGantryData(menuItemObj: IJos4Menu,
                                            containerInfoObj: IContainerQuery[],
                                            userInfoObj: IUserInfo,
                                            routeCategory: String,
                                            redirectionObj?: IRedirectionInfo,
                                        ) {

    const [menuItemListObj, setMenuItemListObj] = useState([]);

    let promiseArray: Promise<any>[] = new Array<Promise<any>>();
    /*promiseArray.push(RestApiService.getPromise(RestApiCommonService.getMenuItemListUrl(menuItemObj.xx_display_menutype)));*/

    /* Get image for redirection_category and then its into */
    useEffect(() => {


        Promise.all(
                promiseArray
        ).then(
            (responseArraySelect: any[]) => {
                /*setMenuItemListObj(responseArraySelect[0].data);*/
                }
        )
    }, []);

    return [
        menuItemListObj,
        setMenuItemListObj
    ]
}
