// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";


/*// Typechecking props for the SidenavItem
SidenavList.propTypes = {
    children: PropTypes.node.isRequired,
};*/

interface IProp {
    children: any;
}

function SidenavList({ children }: IProp
) {
  return (
    <List
      sx={{
        pl: 2,
        ml: 3,
      }}
    >
      {children}
    </List>
  );
}


export default SidenavList;
