import React from "react";
import RokContainerTitle from "./RokContainerTitle";
import SoftBox from "../components/SoftBox";
import SoftTypography from "../components/SoftTypography";
import RokPageTitle from "./RokPageTitle";

const ElementGlobalPageTitleTooltip = ({controllerInfoObj}) => {
    let styleString = {
        "display": controllerInfoObj.css_display
    };
    let imgStyleString = {
        "width": "24px"
    };


    return (
        <>
            <RokPageTitle
                containerCard={controllerInfoObj.container_card}
            >{controllerInfoObj.module_title}</RokPageTitle>
            {
                (controllerInfoObj.tooltip_content !== "") ?
                    <SoftBox mb={2}>
                        <SoftTypography variant="body2" color="text">
                            <strong>{controllerInfoObj.tooltip_title}</strong>
                            {controllerInfoObj.tooltip_content}
                        </SoftTypography>
                    </SoftBox>
                    :
                    ''
            }
        </>

        /*<div className="divModuleTitleTable">
            <div className="divModuleTitleTableRow">
                <div className="divModuleTitleTableCell">

                    <h3 className="afd_h3_kurs"> {containerRow.module_title} </h3>

                </div>
                <div className="divModuleTitleTableCell">
                    <div><a className="afd_tooltip" href="#"> <img style={imgStyleString}
                                                                   src="/images/icon/32x32/Info2.png" alt="image"/>
                        <span className="afd_tooltip_content"> <img style={styleString}
                                                                    src={containerRow.instructor_image_path}
                                                                    alt="image"/> <strong>{containerRow.tooltip_title}</strong> {containerRow.tooltip_content} </span>
                    </a></div>
                </div>
            </div>
        </div>*/
    );
}

export default ElementGlobalPageTitleTooltip;
