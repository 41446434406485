import React, {useContext, useEffect, useState} from "react";
import {debugLog} from "../../util/UtilReact";

// @mui material components
import Container from "@mui/material/Container";
import {Form, Formik, FormikErrors, FormikHelpers, FormikProps, FormikValues} from 'formik';

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";

// Soft UI Dashboard PRO React example components
import PageLayout from "../../examples/LayoutContainers/PageLayout";

// Pricing page components
import Grid from "@mui/material/Grid";

import AfdSelectorSimple from "../../selector/AfdSelectorSimple";
import AfdSelectorImage from "../AfdSelectorImage";
import IContainerQuery from "../../type/IContainerQuery";
import useCHGantryData from "../../hook/useCHGantryData";
import IPropGantry from "../../type/IPropGantry";
import SoftTypography from "../../components/SoftTypography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
import SoftButton from "../../components/SoftButton";

/* useFormik() is a custom React hook that will return all Formik state and helpers directly.
    Despite its name, it is not meant for the majority of use cases.
    Internally, Formik uses useFormik to create the <Formik> component (which renders a React Context Provider).
If you are trying to access Formik state via context, use useFormikContext. Only use this hook if you are NOT using <Formik> or withFormik.
 Be aware that <Field>, <FastField>, <ErrorMessage>, connect(), and <FieldArray> will NOT work with useFormik() as they all require React Context.
*/

import * as Yup from 'yup';

import {getPosition2ContainerMap} from "../../util/UtilReact";
import {useLocation} from "react-router-dom";
import IFormFormikGantry from "../../type/form/IFormFormikGantry";
import IFormFormikParamObj from "../../type/form/IFormFormikParamObj";
import {useDispatch, useSelector} from "react-redux";
import IRdxState from "../../type/reducer_state/IRdxState";
import {
    actAccountTrainStepUpdate,
    actUserCookieName
} from "../../redux_action/rdxAction";
import RestApiService from "../../service/restapi/RestApiService";
import RestApiAuthorizationService from "../../service/restapi/RestApiAuthorizationService";
import IFormFormikHandleSubmit from "../../type/form/IFormFormikHandleSubmit";
import ISelectorPosition from "../../type/ISelectorPosition";
import {isDev} from "../../util/isDev";
import IUniversalFormSubmitReponse from "../../type/comprofiler/IUniversalFormSubmitReponse";
import {formikAccountHandleSubmit, formikAmocourseHandleSubmit} from "../../form/formikHandleSubmit";
import AfdMainpageBanner from "../AfdMainpageBanner";
import IValueRtAccountTrain from "../../type/value_initial/IValueRtAccountTrain";
import GetMenuPageBanner from "../../util/GetMenuPageBanner";
import {getIsSubmittingProgressTSX} from "../../util/UtilReactElement";
import CtxYupSchemaObj from "../../context/CtxYupSchemaObj";
import ICtxFormResponse from "../../type/context/ICtxFormResponse";
import CtxFormResponse from "../../context/CtxFormResponse";
import IAppObj from "../../type/context/IAppObj";
import CtxAppObj from "../../context/CtxApp";
import useCHFBConversionAPI from "../../hook/useCHFBConversionAPI";
import ICtxYupSchema from "../../type/context/ICtxYupSchema";


/*  return array(
      'status' => 'error',
      'message' => 'post_count_0',
      'message_desc' => 'Brak danych logoowania. Skontaktuj się z administratorem.',
      'data' => $_POST
  );
    array(
          'status' => 'error',
          'message' => 'spoof_check_error',
          'message_desc' => "Błąd weryfikacji CRC. Skontaktuj się z administratorem."
           'data' => array(
              'result_error' => $resultError,
          )
      );
  */

/*import React from "@types/react";*/

function RtAccountTrain({
                            menuItemObj,
                            containerObjList,
                            userInfoObj,
                            routeCategory,
                            redirectionObj
                        }: IPropGantry) {

    const ctxYupSchemaObj: ICtxYupSchema = useContext<ICtxYupSchema>(CtxYupSchemaObj);
    var [menuItemListObj, setMenuItemListObj] = useCHGantryData(
        menuItemObj,
        containerObjList,
        userInfoObj,
        routeCategory,
        redirectionObj
    );
    const ctxAppObj: IAppObj = React.useContext(CtxAppObj);
    const ctxFormResponse: ICtxFormResponse = useContext<ICtxFormResponse>(CtxFormResponse);

    const location = useLocation();
    const { facebookApiFunction, facebookApiIsLoading, facebookApiData, facebookApiError } = useCHFBConversionAPI(
        {
            user_email: userInfoObj.user_email,
            user_score: 100,
            client_ip: ''
        });

    /* https://github.com/jaredpalmer/formik/issues/1436
    Validation schema is just another prop. You can lift up some other state above your Formik component and then update it imperatively from a child component.
     Then you can alter the prop or return value of the function at will.
    */

    // Wyjąkowo potrzebujemy parametrów wcześniej niż w SELECTOR
    let queryHookObj = new URLSearchParams(useLocation().search);

    /*Once*/
    useEffect(() => {


    }, []);

    /* Promoode change */
    useEffect(() => {

    }, []);

    /*Wizard controls*/
    /*const [activeStep, setActiveStep] = useState(0);*/
    let activeStep = useSelector((state: IRdxState) => {
        return state.account_train_step;
    });
    const steps = getSteps();
    const isLastStep = activeStep === steps.length - 1;

    const rdxDispatchFunction = useDispatch();
    const handleNextStepFunction = () => {
        rdxDispatchFunction(actAccountTrainStepUpdate(
            1
        ));
    };
    const handleBackStepFunction = () => {
        rdxDispatchFunction(actAccountTrainStepUpdate(
            -1
        ));
    }

    const position2ContainerMap = getPosition2ContainerMap(containerObjList);

    const getSelectorPositionJSX = function (
        positionCode: ISelectorPosition,
        formikGantry?: IFormFormikGantry
    ): JSX.Element | null {
        debugLog("VALUES: " + JSON.stringify(formikGantry?.values));
        debugLog("ERRORS" + JSON.stringify(formikGantry?.errors));

        if (position2ContainerMap[positionCode]?.length > 0) {
            return (
                <Container>
                    <AfdSelectorSimple
                        menuItemObj={menuItemObj}
                        userInfoObj={userInfoObj}
                        position={positionCode}
                        redirectionObj={redirectionObj}
                        containerAssocList={position2ContainerMap[positionCode]}
                        formikGantry={formikGantry}
                    /></Container>);
        } else {
            return null;
        }
    }


    function getSteps() {
        return ["Start", "Dane konta", "Preferencje"];
    }

    function getStepContent(stepIndex: number,
                            formikGantry: any
    ) {
        switch (stepIndex) {
            case 0:
                return getSelectorPositionJSX("body",
                    formikGantry
                );
            case 1:
                return getSelectorPositionJSX("body",
                    formikGantry
                );
            case 2:
                return getSelectorPositionJSX("mainbottom-a",
                    formikGantry
                );
            default:
                return null;
        }
    }

    var containerMarginTop: number = GetMenuPageBanner(menuItemObj).containerMarginTop;
    return (
        <PageLayout>
            {/*// @ts-ignore*/}
            <AfdSelectorImage
                menuItemObj={menuItemObj}
                containerObjList={containerObjList}
                userInfoObj={userInfoObj}
                routeCategory={routeCategory}
                redirectionObj={redirectionObj}
                mainpageBannerTSX={<AfdMainpageBanner
                    menuItemObj={menuItemObj}
                    containerObjList={containerObjList}
                    userInfoObj={userInfoObj}
                    routeCategory={routeCategory}
                    redirectionObj={redirectionObj}
                    getSelectorPositionJSX={getSelectorPositionJSX}
                />}
            >
                {getSelectorPositionJSX('top-a')}
                {getSelectorPositionJSX('showcase-a')}
                {/*banner below menu */}

            </AfdSelectorImage>
            <SoftBox
                position="relative"
                px={{xs: 1, sm: 0}}
                mt={containerMarginTop}
            >
                {/*<Header tabValue={tabValue} tabHandler={handleSetTabValue} />*/}
                <Formik
                    initialValues={ctxYupSchemaObj.storeFormikInitialValueObj['rt-account-train']}
                    onSubmit={
                        (
                            values: IValueRtAccountTrain,
                            {setSubmitting}: any,
                        ) => {
                            return formikAccountHandleSubmit(
                                values,
                                {setSubmitting},
                                userInfoObj,
                                ctxFormResponse,
                                {
                                    handleNextStepFunction: handleNextStepFunction,
                                    utm_source: ctxAppObj.utm_source,
                                    utm_medium: ctxAppObj.utm_medium,
                                    utm_campaign: ctxAppObj.utm_campaign,
                                    utm_content: ctxAppObj.utm_content,
                                    utm_placement: ctxAppObj.utm_placement,
                                    location: location,
                                    facebookApiFunction: facebookApiFunction,
                                });
                        }
                    }
                    validationSchema={Yup.object().shape(ctxYupSchemaObj.storeValidationSchemaShapeObj)}
                    validateOnBlur={false}
                    validateOnChange={true}
                >
                    {
                        ({
                             values,
                             errors,
                             touched,
                             isSubmitting,
                             handleChange,
                             handleBlur,
                             handleSubmit,
                         }) => (
                            <Form id={"afd_form"} autoComplete="off" onSubmit={handleSubmit}>
                                <SoftBox pt={{xs: 1, md: 2, lg: 3}}
                                         pb={8} // footer
                                >
                                    <Grid container justifyContent="center">
                                        <Grid item xs={12} lg={8}>
                                            <Card
                                            >
                                                <SoftBox px={{xs: 0.5, sm: 1, md: 2, lg: 2}} // szersze pola
                                                         py={{xs: 0.5, md: 1, lg: 2}}
                                                >
                                                    {getStepContent(activeStep,
                                                        {
                                                            values,
                                                            touched,
                                                            errors,
                                                            isSubmitting
                                                        }
                                                    )}

                                                </SoftBox>
                                                <SoftBox
                                                    display={"flex"}
                                                    justifyContent={"center"}
                                                    alignItems={"center"}
                                                >{getIsSubmittingProgressTSX(isSubmitting)}</SoftBox>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </SoftBox>
                            </Form>
                        )}
                </Formik>
            </SoftBox>
        </PageLayout>

    )
}

export default RtAccountTrain;
