import React, {Suspense, useEffect, useState} from "react";
import {debugLog} from "../../util/UtilReact";

// @mui material components
import Container from "@mui/material/Container";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";

// Soft UI Dashboard PRO React example components
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import {getPosition2ContainerMap} from "../../util/UtilReact";

import AfdSelectorProfile from "../../selector/AfdSelectorProfile";
import AfdSelectorImage from "../AfdSelectorImage";
import IContainerQuery from "../../type/IContainerQuery";
import useCHGantryData from "../../hook/useCHGantryData";
import IPropGantry from "../../type/IPropGantry";

import GetMenuPageBanner from "../../util/GetMenuPageBanner";

import ISelectorPosition from "../../type/ISelectorPosition";
import Grid from "@mui/material/Grid";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import GetClubDashboardNavbar from "../../component/controller/get_club_dashboard_navbar/GetClubDashboardNavbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';

import CtxSiteProfile from "../../context/CtxSiteProfile";
import IElementGalleryCommentLine from "../../type/gallery/IElementGalleryCommentLine";
import AppBar from "@mui/material/AppBar";
import {
    navbar,
    navbarContainer,
    navbarDesktopMenu, navbarIconButton,
    navbarRow
} from "../../component/controller/get_club_dashboard_navbar/styles";
import Toolbar from "@mui/material/Toolbar";
import Breadcrumbs from "../../examples/Breadcrumbs";
import Icon from "@mui/material/Icon";
import GetGlobalProfileIcon from "../../component/sourcerer/GetGlobalProfileIcon";
import useCHGetContextSoftUI from "../../hook/useCHGetContextSoftUI";
import {useLocation, useNavigate} from "react-router-dom";
import {setMiniSidenav, setOpenConfigurator, setTransparentNavbar} from "../../redux_reducer/util/dispatchShortcut";
import Menu from "@mui/material/Menu";
import NotificationItem from "../../examples/Items/NotificationItem";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import ConfigButtonFactory from "../../examples/Configurator/ConfigButtonFactory";
import {isDev} from "../../util/isDev";
import AfdConfigurator from "../../examples/Configurator/AfdConfigurator";
import IElementGalleryLatestCommentNg from "../../type/gallery/IElementGalleryLatestCommentNg";
import SoftAvatar from "../../components/SoftAvatar";
import IconButton from "@mui/material/IconButton";
import GetMyAcademyNotificationList from "../../component/sourcerer/GetMyAcademyNotificationList";
import SoftTypography from "../../components/SoftTypography";
import {ToastContainer} from "react-toastify";
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import linkDictionary from "../../linkDictionary";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import {useQuery} from "react-query";

function RtSiteProfile({
                           menuItemObj,
                           containerObjList,
                           userInfoObj,
                           routeCategory,
                           redirectionObj
                       }: IPropGantry) {

    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    var [menuItemListObj, setMenuItemListObj] = useCHGantryData(
        menuItemObj,
        containerObjList,
        userInfoObj,
        routeCategory,
        redirectionObj
    );
    const [storeMyAcademyNotifCounter, setStoreMyAcademyNotifCounter] = useState<{
        comment: number;
        post: number;
        instructor: number;
    }>({
        comment: 0,
        post: 0,
        instructor: 0
    });

    /* Opcja wymuszenia TAB z URL */
    const useQuery = () => new URLSearchParams(useLocation().search);
    const queryHookObj = useQuery();
    let initialUrlTab = queryHookObj.get('tab');

    const [storeTabValue, setStoreTabValue] = useState<"profile-user-newsletter" | string | null>(initialUrlTab);
    const navigate = useNavigate();
    const handleConfiguratorOpen = () => {
        navigate(linkDictionary.CLUB_GALLERY_NEW_IMAGE);

    };

    let isProfileCurrentUser: boolean = (userInfoObj.user_id === redirectionObj.object_user_id);

    function getGalleryImageListButtonTSX() {
        return <CollectionsOutlinedIcon
            sx={{
                /*color: "primary.main",*/
            }}
        />;
    }


    let configButtonTSX = ConfigButtonFactory(
        handleConfiguratorOpen,
        getGalleryImageListButtonTSX()
    );

    /* Button */
    function getLatestCommentButtonTSX() {
        return <Icon color="inherit">
            rss_feed
        </Icon>
    }

    const handleSetTabValueFunction = (event: any, newValue: any) => {
        setStoreTabValue(newValue);
        debugLog(storeTabValue);
    };

    const position2ContainerMap = getPosition2ContainerMap(containerObjList);

    /* App bar */
    let absolute: boolean = false;
    let light: boolean = false;
    let isMini: boolean = false;


    const [navbarType, setNavbarType] = useState('');
    const [controller, dispatch] = useCHGetContextSoftUI();
    const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator} = controller;
    const route = useLocation().pathname.split("/").slice(1);


    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        /* A function that sets the transparent state of the navbar.
        - either FIXED and position 0
        or
        - not FIXED
         */
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

    const getSelectorPositionJSX = function (positionCode: ISelectorPosition): JSX.Element | null {
        if (position2ContainerMap[positionCode]?.length > 0) {

            /* BUGFIX: disableGutters nie działa */
            if (menuItemObj.xx_display_menutype === 'dummy') {
                return (
                    <AfdSelectorProfile
                        key={positionCode}
                        menuItemObj={menuItemObj}
                        userInfoObj={userInfoObj}
                        position={positionCode}
                        redirectionObj={redirectionObj}
                        containerAssocList={position2ContainerMap[positionCode]}
                    />
                );
            } else {
                return (
                    <Container
                        /*disableGutters={true}*/ // nie działa
                    >
                        <AfdSelectorProfile
                            key={positionCode}
                            menuItemObj={menuItemObj}
                            userInfoObj={userInfoObj}
                            position={positionCode}
                            redirectionObj={redirectionObj}
                            containerAssocList={position2ContainerMap[positionCode]}
                        /></Container>);
            }
        } else {
            return null;
        }

    }


    /*****           *******************************************************                   ******/
    var containerMarginTop: number = GetMenuPageBanner(menuItemObj).containerMarginTop;

    function getTabUserInfoTSX() {
        return (<>
            {getSelectorPositionJSX('feature-a')}
            <Grid container
            >
                <Grid
                    item xs={userInfoObj.page_set_code == 'viewer' ? 12 : 6}
                    lg={6}
                >{getSelectorPositionJSX('content-top-a')}</Grid>
                <Grid
                    item xs={userInfoObj.page_set_code == 'viewer' ? 12 : 6}
                    lg={6}
                >{getSelectorPositionJSX('content-top-b')}</Grid>
            </Grid>
            {/*zaznaczanie Oli dla CHAT*/}
            {getSelectorPositionJSX('body')}
            {getSelectorPositionJSX('content-bottom-a')}
        </>);
    }

    function getTabOtherAllTSX() {

        return <Grid container>
            <Grid item xs={12} lg={12}>
                <SoftBox my={3}>
                    {
                        // @ts-ignore
                        getSelectorPositionJSX(storeTabValue)
                    }
                </SoftBox>
            </Grid>
        </Grid>;
    }

    /* Nie pokazuj jeżeli tylko jedna */
    /*    function getTabListTSX() {
            if (specificationObjList.length < 2
                || menuItemObj.id != 617
            ) {
                return null;
            } else {
                return <SoftBox
                    alignItems={"left"}
                > <Tabs orientation={"horizontal"}
                        value={storeTabValue}
                        onChange={handleSetTabValueFunction}>
                    {
                        specificationObjList.map((specificationObj, index) => {
                                return <Tab
                                    key={index}
                                    label={globalConfigParamObj.isMobile &&
                                    index !== storeTabValue
                                        ? '' : specificationObj.label} // nie pokazuj reszty
                                    icon={specificationObj.icon}
                                    value={specificationObj.value}
                                    sx={{
                                        paddingRight: 2
                                    }}
                                />
                            }
                        )
                    }
                </Tabs>
                </SoftBox>;
            }
        }*/

    return (
        <>
            <CtxSiteProfile.Provider value={{
                storeMyAcademyNotifCounter: storeMyAcademyNotifCounter,
                setStoreMyAcademyNotifCounter: setStoreMyAcademyNotifCounter,
                storeTabValue: storeTabValue,
                setStoreTabValue: setStoreTabValue,
            }}>


                {getSelectorPositionJSX('showcase-a')}
                <DashboardLayout>

                    <AppBar
                        // @ts-ignore
                        position={absolute ? "absolute" : navbarType}
                        color="inherit"
                        sx={
                            (theme) => navbar(
                                theme,
                                {
                                    transparentNavbar, absolute, light
                                }
                            )
                        }
                    >
                        <Toolbar sx={(theme) => navbarContainer(theme)}>
                            <SoftBox color="inherit" mb={{xs: 1, md: 0}}
                                     sx={(theme: any) => navbarRow(theme, {isMini})}
                            >
                                {/*<Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light}/>*/}
                                <Icon fontSize="medium"
                                    /*sx={navbarDesktopMenu}*/
                                      onClick={handleMiniSidenav}>
                                    {miniSidenav ? "menu_open" : "menu"}
                                </Icon>
                                {getSelectorPositionJSX('top-a')}
                                {getSelectorPositionJSX('navigation')}
                            </SoftBox>


                        </Toolbar>
                    </AppBar>


                    <SoftBox mt={2}>
                        <>
                            {/*  {!globalConfigParamObj.isMobile ? getTabListTSX() : getTabListTSX()
                        }*/}
                            {getSelectorPositionJSX('maintop-a')}
                        </>

                        <Grid container spacing={1}>
                            {/* RIGHT sidebar */}
                            {/*       <Grid item xs={12} lg={3}>

            </Grid>*/}
                            {
                                isProfileCurrentUser ? <Grid item xs={12} md={3} lg={3}
                                >
                                    {getSelectorPositionJSX('sidebar-a')}
                                    {getSelectorPositionJSX('sidebar-b')}
                                    {getSelectorPositionJSX('sidebar-c')}
                                </Grid> : <></>
                            }
                            {/*
                            Show standard profile CONTAINERSm if no option selected - otherwise getTabOtherAllTSX()
                            */}
                            <Grid item xs={12}
                                  md={isProfileCurrentUser ? 9 : 12}
                                  lg={isProfileCurrentUser ? 9 : 12}
                            >
                                {storeTabValue == null || storeTabValue == ''
                                    ?
                                    getTabUserInfoTSX() :
                                    getTabOtherAllTSX()
                                }
                            </Grid>
                        </Grid>
                    </SoftBox>
                    <SoftBox
                        display="flex"
                        flexDirection={"column"}
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                        {getSelectorPositionJSX('mainbottom-a')}
                        {getSelectorPositionJSX('bottom-a')}
                    </SoftBox>
                    {getSelectorPositionJSX('module-footer-a')}
                    {
                        (menuItemObj)
                            ?
                            <AfdConfigurator
                                menuItemObj={menuItemObj}
                                redirectionObj={redirectionObj}
                                userInfoObj={userInfoObj}
                                routeCategory={routeCategory}
                                selectorTSX={getSelectorPositionJSX('drawer')}
                            /> : null
                    }
                </DashboardLayout>
            </CtxSiteProfile.Provider>
        </>
    )
}

export default RtSiteProfile;
