const cardActionStyles = {
    /*display: "inline",*/
    textDecoration: "none",
    baseClassName: 'material-icons-two-tone',

    "& .MuiTypography-root": {
        display: "flex",
        alignItems: "center",
    },

    "& .material-icons, .material-icons-round,": {
        fontSize: "1rem",
        fontWeight: 100,
    }
    /*        "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
            {
                transform: `translateX(6px)`,
            },*/
};

export {cardActionStyles};

