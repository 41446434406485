/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
function navbar(
    theme: any,
    ownerState: any
) {
  const { palette, boxShadows, functions, transitions, breakpoints, borders } = theme;
  const { transparentNavbar, absolute, light } = ownerState;

  const { dark, white, text, transparent } = palette;
  const { navbarBoxShadow } = boxShadows;
  const { rgba, pxToRem } = functions;
  const { borderRadius } = borders;

  return {
    boxShadow: transparentNavbar || absolute ? "none" : navbarBoxShadow,
    backdropFilter: transparentNavbar || absolute ? "none" : `saturate(200%) blur(${pxToRem(30)})`,
    backgroundColor:
      transparentNavbar || absolute ? `${transparent.main} !important` : rgba(white.main, 0.8),

    color: () => {
      let color;

      if (light) {
        color = white.main;
      } else if (transparentNavbar) {
        color = text.main;
      } else {
        color = dark.main;
      }

      return color;
    },
    top: absolute ? 0 : {
        xs: pxToRem(12),
        sm: pxToRem(12),
        md: pxToRem(12),
        lg: pxToRem(12),
        xl: pxToRem(12)
    },
    minHeight: {
          xs: pxToRem(36),
            sm: pxToRem(48),
            md: pxToRem(75),
            lg: pxToRem(75),
            xl: pxToRem(75)
    },
    display: "grid",
    alignItems: "center",
    borderRadius: borderRadius.xl,
    paddingTop: {
        xs: pxToRem(3),
        sm: pxToRem(4),
        md: pxToRem(6),
        lg: pxToRem(8),
        xl: pxToRem(8)
    },
    paddingBottom: {
      xs: pxToRem(1),
      sm: pxToRem(2),
      md: pxToRem(5),
      lg: pxToRem(8),
      xl: pxToRem(8)
    },
    paddingRight: absolute ? pxToRem(8) : 0,
    paddingLeft: absolute ? pxToRem(16) : 0,

    "& > *": {
      transition: transitions.create("all", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "& .MuiToolbar-root": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      [breakpoints.up("sm")]: {
        minHeight: "auto",
        padding: `${pxToRem(4)} ${pxToRem(16)}`,
      },
    },
  };
}

const navbarContainer = ({ breakpoints }: any) => ({
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  pt: { xs: 0.1, sm: 0.2, md: 0.5, lg: 0.5, xl: 0.5 }, // mniejszy pasek
  pb:  { xs: 0.1, sm: 0.2, md: 0.5, lg: 0.5, xl: 0.5 }, // mniejszy pasek
    pr: { xs: 0.1, sm: 0.2, md: 0.5, lg: 0.5, xl: 0.5  }, // miejsce na avatary
    pl: { xs: 0.1, sm: 0.2, md: 0.5, lg: 0.5, xl: 0.5  }, // miejsce na avatary

  [breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: "0",
    paddingBottom: "0",
  },
});

const navbarRow = ({ breakpoints }: any, { isMini }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",

  [breakpoints.up("md")]: {
    justifyContent: isMini ? "space-between" : "stretch",
    width: isMini ? "100%" : "max-content",
  },

  [breakpoints.up("xl")]: {
    justifyContent: "stretch !important",
    width: "max-content !important",
  },
});

const navbarIconButton = ({ typography: { size }, breakpoints }: any) => ({
  px: 0.75,

  /* MW: usunięte, aby nadpisać
  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },*/

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
  /* BUGFIX: Ikona nie widoczna na jasnym */
  backgroundColor: "rgba(220, 220, 220, 0.4)",
  borderRadius: "55%",
});

const navbarDesktopMenu = ({ breakpoints }: any) => ({
  display: "none !important",
  cursor: "pointer",

  [breakpoints.up("xl")]: {
    display: "inline-block !important",
  },
});

const navbarMobileMenu = ({ breakpoints }: any) => ({
  display: "inline-block",
  lineHeight: 0,

  [breakpoints.up("xl")]: {
    display: "none",
  },
});

export {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
};
